import React, { Component } from "react";

import "./CollectionCard.scss";

class CollectionCard extends Component {

  
  render() {
    return (
      <div className="collection-card py-4" >
        <a href="/product-display" className="product-img d-block ">
          <img src={this.props.productimg} alt="t-shirt-2" className="w-100" />
        </a>
        <div className="product-meta">
          <h5 className="mt-2">{this.props.productname}</h5>
          <h5 className="text-warning mt-3">{this.props.productprice}</h5>
        </div>
      </div>
    );
  }
}
export default CollectionCard;
