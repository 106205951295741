import { authConstants } from "../../_constants/";

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : { loggedIn: false, user: {} };

export const signup_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.SIGNUP_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.SIGNUP_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload,
            };
        case authConstants.SIGNUP_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
};

export const login_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.LOGIN_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.LOGIN_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload
            }
        case authConstants.LOGIN_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
};

export const google_auth_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.GOOGLE_AUTH_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.GOOGLE_AUTH_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload
            }
        case authConstants.GOOGLE_AUTH_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
};

export const facebook_auth_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.FACEBOOK_AUTH_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.FACEBOOK_AUTH_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload
            }
        case authConstants.FACEBOOK_AUTH_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
};

export const logout_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.LOGOUT_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.LOGOUT_USER_ACTION_SUCCESS:
            return {
                loggedOut: true,
                user: null
            }
        case authConstants.LOGOUT_USER_ACTION_FAILURE:
            return {
                type: 'alert-failure',
                message: action.payload
            }
        default:
            return state;
    }
};


export const reset_password_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.RESET_USER_PASSWORD_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.RESET_USER_PASSWORD_ACTION_SUCCESS:
            return {
                type: "alert-success",
                message: action.payload
            }
        case authConstants.RESET_USER_PASSWORD_ACTION_FAILURE:
            return {
                type: 'alert-failure',
                message: action.payload
            }
        default:
            return state;
    }
};

export const forgot_password_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.FORGOT_PASSWORD_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case authConstants.FORGOT_PASSWORD_ACTION_SUCCESS:
            return {
                type: "alert-success",
                message: action.payload
            }
        case authConstants.FORGOT_PASSWORD_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
};