import React, { useState } from "react";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import * as PaymentActions from "../../../../_actions/User/payment.action";
import "./CheckoutForm.scss";
import swal from "sweetalert";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import { withRouter } from "react-router-dom";

//auth.net
import { useAcceptJs } from "react-acceptjs";

const CheckoutForm = (props) => {
  // const stripe = useStripe();
  // const elements = useElements();


  const [cardData, setCardData] = React.useState({
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
  });

  const authData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_DOT_NET_API_LOGIN_ID,
    clientKey: process.env.REACT_APP_AUTHORIZE_DOT_NET_PUBLIC_CLIENT_KEY,
  };

  //auth.net
  const { dispatchData, error } = useAcceptJs({environment: "PRODUCTION" ,authData });

  const handleCheck = () => {
    setChecked(!checked);
  };
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const planType = JSON.parse(localStorage.getItem("plan"));
  const productDetail = JSON.parse(localStorage.getItem("productDetail"));

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setLoading(true);
    // if (!stripe || !elements) {
    //   // Stripe.js has not loaded yet. Make sure to disable
    //   // form submission until Stripe.js has loaded.
    //   return;
    // }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    // const cardElement = elements.getElement(CardElement);
    const userDetail = JSON.parse(localStorage.getItem("user"));
    //customer: userDetail && userDetail.user && userDetail.user.email
    // Use your card Element with other Stripe.js APIs
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    //   billing_details: {
    //     email: userDetail && userDetail.user && userDetail.user.email,
    //   },
    // });
    // const response = {};

    let response = await dispatchData({ cardData });

    let paymentMethod = {};
    response = await response;
    if (response?.opaqueData) {
      paymentMethod = response?.opaqueData;
    }

    if (paymentMethod?.dataValue) {
      const productDetail = JSON.parse(localStorage.getItem("productDetail"));
      const planType = JSON.parse(localStorage.getItem("plan"));

      if (planType && planType.plan == "per_product") {
        var payload = {
          payment_method_id: paymentMethod?.dataValue,
          product_type: productDetail && productDetail.content_type,
          product_id: productDetail && productDetail.id,
        };

        const response = await props.singleProductPayment(payload);
        setLoading(false);

        if (response) {
          /*           localStorage.removeItem("productDetail");
                      localStorage.removeItem("plan"); */
          props.history.goBack();
          swal(response && response.data && response.data.msg);
        }
      } else if (planType && planType.plan == "upgrade") {
        const response = await props.upgradePayement();
        setLoading(false);
        if (response) {
          let premiumLogin = localStorage.getItem("premiumLogin");
          let episodeId = localStorage.getItem("episodeId");
          let videoId = localStorage.getItem("videoId");
          if (premiumLogin && videoId) {
            props.history.push(`/video/${videoId}`);
          } else if (premiumLogin && episodeId) {
            props.history.push(`/watch-show/${videoId}`);
          } else {
            props.history.push("/user-plan-details");
          }

          swal(response && response.data && response.data.msg);
        }
      } else {
        var payload = {
          payment_method_id: paymentMethod?.dataValue,
          plan: planType && planType.plan,
          automatic: checked == true ? "on" : "off",
        };

        const response = await props.subscriptionProductPayment(payload);
        setLoading(false);

        if (response) {
          let premiumLogin = localStorage.getItem("premiumLogin");

          let episodeId = localStorage.getItem("episodeId");
          let videoId = localStorage.getItem("videoId");
          if (premiumLogin && videoId) {
            props.history.push(`/video/${videoId}`);
          } else if (premiumLogin && episodeId) {
            props.history.push(`/watch-show/${videoId}`);
          } else {
            props.history.push("/user-plan-details");
          }

          swal(response && response.data && response.data.msg);
        }
      }
    } else {
      swal("Error Processing Card");
      setLoading(false);
      return;
    }
  };

  const goBackHandler = () => {
    props.history.goBack();
  };

  return (
    <>
      <div className="container">
        <div
          onClick={goBackHandler}
          className="action d-flex align-items-center font-weight-bold cursor-pointer pt-3"
        >
          <img src="/assets/images/back-arrow.svg" alt="" />
          Back
        </div>
      </div>
      <div className="checkout-wrapper d-flex justify-content-center align-items-center">
        <div className="checkout-inner ">
          <div className="container px-0">
            <div className="row no-gutters bg-white">
              <div className="col-md-6">
                <div className="left">
                  <img
                    className="w-100"
                    src="../../../../assets/images/make-payment-img.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right py-5 px-lg-0 mx-auto">
                  <h4 className="plan-type text-uppercase text-primary text-center border-bottom  mb-5 pb-2">
                    {planType && planType.plan !== "per_product" ? (
                      <>{planType && planType.plan}</>
                    ) : (
                      <>
                        {productDetail
                          ? productDetail && productDetail.content_type
                          : null}
                      </>
                    )}
                  </h4>

                  <h3 className="text-primary mb-4 pb-md-3">
                    Add Your Card Details
                  </h3>
                  <form onSubmit={handleSubmit}>
                    {/* <CardElement className="mb-4" /> */}

                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            Card Number
                          </label>
                          <input
                            type="text"
                            name="cardNumber"
                            placeholder="Enter Card Number"
                            value={cardData.cardNumber}
                            onChange={(event) =>
                              setCardData({
                                ...cardData,
                                cardNumber: event.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            Expiry Date{" "}
                          </label>

                          <div className="d-flex">
                            <input
                              type="text"
                              name="month"
                              placeholder="MM"
                              value={cardData.month}
                              onChange={(event) =>
                                setCardData({
                                  ...cardData,
                                  month: event.target.value,
                                })
                              }
                              className="form-control"
                            />

                            <input
                              type="text"
                              name="year"
                              placeholder="YYYY"
                              value={cardData.year}
                              onChange={(event) =>
                                setCardData({
                                  ...cardData,
                                  year: event.target.value,
                                })
                              }
                              className="form-control ml-2"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-5">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            CVV
                          </label>

                          <input
                            type="text"
                            name="cardCode"
                            placeholder="cvv"
                            value={cardData.cardCode}
                            onChange={(event) =>
                              setCardData({
                                ...cardData,
                                cardCode: event.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {/* </form> */}

                    {planType.plan !== "upgrade" &&
                    planType.plan !== "per_product" ? (
                      <>
                        <label htmlFor="checkbox">
                          Apply automatic {planType && planType.plan} renewal
                          for this plan.
                        </label>
                      </>
                    ) : null}
                    <div className="text-center mt-4">
                      <div className=" rounded-pill  d-flex bg-grey-5 align-items-center ">
                        <button
                          type="submit"
                          className="btn btn-primary text-white py-sm-3 px-sm-5 flex-grow-1"
                          // disabled={!stripe}
                        >
                          {loading ? (
                            <ContentLoader color="text-white" />
                          ) : (
                            <span> Make Payment</span>
                          )}
                        </button>
                        <span className="d-inline-block text-primary font-weight-bold fs-24 py-sm-3 px-4">
                          ${planType && planType.amount}
                          {/* ${(planType && planType.amount) / 100} */}
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  singleProductPayment: (payload) =>
    dispatch(PaymentActions.single_product_payment_action(payload)),
  subscriptionProductPayment: (payload) =>
    dispatch(PaymentActions.subscription_payment_action(payload)),
  upgradePayement: () => dispatch(PaymentActions.upgrade_payment_action()),
});

const connectedCheckoutForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
export { connectedCheckoutForm as CheckoutForm };
