import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import "./FaqsTopic.scss";

import TextEditor from "../../../../Common/TextEditor/TextEditor";
import {
  delete_subTopic_action,
  fetch_faq_topic_details_action, new_faq_subtopic_addition_for_topic_action,
  update_faq_question_action,
  upload_faq_question_action
} from "../../../../../_actions/Admin";
import ContentLoader from "../../../../Common/Loader/ContentLoader";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import TopTab from '../../../common/TopTab/TopTab'
import config from "../../../../../Config/config";

const toolbarConfig = {
  options: ["inline", "list"],
  inline: {
    inDropdown: false,
    bold: { className: "bg-grey-7 px-2" },
    italic: { className: "bg-grey-7 px-2" },
    underline: { className: "bg-grey-7 px-2" },
    strikethrough: { className: "d-none" },
    monospace: { className: "d-none" },
    superscript: { className: "d-none" },
    subscript: { className: "d-none" },
  },

  list: {
    options: ["unordered", "ordered"],
    unordered: { className: "bg-grey-7 px-2" },
    ordered: { className: "bg-grey-7 px-2" },
  },
};

const initialState = {
  loading: false,
  pageLoading:false,
  submitTriggered: false,
  topicName: "",
  questions: [
    {
      id: -1,
      subTopic: "",
      answer: "",
    },
  ],
  uploadRes: '',
  isTopicSame: true,
  isSubTopicSame: true,
  isAnswerSame: true
};

class FaqsTopic extends Component {
  state = initialState

  componentDidMount() {
    this.setState(initialState)
    this.loadTopicDataIfAvailable()
  }

  loadTopicDataIfAvailable = async () => {
    const { topicId } = this.props.match.params
    if (topicId && topicId === 'new-topic'){
      this.setState({
        isTopicSame: false,
        isSubTopicSame: false,
        isAnswerSame: false
      })
      return
    }

    if (topicId && typeof (parseInt(topicId)) === "number") {
      this.setState({ pageLoading: true })
      const topicDetailsRes = await this.props.getTopicDetails(topicId)

      if (topicDetailsRes && topicDetailsRes.data) {
        let filteredSubTopicArray = []
        topicDetailsRes.data['subtopics'] &&
        topicDetailsRes.data['subtopics'].forEach(subTopic => {
          const tempObj = {}
          tempObj['id'] = subTopic.id
          tempObj['subTopic'] = subTopic['subtopic']
          tempObj['answer'] = subTopic['answer']
          filteredSubTopicArray.push(tempObj)
        })

        await this.setState({
          topicName: topicDetailsRes.data.topic,
          questions: filteredSubTopicArray
        })
      }
      this.setState({ pageLoading: false })
    }
  }

  handleChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value });

    if (this.props &&
        this.props.initialFaqData &&
        this.props.initialFaqData.data &&
        this.props.initialFaqData.data.data &&
        this.props.initialFaqData.data.data.topic
    )
    await this.setState({ isTopicSame: this.props.initialFaqData.data.data.topic === this.state.topicName })
  };

  handleAddMoreSubTopic = () => {
    this.setState((prevState) => ({ questions: [...prevState.questions, { subTopic: "", answer: "" }] }));
  };

  handleDeleteSubTopic = async (i, subTopicId) => {
    if (this.state.questions &&
        this.state.questions.length === 1
    ) return

    this.setState((prevState) => ({
      questions: prevState.questions.filter((question, index) => index !== i),
    }));

    await this.checkForChanges()
    if (subTopicId) {
      const deleteSubTopicRes = await this.props.deleteSubTopic(subTopicId)
      if (!deleteSubTopicRes)
        this.setState({ uploadRes: 'Subtopic Delete Unsuccessful' })
      else
        this.setState({ uploadRes: 'Subtopic Delete Successful' })
    }
  };


  handleUpdateSubTopic = async (e, i) => {
    const { value } = e.target;
    await this.setState((prevState) => ({
      questions: prevState.questions.map((question, index) =>
          index === i ? { ...question, subTopic: value } : question
      ),
    }), () => {
      if (this.props &&
          this.props.initialFaqData &&
          this.props.initialFaqData.data &&
          this.props.initialFaqData.data.data &&
          this.props.initialFaqData.data.data['subtopics']
      )
        this.checkForChanges()
    });
  };

  handleUpdateAnswer = async (data, i) => {
    await this.setState((prevState) => ({
      questions: prevState.questions.map((question, index) =>
          index === i ? { ...question, answer: data } : question
      ),
    }), () => {
      if (this.props &&
          this.props.initialFaqData &&
          this.props.initialFaqData.data &&
          this.props.initialFaqData.data.data &&
          this.props.initialFaqData.data.data['subtopics']
      )
        this.checkForChanges()
    });
  };

  checkForChanges = async () => {
    const { questions } = this.state
    const { subtopics } = this.props && this.props.initialFaqData && this.props.initialFaqData.data && this.props.initialFaqData.data.data
    let subTopicCheckArray = []
    let answerCheckArray = []

    if (subtopics && subtopics.length !== questions.length) {
      this.setState({ isSubTopicSame: false })
      return
    }
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].id) {
        this.setState({ isSubTopicSame: false })
        return
      }
      const index = subtopics.findIndex(subtopic => subtopic.id === questions[i].id)
      if (index !== -1) {
        subTopicCheckArray.push(subtopics[index].subtopic === questions[i].subTopic)
        answerCheckArray.push(subtopics[index].answer === questions[i].answer)
      }
    }

    if (subTopicCheckArray.indexOf(false) !== -1)
      this.setState({ isSubTopicSame: false })
    else
      this.setState({ isSubTopicSame: true })


    if (answerCheckArray.indexOf(false) !== -1)
      this.setState({ isAnswerSame: false })
    else
      this.setState({ isAnswerSame: true })
  }

  handleFaqSubmit = async () => {
    const { topicName, questions } = this.state;
    this.setState({ submitTriggered: true });
    if (this.state.questions[0].id !== -1)
      this.checkForChanges()

    let isSomeFieldEmpty = false;
    // validate if any of the fields is left empty.
    questions.forEach((question) => {
      const stringWithoutTags = question.answer.substring(3, question.answer.length - 4);
      if (!question.subTopic || !stringWithoutTags.length) {
        isSomeFieldEmpty = true;
        return;
      }
    });
    if (!topicName || isSomeFieldEmpty) return;

    this.setState(prevState => ({ loading: !prevState.loading }));

    const subTopicsArray = [], newSubtopicAdditionsArray= [];
    questions.forEach((question) => {
      let tempObj
      if (this.props.match.params['topicId'] === 'new-topic') {
        tempObj = {
          subtopic: question.subTopic,
          answer: question.answer,
        };
        subTopicsArray.push(tempObj);
      } else {
        const objectFromState = this.props &&
            this.props.initialFaqData &&
            this.props.initialFaqData.data &&
            this.props.initialFaqData.data.data &&
            this.props.initialFaqData.data.data['subtopics'] &&
            this.props.initialFaqData.data.data['subtopics'].filter(obj => obj.id === question.id)
        if (!objectFromState || !objectFromState[0]) {
          tempObj = {
            faq_topic: this.props.match.params['topicId'],
            subtopic: question.subTopic,
            answer: question.answer,
          };
          newSubtopicAdditionsArray.push(tempObj);
        } else if (objectFromState && objectFromState[0] && !(objectFromState[0].subtopic === question.subTopic && objectFromState[0].answer === question.answer)) {
          tempObj = {
            sub_topic_id: question.id,
            sub_topic_details: {
              faq_topic: this.props.match.params['topicId'],
              subtopic: question.subTopic,
              answer: question.answer,
            }
          }
          subTopicsArray.push(tempObj);
        }
      }
    });

    if (newSubtopicAdditionsArray.length !== 0) {
      const uploadFaqData = {
        data: newSubtopicAdditionsArray
      }
      const newSubTopicsAdditionRes = await this.props.newSubtopicAddition(uploadFaqData)
      if (!newSubTopicsAdditionRes)
        this.setState({ uploadRes: 'New Topic Addition Unsuccessful' })
    }
    let uploadRes;
    if (this.props.match.params['topicId'] === 'new-topic') {
      const uploadFaqData = {
        topic: this.state.topicName,
        subtopic: subTopicsArray
      };
      uploadRes = await this.props.uploadFaqQuestion(uploadFaqData);
    } else {
      let subTopicUpdateArrayApis = []
      for(let i = 0; i < subTopicsArray.length; i++) {
        subTopicUpdateArrayApis.push([
          `${config.content.updateSubTopic}${subTopicsArray[i]['sub_topic_id']}/`,
          subTopicsArray[i]['sub_topic_details']
        ])
      }
      if (this.props &&
          this.props.initialFaqData &&
          this.props.initialFaqData.data &&
          this.props.initialFaqData.data.data &&
          this.props.initialFaqData.data.data.topic
      ) {
        await this.setState({ isTopicSame: this.props.initialFaqData.data.data.topic === this.state.topicName })
      }
      const uploadFaqData = {
        topicUpdateObj: {
          topic: this.state.topicName
        },
        isTopicSame: this.state.isTopicSame,
        isSubTopicSame: this.state.isSubTopicSame,
        isAnswerSame: this.state.isAnswerSame,
        subTopicUpdateArrayApis,
      };
      uploadRes = await this.props.updateFaqQuestion(uploadFaqData, this.props.match.params['topicId'])
      await this.loadTopicDataIfAvailable()
    }

    if (uploadRes && !uploadRes.status) {
      this.setState({ uploadRes: uploadRes.msg })
    } else if (uploadRes && uploadRes.status) {
      await this.setState({ uploadRes: uploadRes.msg })
    }

    this.setState(prevState => ({ loading: !prevState.loading }))
    if (this.props.match.params['topicId'] === 'new-topic')
      this.props.history.push('/faqs-listing-management')
  };

  render() {
    const { pageLoading, uploadRes, submitTriggered, loading, topicName, questions } = this.state;
    return (
        <div className="content-management admin-home-dashboard common-dashboard">
          <Container className="p-0">
            <AdminSidebarMenu />
            <TopTab activeTab="FAQs" tabCategoryName="contentManagment" />
            <div className="main-content bg-white pt-5">
              <div className="faqs-topic  bg-grey-6 pt-md-4 mt-4 pl-3">
                <Container className="p-0">
                  <Row>
                    <Col sm={3}>
                      <p
                          onClick={() => this.props.history.push('/faqs-listing-management')}
                          className="back-action d-flex align-items-center font-weight-bold cursor-pointer"
                      >
                        <img src={"/assets/images/back-arrow.svg"} alt="Back" />
                        Back
                      </p>
                    </Col>
                    <Col sm={6}>
                      <div className="faqs-topic mb-4">
                        <h2 className=" mb-4">
                          {
                            this.props.match.params['topicId'] === 'new-topic' ?
                                'New Topic for FAQ'
                                : 'Edit Topic for FAQ'
                          }
                        </h2>
                        <div className="form-content position-relative pt-3">
                          {
                            pageLoading ?

                                  <div style={{ margin: "100px auto 0", textAlign: 'center' }}
                                  >
                                    <ContentLoader color='text-warning'/>
                                  </div>
                                : <Form>
                                  <Form.Group className="bg-grey-4 p-1 rounded">
                                    <Form.Control
                                        className="bg-transparent  border-0 fs-18 font-weight-bold"
                                        type="text"
                                        name="topicName"
                                        value={topicName}
                                        onChange={this.handleChange}
                                        placeholder="Topic"
                                    />
                                  </Form.Group>
                                  {!topicName && submitTriggered ? (
                                      <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                        Field required!
                                      </p>
                                  ) : null}
                                  {
                                    questions && questions.length
                                        ? questions.map((question, index) => (
                                            <div
                                                key={index}
                                                className="bg-grey-4  rounded p-3 mb-3"
                                            >
                                              <Form.Row className="mb-3 align-items-center">
                                                <Col xs={11}>
                                                  <Form.Group className="mb-0">
                                                    <Form.Control
                                                        className="bg-white font-weight-semi p-2 h-auto "
                                                        type="text"
                                                        defaultValue=""
                                                        name="subTopic"
                                                        value={question.subTopic}
                                                        onChange={(e) =>
                                                            this.handleUpdateSubTopic(e, index)
                                                        }
                                                        placeholder="Sub-Topic"
                                                    />
                                                  </Form.Group>
                                                </Col>
                                                {/* delete sub topic icon */}
                                                <Col
                                                    xs={1}
                                                    className="d-flex align-itmes-center"
                                                >
                                                  <a
                                                      onClick={() =>
                                                          this. handleDeleteSubTopic(index, question.id)
                                                      }
                                                  >
                                                    <img
                                                        src={
                                                          "/assets/images/delete-content.svg"
                                                        }
                                                        alt="Delete"
                                                        className="align-middle"
                                                    />
                                                  </a>
                                                </Col>
                                              </Form.Row>
                                              {!question.subTopic && submitTriggered ? (
                                                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                                    Field required!
                                                  </p>
                                              ) : null}
                                              <Form.Group>
                                                <TextEditor
                                                    htmlData={question.answer}
                                                    getHtmlData={(data) => this.handleUpdateAnswer(data, index)}
                                                    toolbarSetting={toolbarConfig}
                                                />
                                              </Form.Group>
                                              {(!question.answer ||
                                                  !question.answer.substring(
                                                      3,
                                                      question.answer.length - 4
                                                  )) &&
                                              submitTriggered ? (
                                                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                                    Field required!
                                                  </p>
                                              ) : null}
                                            </div>
                                        ))
                                        : null
                                  }
                                  {
                                    uploadRes ?
                                        uploadRes.toLowerCase().includes('unsuccessful')  ?
                                            (<p className="fs-14 text-danger font-source-sans text-left font-weight-semi">
                                              {uploadRes}
                                            </p>)
                                            : uploadRes.toLowerCase().includes('successful')  ?
                                            (<p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                                              {uploadRes}
                                            </p>)
                                            : (<p className="fs-14 text-info font-source-sans text-left font-weight-semi">
                                              {uploadRes}
                                            </p>)
                                        : null
                                  }
                                  <div className="button-group d-flex justify-content-end font-smallest font-weight-400 mt-3">
                              <span
                                  onClick={this.handleAddMoreSubTopic}
                                  className="d-flex fs-14 font-weight-bold align-items-center mr-auto cursor-pointer"
                              >
                                <img
                                    src={"/assets/images/add-more.svg"}
                                    alt="addmore icon"
                                    className="mr-2"
                                />
                                Add more
                              </span>
                                    <button
                                        className="btn btn-outline-dark rounded px-3 py-2  text-black"
                                        type="button"
                                        onClick={() => this.props.history.push('/faqs-listing-management')}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary rounded px-3 py-2 ml-2"
                                        type="button"
                                        onClick={this.handleFaqSubmit}
                                        disabled={this.state.isTopicSame && this.state.isSubTopicSame && this.state.isAnswerSame}
                                    >
                                      {

                                        loading === true ?
                                            <ContentLoader />
                                            : this.props.match.params['topicId'] === 'new-topic' ?
                                            "Upload"
                                            : "Save Changes"
                                      }
                                    </button>
                                  </div>
                                </Form>
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialFaqData: state.fetch_faq_topic_details_reducer
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadFaqQuestion: (data) => dispatch(upload_faq_question_action(data)),
  updateFaqQuestion: (data, topicId) => dispatch(update_faq_question_action(data, topicId)),
  getTopicDetails: (data) => dispatch(fetch_faq_topic_details_action(data)),
  newSubtopicAddition: (data) => dispatch(new_faq_subtopic_addition_for_topic_action(data)),
  deleteSubTopic: (data) => dispatch(delete_subTopic_action(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqsTopic);
