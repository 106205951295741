import React from "react";
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js/pure';
import { CheckoutForm } from "../CheckoutForm/CheckoutForm";

// const stripePromise = loadStripe(
//   //Config.stripe_payment_key
//   process.env.REACT_APP_STRIPE_KEY
// );

const Payment = () => (
  // <Elements stripe={stripePromise}>
  <div><CheckoutForm /></div>
  
  // </Elements>
);
export default Payment;
