import React, { Component } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  set_client_token_for_notification_action,
  signup_user_action,
} from "../../../_actions/Common";
import swal from "sweetalert";
import { validateEmail, validatePassword } from "../../../_helpers/validators";
import ContentLoader from "../Loader/ContentLoader";
import OneSignalReact from "react-onesignal";

class SignUp extends Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirm_password: "",
    isValidationError: "",
    isEmailValid: true,
    isPasswordValid: true,
    apiHit: false,
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ apiHit: true });
    const {
      email,
      firstName,
      lastName,
      password,
      confirm_password,
      isEmailValid,
      isPasswordValid,
    } = this.state;
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      password1: password,
      password2: confirm_password,
    };

    if (!email || !firstName || !lastName || !password || !confirm_password) {
      swal("All fields are required!");
      this.setState({ apiHit: false });
      return;
    }

    if (!isPasswordValid || password !== confirm_password) {
      const isError = !isPasswordValid
        ? "Your password must be 8 to 20 characters long with at least one numeric digit, one uppercase and one lowercase letter"
        : password !== confirm_password
        ? "Confirm Password should match password."
        : !isEmailValid
        ? "Email format not valid!"
        : "";
      swal(isError);
      this.setState({ apiHit: false });
      return;
    }

    if (isEmailValid) {
      const signupRes = await this.props.onSignUpUser(data);
      if (signupRes) {
        if (signupRes && signupRes.isEmailExists) {
          this.props.history.push("/login");
          this.setState({ apiHit: false });
          return;
        }

        if (signupRes && signupRes.user) {
          let userRole = signupRes.user.role;
          if (userRole === "user") {
            // hit the fcm token and redierct to home page
            let fcmToken;
          await  OneSignalReact.getUserId((id) => {
              fcmToken = id;
            });
            if (fcmToken) {
              const data = {
                registration_id: fcmToken,
                type: "web",
                device_id: fcmToken.substr(0, 150),
                active: true,
              };
              await this.props.setClientForNotification(data);
            }
            this.props.history.push("/user-home-page");
            return;
          }
        }
      }
    } else {
      swal("Please enter a valid email!");
    }
    this.setState({ apiHit: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "email") {
        this.setState({ isEmailValid: validateEmail(this.state.email) });
      }

      if (name === "password") {
        this.setState({
          isPasswordValid: validatePassword(this.state.password),
        });
      }
    });
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      password,
      confirm_password,
      isEmailValid,
      isPasswordValid,
      apiHit,
    } = this.state;
    return (
      <div className="common-login d-flex align-items-center jusfity-content-center">
        <Container className="p-0">
          <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="form-wraper d-flex  order-2 order-md-1  mb-4 mb-md-0 py-5">
              <form onSubmit={(e) => e.preventDefault()}>
                <h2 className="text-dark form-title text-center font-poppins mb-5">
                  Create using Email
                </h2>

                <div className="with-placeholder form-group">
                  <input className="form-control"
                    type="text"
                    placeholder=" "
                    name="firstName"
                    value={firstName}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text font-montserrat">
                    First Name
                  </span>
                </div>
                <div className="form-group with-placeholder">
                  <input className="form-control"
                    type="text"
                    placeholder=" "
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text font-montserrat">
                    Last Name
                  </span>
                </div>
                <div className="form-group with-placeholder">
                  <input
                    className={`form-control ${
                      email.length && !isEmailValid ? "input-invalid" : ""
                    }`}
                    type="email"
                    placeholder=" "
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text font-montserrat">
                    Email address
                  </span>
                </div>
                {email.length && !isEmailValid ? (
                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                    Email not valid!
                  </p>
                ) : null}
                <div className="form-group with-placeholder">
                  <input
                    className={`form-control ${
                      password.length && !isPasswordValid ? "input-invalid" : ""
                    }`}
                    type="password"
                    placeholder=" "
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text font-montserrat">
                    Password
                  </span>
                </div>
                {password.length && !isPasswordValid ? (
                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                    Your password must be 8 to 20 characters long with at least
                    one numeric digit, one uppercase and one lowercase letter.
                  </p>
                ) : null}
                <div className="form-group with-placeholder">
                  <input
                    className={`form-control ${
                      confirm_password !== password && confirm_password.length
                        ? "input-invalid"
                        : ""
                    }`}
                    type="password"
                    placeholder=" "
                    name="confirm_password"
                    value={confirm_password}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="placeholder-text font-montserrat">
                    Confirm Password
                  </span>
                </div>
                {confirm_password !== password && confirm_password.length ? (
                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                    Confirm Password should match password.
                  </p>
                ) : null}
                <p className="fs-14 font-source-sans text-center font-weight-semi my-3">
                  Read the
                  <a href="/terms-conditions" className="text-underline text-primary mx-1">
                    Terms and Conditions
                  </a>
                  and the
                  <a href="/privacy-policy" className="text-underline text-primary mx-1">
                    privacy policy
                  </a>
                  here
                </p>

                <div className="mb-3">
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="warning"
                    className="fs-18"
                    block
                  >
                    {apiHit ? (
                      <ContentLoader color="text-white" />
                    ) : (
                      <div>Signup</div>
                    )}
                  </Button>
                </div>
                <p className="fs-16 font-source-sans text-center font-weight-semi">
                  Already have an Account?{" "}
                  <a
                    href={"/login"}
                    className="fs-20 text-underline text-primary font-weight-bold"
                  >
                    Login
                  </a>{" "}
                </p>

                {/* error text  */}
                {/* <p className="fs-14 text-danger-2 font-source-sans text-center font-weight-semi">Email already regsitered ! <a href="#" className=" text-underline text-primary">Sign In </a> </p> */}
              </form>
            </div>
            <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
              <img src={"/assets/images/bg-signup-email.svg"} alt="login bg" />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSignUpUser: (data) => dispatch(signup_user_action(data)),
  setClientForNotification: (data) =>
    dispatch(set_client_token_for_notification_action(data)),
});

export default connect(null, mapDispatchToProps)(SignUp);
