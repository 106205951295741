import swal from 'sweetalert'
import { authConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// SIGN UP USER ACTION
export const signup_user_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.SIGNUP_USER_ACTION_REQUEST
    });
    try {
        const response = await axiosInstance.post(config.auth.signup, data);
        if (response.status) {
            localStorage.setItem('user', JSON.stringify(response.data));
            dispatch({
                type: authConstants.SIGNUP_USER_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.SIGNUP_USER_ACTION_FAILURE,
            payload: e.msg
        });

        return e.msg.toLowerCase().includes("already registered") ? { isEmailExists: true, msg: e.msg } : { isEmailExists: false, msg: e.msg }
    }
};


// LOGIN USER ACTION
export const login_user_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.SIGNUP_USER_ACTION_REQUEST
    });
    try {
        const response = await axiosInstance.post(config.auth.login, data);
        if (response.status) {
            localStorage.setItem('user', JSON.stringify(response.data));
            dispatch({
                type: authConstants.LOGIN_USER_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.LOGIN_USER_ACTION_FAILURE,
            payload: e.msg
        });
    }
};

// GOOGLE LOGIN USER ACTION
export const google_login_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.GOOGLE_AUTH_USER_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.auth.googleAuth, data)
        if (response.status) {
            localStorage.setItem("user", JSON.stringify(response.data))
            dispatch({
                type: authConstants.GOOGLE_AUTH_USER_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.GOOGLE_AUTH_USER_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// FACEBOOK LOGIN USER ACTION
export const facebook_login_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.FACEBOOK_AUTH_USER_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.auth.facebookAuth, data)
        if (response.status) {
            localStorage.setItem("user", JSON.stringify(response.data))
            dispatch({
                type: authConstants.FACEBOOK_AUTH_USER_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.FACEBOOK_AUTH_USER_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


// USER LOGOUT
export const logout_user_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.LOGOUT_USER_ACTION_REQUEST
    })
    try {
        // calling backend api for logout
        const response = await axiosInstance.post(config.auth.logout, data)

        if (response.status) {
            // removing user from localstorage if response status is 200
            localStorage.removeItem("user");
            localStorage.clear("videoId");
            localStorage.clear("recipeId");
            localStorage.clear("episodeId");
            dispatch({
                type: authConstants.LOGOUT_USER_ACTION_SUCCESS // updating user object to null after logout
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.LOGOUT_USER_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// FORGOT PASSWORD ACTION
export const forgot_password_user_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.FORGOT_PASSWORD_ACTION_REQUEST
    });
    try {
        const response = await axiosInstance.post(config.auth.forgotPassword, data)
        if (response.status) {
            dispatch({
                type: authConstants.LOGIN_USER_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.FORGOT_PASSWORD_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


// RESET PASSWORD USER ACTION
export const reset_user_password_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.RESET_USER_PASSWORD_ACTION_REQUEST
    });
    try {
        // @todo hit the reset password api with the required data and then process accordingly
        const response = await axiosInstance.post(`${config.auth.resetPassword}${data.uid}/${data.token}/`, data);
        if (response.status === 200) {
            if (response.data.detail.toLowerCase().includes('has been reset')) {
                await dispatch({
                    type: authConstants.RESET_USER_PASSWORD_ACTION_SUCCESS,
                    payload: response.data,
                });
                return response.data;
            } else return false
        } else {
            return false;
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.RESET_USER_PASSWORD_ACTION_FAILURE,
            payload: e.msg
        });
    }
};


export const set_client_token_for_notification_action = (data) => async (dispatch) => {
    dispatch({
        type: authConstants.SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_REQUEST
    });
    try {
        const response = await axiosInstance.post(config.auth.handleFCMToken, data);
        if (response.status === 200) {
            await dispatch({
                type: authConstants.SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_SUCCESS,
                payload: response && response.data && response.data,
            });
            return response.data.data;
        } else {
            return false;
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: authConstants.SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_FAILURE,
            payload: e.msg
        });
    }
}

