import React, { Component } from "react";
import { Container, Tabs, Tab, Row, Col, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import ContentBox from "../common/ContentBox/ContentBox";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import "../UserPosts/UserPosts.scss";
import {
  get_all_user_posts_action,
  hit_like_action,
  delete_post_by_id_action,
  report_item_action
} from "../../../_actions/User/post.action";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import ContentLoader from "../../Common/Loader/ContentLoader";
import swal from "sweetalert";

class UserPosts extends Component {
  state = {
    postsLoading: false,
    posts: [],
    statusPosts: [],
    photoPosts: [],
    videoPosts: [],
    recipePosts: [],
    reportReason: null
  };

  // routeHandler = (id, type) => {
  //   if (type === "status") this.props.history.push(`/post-status/${id}`);
  //   else this.props.history.push(`/view-post/${id}`);
  // };
  routeHandler = (id, type) => {
    if (type === "status") {
      this.props.history.push({
        pathname: `/post-status/${id}`,
        state: { backPageUrl: "chatboard" }
      });
    } else {
      this.props.history.push({
        pathname: `/view-post/${id}`,
        state: { backPageUrl: "chatboard" }
      });
    }
  };

  componentDidMount() {
    this.mountPostsData();
  }

  mountPostsData = async () => {
    this.setState({ postsLoading: true });

    const postsDataRes = await this.props.getAllUserPosts();
    if (postsDataRes && postsDataRes.data) {
      await this.setState({
        posts: postsDataRes.data,
        statusPosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "status"
        ),
        photoPosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "photo"
        ),
        videoPosts: postsDataRes.data.filter(
          (post) =>
            post["post_type"] === "video" && post["video_playback_id"] !== null
        ),
        recipePosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "recipe"
        )
      });
    }

    this.setState({ postsLoading: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title)
    });
  };

  likePost = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id
    };
    await this.props.hitLike(data);
    this.mountPostsData();
  };

  reportPost = async (id, type) => {
    let data = {
      post: id,
      report_type: this.state.reportReason
    };
    const res = await this.props.reportItem(data);
    swal(res && res.msg);
  };
  reportReason = (reason) => {
    this.setState({ reportReason: reason });
  };

  deletePost = async (id, type) => {
    if (!id) return;
    let redirectRoute = "";
    switch (type) {
      case "photo":
        redirectRoute = "Photos";
        break;
      case "video":
        redirectRoute = "Videos";
        break;
      case "recipe":
        redirectRoute = "Recipe";
        break;
      default:
        redirectRoute = "Status";
    }
    await this.props.deletePostById(id);
    swal("", "Post Deleted Successfully!", "success");
    this.mountPostsData();
    this.props.history.push(`/chatboard/${redirectRoute}`);
  };

  render() {
    const {
      postsLoading,
      posts,
      photoPosts,
      videoPosts,
      statusPosts,
      recipePosts
    } = this.state;
    const currentUserId = JSON.parse(localStorage.getItem("user")).user.id;
    return (
      <div className="common-dashboard UserPosts-wrapper">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />

          <div className="main-content bg-white">
            <Header activeMenuItem="community" />
            <div className="UserPosts-tabs pl-3">
              <Tabs
                className="comments-recipe-tab"
                defaultActiveKey={this.props.match.params.postType || "Photos"}
                id="uncontrolled-tab-example"
              >

                {/* photos tab */}
                <Tab eventKey="Photos" title="Photos" className="ml-n3">

                  <Row className="mt-5 p-0">
                    {postsLoading ? (
                      <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center"
                        }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : photoPosts && photoPosts.length > 0 ? (
                      photoPosts.map((post) => (
                        <Col key={post.id} md={4} className="mb-5">
                          <ContentBox
                            post={post}
                            likesArray={post["likes"]}
                            likesCount={post["likes_count"]}
                            bookmarkArray={post["bookmarks"]}
                            redirect={() =>
                              this.routeHandler(post.id, post["post_type"])
                            }
                            userimg={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["profile_image"]
                            }
                            username={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["first_name"]
                            }
                            thumbnail={post && post["image"]}
                            commentsCount={
                              `${post && post["comments_count"]}` + " " +
                              `${post && post["comments_count"] && post["comments_count"] > 1 ? "comments" : "comment"}`
                            }
                            // report="Report"
                            statusText={post && post["status_text"]}
                            history={this.props.history}
                            delete={
                              post["user_details"].id === currentUserId
                                ? "Delete"
                                : null
                            }
                            deletePost={() =>
                              this.deletePost(post.id, post["post_type"])
                            }
                            edit={
                              post["user_details"].id === currentUserId
                                ? "Edit"
                                : null
                            }
                            editPost={() =>
                              this.props.history.push(`/add-post/${post.id}`)
                            }
                            report={
                              post["user_details"].id !== currentUserId
                                ? "Report"
                                : null
                            }
                            reportPost={() =>
                              this.reportPost(post.id, post["post_type"])
                            }
                            reportReason={this.reportReason}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col>
                        <div className="text-center p-2 mt-4 mt-lg-5">
                          <img src="/assets/images/no-photos.png" alt="" />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Tab>
                {/* video tab */}
                {/* <Tab eventKey="Videos" title="Videos" className="ml-n3">
                  <Row className="mt-5 p-0">
                    {postsLoading ? (
                      <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center"
                        }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : videoPosts && videoPosts.length > 0 ? (
                      videoPosts.map((post) => (
                        <Col key={post.id} md={4} className="mb-5">
                          <ContentBox
                            post={post}
                            likesArray={post["likes"]}
                            bookmarkArray={post["bookmarks"]}
                            redirect={() =>
                              this.routeHandler(post.id, post["post_type"])
                            }
                            userimg={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["profile_image"]
                            }
                            username={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["first_name"]
                            }
                            thumbnail={post && post["image"]}
                            commentsCount={
                              `${post && post["comments_count"]}` +  " " +
                              `${post && post["comments_count"] && post["comments_count"] > 1 ? "comments" : "comment"}`
                            }
                            videoId={post && post["video_playback_id"]}
                            statusText={post && post["status_text"]}
                            history={this.props.history}
                            delete={
                              post["user_details"].id === currentUserId
                                ? "Delete"
                                : null
                            }
                            deletePost={() =>
                              this.deletePost(post.id, post["post_type"])
                            }
                            edit={
                              post["user_details"].id === currentUserId
                                ? "Edit"
                                : null
                            }
                            editPost={() =>
                              this.props.history.push(`/add-post/${post.id}`)
                            }
                            report={
                              post["user_details"].id !== currentUserId
                                ? "Report"
                                : null
                            }
                            reportPost={() =>
                              this.reportPost(post.id, post["post_type"])
                            }
                            reportReason={this.reportReason}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col>
                        <div className="text-center p-2 mt-4 mt-lg-5">
                          <img src="/assets/images/no-videos.png" alt="" />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Tab> */}
                {/* Recipe tab */}
                <Tab eventKey="Recipe" title="Recipe" className="ml-n3">
                  <Row className="mt-5 p-0">
                    {postsLoading ? (
                      <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center"
                        }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : recipePosts && recipePosts.length > 0 ? (
                      recipePosts.map((post) => (
                        <Col key={post.id} md={4} className="mb-5">
                          <ContentBox
                            post={post}
                            likesArray={post["likes"]}
                            bookmarkArray={post["bookmarks"]}
                            redirect={() =>
                              this.routeHandler(post.id, post["post_type"])
                            }
                            userimg={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["profile_image"]
                            }
                            username={
                              post &&
                              post["user_details"] &&
                              post["user_details"]["first_name"]
                            }
                            thumbnail={post && post["image"]}
                            commentsCount={
                              `${post && post["comments_count"]}` + " " +
                              `${post && post["comments_count"] && post["comments_count"] > 1 ? "comments" : "comment"}`
                            }
                            statusText={post && post["status_text"]}
                            history={this.props.history}
                            delete={
                              post["user_details"].id === currentUserId
                                ? "Delete"
                                : null
                            }
                            deletePost={() =>
                              this.deletePost(post.id, post["post_type"])
                            }
                            edit={
                              post["user_details"].id === currentUserId
                                ? "Edit"
                                : null
                            }
                            editPost={() =>
                              this.props.history.push(`/add-post/${post.id}`)
                            }
                            report={
                              post["user_details"].id !== currentUserId
                                ? "Report"
                                : null
                            }
                            reportPost={() =>
                              this.reportPost(post.id, post["post_type"])
                            }
                            reportReason={this.reportReason}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col>
                        <div className="text-center p-2 mt-4 mt-lg-5">
                          <img src="/assets/images/no-recepies.png" alt="" />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Tab>
                {/* status */}
                <Tab eventKey="Status" title="Status" className="ml-n3">
                  <Row className="mt-5 p-0">
                    {postsLoading ? (
                      <div
                        style={{
                          margin: "50px auto 0",
                          textAlign: "center"
                        }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : statusPosts && statusPosts.length > 0 ? (
                      statusPosts.map((post) => (
                        <Col key={post.id} md={6} className="mb-5 ">
                          <div className="chatboard-status bg-grey border-radius-10 p-4">
                            <ContentBox
                              post={post}
                              likesArray={post["likes"]}
                              bookmarkArray={post["bookmarks"]}
                              redirect={() =>
                                this.routeHandler(post.id, post["post_type"])
                              }
                              userimg={
                                post &&
                                post["user_details"] &&
                                post["user_details"]["profile_image"]
                              }
                              username={
                                post &&
                                post["user_details"] &&
                                post["user_details"]["first_name"]
                              }
                              statusText={post && post["status_text"]}
                              commentsCount={
                                `${post && post["comments_count"]}` + " " +
                                `${post && post["comments_count"] && post["comments_count"] > 1 ? "comments" : "comment"}`
                              }
                              delete={
                                post["user_details"].id === currentUserId
                                  ? "Delete"
                                  : null
                              }
                              deletePost={() =>
                                this.deletePost(post.id, post["post_type"])
                              }
                              edit={
                                post["user_details"].id === currentUserId
                                  ? "Edit"
                                  : null
                              }
                              editPost={() =>
                                this.props.history.push(`/add-post/${post.id}`)
                              }
                              report={
                                post["user_details"].id !== currentUserId
                                  ? "Report"
                                  : null
                              }
                              reportPost={() =>
                                this.reportPost(post.id, post["post_type"])
                              }
                              reportReason={this.reportReason}
                            />
                          </div>
                        </Col>
                      ))
                    ) : (
                      <Col>
                        <div className="text-center p-2 mt-4 mt-lg-5">
                          <img src="/assets/images/no-status.png" alt="" />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserPosts: () => dispatch(get_all_user_posts_action()),
  hitLike: (data) => dispatch(hit_like_action(data)),
  deletePostById: (id) => dispatch(delete_post_by_id_action(id)),
  reportItem: (data) => dispatch(report_item_action(data))
});
export default connect(null, mapDispatchToProps)(UserPosts);
