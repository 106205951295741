import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import AdminSidebarMenu from '../common/SidebarMenu/AdminSidebarMenu';
import './LiveStreamHome.scss';
class LiveStreamHome extends Component {
    render() {
        return (
            <div className="content-management admin-home-dashboard common-dashboard">
                <Container className="p-0">
                    <AdminSidebarMenu />
                    <div className="live-stream-home-content main-content bg-white pt-5 fs-18" >
                        <p className="text-dark mt-md-5 mb-5 fs-18 font-weight-bold ">Last live stream on :  11-02-11 16:47:35,985 +0000</p>
                        <div className="Viewers-and-comment mb-5 pt-md-4">
                            <div className="view-stats-list pr-5 pt-md-4">
                                <div className="row">
                                    <div className="col-lg-5 mb-5 mb-lg-0">
                                        <div className="view-stat">
                                            <h1 className="fs-64 text-warning d-flex mb-4"><span className="mr-5">20000</span> <img src="assets/images/viewers-img.svg" alt="" /></h1>
                                            <p className="fs-24 text-grey-5 font-weight-bold">Viewers joined your stream</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="view-stat">
                                            <h1 className="fs-64 text-warning d-flex mb-4"><span className="mr-5 ">1800</span> <img src="assets/images/comment-icon.svg" alt="" /></h1>
                                            <p className="fs-24 text-grey-5 font-weight-bold">Comments</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="live-action d-flex flex-wrap align-items-center pt-md-5">
                            <button className="btn btn-primary rounded py-3 px-5 mr-5">Go Live</button>

                            <div className="custom-control my-4 custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="Notification" name="example1" />
                                <label className="custom-control-label fs-14 font-weight-semi" htmlFor="Notification">Send Notifications to users when you go live</label>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
export default LiveStreamHome;