import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import HelpAccordion from "./HelpAccordion/HelpAccordion";
import HelpTabs from "./HelpTabs/HelpTabs";
import "./Help.scss";
import { connect } from "react-redux";
import { get_faq_content_action } from "../../../_actions/User";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
    };
  }

  componentWillMount = () => {
    this.props.geFAQContent();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.geFAQContentData !== this.props.geFAQContentData) {
      this.setState({
        content: nextProps.geFAQContentData,
      });
    }
  };
  render() {
    return (
      <div className="common-dashboard help-wrapper">
        <Container className="p-0">
          <SidebarMenu />

          <div className="main-content bg-white">
            <Header />
            {/* help solutions starts here */}
            <div className="help-faq-wrapper">
              <a href="/user-profile" className="d-flex align-items-center">
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <h2 className="text-primary mt-5">FAQs</h2>

              {/* tabs for desktop */}
              <div className="faq-tabs d-none d-md-block">
                <HelpTabs data={this.state && this.state.content} />
              </div>

              {/* accordion for mobile */}
              <div className="faq-accordion d-block d-md-none">
                <HelpAccordion data={this.state && this.state.content} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geFAQContentData: state.get_faq_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  geFAQContent: () => dispatch(get_faq_content_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
