import React, { Component } from "react";
import {
  Container,
  FormControl,
  Table,
  Button,
  Modal,
  FormGroup,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import ReactHtmlparser from "react-html-parser";

import DateRange from "../../../Common/DatePicker/DateRange";
import TextEditor from "../../../Common/TextEditor/TextEditor";
import "./Announcements.scss";
import TopTab from "../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import {
  add_new_announcement_action,
  delete_announcement_action,
  get_announcement_table_list_action,
  get_announcement_search_table_list_action,
  get_single_announcement_details_action,
  update_announcement_details_action,
} from "../../../../_actions/Admin";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import moment from "moment";
import { Pagination } from "antd";
import { debounce } from "../../../../_helpers/debounce";
const toolbarConfig = {
  options: ["inline", "colorPicker"],
  inline: {
    inDropdown: false,
    bold: { className: "bg-grey-7 px-2" },
    italic: { className: "bg-grey-7 px-2" },
    underline: { className: "bg-grey-7 px-2" },
    strikethrough: { className: "d-none" },
    monospace: { className: "d-none" },
    superscript: { className: "d-none" },
    subscript: { className: "d-none" },
  },
  colorPicker: {
    colors: [
      "rgba(36, 170, 225, 1)",
      "rgba(135, 68, 154, 1)",
      "rgba(255, 164, 0, 1)",
    ],
    className: "bg-grey-7 px-2",
  },
};

class Announcements extends Component {

  constructor(props) {
    super(props)

    this.search = debounce(this.loadAnnouncementData)
  }
  state = {
    deleteActionModal: false,
    announcementLoading: false,
    announcementTableData: [],
    filteredTableData: [],
    displayAddNewAnnouncementSection: false,
    searchString: "",
    deleteAnnouncementId: null,
    deleteLoading: false,
    itemDeleted: false,
    announcementToEditDetails: {
      editorHtmlText: "",
      startDate: null,
      endDate: null,
    },
    addAnnouncementClicked: false,
    errorText: "",
    successText: "",
    addAnnouncementLoading: false,
    mountAnnouncementForEditLoading: false,
    editingAnnouncement: false,
    dataBeforeEdit: null,
    isDataSame: true,
    // entriesPerPage: 25,
    // currentPageNumber: 1,
    // maxPages: 1,
    // prevAnnouncementsLink: null,
    // nextAnnouncementsLink: null
    pagination: {
      next: "",
      total: 1,
      current: 1,
      pageSize: 25,
      previous: "",
    },
    promiseStack: [],
    searchText: null, // stack of chars
  };

  componentDidMount() {
    this.loadAnnouncementData();
  }


  checkSearch = () => {
    let val = this.state.promiseStack.filter(
      (item) => item.searchKey === this.state.searchString
    );

    if (val && val.length) {
      const loadAnnouncementDataRes = val[0].response;
      this.setState((prevState) => ({
        announcementTableData:
          loadAnnouncementDataRes.response.data.results,
        filteredTableData:
          loadAnnouncementDataRes.response.data.results,
        pagination: {
          ...prevState.pagination,
          total: loadAnnouncementDataRes.response.data.count === 0 ? 1 : loadAnnouncementDataRes.response.data.count,
          next: loadAnnouncementDataRes.response.data.next,
          previous: loadAnnouncementDataRes.response.data.previous,
        },
      }));
    }
  };

  loadAnnouncementData = async () => {
    this.setState({ announcementLoading: true });
    const { searchString } = this.state;
    let loadAnnouncementDataRes = {};
    if (searchString && searchString !== "") {
      let data = { search_announcement: searchString };
      loadAnnouncementDataRes = await this.props.getAnnouncementSearchData(
        "admin",
        this.state.pagination.current,
        data
      );
    } else {
      loadAnnouncementDataRes = await this.props.getAnnouncementData(
        "admin",
        this.state.pagination.current
      );
    }
    if (
      loadAnnouncementDataRes &&
      loadAnnouncementDataRes.type &&
      loadAnnouncementDataRes.type === "admin"
    ) {
      if (
        loadAnnouncementDataRes &&
        loadAnnouncementDataRes.response &&
        loadAnnouncementDataRes.response.data &&
        loadAnnouncementDataRes.response.data.results
      ) {
        let newStack = this.state.promiseStack;
        newStack.push({
          searchKey: searchString,
          response: loadAnnouncementDataRes,
        });

        this.setState({ promiseStack: newStack }, () => {
          this.checkSearch();
        });
      } else if (
        loadAnnouncementDataRes &&
        loadAnnouncementDataRes.response &&
        loadAnnouncementDataRes.response.msg
      ) {
        swal(loadAnnouncementDataRes.response.msg);
      }
    }

    this.setState({ announcementLoading: false });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({ searchString: e.target.value }, () => {
      this.search();
    });
  };

  // searchAnnouncement = () => {
  //   const { searchString, announcementTableData } = this.state;
  //   const filterBy = (stringPassed) => {
  //     const lowerCaseStringValue = stringPassed.toLowerCase();
  //     return (object) =>
  //       Object.keys(object) &&
  //       Object.keys(object).some(() => {
  //         return (
  //           object.content.toLowerCase().indexOf(lowerCaseStringValue) !== -1
  //         );
  //       });
  //   };
  //   const searchedAnnouncements = announcementTableData.filter(
  //     filterBy(searchString)
  //   );
  //   this.setState({ filteredTableData: searchedAnnouncements });
  // };

  deleteActionModalHandler = (id) => {
    this.setState(
      (prevState) => ({
        deleteActionModal: !prevState.deleteActionModal,
      }),
      () => {
        if (this.state.deleteActionModal)
          this.setState({ deleteAnnouncementId: id });
        else this.setState({ deleteAnnouncementId: null });
      }
    );
  };

  handleAnnouncementDelete = async () => {
    const { deleteAnnouncementId, dataBeforeEdit } = this.state;
    if (!deleteAnnouncementId) return;

    this.setState({ deleteLoading: true });

    const deleteRes = await this.props.deleteAnnouncement(deleteAnnouncementId);
    if (dataBeforeEdit && dataBeforeEdit.id === deleteAnnouncementId) {
      await this.addNewAnnouncement();
      this.setState({ displayAddNewAnnouncementSection: false });
    }

    if (deleteRes && deleteRes.status) {
      this.setState({ itemDeleted: true, deleteAnnouncementId: null }, () => {
        this.loadAnnouncementData();
        this.deleteActionModalHandler();
        setTimeout(() => {
          this.setState({ itemDeleted: false });
        }, 5000);
      });
    }

    this.setState({ deleteLoading: false });
  };

  handleUpdateAnnouncementContent = async (htmlData) => {
    this.setState(
      {
        announcementToEditDetails: {
          ...this.state.announcementToEditDetails,
          editorHtmlText: htmlData,
        },
      },
      () => {
        this.checkForChanges();
      }
    );
  };

  updateDate = async (data) => {
    if (data && data.startValue) {
      await this.setState(
        {
          announcementToEditDetails: {
            ...this.state.announcementToEditDetails,
            startDate: data.startValue["_d"].toLocaleDateString("en-029"),
          },
        },
        () => {
          this.checkForChanges();
        }
      );
    }

    if (data && data.endValue) {
      await this.setState(
        {
          announcementToEditDetails: {
            ...this.state.announcementToEditDetails,
            endDate: data.endValue["_d"].toLocaleDateString("en-029"),
          },
        },
        () => {
          this.checkForChanges();
        }
      );
    }
  };

  checkForChanges = () => {
    const { editingAnnouncement, announcementToEditDetails, dataBeforeEdit } =
      this.state;
    if (!editingAnnouncement) return;

    if (editingAnnouncement) {
      const checkForChangesArray = [];
      if (dataBeforeEdit !== null) {
        checkForChangesArray.push(
          dataBeforeEdit.content &&
          dataBeforeEdit.content === announcementToEditDetails.editorHtmlText
        );
        checkForChangesArray.push(
          dataBeforeEdit.end_date &&
          dataBeforeEdit.end_date ===
          new Date(announcementToEditDetails.endDate)
            .toLocaleDateString("en-ZA")
            .toString()
            .replaceAll("/", "-")
        );
        checkForChangesArray.push(
          dataBeforeEdit.start_date &&
          dataBeforeEdit.start_date ===
          new Date(announcementToEditDetails.startDate)
            .toLocaleDateString("en-ZA")
            .toString()
            .replaceAll("/", "-")
        );
      }

      if (checkForChangesArray.includes(false))
        this.setState({ isDataSame: false });
      else this.setState({ isDataSame: true });
    }
  };

  handleAnnouncementSubmit = async () => {
    const { announcementToEditDetails } = this.state;
    this.setState({ addAnnouncementClicked: true });
    if (
      announcementToEditDetails.editorHtmlText === "" ||
      announcementToEditDetails.editorHtmlText === "<p></p>" ||
      announcementToEditDetails.endDate === null ||
      announcementToEditDetails.startDate === null
    )
      return;

    this.setState({ addAnnouncementLoading: true });

    const postData = {
      content: announcementToEditDetails.editorHtmlText,
      start_date:
        new Date(announcementToEditDetails.startDate)
          .toLocaleDateString("en-ZA")
          .replaceAll("/", "-") + "T00:00:00",
      end_date:
        new Date(announcementToEditDetails.endDate)
          .toLocaleDateString("en-ZA")
          .replaceAll("/", "-") + "T00:00:00",
    };

    const addAnnouncementRes = await this.props.addNewAnnouncement(postData);

    this.setState({ displayAddNewAnnouncementSection: false });
    if (addAnnouncementRes) {
      if (addAnnouncementRes.status) {
        if (addAnnouncementRes.msg)
          this.setState({ successText: addAnnouncementRes.msg });
      } else if (addAnnouncementRes.msg) {
        this.setState({ errorText: addAnnouncementRes.msg });
      }
    }
    setTimeout(() => {
      this.setState({ successText: "", errorText: "" });
    }, 5000);
    this.setState({ addAnnouncementLoading: false });
    await this.loadAnnouncementData();
  };

  mountAnnouncementDataForEditing = async (announcementId) => {
    window.scrollTo(0, 0);
    if (announcementId) {
      this.setState({
        mountAnnouncementForEditLoading: true,
        displayAddNewAnnouncementSection: true,
      });

      const getAnnouncementDetailsRes =
        await this.props.getSingleAnnouncementDetails(announcementId);
      if (getAnnouncementDetailsRes) {
        this.setState({
          announcementToEditDetails: {
            ...this.state.announcementToEditDetails,
            editorHtmlText: getAnnouncementDetailsRes["content"],
            startDate: getAnnouncementDetailsRes["start_date"],
            endDate: getAnnouncementDetailsRes["end_date"],
          },
          editingAnnouncement: true,
          dataBeforeEdit: getAnnouncementDetailsRes,
        });
      } else {
        this.setState({ errorText: "Unable to find Announcement details!" });
      }
      setTimeout(() => {
        this.setState({ errorText: "" });
      }, 4000);

      this.setState({ mountAnnouncementForEditLoading: false });
    }
  };

  updateAnnouncementDetails = async () => {
    const { announcementToEditDetails, dataBeforeEdit } = this.state;
    this.setState({ addAnnouncementClicked: true });

    if (
      announcementToEditDetails.editorHtmlText === "" ||
      announcementToEditDetails.editorHtmlText === "<p></p>" ||
      announcementToEditDetails.endDate === null ||
      announcementToEditDetails.startDate === null
    )
      return;

    this.setState({ addAnnouncementLoading: true });

    const data = {
      announcementId: dataBeforeEdit.id,
      postData: {
        content: announcementToEditDetails.editorHtmlText,
        start_date:
          new Date(announcementToEditDetails.startDate)
            .toLocaleDateString("en-ZA")
            .replaceAll("/", "-") + "T00:00:00",
        end_date:
          new Date(announcementToEditDetails.endDate)
            .toLocaleDateString("en-ZA")
            .replaceAll("/", "-") + "T00:00:00",
      },
    };
    const updateAnnouncementRes = await this.props.updateAnnouncement(data);
    this.setState({ displayAddNewAnnouncementSection: false });
    if (updateAnnouncementRes && updateAnnouncementRes.msg)
      this.setState({ successText: updateAnnouncementRes.msg });
    else this.setState({ errorText: "Update Unsuccessful!" });

    setTimeout(() => {
      this.setState({ successText: "", errorText: "" });
    }, 5000);

    this.setState({ addAnnouncementLoading: false });
    await this.loadAnnouncementData();
  };

  addNewAnnouncement = async () => {
    await this.setState({ mountAnnouncementForEditLoading: true });

    await this.setState((prevState) => ({
      displayAddNewAnnouncementSection: false,
      dataBeforeEdit: null,
      editingAnnouncement: false,
      isDataSame: true,
      announcementToEditDetails: {
        ...this.state.announcementToEditDetails,
        editorHtmlText: "",
        startDate: null,
        endDate: null,
      },
    }));

    await this.setState({
      mountAnnouncementForEditLoading: false,
      displayAddNewAnnouncementSection: true,
    });
  };

  handlePagination = async (page) => {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, current: page },
      }),
      async () => {
        await this.loadAnnouncementData();
      }
    );
  };
  render() {
    const {
      itemDeleted,
      deleteLoading,
      searchString,
      filteredTableData,
      displayAddNewAnnouncementSection,
      announcementLoading,
      announcementToEditDetails,
      addAnnouncementClicked,
      addAnnouncementLoading,
      successText,
      mountAnnouncementForEditLoading,
      editingAnnouncement,
      isDataSame,

      pagination,
    } = this.state;

    let announcements = filteredTableData;

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu activeTab="Announcement" />
          <TopTab activeTab="Announcement" tabCategoryName="contentManagment" />
          <div className="main-content  bg-white pt-5">
            <div className="user-data announcements-wrapper bg-grey-6  px-lg-3">
              <div className="upload-content-wrapper bg-white">
                <h2 className="fs-34 mb-4">Announcement</h2>
                {/* add announcement section */}
                {displayAddNewAnnouncementSection ? (
                  mountAnnouncementForEditLoading ? (
                    <div className="text-center p-4">
                      <ContentLoader color="text-warning" />
                    </div>
                  ) : (
                    <div className="top-section">
                      <Row className="mb-5 px-3">
                        <Col md={6} className="bg-grey-3">
                          {/* text editor */}
                          <TextEditor
                            htmlData={announcementToEditDetails.editorHtmlText}
                            getHtmlData={(data) =>
                              this.handleUpdateAnnouncementContent(data)
                            }
                            toobarSetting={toolbarConfig}
                          />

                        </Col>
                        <Col
                          md={6}
                          className="d-flex justify-content-between flex-column"
                        >
                          {/* datepicker */}
                          <DateRange
                            updateDate={(dateData) => this.updateDate(dateData)}
                            startDate={
                              this.state.announcementToEditDetails.startDate
                            }
                            endDate={
                              this.state.announcementToEditDetails.endDate
                            }
                          />
                          {/* add announcement action-btn */}
                          <div className="action-btns">
                            <Button
                              className="rounded mr-3"
                              onClick={
                                editingAnnouncement
                                  ? this.updateAnnouncementDetails
                                  : this.handleAnnouncementSubmit
                              }
                              variant="info"
                              disabled={editingAnnouncement && isDataSame}
                            >
                              {addAnnouncementLoading ? (
                                <ContentLoader />
                              ) : (
                                "Announce"
                              )}
                            </Button>
                            <Button
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                this.setState({
                                  displayAddNewAnnouncementSection: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                        <Col>

                          {addAnnouncementClicked &&
                            (
                              announcementToEditDetails.editorHtmlText === "" ||
                              announcementToEditDetails.editorHtmlText ===
                              "<p></p>" ||
                              announcementToEditDetails.endDate === null ||
                              announcementToEditDetails.startDate === null) ? (
                            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                              All fields are required!
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  )
                ) : null}

                {/* search bar ========== */}
                {successText ? (
                  <h4 className="mb-3 text-success text-center font-source-sans font-weight-semi">
                    {successText}
                  </h4>
                ) : null}

                <div className="search-and-btn mb-3 d-flex">
                  <FormGroup className="mb-0 mr-2 search-bar flex-grow-1 position-relative">
                    <FormControl
                      className="text-grey-3 h-100"
                      onChange={this.handleSearch}
                      value={searchString}
                      placeholder="Search"
                    />
                    <i className="fa fa-search fa-1x" aria-hidden="true"></i>
                  </FormGroup>
                  <div className="new-content">
                    <button
                      onClick={this.addNewAnnouncement}
                      className="btn btn-primary py-2 rounded h-100"
                    >
                      New announcement
                    </button>
                  </div>
                </div>
                {/* top-section-ends */}
                {itemDeleted ? (
                  <h4 className="text-success font-source-sans text-center font-weight-semi">
                    Announcement deleted successfully!
                  </h4>
                ) : null}

                {/* ------ pagination ---------- */}
                <div className="count-filters-and-pagination d-flex justify-content-end mt-5 mb-4">
                  <Pagination
                    simple
                    onChange={this.handlePagination}
                    showSizeChanger={false}
                    current={pagination && pagination.current}
                    pageSize={pagination && pagination.pageSize}
                    total={pagination && pagination.total}
                  />
                </div>
                {/* ------ pagination ---------- */}

                {/* announcement listing table */}
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="text-black mt-5"
                >
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>id</th>
                      <th>Announcement</th>
                      <th>Upload date</th>
                      <th>End Date</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody className="font-smallest font-weight-semi">
                    {announcementLoading ? (
                      <tr>
                        <td colSpan="6">
                          <div className="text-center">
                            <div
                              className="spinner-border position-absolute text-warning"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : announcements && announcements.length ? (
                      announcements !== null &&
                      announcements.map((announcement) => (
                        <tr key={announcement.id} className="rows">
                          <td className="font-weight-400">{announcement.id}</td>
                          <td className="annoucement-desc">
                            {ReactHtmlparser(announcement.content)}
                          </td>
                          <td>
                            {moment(announcement["start_date"]).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td>
                            {moment(announcement["end_date"]).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          {/* edit icon */}
                          <td className="edit">
                            <a
                              onClick={() =>
                                this.mountAnnouncementDataForEditing(
                                  announcement.id
                                )
                              }
                            >
                              <img
                                src={"assets/images/add-content.svg"}
                                alt="Write"
                              />
                            </a>
                          </td>
                          {/* delete icon */}
                          <td>
                            <a
                              onClick={() =>
                                this.deleteActionModalHandler(announcement.id)
                              }
                            >
                              <img
                                src={"assets/images/delete-content.svg"}
                                alt="Delete"
                              />
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="text-center ">
                            <h4 className="text-danger">No Data Found</h4>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {/* delete announcement popup */}
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.deleteActionModal}
                onHide={this.deleteActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to delete this Announcement? All data
                    will be lost.
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.deleteActionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="rounded"
                    onClick={this.handleAnnouncementDelete}
                  >
                    {deleteLoading ? <ContentLoader /> : "Delete"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAnnouncementData: (userType, page) =>
    dispatch(get_announcement_table_list_action(userType, page)),
  getAnnouncementSearchData: (userType, page, data) =>
    dispatch(get_announcement_search_table_list_action(userType, page, data)),
  deleteAnnouncement: (id) => dispatch(delete_announcement_action(id)),
  addNewAnnouncement: (data) => dispatch(add_new_announcement_action(data)),
  getSingleAnnouncementDetails: (id) =>
    dispatch(get_single_announcement_details_action(id)),
  updateAnnouncement: (data) =>
    dispatch(update_announcement_details_action(data)),
});

export default connect(null, mapDispatchToProps)(Announcements);
