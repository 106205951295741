import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button } from "react-bootstrap";

import "./ReportedComment.scss";
import {
  get_reported_comment_by_id_action,
  take_on_reported_post_action,
} from "../../../_actions/Admin";
import { AntDLoader, LoaderBasic } from "../common/Loader";
import CommentCard from "../common/CommentCard/CommentCard";
import { isEmptyObject } from "../../../_helpers/validators";

class ReportedComment extends Component {
  state = {
    commentId: "",
    reportId: "",
    successText: "",
    isApiLoading: false,
    reportedComment: {},
    isActionApiLoading: { loading: false, type: "" },
    reportedBy: "",
    reportType: "",
  };

  componentDidMount() {
    if (!isEmptyObject(this.props.match.params) && this.props.match.params.id) {
      this.setState(
        {
          commentId: this.props.match.params.id,
          reportId: this.props.location.state.reportId,
          reportedBy: this.props.location.state.reporterName,
          reportType: this.props.location.state.reportType,
        },
        () => {
          this.getReportedCommentApi();
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportedComment !== this.props.reportedComment) {
      if (this.props.reportedComment.id) {
        this.setState({ reportedComment: { ...this.props.reportedComment } });
      }
    }

    if (prevProps.reportedAction !== this.props.reportedAction) {
      if (this.props.reportedAction.msg) {
        this.setState({ successText: `You've resolved this report request.` });
      }
    }
  }

  getReportedCommentApi = async () => {
    const { commentId } = this.state;

    this.setState({ isApiLoading: true });
    await this.props.getReportedComment(commentId);
    this.setState({ isApiLoading: false });
  };

  handleBackClickHandler = () => {
    return this.props.history.goBack();
  };

  handleActionHandler = async (type) => {
    const { reportId } = this.state;
    const data = {
      action: type,
    };
    this.setState({ isActionApiLoading: { loading: true, type } });
    await this.props.takeOnReport(reportId, data);
    this.setState({ isActionApiLoading: { loading: false, type: "" } });
  };
  render() {
    const {
      successText,
      reportedComment,
      isApiLoading,
      isActionApiLoading,
      reportedBy,
      reportType,
    } = this.state;

    return isApiLoading ? (
      <LoaderBasic />
    ) : (
      <div className="reported-comment reported-content-wrapper">
        <div className="reported-header fixed-top ">
          <Container className="bg-white py-4">
            <h3 className="text-primary fs-30">DariusCooks</h3>
            <div className="action-wrapper flex-wrap d-flex align-items-center">
              <span
                onClick={this.handleBackClickHandler}
                className="action d-flex align-items-center font-weight-bold cursor-pointer"
              >
                <img src="/assets/images/back-arrow.svg" alt="" />
                Back
              </span>
              <button
                className="btn btn-primary rounded fs-14 font-weight-semi ml-auto p-2 py-md-3 px-md-5"
                onClick={() => this.handleActionHandler("dismiss")}
              >
                {isActionApiLoading.loading &&
                isActionApiLoading.type === "dismiss" ? (
                  <AntDLoader />
                ) : (
                  "Dismiss the Report"
                )}
              </button>
              <button
                className="btn btn-outline-dark rounded fs-14 font-weight-semi p-2 ml-3 p-md-3"
                onClick={() => this.handleActionHandler("delete")}
              >
                {isActionApiLoading.loading &&
                isActionApiLoading.type === "delete" ? (
                  <AntDLoader />
                ) : (
                  "Delete Comment"
                )}
              </button>
            </div>
            {successText && successText.length ? (
              <div className="text-success">{successText}</div>
            ) : null}
          </Container>
        </div>
        <div className="reported-content-inner">
          <Container className="bg-white">
            <div className="reported-photo-wrapper">
              <Row>
                <Col md={7} className="mb-4 mb-md-0">
                  <div className="d-flex flex-wrap justify-content-between font-smallest">
                    <div>
                      <p className="mb-0">
                        <span>Reported By: </span>
                        <span className="font-weight-semi text-primary">
                          {reportedBy}
                        </span>
                      </p>
                      <p>
                        Reason: 
                        <span className="font-weight-semi text-danger">
                          {reportType}
                        </span>
                      </p>
                    </div>
                    <div className="ml-2">
                      <span>Posted By: </span>
                      <span className="font-weight-semi text-info">{`${
                        reportedComment &&
                        reportedComment.user_posted_details &&
                        reportedComment.user_posted_details.first_name
                      } ${
                        reportedComment &&
                        reportedComment.user_posted_details &&
                        reportedComment.user_posted_details.last_name
                      }`}</span>
                    </div>
                  </div>
                  <div className="reported-image">
                    <img
                      src={
                        reportedComment.content_details &&
                        reportedComment.content_details.title_image
                      }
                      alt="reported-image"
                      className="image w-100"
                    />
                  </div>
                </Col>

                <Col md={5}>
                  <div className="reported-image-comment">
                    <h3 className="mb-4">Comments</h3>
                    <div className="reported-comments-wrapper bg-danger-2 p-3 rounded">
                      <h4 className="fs-18 mb-4 text-danger-5">
                        Reported Comments
                      </h4>
                      <CommentCard
                        isHideImage
                        commentText={reportedComment.text}
                        user={reportedComment.user_posted_details}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    error: state.add_to_dashboard_items_reducer.errorMessage,
    reportedAction: state.reported_content_reducer.reportedAction,
    reportedComment: state.reported_content_reducer.reportedComment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReportedComment: (id) => dispatch(get_reported_comment_by_id_action(id)),
  takeOnReport: (id, data) => dispatch(take_on_reported_post_action(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportedComment);
