import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Form, Row, Col } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { header_search_query_action } from "../../../_actions/User";
// import ContentLoader from "../../Common/Loader/ContentLoader";
import "./SearchResults.scss";
import HeaderNew from "../HomePageNew/HeaderNew/HeaderNew";
import { useDispatch } from "react-redux";
import { load } from "dotenv";
import Loader from "../../Common/Loader/ContentLoader";

function SearchResult(props) {
  const dispatch = useDispatch();
  const searchKeyword =
    props.location.state && props.location.state.searchKeyword;
  const [videoLoading, setVideoLoading] = useState(false);
  const [pageSearchResults, setPageSearchResults] = useState([]);
  const [newAddition, setNewAddition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setNoResultsFound(true);
    } else {
      setSearchText(searchKeyword);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    callApi();
  }, [searchText]);

  const callApi = async () => {
    let searchKeywordText = { search: searchText };
    if (searchText && searchText.length > 0) {
      const searchResult = await dispatch(
        header_search_query_action(searchKeywordText)
      );
      const searchResultfromApi = searchResult && searchResult.data;
      setPageSearchResults(
        searchResultfromApi
      );
      setSearchResultData(
        searchResultfromApi
      );
      setSearchStatus(true);
    }
    setLoading(false);
  };

  // search handler ===========
  const searchHandler = async (e) => {
    setSearchText(e.target.value);

  };

  const routeHandler = (data) => {
    if (data && data.content_type === "video") {
      props.history.push(`/video/${data.id}`);
    } else if (data && data.content_type === "show") {
      props.history.push(`/view-show/${data.id}`);
    } else if (
      data &&
      data.content_type === "youtube-recipe"
    ) {
      props.history.push(`/recipe/${data.id}`);
    }
    else if (
      data &&
      data.content_type === "episode"
    ) {
      props.history.push(`/watch-show/${data.id}`);
    }
  };

  const splitTitleImage = (data) => {
    const splitImage = data.split("?")
    return splitImage[0]
  }

  return (
    <div className="common-dashboard">
      <Container className="p-0">
        {JSON.parse(localStorage.getItem("user")) !== null ? (
          <SidebarMenu />
        ) : (
          null
        )}
        <div className="main-content bg-white position-relative">
          {JSON.parse(localStorage.getItem("user")) !== null ? (
            <Header searchType="videos" />
          ) : (
            <HeaderNew history={props.history} />
          )}
          <div className="top-search-wrapper flex-grow-1 mb-4 mr-0 mr-sm-2 mr-md-5">
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group className="mb-0 h-100 position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => searchHandler(e)}
                  defaultValue={searchText}
                />
                <i className="fa fa-search fa-1x" aria-hidden="true"></i>
              </Form.Group>
            </Form>
          </div>

          {/* all video wrapper */}
          <div className="all-video-wrapper mt-3 mt-md-5">
            <h3 className="font-weight-semi pt-4 mb-5">Search Results</h3>
            {loading ? (
              <Loader color="text-warning" className="text-center" />
            ) : (
              <>
                {searchResultData && searchResultData.length !== 0 ? (
                  <Row className="all-videos-row">
                    {searchResultData &&
                      searchResultData.map((data) => (
                        <Col className="col-6 col-md-3 mb-5">
                          <div
                            className="video-box cursor-pointer"
                            onClick={() => routeHandler(data)}
                          >
                            {data.title_image ? <>
                              <img
                                src={process.env.REACT_APP_STAGE === "production" ? data.title_image && data.title_image : data.title_image && splitTitleImage(data.title_image)}
                                alt="slider-img-1"
                                className="border-6 img-fit w-100"
                              />

                            </> : <>
                            <img
                              src={"../assets/images/placeholder-recipe.jpg"}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                            
                            </>}
                            <h4 className="title font-weight-semi my-2">
                              {data.title}
                            </h4>
                          </div>
                        </Col>
                      ))}
                  </Row>
                ) : (
                  <div className="text-center p-5">
                    <h3 className="text-danger-2">Oops! No Results Found</h3>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SearchResult;

