import React, { Component } from 'react';
import './SideBarNew.scss';

export class SideBarNew extends Component {
    render() {
        return (
            <div className="sidebar-menu bg-primary position-fixed  h-100">
                <ul className="menu-content d-flex flex-md-column align-items-center py-md-5 justify-content-between justify-content-md-start w-100">
                    <li><a href="#"> <img src={"/assets/images/home-icon.svg"} alt="home"/></a></li>
                    <li><a href="#"> <img src={"/assets/images/store-icon.svg"} alt="store" /></a></li>
                    <li><a href="#"><img src={"/assets/images/plus-icon.svg"} alt="plus icon" /></a></li>
                    <li><a href="#"><img src={"/assets/images/group-icon.svg"} alt="group icon" /></a></li>
                    <li><a href="#"><span className="fa fa-bell text-white fs-20" /></a></li>
                    
                </ul>
            </div>
        )
    }
}

export default SideBarNew
