import React, { Component } from 'react';
import { Container} from 'react-bootstrap';
import './WelcomeScreen.scss';
import {notification} from "antd";
import ReactHtmlparser from "react-html-parser";

class WelcomeScreen extends Component {
    componentDidMount() { this.openNewNotification() }

    openNewNotification = (payload) => {
        notification['info']({
            description: 'Please enable notification for receiving notifications.'
        })
    }

    render() {
        setTimeout(() => {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                if (user.user.role === 'user') {
                    this.props.history.push('/user-home-page')
                }
                if (user.user.role === 'admin') {
                    this.props.history.push('/admin-home-page')
                }
            } else {
                this.props.history.push('/home-page-new')
            }
        }, 5000)
        return (
            <div className="common-login d-flex align-items-center jusfity-content-center fs-18">
                <Container className="p-0">
                    <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
                        <div className="welcome-box h-100 w-100 position-relative">
                            <img src="/assets/images/welcome-screen.svg" className="w-100" />
                            <h1 className="position-absolute font-poppins font-largest text-white text-center w-100 p-3">DariusCooks</h1>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
export default WelcomeScreen;