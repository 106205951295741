import React, { useEffect } from "react";
import "./MobileCheckout.scss";
import swal from "sweetalert";

//auth.net
import { useAcceptJs } from "react-acceptjs";

export default function MobilePayment(props) {
  const [cardData, setCardData] = React.useState({
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
  });

  useEffect(() => {
    if (window.ReactNativeWebView) {
    }
  }, []);

  const authData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_DOT_NET_API_LOGIN_ID,
    clientKey: process.env.REACT_APP_AUTHORIZE_DOT_NET_PUBLIC_CLIENT_KEY,
  };

  //auth.net
  const { dispatchData, error } = useAcceptJs({ authData });

  const handleSubmit = async (event) => {
    event.preventDefault();

    let response = await dispatchData({ cardData });

    let paymentMethod = {};
    response = await response;
    if (response?.opaqueData) {
      paymentMethod = response?.opaqueData;
    }
    if (paymentMethod?.dataValue) {
      window.ReactNativeWebView.postMessage(paymentMethod?.dataValue);
    } else {
      swal("Error Processing Card");
      return;
    }
  };

  return (
    <>
      <div className="checkout-wrapper d-flex justify-content-center align-items-center">
        <div className="checkout-inner ">
          <div className="container px-0">
            <div className="row no-gutters bg-white">
              <div className="col-md-6">
                <div className="left">
                  <img
                    className="w-100"
                    src="../../../../assets/images/make-payment-img.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right py-5 px-lg-0 mx-auto">
                  <h3 className="text-primary mb-4 pb-md-3">
                    Add Your Card Details
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            Card Number
                          </label>
                          <input
                            type="text"
                            name="cardNumber"
                            placeholder="Enter Card Number"
                            value={cardData.cardNumber}
                            onChange={(event) =>
                              setCardData({
                                ...cardData,
                                cardNumber: event.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            Expiry Date{" "}
                          </label>

                          <div className="d-flex">
                            <input
                              type="text"
                              name="month"
                              placeholder="MM"
                              value={cardData.month}
                              onChange={(event) =>
                                setCardData({
                                  ...cardData,
                                  month: event.target.value,
                                })
                              }
                              className="form-control"
                            />

                            <input
                              type="text"
                              name="year"
                              placeholder="YYYY"
                              value={cardData.year}
                              onChange={(event) =>
                                setCardData({
                                  ...cardData,
                                  year: event.target.value,
                                })
                              }
                              className="form-control ml-2"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-5">
                        <div className="form-group">
                          <label className="font-weight-bold mb-2 font-small">
                            CVV
                          </label>

                          <input
                            type="text"
                            name="cardCode"
                            placeholder="cvv"
                            value={cardData.cardCode}
                            onChange={(event) =>
                              setCardData({
                                ...cardData,
                                cardCode: event.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {/* </form> */}

                    <div className="text-center mt-4">
                      <div className=" rounded-pill  d-flex bg-grey-5 align-items-center ">
                        <button
                          type="submit"
                          className="btn btn-primary text-white py-sm-3 px-sm-5 flex-grow-1"
                          // disabled={!stripe}
                        >
                          <span> Make Payment</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
