import swal from 'sweetalert'
import { uploadVideoConstants } from "../../_constants/Admin/uploadVideo.constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// SAVE VIDEO DETAILS
export const save_video_details_action = (data) => async (dispatch) => {
    dispatch({
        type: uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.uploadVideoContent, data)
        if (response.status) {
            dispatch({
                type: uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Add recipe content
export const publish_video_action = (id, data) => async (dispatch) => {
    dispatch({
        type: uploadVideoConstants.PUBLISH_VIDEO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.updateVideoContent.replace(':id', id), data)
        if (response.status) {
            dispatch({
                type: uploadVideoConstants.PUBLISH_VIDEO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: uploadVideoConstants.PUBLISH_VIDEO_ACTION_FAILURE,
            payload: e.msg
        })
        
    }
}

// Delete connected recipe content
export const delete_connected_recipe_action = (id, data) => async (dispatch) => {
    dispatch({
        type: uploadVideoConstants.DELETE_CONNECTED_RECIPE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.updateVideoContent.replace(':id', id), data)
        if (response.status) {
            dispatch({
                type: uploadVideoConstants.DELETE_CONNECTED_RECIPE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg )
    }
}


// Add recipe content
export const create_mux_url_action = () => async (dispatch) => {
    dispatch({
        type: uploadVideoConstants.CREATE_MUX_URL_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.mux.muxVideoUrl);
        if (response.status) {
            dispatch({
                type: uploadVideoConstants.CREATE_MUX_URL_ACTION_SUCCESS,
                payload: response.data.data.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: uploadVideoConstants.CREATE_MUX_URL_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// Add recipe content
export const get_playback_asset_id_action = (uploadId) => async (dispatch) => {
    dispatch({
        type: uploadVideoConstants.GET_PLAYBACK_ASSET_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.mux.getPlaybackAssetId.replace(":uploadId", uploadId));
        if (response.status) {
            dispatch({
                type: uploadVideoConstants.GET_PLAYBACK_ASSET_ID_ACTION_SUCCESS,
                payload: response.data.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: uploadVideoConstants.GET_PLAYBACK_ASSET_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}