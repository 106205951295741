import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import VideoPlayer from "../common/VideoPlayer/VideoPlayer";
import {
  get_current_livestream_playbackid_action,
  add_comment_live_stream_action,
  get_comments_list_action,
  get_livestream_viewers_count_action,
  get_mux_livestream_viewers_count_action,
} from "../../../_actions/User/livestream.action";
import { Form, Button } from "react-bootstrap";
import ContentLoader from "../../Common/Loader/ContentLoader";
import swal from "sweetalert";
class JoinLiveStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playbackId: null,
      streamId: null,
      comment: "",
      loading: false,
      commentList: [],
      isContentLoading: true,
      viewersCount: null,
      statusCode: null,
      isCommentVisible: false,
    };
  }
  componentDidMount = async () => {
    let data = await this.props.currentLivestreamPlayBackId();
    this.setState(
      {
        playbackId:
          this.props &&
          this.props.playbackId &&
          this.props.playbackId.playback_id,
        streamId:
          this.props &&
          this.props.playbackId &&
          this.props.playbackId.livestream_id,
        isContentLoading: false,
      },

      async () => {
        const checkStream = await fetch(
          `https://stream.mux.com/${this.state.playbackId}`
        )
          .then((res) => {
            this.setState({ statusCode: res.status });
            return res;
          })
          .catch((e) => {
            console.error(e);
          });

        if (checkStream && checkStream.status == 200) {
          
          const commentList = await this.props.getCommentList(
            this.state.streamId
          );
          const count = await this.props.getViewersCount(this.state.streamId);
          this.timeVar = setInterval(this.myTimer, 5000);
          this.setState({
            commentList: commentList && commentList.data,
            viewersCount: count && count.data && count.data.data,
          });
        } else {
          return;
        }
      }
    );
    //this.timeVar= setInterval(this.myTimer,10000)
  };

  myTimer = async () => {
    // if(this.state && this.state.statusCode !== 412){
    const count = await this.props.getViewersCount(this.state.streamId);
    this.setState({
      viewersCount: count && count.data && count.data.data

    });
    // }else{
    //     return
    // }
  };

  componentWillUnmount() {
    clearInterval(this.timeVar);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.viewersCount !== this.props.viewersCount) {
    
      this.setState({
        viewersCount: nextProps && nextProps.viewersCount  && nextProps.viewersCount.data,
      });
    }
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      stream_id: this.state.streamId,
      text: this.state.comment,
      user: user && user.user && user.user.id,
    };
    if (!data.text) {
      swal("Please enter comment");
      return;
    }
    this.setState({ loading: true }, async () => {
      const res = await this.props.addCommentLiveStream(data);
      const commentList = await this.props.getCommentList(this.state.streamId);
      this.setState({
        commentList: commentList && commentList.data,
        loading: false,
        comment: "",
      });
    });
  };

  handleCommentsHide = () => {
    const { isCommentVisible } = this.state;
    this.setState({
      isCommentVisible: !isCommentVisible,
    });
  };

  render() {
    const { isCommentVisible } = this.state;
    return (
      <div className="common-dashboard status-post-wrapper post-wrapper">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />
          <div className="main-content bg-white">
            <Header />
            <div>
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  {this.state.isContentLoading ? (
                    <div
                      style={{
                        margin: "50px auto 0",
                        textAlign: "center",
                      }}
                    >
                      <ContentLoader color="text-primary" />
                    </div>
                  ) : (
                    <>
                      {this.state && this.state.statusCode !== 200 ? (
                        <p className="py-5">
                          Currently there is not any livestream on this
                          endpoint.
                        </p>
                      ) : (
                        <>
                          <div style={{ margin: "10px 0 5px 0" }}>
                            <b>Viewers</b> <i className="fa fa-eye"></i>
                            <span>
                              {" "}
                              :
                              {this.state && this.state.viewersCount && this.state.viewersCount[0] && this.state.viewersCount[0].viewers}
                            </span>
                          </div>
                          {this.state && this.state.playbackId ? (
                            <VideoPlayer
                              playbackId={this.state && this.state.playbackId}
                              isLiveStream={true}
                            ></VideoPlayer>
                          ) : null}

                          <Form.Group className="mb-0 search-bar flex-grow-1 d-flex align-items-center">
                            <Form.Control
                              type="text"
                              value={this.state.comment}
                              placeholder="Post Comment"
                              onChange={(e) => this.handleChange(e)}
                              className="search-bar bg-grey-2 py-4 my-3 mr-3 border-radius-10"
                            ></Form.Control>
                            <br />
                            <Button
                              variant="primary"
                              onClick={this.handleSubmit}
                              className="py-3"
                            >
                              {this.state.loading ? (
                                <ContentLoader color="text-white" />
                              ) : (
                                "Post"
                              )}
                            </Button>
                          </Form.Group>
                          <div className="button-wrapper">
                            <Button
                              variant=" "
                              onClick={this.handleCommentsHide}
                              className="btn btn-warning mb-1 mt-2"
                              style={{ width: "200px" }}
                            >
                              {isCommentVisible
                                ? "Hide Comments"
                                : " Show Comments"}
                            </Button>
                            <h3 style={{ margin: "10px 10px 20px 0" }}>
                              Comments :{" "}
                            </h3>
                          </div>
                          {this.state.commentList.map((comment) => (
                            <div
                              style={{
                                display: isCommentVisible ? "block" : "none",
                              }}
                            >
                              <span className="d-inline-block mb-2">
                                <b>
                                  {comment.user_details &&
                                    comment.user_details.first_name}{" "}
                                  :{" "}
                                </b>
                              </span>
                              <span className="d-inline-block mb-2 ml-1">
                                {comment && comment.text}
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playbackId:
      state.get_current_livestream_playbackid_reducer &&
      state.get_current_livestream_playbackid_reducer.data &&
      state.get_current_livestream_playbackid_reducer.data.data,

    viewersCount:
      state.get_livestream_viewers_count_reducer &&
      state.get_livestream_viewers_count_reducer.data &&
      state.get_livestream_viewers_count_reducer.data.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  currentLivestreamPlayBackId: () =>
    dispatch(get_current_livestream_playbackid_action()),
  addCommentLiveStream: (data) =>
    dispatch(add_comment_live_stream_action(data)),
  getCommentList: (id) => dispatch(get_comments_list_action(id)),
  getViewersCount: (id) => dispatch(get_livestream_viewers_count_action(id)),
  getMuxViewersCount: (id) =>
    dispatch(get_mux_livestream_viewers_count_action(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinLiveStream);
