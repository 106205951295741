import {faqConstants} from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// get faq content from backend
export const get_faq_content_action = () => async (dispatch) => {
    dispatch({
        type: faqConstants.GET_FAQ_CONTENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userfaq.getFAQContent)
        if (response.status) {
            dispatch ({
                type: faqConstants.GET_FAQ_CONTENT_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: faqConstants.GET_FAQ_CONTENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}