import React, { Component } from "react";
import { Container, Row, Col, Table, Dropdown } from "react-bootstrap";
import "./AddGroceryList.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { connect } from "react-redux";
import { add_grocery_list_action } from "../../../_actions/User/grocery.action";
import { search_category_action_user } from "../../../_actions/User";
import swal from "sweetalert";
import { debounce } from "../../../_helpers/debounce";
class AddGroceryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list_name: "",
      item_name: "",
      quantity: null,
      category: "",
      // unit:"",
      successMessage: "",

      children: [
        {
          item_name: "",
          quantity: null,
          category: "",
          // unit: "",
        },
      ],
      searchCategoryString: "",
      categoryTagSuggestion: ["fruit", "vegetable"],
      showSearchDropdownIndex: null,
      searchCategoryLoading: true,
    };
    this.searchCategoryApi = debounce(this.loadSearchCategoryData);
  }

  createGroceryList = async () => {
    const {
      list_name,
      item_name,
      quantity,
      category,
      // unit
    } = this.state;

    if (!this.state.list_name) {
      swal("List Name is required!");
      return;
    }

    let obj = this.state.children;
    let isEmpty = "";
    let isValid = "";
    obj.forEach((objItem) => {
      if (
        !objItem.item_name ||
        !objItem.quantity ||
        !objItem.category
        // || !objItem.unit
      ) {
        !objItem.item_name
          ? (isEmpty = "Item Name")
          : !objItem.quantity
          ? (isEmpty = "Quantity")
          // : !objItem.category
          // ? (isEmpty = "Category")
           // : !objItem.unit
            // ? (isEmpty = "Unit")
           : (isEmpty = "");
        return;
      }
    });

    if (isEmpty.length > 0) {
      swal(`${isEmpty} is required!`);
      return;
    }
    // this.state.children.forEach((obj) =>
    //   isNaN(obj.quantity) == true
    //     ? (isValid = "Please enter valid Quantity")
    //     : obj.quantity <= 0
    //     ? (isValid = "Please enter valid Quantity")
    //     : (isValid = "")
    // );

    // if (isValid.length > 0) {
    //   swal(`${isValid}`);
    //   return;
    // }

    const addGroceryList = {
      list_name: this.state.list_name,
      grocery_items: this.state.children,
    };

    const res = await this.props.addGroceryList(addGroceryList);
    if (res.msg == "Successfully Submitted") {
      this.setState({
        successMessage: swal("Grocery List is created Successfully!"),
        list_name: "",
        item_name: "",
        quantity: null,
        category: "",
        unit: "",
        children: [
          {
            item_name: "",
            quantity: "",
            category: "",
            // unit: "",
          },
        ],
      });
    } else {
      swal("Something went wrong.Try again later!");
    }
  };

  appendChild = () => {
    this.setState({
      children: [
        ...this.state.children,
        {
          item_name: "",
          quantity: null,
          category: "",
          // unit: "",
        },
      ],
    });
  };

  deleteChild = (e, index) => {
    e.stopPropagation();
    let array = [...this.state.children];
    array.splice(index, 1);
    this.setState({ children: array });
  };

  onFieldChange = (index) => e => {
    const array = [...this.state.children];
    const { name, value } = e.target;
    array[index] = { ...array[index], [name]: value };

    this.setState({ children: array, [name]: value });
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddition = (value, index) => {
    const name = "category"
    const array = [...this.state.children];
    array[index] = { ...array[index], [name]: value };
    this.setState({ showSearchDropdownIndex: null, searchCategoryString: "" , children:array,[name]:value});
  };

  handleSearch = (input, index) => {
    const array = [...this.state.children];
    array[index] = { ...array[index], category: "" };
    this.setState({ children:array});
    this.setState(
      {
        searchCategoryString: input.target.value,
        showSearchDropdownIndex: index,
      },
      () => {
        if (this.state.searchCategoryString !== "") {
          this.setState({ searchCategoryLoading: true });
          this.searchCategoryApi();
        }
      }
    );
  };

  loadSearchCategoryData = async () => {
    const response = await this.props.onSearchCategory({
      search: this.state.searchCategoryString,
    });

    this.setState({ categoryTagSuggestion: response && response.data });
    this.setState({ searchCategoryLoading: false });
  };

  render() {
    const {
      successMessage,
      searchCategoryLoading,
      searchCategoryString,
      categoryTagSuggestion,
      showSearchDropdownIndex
    } = this.state;

    return (
      <div className="common-dashboard grocery-creation grocery-list">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content grocery-list-content grocery-creation-content bg-white">
            <Header />
            <div className="grocery-list-wrapper grocery-creation-wrapper">
              <a
                href="/grocery-lists"
                className="d-none d-md-flex align-items-center pt-5"
              >
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row>
                <Col lg={7} xl={6} className="mb-5">
                  <div className="grocery-cards grocery-list-wrapper mb-4">
                    {/* <h3 className="font-weight-bold font-montserrat pt-4 mb-3">
                      List Name
                    </h3> */}

                    <div className="top-actions mb-5">
                      <div className="add-item d-flex align-items-center">
                        {/* <div className="item-name-input  mr-2">
                          <h4 className="category-title  fs-18 mb-0">
                            Item Name
                          </h4>
                        </div>
                        <div className="right d-flex ml-auto">
                          <h4 className="fs-18 mb-0">Qty</h4>
                          <h4 className="fs-18 mb-0 ml-2">Category</h4>
                        </div> */}
                      </div>
                      <p className="mt-2">
                        <span
                          className="cursor-pointer text-grey-4 font-weight-bold "
                          //onClick={this.addAttributeForm}
                          onClick={this.appendChild}
                        >
                          + List Item
                        </span>
                      </p>
                      {/* add item form */}
                      {/* add class "d-none" to hide form  */}
                      <div className="item-name-input mr-0 mr-sm-2">
                        <input
                          type="text"
                          placeholder="List Name"
                          className="form-control"
                          name="list_name"
                          onChange={this.handleChange}
                          value={this.state.list_name}
                          required
                        />
                      </div>
                      <br />

                      {this.state.children.map((element, index) => (
                        <>
                          <form action="#" className="" key={index} autoCapitalize="off">
                            <div className="add-item d-flex  justify-content-between flex-wrap flex-sm-nowrap ">
                              <div className="item-name-input mr-0 mr-sm-2">
                                <input
                                  type="text"
                                  placeholder="Enter Item Name"
                                  className="form-control mb-2 mb-md-0"
                                  name="item_name"
                                  //  onChange={this.handleChange}
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].item_name}
                                  required
                                />
                              </div>
                              <div className="item-name-input mr-0 mr-sm-2">
                                <input
                                  type="text"
                                  placeholder="Enter Quantity"
                                  name="quantity"
                                  className="form-control mb-2 mb-md-0"
                                  //onChange={this.handleChange}
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].quantity}
                                  required
                                />
                              </div>
                              {/* <div className="item-name-input mr-0 mr-sm-2">
                                <input
                                  type="text"
                                  placeholder="Unit"
                                  name="unit"
                                  className="form-control"
                                  //  onChange={this.handleChange}
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].unit}
                                  required
                                />
                              </div> */}
                              <div className="item-name-input mr-0 mr-sm-2">
                                <input
                                  name="category"
                                  placeholder="Category Title"
                                  autoComplete="off"
                                  value={
                                    this.state.children[index].category
                                      ? this.state.children[index].category
                                      : searchCategoryString
                                  }
                                  onChange={(e) => this.handleSearch(e, index)}
                                  type="text"
                                  className="form-control"
                                  // onFocus={this.resetCategory(index)}
                                  // onChange={this.handleChange}
                                  // onChange={this.onFieldChange(index)}
                                />
                                {showSearchDropdownIndex === index ? (
                                        <div className="bg-white border">
                                          {searchCategoryLoading ? (
                                            <span
                                              className={`bg-white font-weight-semi  h-auto tag-button`}
                                            >
                                              Loading...
                                            </span>
                                          ) : categoryTagSuggestion &&
                                            categoryTagSuggestion.length ===
                                              0 ? (
                                            searchCategoryString.length ===
                                            0 ? null : (
                                              <button
                                                className={`bg-white font-weight-semi text-left h-auto tag-button`}
                                                onClick={(e) =>
                                                  this.handleAddition(
                                                    searchCategoryString,
                                                    index
                                                  )
                                                }
                                              >
                                                Add {searchCategoryString}
                                              </button>
                                            )
                                          ) : (
                                            categoryTagSuggestion && categoryTagSuggestion.map(
                                              (item, i) => (
                                                <button
                                                  className={`bg-white font-weight-semi text-left h-auto tag-button`}
                                                  onClick={(e) =>
                                                    this.handleAddition(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                  key={i}
                                                >
                                                  {item}
                                                </button>
                                              )
                                            )
                                          )}
                                        </div>
                                      ) : null}
                              </div>
                              {/* <div className="ml-sm-2 mt-2 mt-sm-0">
                                <select
                                  className="form-control"
                                  name="category"
                                  // onChange={this.handleChange}
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].category}
                                  required
                                >
                                  <option value="0">Select</option>
                                  <option value="vegetable">Vegetable</option>
                                  <option value="fruit">Fruit</option>
                                  <option value="meat">Meat</option>
                                  <option value="diary">Dairy</option>
                                  <option value="sauce">Sauce</option>
                                </select>
                              </div> */}
                              &nbsp;&nbsp;&nbsp;
                              {index > 0 && (
                                <div className="remove-item">
                                  <a
                                    onClick={(e) => this.deleteChild(e, index)}
                                    className="text-red"
                                  >
                                    <span className="fa fa-minus-circle"></span>
                                  </a>
                                </div>
                              )}
                            </div>
                          </form>
                          <br />{" "}
                        </>
                      ))}

                      {/*      { 
                this.state.attributeForm.map(input => {
                    return input
                })
              } */}
                      <div
                        className="action mt-2 mt-sm-0"
                        onClick={() => {
                          this.createGroceryList();
                        }}
                      >
                        <button className="btn btn-primary h-100 btn-block py-1 px-3 rounded ">
                          Add
                        </button>
                      </div>
                      {successMessage.length ? (
                        <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                          {successMessage}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* category  */}
                    {/*    <div className="category mb-4">
                      <h4 className="category-title text-grey-4 fs-18 mb-3">
                        Add-ons
                      </h4>
                      
                      <div className="item d-flex align-items-center">
                        /* add these classes " text-deleted text-danger font-weight-semi" when checked*
                        <h5 className="category-title text-deleted text-danger font-weight-semi">
                          Ginger
                        </h5>
                        <div className="right pl-2 ml-auto d-flex align-items-center">
                          <p className="qty text-grey-4 mb-0  mr-5">250gm</p>
                          <label
                            htmlFor="actionDelete"
                            className="custom-control custom-checkbox"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="actionDelete"
                              checked
                            />
                            <span
                              className="custom-control-label"
                              for="actionDelete"
                            ></span>
                          </label>
                          <span className="cursor-pointer fa fa-minus-circle fs-18 ml-4"></span>
                        </div>
                      </div> 
                     
                      <div className="item d-flex align-items-center">
                        {/* add these classes " text-deleted text-danger font-weight-semi" when checked  *
                        <h5 className="category-title ">Ginger</h5>
                        <div className="right pl-2 ml-auto d-flex align-items-center">
                          <p className="qty text-grey-4 mb-0  mr-5">250gm</p>
                          <label
                            htmlFor="actionDelete"
                            className="custom-control custom-checkbox"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="actionDelete"
                              name=""
                            />
                            <span
                              className="custom-control-label"
                              for="actionDelete"
                            ></span>
                          </label>
                          <span className="cursor-pointer fa fa-minus-circle fs-18 ml-4"></span>
                        </div>
                      </div>
                      
                    </div>
                    {/* category  */}
                  </div>
                </Col>
              </Row>
              <a
                href="/grocery-lists"
                className="fs-14 btn-add-grocery-list d-flex align-items-center btn btn-primary px-4 py-1 cursor-pointer position-fixed"
              >
                Done
              </a>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // groceryList: state.get_grocery_list_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addGroceryList: (data) => dispatch(add_grocery_list_action(data)),
  onSearchCategory: (data) => dispatch(search_category_action_user(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddGroceryList);
