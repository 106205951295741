import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import "./ShoppingStore.scss";
import Client from "shopify-buy";
import config from "../../../Config/config";
import { Link } from "react-router-dom";

class ShoppingStore extends Component {
  render() {
    return (
      <div className="common-dashboard shopping-home-wrapper">
        <Container>
          <SidebarMenu />
          <div className="main-content bg-white position-relative">
            <Header activeMenuItem="store" />

            <div className="collection-wrapper mt-5 mx-n1">
              <h3 className="font-weight-semi my-4 ml-2">
                Shopping the way you like it!
              </h3>
              <Row>
                <Col lg={3} md={4} sm={6}>
                  <Link to="/all-products">
                    <div className="collection-slide shadow p-2 rounded-lg cursor-pointer">
                      <div
                        className="img-box border-6"
                        // onClick={() => this.routeHandler(collection)}
                      >
                        <img
                          src={"/assets/images/collection-2.svg"}
                          alt="slider-img-1"
                          className="border-6 img-fit w-100"
                        />
                      </div>
                      <h4 className="title font-weight-semi mb-0 mt-2">
                        All Products
                      </h4>
                    </div>
                  </Link>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Link to="/shopping-home">
                    <div className="collection-slide shadow p-2 rounded-lg cursor-pointer">
                      <div
                        className="img-box border-6"
                        // onClick={() => this.routeHandler(collection)}
                      >
                        <img
                          src={"/assets/images/collection-3.svg"}
                          alt="slider-img-1"
                          className="border-6 img-fit w-100"
                        />
                      </div>
                      <h4 className="title font-weight-semi mb-0 mt-2">
                        Collections
                      </h4>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default ShoppingStore;
