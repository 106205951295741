import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get_vegan_recipes_content_action } from "../../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
export class VeganRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      veganRecipesContent: []
    };
  }
  componentDidMount() {
    this.getVeganRecipesContent();
  }

  getVeganRecipesContent = async () => {
    let data = await this.props.getVeganRecipes();
    this.setState({ veganRecipesContent: data && data.data });
  };
  redirectRecipeHandler = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          this.props.history.push(
            `/video/${recipe?.video_details && recipe.video_details?.id}`
          );
          return;
        case "show":
          this.props.history.push(
            `/view-show/${recipe?.show_details && recipe.show_details?.id}`
          );
          return;
        case "episode":
          this.props.history.push(
            `/watch-show/${recipe?.episode_details && recipe.episode_details?.id
            }`
          );
          return;
        case "youtube-recipe":
          this.props.history.push(
            `/recipe/${recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.id
            }`
          );
          return;
        default:
          return null;
      }
    }
  };

  getRecipeTitle = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.title;
        case "show":
          return recipe?.show_details && recipe.show_details?.title;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.title;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.title
          );
        default:
          return null;
      }
    }
  };

  getRecipeImage = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.title_image;
        case "show":
          return recipe?.show_details && recipe.show_details?.title_image;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.title_image;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.title_image
          );
        default:
          return null;
      }
    }
  };

  getRecipeDescription = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.description;
        case "show":
          return recipe?.show_details && recipe.show_details?.description;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.description;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.description
          );
        default:
          return null;
      }
    }
  };
  render() {
    let { veganRecipesContent } = this.state;
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="recipe-section">
        {veganRecipesContent && veganRecipesContent.length ? (
          <>
            <Slider {...settings}>
              {veganRecipesContent &&
                veganRecipesContent.map((recipe) => (
                  <div className="recipe-wrapper border-radius-20 position-relative" key={recipe && recipe.id}>
                    {/* premium content icon will come after adding active class */}
                    {/*  <span className="premium-icon active position-absolute">
                 <img
                    src="/assets/images/premium-icon.svg"
                    alt="premium-icon"
                    className="img-fit img-fluid"
                  />
                </span> */}
                    <span className="recipe-img-wrapper bg-grey-2">
                      {recipe ? (
                        <img
                          src={this.getRecipeImage(recipe) ? this.getRecipeImage(recipe) : "/assets/images/placeholder-recipe.jpg"}
                          alt={this.getRecipeTitle(recipe)}
                          className="tutotials-img img-fit img-fluid"
                        />
                      ) : (
                        <img
                          src={"/assets/images/placeholder-recipe.jpg"}
                          alt={"Placeholder"}
                          className="tutotials-img img-fit img-fluid w-100"
                        />
                      )}
                    </span>
                    <div className="recipe-content-wrapper p-4 bg-grey-10">
                      <div className="top-section d-flex align-items-center justify-content-between">
                        <h4 className="recipe-title mr-1">{this.getRecipeTitle(recipe)}</h4>
                        <p className="fs-14 text-grey-4 text-nowrap mb-0">
                          {recipe.updated.substring(0, 10)}
                        </p>
                      </div>
                      <p className="fs-16 text-dark-2 mt-3">
                        {recipe?.description && recipe.description.substring(0, 10)}
                      </p>
                      <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                        <span
                          href="#"
                          onClick={() => this.redirectRecipeHandler(recipe)}
                          className="btn btn-outline-purple rounded py-2 px-3"
                        >
                          Read More
                        </span>

                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        ) : (
          <div className="text-center">
            <img
              src="/assets/images/no-recepies.png"
              alt="premium-icon"
              className="img-fit img-fluid"
            />
          </div>
        )}

        {veganRecipesContent && veganRecipesContent.length ? (
          <>
            <div className="d-flex align-items-center justify-content-center mt-5">
              <a
                href="/all-recipes"
                className="btn btn-primary rounded py-2 px-4"
              >
                View More
              </a>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVeganRecipes: () => dispatch(get_vegan_recipes_content_action())
});

export default connect(null, mapDispatchToProps)(VeganRecipe);
