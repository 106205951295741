import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import mux from "mux-embed";

export default function VideoPlayer(props) {
  const videoRef = useRef(null);
  const src = `https://stream.mux.com/${props.playbackId}.m3u8`;
  const playerInitTime = mux.utils.now();
  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;
      // props.isLiveStream &&
      //   (video.onpause = () => {
      //     video.play();
      //   });
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // This will run in safari, where HLS is supported natively
        video.src = src;
      }
      if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);

      }

      mux.monitor(video, {
        debug: false,
        hlsjs: hls,
        Hls,
        data: {
          env_key: process.env.REACT_APP_MUX_DATA_STREAM_ENV_KEY,

          player_name: "Main Player",
          player_init_time: playerInitTime,
        },
      });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  return (
    <video
      controls
      ref={videoRef}
      style={{ width: "100%", maxWidth: "100%", maxHeight: "480px" }}
    />
  );
}
