export const homePageConstants = {

    GET_TOP_LEFT_SECTION_ACTION_REQUEST: 'GET_TOP_LEFT_SECTION_ACTION_REQUEST',
    GET_TOP_LEFT_SECTION_ACTION_SUCCESS: 'GET_TOP_LEFT_SECTION_ACTION_SUCCESS',
    GET_TOP_LEFT_SECTION_ACTION_FAILURE: 'GET_TOP_LEFT_SECTION_ACTION_FAILURE',

    GET_TOP_RIGHT_SECTION_ACTION_REQUEST: 'GET_TOP_RIGHT_SECTION_ACTION_REQUEST',
    GET_TOP_RIGHT_SECTION_ACTION_SUCCESS: 'GET_TOP_RIGHT_SECTION_ACTION_SUCCESS',
    GET_TOP_RIGHT_SECTION_ACTION_FAILURE: 'GET_TOP_RIGHT_SECTION_ACTION_FAILURE',

    UPDATE_TOP_LEFT_SECTION_ACTION_REQUEST: 'UPDATE_TOP_LEFT_SECTION_ACTION_REQUEST',
    UPDATE_TOP_LEFT_SECTION_ACTION_SUCCESS: 'UPDATE_TOP_LEFT_SECTION_ACTION_SUCCESS',
    UPDATE_TOP_LEFT_SECTION_ACTION_FAILURE: 'UPDATE_TOP_LEFT_SECTION_ACTION_FAILURE',

    UPDATE_TOP_RIGHT_SECTION_ACTION_REQUEST: 'UPDATE_TOP_RIGHT_SECTION_ACTION_REQUEST',
    UPDATE_TOP_RIGHT_SECTION_ACTION_SUCCESS: 'UPDATE_TOP_RIGHT_SECTION_ACTION_SUCCESS',
    UPDATE_TOP_RIGHT_SECTION_ACTION_FAILURE: 'UPDATE_TOP_RIGHT_SECTION_ACTION_FAILURE',

    ADD_HEADING_TOP_RIGHT_SECTION_ACTION_REQUEST: 'ADD_HEADING_TOP_RIGHT_SECTION_ACTION_REQUEST',
    ADD_HEADING_TOP_RIGHT_SECTION_ACTION_SUCCESS: 'ADD_HEADING_TOP_RIGHT_SECTION_ACTION_SUCCESS',
    ADD_HEADING_TOP_RIGHT_SECTION_ACTION_FAILURE: 'ADD_HEADING_TOP_RIGHT_SECTION_ACTION_FAILURE',

    GET_EXPERT_SECTION_ACTION_REQUEST: 'GET_EXPERT_SECTION_ACTION_REQUEST',
    GET_EXPERT_SECTION_ACTION_SUCCESS: 'GET_EXPERT_SECTION_ACTION_SUCCESS',
    GET_EXPERT_SECTION_ACTION_FAILURE: 'GET_EXPERT_SECTION_ACTION_FAILURE',

    UPDATE_EXPERT_SECTION_ACTION_REQUEST: 'UPDATE_EXPERT_SECTION_ACTION_REQUEST',
    UPDATE_EXPERT_SECTION_ACTION_SUCCESS: 'UPDATE_EXPERT_SECTION_ACTION_SUCCESS',
    UPDATE_EXPERT_SECTION_ACTION_FAILURE: 'UPDATE_EXPERT_SECTION_ACTION_FAILURE',

    ADD_FEATURES_SECTION_ACTION_REQUEST: 'ADD_FEATURES_SECTION_ACTION_REQUEST',
    ADD_FEATURES_SECTION_ACTION_SUCCESS: 'ADD_FEATURES_SECTION_ACTION_SUCCESS',
    ADD_FEATURES_SECTION_ACTION_FAILURE: 'ADD_FEATURES_SECTION_ACTION_FAILURE',

    GET_FEATURES_SECTION_ACTION_REQUEST: 'GET_FEATURES_SECTION_ACTION_REQUEST',
    GET_FEATURES_SECTION_ACTION_SUCCESS: 'GET_FEATURES_SECTION_ACTION_SUCCESS',
    GET_FEATURES_SECTION_ACTION_FAILURE: 'GET_FEATURES_SECTION_ACTION_FAILURE',

    UPDATE_FEATURES_SECTION_ACTION_REQUEST: 'UPDATE_FEATURES_SECTION_ACTION_REQUEST',
    UPDATE_FEATURES_SECTION_ACTION_SUCCESS: 'UPDATE_FEATURES_SECTION_ACTION_SUCCESS',
    UPDATE_FEATURES_SECTION_ACTION_FAILURE: 'UPDATE_FEATURES_SECTION_ACTION_FAILURE',

    POST_FEATURES_SECTION_ACTION_REQUEST: 'POST_FEATURES_SECTION_ACTION_REQUEST',
    POST_FEATURES_SECTION_ACTION_SUCCESS: 'POST_FEATURES_SECTION_ACTION_SUCCESS',
    POST_FEATURES_SECTION_ACTION_FAILURE: 'POST_FEATURES_SECTION_ACTION_FAILURE',


    UPDATE_SOCIAL_MEDIA_SECTION_ACTION_REQUEST: 'UPDATE_SOCIAL_MEDIA_SECTION_ACTION_REQUEST',
    UPDATE_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS: 'UPDATE_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS',
    UPDATE_SOCIAL_MEDIA_SECTION_ACTION_FAILURE: 'UPDATE_SOCIAL_MEDIA_SECTION_ACTION_FAILURE',

    GET_SOCIAL_MEDIA_SECTION_ACTION_REQUEST: 'GET_SOCIAL_MEDIA_SECTION_ACTION_REQUEST',
    GET_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS: 'GET_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS',
    GET_SOCIAL_MEDIA_SECTION_ACTION_FAILURE: 'GET_SOCIAL_MEDIA_SECTION_ACTION_FAILURE',


    GET_GALLERY_SECTION_HEADING_ACTION_REQUEST: 'GET_GALLERY_SECTION_HEADING_ACTION_REQUEST',
    GET_GALLERY_SECTION_HEADING_ACTION_SUCCESS: 'GET_GALLERY_SECTION_HEADING_ACTION_SUCCESS',
    GET_GALLERY_SECTION_HEADING_ACTION_FAILURE: 'GET_GALLERY_SECTION_HEADING_ACTION_FAILURE',


    UPDATE_GALLERY_HEADING_SECTION_ACTION_REQUEST: 'UPDATE_GALLERY_HEADING_SECTION_ACTION_REQUEST',
    UPDATE_GALLERY_HEADING_SECTION_ACTION_SUCCESS: 'UPDATE_GALLERY_HEADING_SECTION_ACTION_SUCCESS',
    UPDATE_GALLERY_HEADING_SECTION_ACTION_FAILURE: 'UPDATE_GALLERY_HEADING_SECTION_ACTION_FAILURE',


    GET_GALLERY_SECTION_ACTION_REQUEST: 'GET_GALLERY_SECTION_ACTION_REQUEST',
    GET_GALLERY_SECTION_ACTION_SUCCESS: 'GET_GALLERY_SECTION_ACTION_SUCCESS',
    GET_GALLERY_SECTION_ACTION_FAILURE: 'GET_GALLERY_SECTION_ACTION_FAILURE',


    POST_GALLERY_SECTION_ACTION_REQUEST: 'POST_GALLERY_SECTION_ACTION_REQUEST',
    POST_GALLERY_SECTION_ACTION_SUCCESS: 'POST_GALLERY_SECTION_ACTION_SUCCESS',
    POST_GALLERY_SECTION_ACTION_FAILURE: 'POST_GALLERY_SECTION_ACTION_FAILURE',

    UPDATE_GALLERY_SECTION_ACTION_REQUEST: 'UPDATE_GALLERY_SECTION_ACTION_REQUEST',
    UPDATE_GALLERY_SECTION_ACTION_SUCCESS: 'UPDATE_GALLERY_SECTION_ACTION_SUCCESS',
    UPDATE_GALLERY_SECTION_ACTION_FAILURE: 'UPDATE_GALLERY_SECTION_ACTION_FAILURE',


    GET_APP_SECTION_ACTION_REQUEST: 'GET_APP_SECTION_ACTION_REQUEST',
    GET_APP_SECTION_ACTION_SUCCESS: 'GET_APP_SECTION_ACTION_SUCCESS',
    GET_APP_SECTION_ACTION_FAILURE: 'GET_APP_SECTION_ACTION_FAILURE',


    UPDATE_APP_SECTION_ACTION_REQUEST: 'UPDATE_APP_SECTION_ACTION_REQUEST',
    UPDATE_APP_SECTION_ACTION_SUCCESS: 'UPDATE_APP_SECTION_ACTION_SUCCESS',
    UPDATE_APP_SECTION_ACTION_FAILURE: 'UPDATE_APP_SECTION_ACTION_FAILURE',


    POST_CATEGORY_SECTION_ACTION_REQUEST: 'POST_CATEGORY_SECTION_ACTION_REQUEST',
    POST_CATEGORY_SECTION_ACTION_SUCCESS: 'POST_CATEGORY_SECTION_ACTION_SUCCESS',
    POST_CATEGORY_SECTION_ACTION_FAILURE: 'POST_CATEGORY_SECTION_ACTION_FAILURE',

    GET_CATEGORY_SECTION_ACTION_REQUEST: 'GET_CATEGORY_SECTION_ACTION_REQUEST',
    GET_CATEGORY_SECTION_ACTION_SUCCESS: 'GET_CATEGORY_SECTION_ACTION_SUCCESS',
    GET_CATEGORY_SECTION_ACTION_FAILURE: 'GET_CATEGORY_SECTION_ACTION_FAILURE',

    GET_RECIPE_CATEGORY_TITLES_ACTION_REQUEST: 'GET_RECIPE_CATEGORY_TITLES_ACTION_REQUEST',
    GET_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS: 'GET_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS',
    GET_RECIPE_CATEGORY_TITLES_ACTION_FAILURE: 'GET_RECIPE_CATEGORY_TITLES_ACTION_FAILURE',


    POST_RECIPE_CATEGORY_TITLES_ACTION_REQUEST: 'POST_RECIPE_CATEGORY_TITLES_ACTION_REQUEST',
    POST_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS: 'POST_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS',
    POST_RECIPE_CATEGORY_TITLES_ACTION_FAILURE: 'POST_RECIPE_CATEGORY_TITLES_ACTION_FAILURE',

}