import React, { Component } from "react";
import humanizeDuration from "humanize-duration";
import "./EpisodesList.scss";
import Login from "../../../Common/Login/Login";
import { Modal } from "react-bootstrap";

class EpisodesList extends Component {
  state = {
    LoginModal: false,

    userObject: null
  };
  componentDidMount() {
    let userObject = JSON.parse(localStorage.getItem("user"));

    this.setState({
      userObject: userObject
    });
  }
  LoginModalHandler = () => {
    this.setState({
      LoginModal: !this.state.LoginModal
    });
  };

  routeHandler = (id) => {
    this.props.history.push(`/watch-show/${id}`);
    window.location.reload();
  };

  handlePremiumRedirect = (ItemId) => {
    localStorage.setItem("episodeId", ItemId);
    this.setState({ LoginModal: !this.state.LoginModal });
  };

  render() {
    const { episodes } = this.props;
    const { userObject } = this.state;
  

    return (
      <div className="common-dashboard suggestions-wapper">
        <ul className="suggestion-list">
          {episodes && episodes.length ? (
            episodes.map((episode) => (
              <li
                key={episode.id}
                className="suggestion-detail d-flex mb-4 text-dark cursor-pointer"
                // href={`/watch-show/${episode.id}`}
                onClick={() => {
                  if (userObject) {
                    this.routeHandler(episode.id);
                  } else {
                    { episode && episode.is_premium ? this.handlePremiumRedirect(episode.id) : this.routeHandler(episode.id) }
                  }
                }}
              >
                <figure className="position-relative">
                  <img
                    src={episode && episode.title_image ? episode.title_image : `https://image.mux.com/${episode && episode.video_playback_id
                      }/thumbnail.png?width=400&height=200&fit_mode=smartcrop&time=35`}
                    alt="suggestions-item"
                    className="img-fit border-6 mr-4"
                  />
                  {episode && episode.is_premium ? (
                    <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                      <img
                        src="../assets/images/royal-crown.png"
                        alt="crown"
                        className="mr-1"
                      />
                      <span className="text">Premium</span>
                    </span>
                  ) : null}
                </figure>

                <span className="right d-flex flex-column align-items-around">
                  <span className="font-weight-semi font-poppins">
                    {episode["title"]}
                  </span>
                  <span className="time text-grey-4 font-weight-medium font-smallest">
                    {episode["video_details"] &&
                      episode["video_details"]["duration"]
                      ? humanizeDuration(
                        episode["video_details"]["duration"] * 1000
                      )
                      : null}
                  </span>
                </span>
              </li>
            ))
          ) : (
            <p className="text-danger">No Episodes to show!</p>
          )}
        </ul>

        {/*  popup  */}
        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.LoginModal}
          onHide={this.LoginModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content p-0 position-relative">
            <span
              onClick={this.LoginModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <Login history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default EpisodesList;
