import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AdminRoute = ({ component: Component, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return (
        <Route {...rest} render={props => (
            (user !== null && user.user.role === 'admin')
                ? <Component {...props} />
                : <Redirect to='/home-page-new' />
        )}/>
    )
}