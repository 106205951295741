import React, { Component } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import "./SubscriptionPopup.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get_profile_content_action } from "../../../../_actions/User";
import * as PaymentActions from "../../../../_actions/User/payment.action";

class SubscriptionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /* subscriptionModal: this.props &&
            this.props.details &&
            this.props.details['is_premium'] ?
                !this.props.details['is_premium']
                : true */
      per_product:
        props &&
        props.pricing &&
        props.pricing.filter(
          (price) => price && price.membership_type === "per_product"
        ),
      monthly:
        props &&
        props.pricing &&
        props.pricing.filter(
          (price) => price && price.membership_type === "monthly"
        ),
      annual:
        props &&
        props.pricing &&
        props.pricing.filter(
          (price) => price && price.membership_type === "annual"
        ),
      upgradeAmount: null,
    };
  }

  componentDidMount = async () => {
    await this.props.getProfileContent();
    const { getProfileContentData } = this.props;

    /*********************checking for single product purchse starts here**************/

    let purchasedItems =
      getProfileContentData && getProfileContentData.purchased_items;
    let productId = this.props && this.props.details && this.props.details.id;

    const checkItems = () => {
      let productPurchaseStatus = false;
      purchasedItems &&
        purchasedItems.forEach((item, index) => {
          Object.values(item).forEach((subitem) => {
            if (subitem == productId) {
              productPurchaseStatus = true;
              return;
            }
          });
        });
      return productPurchaseStatus;
    };

    this.setState({ productPurchased: checkItems() });
    /*********************checking for single product purchse ends here**************/
  }; //end of ComponentDidmount()

  subscriptionModalHandler = () => {
    this.setState({
      subscriptionModal: !this.state.subscriptionModal,
    });
  };
  handleBuy = (details, plan) => {
    localStorage.setItem("productDetail", JSON.stringify(details));
    const { per_product, monthly, annual } = this.state;
    var plan = {
      plan: plan,
      amount:
        plan == "per_product"
          ? per_product[0] && per_product[0].price
          : plan == "monthly"
            ? monthly[0] && monthly[0].price
            : plan == "annual"
              ? annual[0] && annual[0].price
              : null,
    };
    localStorage.setItem("plan", JSON.stringify(plan));
    this.props.history.push("/payment");
  };
  render() {
    const details = this.props && this.props.details;
    const isPremium =
      this.props && this.props.details && this.props.details.is_premium;
    const { per_product, monthly, annual, productPurchased } = this.state;

    const { getProfileContentData } = this.props;
    const membership =
      getProfileContentData &&
      getProfileContentData.membership_type &&
      getProfileContentData.membership_type.membership;
    const membershipType = membership && membership.membership_type;

    return (
      <>
        {membershipType == "monthly" ? null : membershipType ==
          "annual" ? null : isPremium == true ? (
            productPurchased == false ? (
              <>
                <Modal
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  //show={isPremium?!productPurchased:false}
                  show={!productPurchased}
                  ///onHide={this.subscriptionModalHandler}
                  className="text-center Subscription-popup"
                >
                  <Modal.Body className="subscription-popup-content px-4">
                    <h5 className="pt-2">
                      Buy Our Premium plan and continue watching the exciting
                      episodes
                    </h5>
                    <Row className="mt-4">
                      <Col md={4}>
                        <h2 className="plan-price text-primary">
                          ${(per_product && per_product[0] && per_product[0].price)}
                          {/* ${(per_product && per_product[0] && per_product[0].price) / 100} */}
                        </h2>
                        <h5 className="font-montserrat">Per episode</h5>
                        <Button
                          variant=""
                          className="text-warning cursor-pointer"
                        >
                          <span
                            className="h5 font-montserrat"
                            onClick={() => this.handleBuy(details, "per_product")}
                          >
                            Buy Now
                          </span>
                        </Button>
                      </Col>
                      <Col md={4}>
                        <h2 className="plan-price text-primary">
                          {/* $99 */}
                          ${(monthly && monthly[0] && monthly[0].price)}
                          {/* ${(monthly && monthly[0] && monthly[0].price) / 100} */}
                        </h2>
                        <h5 className="font-montserrat">Monthly</h5>
                        <Button
                          variant=""
                          className="text-warning cursor-pointer"
                        >
                          <span
                            className="h5 font-montserrat"
                            onClick={() => this.handleBuy(details, "monthly")}
                          >
                            Buy Now
                          </span>
                        </Button>
                      </Col>
                      <Col md={4}>
                        <h2 className="plan-price text-primary">

                          ${annual && annual[0] && annual[0].price}
                          {/* {(annual && annual[0] && annual[0].price) / 100} */}
                        </h2>
                        <h5 className="font-montserrat">Annual</h5>
                        <Button
                          variant=""
                          className="text-warning cursor-pointer"
                        >
                          <span
                            className="h5 font-montserrat"
                            onClick={() => this.handleBuy(details, "annual")}
                          >
                            Buy Now
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
              </>
            ) : null
          ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProfileContent: () => dispatch(get_profile_content_action()),
  //getUpgradeAmount : ()=> dispatch(PaymentActions.getupgrade_amount_payment_action()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionPopup)
);
