import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Collection.scss";
import { withRouter } from "react-router";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import { Row, Col } from "react-bootstrap";
class Collection extends Component {
  constructor() {
    super();
    this.state = {
      collections: [],
      isApiloading: false,
      products: [],
    };
  }

  componentDidMount() {
    this.setState({ isApiloading: true }, () => {
      this.props.client.collection.fetchAllWithProducts().then((collections) => {
        this.setState({
          isApiloading: false,
          collections: collections,
        });
      });

      this.props.client.product.fetchAll().then((products) => {
        this.setState({
          isApiloading: false,
          products: products,
        });
      });
    })
  }

  routeHandler = (collection) => {
    localStorage.setItem("currentCollectionId", collection && collection.id);
    localStorage.setItem(
      "currentCollectionTitle",
      collection && collection.title
    );
    localStorage.setItem(
      "currentCollectionHandle",
      collection && collection.handle
    );
    this.props.history.push({ pathname: "/collections", detail: collection });
  };

  render() {
   
    return (

      <div className="collection-wrapper mt-5 mx-n1">
             
        <h3 className="font-weight-semi my-4 ml-2">See all collection below</h3>

        <Row>
          {this.state.isApiloading ?
            <div
              style={{
                margin: "50px auto 0",
                textAlign: "center",
              }}
            >
              <ContentLoader color="text-primary" />
            </div>
            : this.state.collections.map((collection) => (
              <Col lg={3} md={4} sm={6}>

                <div className="collection-slide shadow p-2 rounded-lg cursor-pointer">
                  <div
                    className="img-box border-6"
                    onClick={() => this.routeHandler(collection)}
                  >
                    <img
                      src={
                        collection.image
                          ? collection.image.src
                          : "/assets/images/collection-1.svg"
                      }
                      alt="slider-img-1"
                      className="border-6 img-fit w-100"
                    />
                  </div>
                  <h4 className="title font-weight-semi mb-0 mt-2">
                    {collection.title}
                  </h4>
                  {/* <h6 className="text-grey-4 font-weight-semi">
                    by DariusCooks
                  </h6> */}
                </div>

              </Col>
            ))}
        </Row>

      </div>
    );
  }
}
export default withRouter(Collection);
