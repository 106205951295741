export const PaymentConstants = {
    
    SINGLE_PRODUCT_PAYMENT_REQUEST: "SINGLE_PRODUCT_PAYMENT_REQUEST",
    SINGLE_PRODUCT_PAYMENT_SUCCESS: "SINGLE_PRODUCT_PAYMENT_SUCCESS",
    SINGLE_PRODUCT_PAYMENT_FAILURE: "SINGLE_PRODUCT_PAYMENT_FAILURE",

    PAYMENT_SUBSCRIPTION_REQUEST:"PAYMENT_SUBSCRIPTION_REQUEST",
    PAYMENT_SUBSCRIPTION_SUCCESS:"PAYMENT_SUBSCRIPTION_SUCCESS",
    PAYMENT_SUBSCRIPTION_FAILURE:"PAYMENT_SUBSCRIPTION_FAILURE",

    PAYMENT_GET_UPGRADE_AMOUNT_REQUEST:"PAYMENT_GET_UPGRADE_AMOUNT_REQUEST",
    PAYMENT_GET_UPGRADE_AMOUNT_SUCCESS:"PAYMENT_GET_UPGRADE_AMOUNT_SUCCESS",
    PAYMENT_GET_UPGRADE_AMOUNT_FAILURE:"PAYMENT_GET_UPGRADE_AMOUNT_FAILURE",

    PAYMENT_UPGRADE_REQUEST:"PAYMENT_UPGRADE_REQUEST",
    PAYMENT_UPGRADE_SUCCESS:"PAYMENT_UPGRADE_SUCCESS",
    PAYMENT_UPGRADE_FAILURE:"PAYMENT_UPGRADE_FAILURE",

    CANCEL_SUBSCRIPTION_REQUEST:"CANCEL_SUBSCRIPTION_REQUEST",
    CANCEL_SUBSCRIPTION_SUCCESS:"CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_FAILURE:"CANCEL_SUBSCRIPTION_FAILURE",

    PURCHASED_PRODUCT_LAST_MONTH_REQUEST:"PURCHASED_PRODUCT_LAST_MONTH_REQUEST",
    PURCHASED_PRODUCT_LAST_MONTH_SUCCESS:"PURCHASED_PRODUCT_LAST_MONTH_SUCCESS",
    PURCHASED_PRODUCT_LAST_MONTH_FAILURE:"PURCHASED_PRODUCT_LAST_MONTH_FAILURE"
    
}