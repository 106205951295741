import React, { Component } from "react";
import {
  Container,
  Form,
  Button,
  Modal,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import "./UploadNewEpisode.scss";
import TopTab from "../../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
import { connect } from "react-redux";
import {
  save_episode_datails_action,
  get_episode_details_action,
  update_episode_details_action,
  delete_episode_details_action,
} from "../../../../../_actions/Admin/uploadEpisode.action";
import {
  create_mux_url_action,
  get_playback_asset_id_action,
} from "../../../../../_actions/Admin/uploadVideo.action";
import { get_saved_show_details_action } from "../../../../../_actions/Admin/uploadShow.action";
import { add_post_tags_action } from "../../../../../_actions/Admin";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ContentLoader from "../../../../Common/Loader/ContentLoader";
import { Link } from "react-router-dom";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import * as UpChunk from "@mux/upchunk";
import { isEmptyObject } from "../../../../../_helpers/validators";
import swal from "sweetalert";

class UploadNewEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteEpisodeActionModal: false,
      isApiLoading: false,
      episodeName: "",
      videoSrc: null,
      videoFileName: "",
      isPremium: false,
      loading: false,
      submitTriggered: false,
      submitReceipeTriggered: false,
      inputError: { type: "", text: "" },
      episodeDetail: "",
      deleteActionModal: false,
      savedShow: "",
      updateVideoFileName: "",
      showId: "",
      successMessage: "",
      errorText: "",
      currentEpisodeId: "",
      instructionActionModal: false,
      tags: [],
      inputVisible: false,
      inputValue: "",
      authenticatedId: null,
      authenticatedUploadUrl: null,
      muxAssetId: null,
      muxAssetPlayebackId: null,
      uploadedStatus: null,
      progressModal: false,
      imageName: "",
      imageSrc: "",
      imageUrl: null,
    };
  }

  componentDidMount() {
    this.callGetUploadUrlApi();
  }
  callGetUploadUrlApi = async () => {
    await this.props.getUploadUrl();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.uploadUrl !== this.props.uploadUrl) {
      if (
        !isEmptyObject(this.props.uploadUrl) &&
        this.props.uploadUrl.url &&
        this.props.uploadUrl.url.length
      )
        this.setState({
          authenticatedUploadUrl: this.props.uploadUrl.url,
          authenticatedId: this.props.uploadUrl.id,
        });
    }
    if (prevProps.muxAssetsData !== this.props.muxAssetsData) {
      if (
        !isEmptyObject(this.props.muxAssetsData) &&
        this.props.muxAssetsData.asset_id &&
        this.props.muxAssetsData.playback_ids &&
        this.props.muxAssetsData.playback_ids.length
      ) {
        this.setState(
          (prevState) => ({
            ...prevState,
            muxAssetId: this.props.muxAssetsData.asset_id,
            muxAssetPlayebackId: this.props.muxAssetsData.playback_ids[0].id,
          })
          // ,
          //   () => {
          //     this.handleSubmitForm();
          //   }
        );
      }
    }
    if (prevProps.episodeDetail !== this.props.episodeDetail) {
      this.setState((prev) => ({
        episodeDetail:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data,
        episodeName:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.title,
        videoFileName:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.video_file_name,
        isPremium:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.is_premium,
        muxAssetPlayebackId:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.video_playback_id,
        muxAssetId:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.video_asset_id,
        imageUrl:
          this.props &&
          this.props.episodeDetail &&
          this.props.episodeDetail.data &&
          this.props.episodeDetail.data.title_image,
      }));
    }
  }

  componentWillMount = async () => {
    const { params } = this.props.match;

    if (params && params.contentType == "show") {
      this.setState({ showId: Number(params.contentId) });
    } else if (params && params.contentType == "episode") {
      this.setState(
        { currentEpisodeId: Number(params.contentId) },
        async () => {
          const { currentEpisodeId } = this.state;

          const savedEpisodedetail = await this.props.getEpisodeDetail(
            currentEpisodeId
          );
          this.setState({
            showId: savedEpisodedetail.data.show,
            episodeDetail: savedEpisodedetail.data,
            videoFileName:
              savedEpisodedetail &&
              savedEpisodedetail.data &&
              savedEpisodedetail.data.video_file_name,
            episodeName: savedEpisodedetail.data.title,
            isPremium: savedEpisodedetail.data.is_premium,
            // videoSrc:
            //   savedEpisodedetail &&
            //   savedEpisodedetail.data &&
            //   savedEpisodedetail.data.video,
            tags:
              savedEpisodedetail &&
              savedEpisodedetail.data &&
              savedEpisodedetail.data.tags_names,
            imageUrl:
              this.props &&
              this.props.episodeDetail &&
              this.props.episodeDetail.data &&
              this.props.episodeDetail.data.title_image,
          });
        }
      );
    } else {
      return;
    }
  };

  getMuxAssetData = async () => {
    await this.props.onGetMuxAssetData(this.state.authenticatedId);
  };

  uploadVideoToMux = () => {
    this.setState({
      progressModal: true,
    });
    const data = {
      endpoint: this.state.authenticatedUploadUrl,
      file: this.state.videoSrc,
      chunkSize: 5120,
    };

    const upload = UpChunk.createUpload({
      ...data,
    });

    // subscribe to events
    upload.on("error", (err) => {
      console.error("💥 🙀", err.detail);
    });

    upload.on("progress", (progress) => {
      this.setState({
        uploadedStatus: progress.detail,
      });
    });

    // subscribe to events
    upload.on("success", (data) => {
      this.getMuxAssetData();
      swal({
        title: "Video Uploaded Successfully!",
        // text: "You clicked the button!",
        icon: "success",
      }).then(() => {
        this.setState({
          progressModal: false,
        });
        this.handleSubmitForm();
      });
    });
  };

  handleContentSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, isApiLoading: true });
    // this.uploadVideoToMux();
  };

  deleteEpisodeActionModalHandler = (recipe) => {
    this.setState({
      deleteEpisodeActionModal: !this.state.deleteEpisodeActionModal,
      deleteRecipeId: recipe.id,
    });
  };
  deleteRecipe = async () => {
    const { deleteRecipeId } = this.state;
    await this.props.onUpdateEpisodeDetails(this.state.currentEpisodeId, {
      delete_connected_recipe: [deleteRecipeId],
    });
    this.setState(
      {
        deleteEpisodeActionModal: !this.state.deleteEpisodeActionModal,
        deleteRecipeId: "",
      },
      async () => {
        await this.props.getEpisodeDetail(this.state.currentEpisodeId);
      }
    );
  };

  deleteActionModalHandler = (episodeDetail) => {
    if (episodeDetail && episodeDetail.is_premium == false) {
      this.setState({
        deleteActionModal: !this.state.deleteActionModal,
      });
    } else {
      this.setState({
        instructionActionModal: !this.state.instructionActionModal,
      });
    }
  };
  deleteActionModalHandlerClose = () => {
    this.setState({
      deleteActionModal: !this.state.deleteActionModal,
    });
  };

  instructionActionModalHandler = () => {
    this.setState({
      instructionActionModal: !this.state.instructionActionModal,
    });
  };
  deleteEpisode = async () => {
    const deletedEpisode = await this.props.onDeleteEpisode(
      this.state.currentEpisodeId
    );
    this.setState(
      {
        deleteActionModal: !this.state.deleteActionModal,
      },
      async () => {
        this.props.history.push(
          `/update-show-content/show/${this.state.showId}`
        );
      }
    );
  };
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "episodeName" && !this.state.episodeName.length) {
        this.setState({
          inputError: { type: "episodeName", text: `Title is required!` },
        });
      } else {
        this.setState({ inputError: { type: "", text: `` } });
      }
    });
  };
  handleVideoChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    this.setState(
      (prevState) => ({
        videoSrc: file ? file : prevState.videoSrc,
        videoFileName: file && file.name ? file.name : prevState.videoFileName,
      }),
      () => {
        this.uploadVideoToMux();
      }
    );
  };

  handleUploadFile = (e, type) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (type === "image" && uploadedFileType.includes("image")) {
      this.setState({
        imageSrc: file,
        imageName: file.name,
      });
    } else {
      swal("Wrong File type encountered! Please try again");
      return;
    }
  };

  handleSubmitForm = async (e) => {
    // e.preventDefault();

    const {
      episodeName,
      videoSrc,
      isPremium,
      showId,
      muxAssetPlayebackId,
      muxAssetId,
      videoFileName,
    } = this.state;
    const params = this.props && this.props.match && this.props.match.params;

    if (params && params.contentType == "episode") {
      const { episodeName, videoSrc, isPremium, showId, videoFileName } =
        this.state;

      this.setState({ loading: true });
      this.setState({ isApiLoading: true });
      const detailsForm = new FormData();

      detailsForm.append("title", episodeName);
      // if (typeof videoSrc !== "string") {
      //   detailsForm.append("video", videoSrc);
      // }
      if (this.state.imageSrc)
        detailsForm.append("title_image", this.state.imageSrc);

      detailsForm.append("is_premium", isPremium);
      detailsForm.append("show", showId);
      detailsForm.append("video_asset_id", muxAssetId);
      detailsForm.append("video_playback_id", muxAssetPlayebackId);
      detailsForm.append("video_file_name", videoFileName);
      const updateEpisode = await this.props.onUpdateEpisodeDetails(
        this.state.currentEpisodeId,
        detailsForm
      );

      const Episodedetail = await this.props.getEpisodeDetail(
        this.state.currentEpisodeId
      );

      this.setState(
        {
          loading: false,
          submitTriggered: true,
          isApiLoading: false,
          episodeDetail: Episodedetail.data,
          currentEpisodeId: Episodedetail.data.id,
          showId: Episodedetail.data.show,
          videoFileName: Episodedetail.data.video_file_name,
          episodeName: Episodedetail.data.title,
          videoSrc:
            Episodedetail && Episodedetail.data && Episodedetail.data.video,
        },
        () => {
          this.props.history.push(`/update-content/show/${this.state.showId}`);
        }
      );
    } else if (!episodeName || !muxAssetPlayebackId || !muxAssetId) {
      return;
    } else {
      const showId =
        this.props && this.props.match
          ? this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.contentId
          : "";
      const { episodeName, videoSrc, muxAssetPlayebackId, muxAssetId } =
        this.state;
      this.setState({ loading: true });

      this.setState({ isApiLoading: true });
      const detailsForm = new FormData();
      detailsForm.append("title", episodeName);
      detailsForm.append("is_premium", isPremium);
      detailsForm.append("show", Number(showId));
      detailsForm.append("video_asset_id", muxAssetId);
      detailsForm.append("video_playback_id", muxAssetPlayebackId);
      detailsForm.append("video_file_name", videoFileName);

      const saveEpisode = await this.props.onSaveEpisodeDetails(detailsForm);
      const Episodedetail = await this.props.getEpisodeDetail(
        saveEpisode.data.id
      );

      this.setState(
        {
          loading: false,
          submitTriggered: true,
          episodeDetail: Episodedetail.data,
          currentEpisodeId: Episodedetail.data.id,
          showId: Episodedetail.data.show,
          videoFileName:
            Episodedetail &&
            Episodedetail.data &&
            Episodedetail.data.video_file_name,
          episodeName: Episodedetail.data.title,
          isApiLoading: false,
          successMessage: `Your episode details have been saved successfully!`,
          videoSrc:
            Episodedetail && Episodedetail.data && Episodedetail.data.video,
          uploadedStatus: null,
          // && Episodedetail.data.video_details.encodings[0]
          // && Episodedetail.data.video_details.encodings[0].video_file_url
        },
        async () => {
          this.props.history.push(
            `/update-episode-content/episode/${this.state.currentEpisodeId}`
          );
        }
      );
    }
  };
  handleRecipeClick = () => {
    this.props.history.push(
      `/upload-recipe-instructions/episode/${this.state.currentEpisodeId}/new-recipe`
    );
  };

  handleEditRecipeClick = (recipeId) => {
    this.props.history.push(
      `/edit-recipe-instructions/episode/${this.state.currentEpisodeId}/edit-recipe/${recipeId}`
    );
  };
  handleBack = () => {
    this.props.history.push(`/update-show-content/show/${this.state.showId}`);
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags }, async () => {
      let { tags, episodeDetail } = this.state;
      const tagsData = {
        tags: tags,
        episode: episodeDetail && episodeDetail.id,
      };
      const tagsRes = await this.props.addTags(tagsData);
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState(
      {
        tags,
        inputVisible: false,
        inputValue: "",
      },
      async () => {
        let { tags, episodeDetail } = this.state;
        const tagsData = {
          tags: tags,
          episode: episodeDetail && episodeDetail.id,
        };
        const tagsRes = await this.props.addTags(tagsData);
      }
    );
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        color="#87449a"
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };
  render() {
    const {
      episodeName,
      videoFileName,
      videoSrc,
      isPremium,
      submitTriggered,
      inputError,
      isApiLoading,
      successMessage,
      errorText,
      episodeDetail,
      tags,
      inputVisible,
      inputValue,
      video_playback_id,
      video_asset_id,
      uploadedStatus,
      muxAssetPlayebackId,
      muxAssetId,
      imageName,
      imageSrc,
    } = this.state;
    const { params } = this.props && this.props.match;
    const tagChild = tags.map(this.forMap);
    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu />
          <TopTab
            activeTab="Upload Content"
            tabCategoryName="contentManagment"
          />
          <div className="main-content  bg-white">
            <div className="upload-new-content">
              <Container className="px-0 ">
                <Row>
                  <Col sm={3}>
                    <span
                      className="btn-back  cursor-pointer font-weight-bold mb-3 d-flex align-items-center"
                      onClick={this.handleBack}
                    >
                      <img src="/assets/images/back-arrow.svg" alt="" /> Back
                    </span>
                  </Col>
                  <Col lg={6} md={9}>
                    <div className="upload-new-content-wrapper">
                      <h2 className="font-weight-semi pb-2">
                        {params && params.contentType == "episode"
                          ? `Edit Episode`
                          : `Upload New Episode`}
                      </h2>
                      <div className="content mt-5">
                        <Form>
                          <div className="form-outer p-3  bg-grey-4 fs-16 font-weight-semi">
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Episode </span>
                              <a href="#" className="ml-2">
                                {params && params.contentType == "episode" ? (
                                  <img
                                    src="/assets/images/delete-content.svg"
                                    alt="delete"
                                    onClick={() =>
                                      this.deleteActionModalHandler(
                                        episodeDetail
                                      )
                                    }
                                  />
                                ) : null}
                              </a>
                            </div>

                            <Form.Group>
                              <div className="tile-video-checkbox mt-4 fs-14 text-dark-3 d-flex align-items-center justify-content-between">
                                <span className="font-weight-bold text-grey-4 ">
                                  Title and video
                                </span>
                                <div className="custom-control custom-checkbox  info">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="premium-content"
                                    name="PremiumContent"
                                    value={isPremium}
                                    onChange={() =>
                                      this.setState({
                                        isPremium: !this.state.isPremium,
                                      })
                                    }
                                    checked={isPremium ? true : false}
                                  />
                                  <label
                                    className="custom-control-label font-weight-semi ml-2"
                                    htmlFor="premium-content"
                                  >
                                    Check if this is premium content
                                  </label>
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="Enter Episode Name"
                                name="episodeName"
                                value={episodeName}
                                // className="bg-white font-weight-semi py-2 h-auto "
                                onChange={this.handleChange}
                                className={`font-weight-semi fs-16 text-black ${inputError.type === "episodeName"
                                  ? "input-invalid"
                                  : ""
                                  }`}
                                required
                              />
                              {inputError.type === "episodeName" &&
                                inputError.text.length && (
                                  <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                    {inputError.text}
                                  </p>
                                )}
                            </Form.Group>

                            <div className="upload-video d-flex align-items-center">
                              <label
                                htmlFor="videoupload"
                                className="btn btn-outline-dark rounded py-1 px-3"
                                onClick={
                                  episodeName?.length < 3
                                    ? () => {
                                      swal("Please enter episode name!");
                                    }
                                    : null
                                }
                              >
                                <input
                                  disabled={episodeName?.length < 3}
                                  type="file"
                                  className="d-none"
                                  id="videoupload"
                                  onChange={(e) => this.handleVideoChange(e)}
                                  accept="video/*"
                                  required
                                />
                                {videoFileName ? videoFileName : "Upload Video"}
                              </label>
                              <img
                                src="/assets/images/upload-video.svg"
                                alt="upload-video"
                                className="ml-2"
                              />
                            </div>
                            <hr className="bg-info my-4" />
                            <Form.Group className="bg-grey-4 p-3">
                              <Form.Label className="mb-2">Images</Form.Label>
                              <label
                                htmlFor="titleImage"
                                className="btn btn-block text-grey-5 bg-white rounded"
                              >
                                <input
                                  type="file"
                                  className="d-none invisible"
                                  id="titleImage"
                                  name="videoImage"
                                  onChange={(e) =>
                                    this.handleUploadFile(e, "image")
                                  }
                                  accept="image/png, image/jpg, image/jpeg"
                                  required
                                />
                                {imageName ? imageName : <>
                                  <span className="fa fa-upload mr-3"></span>Add Title Image
                                </>}
                              </label>
                              {this.state.imageUrl ? (
                                <img
                                  className="mt-3"
                                  src={this.state.imageUrl}
                                  alt="upload image"
                                />
                              ) : null}
                              {!imageSrc && submitTriggered ? (
                                <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                  Field required!
                                </p>
                              ) : null}
                            </Form.Group>

                            <hr className="bg-info my-4" />
                            {episodeDetail ? (
                              <Form.Group>
                                <div className="bg-grey-4 fs-16 font-weight-semi">
                                  <p className="fs-14 font-weight-bold text-grey-5 mb-3">
                                    Add Tags
                                  </p>
                                  <>
                                    <div style={{ marginBottom: 16 }}>
                                      <TweenOneGroup
                                        enter={{
                                          scale: 0.8,
                                          opacity: 0,
                                          type: "from",
                                          duration: 100,
                                        }}
                                        onEnd={(e) => {
                                          if (
                                            e.type === "appear" ||
                                            e.type === "enter"
                                          ) {
                                            e.target.style =
                                              "display: inline-block";
                                          }
                                        }}
                                        leave={{
                                          opacity: 0,
                                          width: 0,
                                          scale: 0,
                                          duration: 200,
                                        }}
                                        appear={false}
                                      >
                                        {tagChild}
                                      </TweenOneGroup>
                                    </div>
                                    {inputVisible && (
                                      <Input
                                        ref={this.saveInputRef}
                                        type="text"
                                        size="small"
                                        style={{ width: 78 }}
                                        value={inputValue}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputConfirm}
                                        onPressEnter={this.handleInputConfirm}
                                      />
                                    )}
                                    {!inputVisible && (
                                      <Tag
                                        onClick={this.showInput}
                                        className="site-tag-plus"
                                      >
                                        <PlusOutlined /> New Tag
                                      </Tag>
                                    )}
                                  </>
                                </div>
                                <hr className="bg-info my-4" />
                              </Form.Group>
                            ) : null}
                            <Form.Group>
                              <Form.Label className="mb-2 fs-14 font-weight-bold text-grey-4">
                                Recipe
                              </Form.Label>
                              {episodeDetail &&
                                episodeDetail.connected_recipe &&
                                episodeDetail.connected_recipe.length ? (
                                episodeDetail.connected_recipe.map((recipe) => (
                                  <>
                                    <span className="d-flex mt-4">
                                      <button
                                        className="btn btn-block bg-white font-weight-semi py-2 mr-2 border-info rounded text-grey-4 text-left"
                                        onClick={() =>
                                          this.handleEditRecipeClick(
                                            recipe && recipe.id
                                          )
                                        }
                                        disabled={
                                          !videoFileName || !episodeName
                                            ? true
                                            : false
                                        }
                                      >
                                        {recipe.name}
                                      </button>
                                      <img
                                        src="/assets/images/delete-content.svg"
                                        onClick={() =>
                                          this.deleteEpisodeActionModalHandler(
                                            recipe
                                          )
                                        }
                                        alt="delete"
                                        className="cursor-pointer"
                                      />
                                    </span>
                                  </>
                                ))
                              ) : (
                                <>
                                  <span className="d-flex mt-4">
                                    <button
                                      className="btn btn-block bg-white font-weight-semi py-2 mr-2 border-info rounded text-grey-4 text-left"
                                      onClick={this.handleRecipeClick}
                                      disabled={
                                        !video_asset_id ||
                                          !video_playback_id ||
                                          (params &&
                                            params.contentType !== "episode")
                                          ? true
                                          : false
                                      }
                                    >
                                      Add Recipe
                                    </button>
                                  </span>
                                </>
                              )}
                            </Form.Group>

                            <button
                              className="text-info d-block mt-4  bg-transparent border-0"
                              onClick={this.handleRecipeClick}
                              disabled={
                                !videoFileName ||
                                  !episodeName ||
                                  (params && params.contentType !== "episode")
                                  ? true
                                  : false
                              }
                            >
                              + Add more
                            </button>
                          </div>

                          <Modal show={this.state.progressModal} centered>
                            <Modal.Header>
                              <Modal.Title>Upload Progress</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {uploadedStatus ? (
                                <div className=" text-center">
                                  <ProgressBar
                                    animated
                                    now={uploadedStatus}
                                    variant="success"
                                    label={`${uploadedStatus.toFixed(2)}%`}
                                  />
                                </div>
                              ) : null}
                            </Modal.Body>
                          </Modal>

                          <div className="action fs-14  d-flex justify-content-end mt-4">
                            <button
                              className="btn btn-outline-dark font-weight-semi py-2 px-3  rounded"
                              onClick={() =>
                                this.props.history.push(
                                  `/upload-content-listing`
                                )
                              }
                            >
                              Cancel
                            </button>

                            <button
                              className="btn btn-warning py-2 font-weight-semi px-3  ml-3 rounded"
                              onClick={this.handleSubmitForm}
                              disabled={
                                episodeDetail
                                  ? false
                                  : true
                              }
                            >
                              Save
                            </button>

                            {params && params.contentType !== "episode" ? (
                              <button
                                className="btn btn-primary py-2 px-3 font-weight-semi  ml-3 rounded"
                                disabled={
                                  !videoFileName ||
                                    !episodeName ||
                                    !muxAssetPlayebackId ||
                                    !muxAssetId
                                    ? true
                                    : false
                                }
                                onClick={this.handleSubmitForm}
                              >
                                {isApiLoading ? (
                                  <ContentLoader color="text-white" />
                                ) : (
                                  "Save Changes"
                                )}
                              </button>
                            ) : null}
                          </div>

                          {errorText.length ? (
                            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                              {errorText}
                            </p>
                          ) : (
                            ""
                          )}
                          {successMessage.length ? (
                            <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                              {successMessage}
                            </p>
                          ) : (
                            ""
                          )}
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>

              {/* NOT delete Episode -INSTRUCTION modal starts */}
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.instructionActionModal}
                onHide={this.instructionActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    You cannot delete this episode since it has been marked as
                    premium content.
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.instructionActionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* NOT delete Episode -INSTRUCTION modal Ends */}
              {/**delete recipe modal starts*/}
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.deleteEpisodeActionModal}
                onHide={this.deleteEpisodeActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to delete this Recipe?
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.deleteEpisodeActionModalHandler}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    className="rounded"
                    onClick={this.deleteRecipe}
                  >
                    Delete Recipe
                  </Button>
                </Modal.Footer>
              </Modal>
              {/**delete recipe modal ends*/}
              {/* delete action modal */}
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.deleteActionModal}
                onHide={this.deleteActionModalHandler}
                className="text-center delete-action-popup"
              >
                <Modal.Body className="delete-action-popup-content">
                  <p className="font-poppins fs-18 text-center font-weight-medium">
                    Are you sure you want to delete this Episode? Once
                    deleted,All data will be lost.
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
                  <Button
                    onClick={this.deleteActionModalHandlerClose}
                    variant="outline-dark"
                    className="rounded"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="rounded"
                    onClick={this.deleteEpisode}
                  >
                    Delete Episode
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* delete action modal Ends */}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // publishedContent: state.publish_video_content_reducer,
    episodeDetail:
      state.get_episode_reducer && state.get_episode_reducer.updatedShow,
    //  && state.get_episode_reducer.updatedShow.data,
    uploadUrl: state.save_video_details_reducer.authenticatedUrl,
    muxAssetsData: state.save_video_details_reducer.muxAssetsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSavedShow: (showId) => dispatch(get_saved_show_details_action(showId)),
  onSaveEpisodeDetails: (data) => dispatch(save_episode_datails_action(data)),
  getEpisodeDetail: (id) => dispatch(get_episode_details_action(id)),
  onUpdateEpisodeDetails: (id, data) =>
    dispatch(update_episode_details_action(id, data)),
  onDeleteEpisode: (showId) => dispatch(delete_episode_details_action(showId)),
  addTags: (data) => dispatch(add_post_tags_action(data)),
  getUploadUrl: () => dispatch(create_mux_url_action()),
  onGetMuxAssetData: (id) => dispatch(get_playback_asset_id_action(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadNewEpisode)
);
