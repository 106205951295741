import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { Container, Row, Form, Col } from "react-bootstrap";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import NewEpisodes from "../common/NewEpisodes/NewEpisodes";
import {
  get_all_user_recipes_action,
  get_carousel_content_action,
  recipe_search_action
} from "../../../_actions/User";
import ContentLoader from "../../Common/Loader/ContentLoader";
import "./AllRecipes.scss";
import TopNotification from "../common/TopNotification/TopNotification";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import HeaderNew from "../HomePageNew/HeaderNew/HeaderNew";
import { get_announcement_table_list_action } from "../../../_actions/Admin";
import { debounce } from "../../../_helpers/debounce";
// import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";
class AllRecipes extends Component {
  constructor(props) {
    super(props)
    this.search = debounce(this.searchHandler)
  }

  state = {
    recipeLoading: false,
    recipeData: [],
    newAddition: null,
    loading: false,
    searchResultData: [],
    searchStatus: false,
    searchEnabled: false,
    updatingAnnouncements: false,
    topNotifications: [],
    pagination: {
      pageSize: 25,
      current: sessionStorage.getItem('currentRecipePagination')
        ? Number(sessionStorage.getItem('currentRecipePagination'))
        : 1,
      total: 0
    },

    searchText: null, // stack of chars
    userObject: null,
    promiseStack: [],
  };

  componentDidMount() {
    this.mountRecipeData();

    this.setUserData();
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState(
      {
        userObject: userObject
      },
      () => {
        if (userObject) {
          this.setContents();
          this.mountOrUpdateAnnouncements();
        }
      }
    );
  }

  setUserData = async () => {
    const { pagination } = this.state;
    this.setState({ loadingUserData: true });
    // get all user details and set data, prev and next link in state

    const userDetailsRes = await this.props.getAllUserSideRecipes(pagination.current);
    if (userDetailsRes) {
      this.setState(
        {
          userData: userDetailsRes.data.results,
          userArray: userDetailsRes.data.results,
          nextDataLink: userDetailsRes.data.next,
          prevDataLink: userDetailsRes.data.previous
        },
        () => {
          this.setMaxPagesValue();
        }
      );
    }
    this.setState({ loadingUserData: false });
  };

  setMaxPagesValue = () => {
    // calculate max number of pages required to accommodate all the user details
    const { entriesPerPage, userData } = this.state;
    const remainder = userData.length % entriesPerPage;
    const quotient = userData.length / entriesPerPage;
    let maxPagesNumber =
      remainder > 0 ? parseInt((quotient + 1).toString(), 10) : quotient;
    this.setState({ maxPages: maxPagesNumber }, () => {
      // manage current page number if it exceeds the max page capacity.
      const { currentPageNumber, maxPages } = this.state;
      if (currentPageNumber > maxPages)
        this.setState({ currentPageNumber: this.state.maxPages });
      if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
    });
  };

  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevDataLink,
      nextDataLink,
      userData,
      maxPages
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
        // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevDataLink !== null) {
          this.setState({ loadingUserData: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
            prevDataLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...userData);
            this.setState({
              userData: prevUserDataArray,
              prevDataLink: prevUserDataRes.previous
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
        // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextDataLink !== null) {
          this.setState({ loadingUserData: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
            nextDataLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = userData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              userData: nextUserDataArray,
              nextDataLink: nextUserDataRes.next
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    }
  };
  handlePageNumberChange = async (type) => {
    const {
      currentPageNumber,
      prevDataLink,
      nextDataLink,
      userData,
      maxPages
    } = this.state;
    if (type === "decrease") {
      if (currentPageNumber > 1)
        // decrease page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
      else {
        // hit the prev data api to fetch data of users
        if (prevDataLink !== null) {
          this.setState({ loadingUserData: true });
          const prevUserDataRes = await this.props.getPrevUserDetails(
            prevDataLink
          );
          if (prevUserDataRes) {
            const prevUserDataArray = prevUserDataRes.results;
            prevUserDataArray.push(...userData);
            this.setState({
              userData: prevUserDataArray,
              prevDataLink: prevUserDataRes.previous
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    } else if (type === "increase") {
      if (currentPageNumber < maxPages)
        // increase page number while it is greater than 0
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
      else {
        // hit the next data api to fetch data of users
        if (nextDataLink !== null) {
          this.setState({ loadingUserData: true });
          const nextUserDataRes = await this.props.getNextUserDetails(
            nextDataLink
          );
          if (nextUserDataRes) {
            const nextUserDataArray = userData;
            nextUserDataArray.push(...nextUserDataRes.results);
            this.setState({
              userData: nextUserDataArray,
              nextDataLink: nextUserDataRes.next
            });
          }
          this.setState({ loadingUserData: false });
        }
      }
    }
  };
  mountRecipeData = async () => {
    const { pagination } = this.state;
    this.setState({ recipeLoading: true });

    const getAllRecipeRes = await this.props.getAllUserSideRecipes(
      pagination.current
    );
    if (getAllRecipeRes && getAllRecipeRes.data && getAllRecipeRes.data.results)
      this.setState((prevState) => ({
        recipeData: getAllRecipeRes.data.results,
        pagination: {
          ...prevState.pagination,
          // current:getCurrentPage,
          total: getAllRecipeRes.data.count
        }
      }));

    this.setState({ recipeLoading: false });
    sessionStorage.removeItem("currentRecipePagination");
    window.scrollTo(0, 0);
  };

  setContents = async () => {
    this.setState({ loading: true });
    const carouselContentRes = await this.props.getCarouselContent();

    let LatestUpdated = [];
    if (carouselContentRes.data && carouselContentRes.data.length) {
      carouselContentRes.data.forEach((contentItem) => {
        if (
          contentItem["row_type"] === "update" &&
          contentItem["content_type"] &&
          contentItem["content_type"] === "youtube-recipe"
        ) {
          LatestUpdated = [...LatestUpdated, { ...contentItem }];
        }
      });
    }
    this.setState({
      newAddition: LatestUpdated.length
        ? LatestUpdated.sort((a, b) => a.priority - b.priority)
        : []
    });
    this.setState({ loading: false });
  };

  routeHandler = (id, type) => {
    this.props.history.push(`/recipe/${id}`);
    sessionStorage.setItem('currentRecipePagination',
      this.state && this.state.pagination && this.state.pagination.current);
  };
  searchRouteHandler = (item) => {
    if (item && item.show) {
      this.props.history.push(`/watch-show/${item && item.id}`);
    }
    else {
      this.props.history.push(`/recipe/${item && item.id}`);
    }
  };


  handleSearch = (e) => {
    e.preventDefault();
    let searchVal = e.target.value;
    let searchKeyword = { search: searchVal };
    // eslint-disable-next-line no-lone-blocks
    if (searchVal.length === "" || searchVal.length >= 2) {
      this.setState({ searchEnabled: true, searchResultData: [] });
      this.setState({ searchStatus: true, searchText: searchVal }, () => {
        this.search(searchKeyword);
      });
    } else {
      this.setState({ searchEnabled: false });
    }
  };


  checkSearch = () => {
    let val = this.state.promiseStack.filter(
      (item) => item.searchKey === this.state.searchText
    );
    if (val && val.length) {
      this.setState({ searchResultData: val[0].response });
    }
  };

  // search handler ===========
  searchHandler = async (searchKeyword) => {
    let searchResult = await this.props.recipeSearchResultData(searchKeyword);

    let searchResultData =
      searchResult && searchResult.data;
    if (
      searchResultData &&
      searchResultData.length
    ) {
      let newStack = this.state.promiseStack;

      newStack.push({
        searchKey: searchKeyword.search,
        response: searchResultData,
      });

      this.setState({ promiseStack: newStack }, () => {
        this.checkSearch();
      });
    }
    this.setState({ searchStatus: false });

  };

  mountOrUpdateAnnouncements = async () => {
    await this.setState({ updatingAnnouncements: true, topNotifications: [] });

    // re-fetch announcement details and show it again
    const getAnnouncementDetailsRes = await this.props.getAnnouncementData(
      "user"
    );
    if (
      getAnnouncementDetailsRes &&
      getAnnouncementDetailsRes.type &&
      getAnnouncementDetailsRes.type === "user"
    ) {
      if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.data
      ) {
        this.setState({
          topNotifications: getAnnouncementDetailsRes.response.data
        });
      }
    }

    this.setState({ updatingAnnouncements: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title)
    });
    this.mountOrUpdateAnnouncements();
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountRecipeData();
      }
    );
  };

  render() {
    const {
      recipeLoading,
      recipeData,
      searchResultData,
      pagination,
      userObject,
      searchStatus,
    } = this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          {/* <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          /> */}
          <div className="main-content bg-white position-relative">
            {userObject ? (
              <Header activeMenuItem="recipes" />
            ) : (
              <HeaderNew
                history={this.props.history}
                activeMenuItem="recipes"
              />
            )}

            {/* search handler */}
            <div className="top-search-wrapper flex-grow-1 mt-4 mb-4 mr-0 mr-sm-2 mr-xl-5">
              <Form.Group className="mb-0 h-100 position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search Recipes..."
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.handleSearch(e)}
                />
                <i className="fa fa-search fa-1x" aria-hidden="true"></i>
              </Form.Group>
            </div>

            {/* Announcement notifications */}

            {userObject ? (
              <>
                {this.state.topNotifications.length > 0 ? (
                  <TopNotification
                    updatingAnnouncements={this.state.updatingAnnouncements}
                    notifications={
                      this.state.topNotifications.length > 0
                        ? this.state.topNotifications
                        : []
                    }
                  />
                ) : this.state.updatingAnnouncements ? (
                  <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                    <ContentLoader color="text-warning" />
                  </div>
                ) : (
                  <p className="text-primary font-weight-medium">No notifications to show!</p>
                )}
              </>
            ) : null}

            {/* all video wrapper */}
            <div className="all-recipe-wrapper mt-3 mt-md-5">
              <NewEpisodes
                loading={this.state.loading}
                newVideos={this.state.newAddition}
                history={this.props.history}
              />
              <h3 className="font-weight-semi pt-4 mb-3">All Recipes</h3>

              {/* searched recipes */}


              {this.state.searchEnabled ? (
                <>

                  {searchStatus ? (
                    <div className="text-center">
                      <ContentLoader color="text-warning mx-auto mt-5" />
                    </div>
                  ) : (

                    <Row>
                      {Array.isArray(searchResultData) &&
                        searchResultData.length === 0 ? (
                        <div className="text-center p-4 w-100">
                          <img
                            src={"/assets/images/error.png"}
                            alt="no videos"
                            className="p-3"
                          />
                          <p className="text-danger font-weight-bold">
                            Oops! Result Not Found
                          </p>
                        </div>
                      ) : (
                        Array.isArray(searchResultData) &&
                        searchResultData.map((recipe) => (
                          <Col key={recipe.id} className="col-6 col-md-3 mb-5">
                            <div
                              className="video-box cursor-pointer"
                              onClick={() => this.searchRouteHandler(recipe)}
                            >
                              {recipe && recipe.title_image ? (
                                <img
                                  src={recipe && recipe.title_image}
                                  alt="Recipe image"
                                  className="border-6 img-fit w-100"
                                />
                              ) : (
                                <img
                                  src={"../assets/images/placeholder-recipe.jpg"}
                                  alt="Recipe image"
                                  className="border-6 img-fit w-100"
                                />
                              )}
                              <h4 className="title font-weight-semi my-2">
                                {recipe && recipe.title}
                              </h4>
                            </div>
                          </Col>
                        ))
                      )}
                    </Row>
                  )

                  }


                </>
              ) : (
                <>
                  <Row>
                    {recipeLoading ? (
                      <ContentLoader color="text-warning mx-auto mt-5" />
                    ) : recipeData && recipeData.length ? (
                      recipeData.map((recipe) => (
                        <Col key={recipe.id} className="col-6 col-md-3 mb-5">
                          <div
                            className="recipe-box cursor-pointer"
                            onClick={() => this.routeHandler(recipe.id)}
                          >
                            {recipe["title_image"] ? (
                              <img
                                src={recipe["title_image"]}
                                alt="recipe image"
                                className="border-6 img-fit w-100"
                              />
                            ) : (
                              <img
                                src={"../assets/images/placeholder-recipe.jpg"}
                                alt="recipe image"
                                className="border-6 img-fit w-100"
                              />
                            )}

                            <h4 className="title font-weight-semi mb-0">
                              {recipe["title"]}
                            </h4>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="col-12 mt-4 py-3 py-md-4 text-center ">
                        <img
                          src={"/assets/images/norecipes-home.png"}
                          alt="no recipes"
                          className="border border-danger rounded"
                        />
                        <h4 className="text-danger">No Recipes to Show</h4>
                      </div>
                    )}
                  </Row>
                  {recipeData && recipeData.length ?
                    <Pagination
                      current={pagination.current}
                      showSizeChanger={false}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      onChange={this.onHandlePaginationChange}
                    /> : null
                  }
                </>
              )}
            </div>
          </div>
        </Container >
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserSideRecipes: (page) => dispatch(get_all_user_recipes_action(page)),
  getCarouselContent: () => dispatch(get_carousel_content_action()),
  recipeSearchResultData: (data) => dispatch(recipe_search_action(data)),
  getAnnouncementData: (userType) =>
    dispatch(get_announcement_table_list_action(userType))
});

export default connect(null, mapDispatchToProps)(AllRecipes);
