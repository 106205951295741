import React, { Component } from "react";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import CommentsRecipeTabs from "../common/CommentsRecipeTabs/CommentsRecipeTabs";
import "./ViewRecipe.scss";
import { connect } from "react-redux";
import { get_recipe_by_id_action } from "../../../_actions/User";
import ContentLoader from "../../Common/Loader/ContentLoader";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";

import {
  add_bookmark_to_post_action,
  hit_like_action
} from "../../../_actions/User/post.action";
import ReactPlayer from "react-player";
class ViewRecipe extends Component {
  state = {
    content: [],
    recipeId: "",
    loading: false,
    userObject: null,
    copySuccess: "copy"
  };

  componentDidMount = () => {
    this.mountRecipe();
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState({
      userObject: userObject
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getRecipeContentData !== this.props.getRecipeContentData) {
      this.setState({
        content: nextProps.getRecipeContentData
      });
    }
    this.setState({ loading: false });
  };

  mountRecipe = async () => {
    await this.setState({ loading: true });
    window.scrollTo(0, 0);
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      await this.setState(
        { recipeId: this.props.match.params.id },
        async () => {
          await this.props.getRecipeContent(this.state.recipeId);
        }
      ); 
    }
  };

  likeRecipe = async (id) => {
    if (!id) return;
    const data = {
      content_type: "youtube_recipe",
      id
    };
    await this.props.hitLike(data);
    await this.props.getRecipeContent(id);
  };

  bookmarkRecipe = async (id) => {
    if (!id) return;
    const data = {
      content_type: "youtube_recipe",
      id
    };
    await this.props.addBookmarkAction(data);
    await this.props.getRecipeContent(id);
  };

  copyToClipboard = (e) => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    this.setState({ copySuccess: 'Copied!' });
  };

  render() {
    let { userObject, loading } = this.state;
    let getRecipeData =
      this.state && this.state.content && this.state.content.data;

    return (
      <div className="common-dashboard recipe-desc-wrapper">
        <Container className="p-0">
          {/* <SidebarMenu /> */}
          <div className="main-content bg-white position-relative pt-2">
            {userObject ? (
              <Header />
            ) : (
              <HeaderNew history={this.props.history} />
            )}

            {/* video and suggestions */}

            {loading ? (
              <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                <ContentLoader color="text-warning" />
              </div>
              ) : getRecipeData && Object.keys(getRecipeData).length ? (
                <>
                  <h2 className="fs-36 recipe-top-heading mb-3 mt-3">
                    {getRecipeData && getRecipeData.title}
                  </h2>
                  <Row>
                    {getRecipeData &&
                      getRecipeData["title_image"] &&
                      getRecipeData &&
                      getRecipeData["youtube_link"] == "" ? (
                      <Col lg={6}>
                        <img
                          src={getRecipeData && getRecipeData["title_image"]}
                          alt="recipe-img"
                          className="recipe-image border-6 img-fit w-100"
                        />
                        {/* episode name and user action */}
                        {userObject ? (
                          <>
                            <div className="bottom d-flex align-items-center my-4">
                              <ul className="user-action-btn d-flex">
                                {/*like icon */}
                                <li>
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["likes"] &&
                                      getRecipeData["likes"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart text-danger fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                  <p className="font-smallest">
                                    {getRecipeData.likes_count}{" "}
                                    {getRecipeData.likes_count > 1
                                      ? "Likes"
                                      : "Like"}
                                  </p>
                                </li>
                                {/* share icon */}
                                <li className="mr-4">
                                  <Dropdown className="d-inline-block">
                                    <Dropdown.Toggle variant="" className="px-0 btn-share py-0" id="dropdown-basic">
                                      <img
                                        src={"/assets/images/share.png"}
                                        alt="share"
                                        className="mr-2"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className="py-1 px-2 cursor-pointer" onClick={(e) => this.copyToClipboard(e)} >
                                        <span className="fa fa-clone mr-2"></span>
                                        {this.state.copySuccess}
                                      </div>

                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>
                                {/* bookmark icon */}
                                <li className="mr-4">
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["bookmarks"] &&
                                      getRecipeData["bookmarks"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark text-warning fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                </li>
                              </ul>
                              <div className="ml-auto">
                                <p>
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count}
                                  </span>{" "}
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count > 1
                                      ? "comments"
                                      : "comment"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </Col>
                    ) : getRecipeData &&
                      getRecipeData["title_image"] &&
                      getRecipeData &&
                      getRecipeData["youtube_link"] !== "" ? (
                      <Col lg={4}>
                        <img
                          src={getRecipeData && getRecipeData["title_image"]}
                          alt="recipe-img"
                          className="recipe-image border-6 img-fit w-100 mb-4 mb-lg-0"
                        />
                        {userObject ? (
                          <>
                            <div className="bottom d-flex align-items-center my-4">
                              <ul className="user-action-btn d-flex">
                                {/*like icon */}
                                <li>
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["likes"] &&
                                      getRecipeData["likes"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart text-danger fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                  <p className="font-smallest">
                                    {getRecipeData.likes_count}{" "}
                                    {getRecipeData.likes_count > 1
                                      ? "Likes"
                                      : "Like"}
                                  </p>
                                </li>
                                {/* share icon */}
                                <li className="mr-4">
                                  <Dropdown className="d-inline-block">
                                    <Dropdown.Toggle variant="" className="px-0 btn-share py-0" id="dropdown-basic">
                                      <img
                                        src={"/assets/images/share.png"}
                                        alt="share"
                                        className="mr-2"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className="py-1 px-2 cursor-pointer" onClick={(e) => this.copyToClipboard(e)} >
                                        <span className="fa fa-clone mr-2"></span>
                                        {this.state.copySuccess}
                                      </div>

                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>
                                {/* bookmark icon */}
                                <li className="mr-4">
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["bookmarks"] &&
                                      getRecipeData["bookmarks"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark text-warning fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                </li>
                              </ul>
                              <div className="ml-auto">
                                <p>
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count}
                                  </span>{" "}
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count > 1
                                      ? "comments"
                                      : "comment"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </Col>
                    ) : getRecipeData &&
                      getRecipeData["title_image"] == null &&
                      getRecipeData &&
                      getRecipeData["youtube_link"] == "" ? (
                      <Col lg={6}>
                        <img
                          src={"../assets/images/placeholder-recipe.jpg"}
                          alt="recipe-img"
                          className="recipe-image border-6 img-fit w-100 mb-4 mb-lg-0"
                        />
                        {userObject ? (
                          <>
                            <div className="bottom d-flex align-items-center my-4">
                              <ul className="user-action-btn d-flex">
                                {/*like icon */}
                                <li>
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["likes"] &&
                                      getRecipeData["likes"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart text-danger fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.likeRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-heart-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                  <p className="font-smallest">
                                    {getRecipeData.likes_count}{" "}
                                    {getRecipeData.likes_count > 1
                                      ? "Likes"
                                      : "Like"}
                                  </p>
                                </li>
                                {/* share icon */}
                                <li className="mr-4">
                                  <Dropdown className="d-inline-block">
                                    <Dropdown.Toggle variant="" className="px-0 btn-share py-0" id="dropdown-basic">
                                      <img
                                        src={"/assets/images/share.png"}
                                        alt="share"
                                        className="mr-2"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className="py-1 px-2 cursor-pointer" onClick={(e) => this.copyToClipboard(e)} >
                                        <span className="fa fa-clone mr-2"></span>
                                        {this.state.copySuccess}
                                      </div>

                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>
                                {/* bookmark icon */}
                                <li className="mr-4">
                                  <a>
                                    {getRecipeData &&
                                      getRecipeData["bookmarks"] &&
                                      getRecipeData["bookmarks"].includes(
                                        userObject.user.id
                                      ) ? (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark text-warning fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.bookmarkRecipe(
                                            getRecipeData && getRecipeData.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-bookmark-o text-black fs-18"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </a>
                                </li>
                              </ul>
                              <div className="ml-auto">
                                <p>
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count}
                                  </span>{" "}
                                  <span>
                                    {getRecipeData &&
                                      getRecipeData.comments_count > 1
                                      ? "comments"
                                      : "comment"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </Col>
                    ) : null}

                    {/* you tube video box */}
                    <div className="col-12 col-lg">
                      {getRecipeData && getRecipeData["youtube_link"] !== null ? (
                        <div className="video-box">
                          <ReactPlayer
                            className="w-100 h-auto"
                            url={getRecipeData && getRecipeData["youtube_link"]}
                            playing="false"
                            controls="true"
                          />

                          {/* <iframe
                            src={getRecipeData && getRecipeData["youtube_link"]}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="border-6"
                            allowFullScreen
                          /> */}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                  {!userObject ? (
                    <>
                      <div className="mt-4">
                        <Row>

                          {/* recipe instructions */}
                          {getRecipeData &&
                            getRecipeData.connected_recipe[0] &&
                            getRecipeData.connected_recipe[0].instructions ? (
                            <>
                              <div className="col-md-6 col-lg mb-4">
                                <h3 className="text-primary mb-3">
                                  Instructions
                                </h3>
                                <div
                                  className="text-grey-4 fs-14 font-weight-semi mt-3 "
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      getRecipeData &&
                                      getRecipeData.connected_recipe[0] &&
                                      getRecipeData.connected_recipe[0]
                                        .instructions
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : null}
                          {/* recipe ingredients  */}
                          {getRecipeData &&
                            getRecipeData.connected_recipe[0] &&
                            getRecipeData.connected_recipe[0].ingredients &&
                            getRecipeData.connected_recipe[0].ingredients.length ? (
                            <>
                              <div className="col-md-6 col-lg mb-4">
                                <h3 className="text-primary mb-3">Ingredients</h3>
                                <ul className="mb-0">
                                  {getRecipeData &&
                                    getRecipeData.connected_recipe[0] &&
                                    getRecipeData.connected_recipe[0]
                                      .ingredients &&
                                    getRecipeData.connected_recipe[0].ingredients.map(
                                      (ingredient) => (
                                        <li>
                                          <strong>{ingredient.category}</strong>
                                          <span className="ml-2">
                                            {ingredient.name}
                                          </span>
                                          <span className="ml-2">
                                            {ingredient.quantity}
                                          </span>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </>
                          ) : null}

                          {getRecipeData &&
                            getRecipeData.connected_recipe[0] &&
                            getRecipeData.connected_recipe[0].notes ? (
                            <>
                              <div className="col-md-6 col-lg">
                                <h3 className="text-primary mb-3">Notes</h3>
                                <p>notes</p>
                              </div>
                            </>
                          ) : null}
                        </Row>
                      </div>
                    </>
                  ) : null}

                  {/* Recipe & comments tab*/}
                  {userObject ? (
                    <>
                      <div className="recipe-desc-wrapper">
                        {this.state.loading && !getRecipeData ? (
                          <div
                            style={{ margin: "50px auto 0", textAlign: "center" }}
                          >
                            <ContentLoader color="text-warning" />
                          </div>
                        ) : (
                          <div className="recipe-desc-wrapper">
                            <CommentsRecipeTabs details={getRecipeData} />
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
            ) : (
              <div className="text-center">
                <p className="text-danger-2 font-weight-semi">
                  Oops! No Recipe details found!
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getRecipeContentData: state.get_recipe_content_reducer.data
  };
};
const mapDispatchToProps = (dispatch) => ({
  getRecipeContent: (id) => dispatch(get_recipe_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecipe);
