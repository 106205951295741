import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./ChangePassword.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { connect } from "react-redux";
import { change_password_action } from "../../../_actions/User/profile.action";
import { validatePassword } from "../../../_helpers/validators";
import swal from "sweetalert";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isPasswordValid: true,
      // formErrorText: "",
    };
  }

  goBackHandler = () => {
    this.props.history.goBack();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.changeUserPassword !== this.props.changeUserPassword) {
      if (
        this.props.changeUserPassword &&
        this.props.changeUserPassword.message &&
        this.props.changeUserPassword.message.detail &&
        this.props.changeUserPassword.message.detail.length
      ) {
        swal({
          title: "Great!",
          text: "Password has been updated successful",
          icon: "success",
        });
        this.props.history.goBack();
        return;
      }
      if (
        this.props.changeUserPassword &&
        this.props.changeUserPassword.errorMessage &&
        this.props.changeUserPassword.errorMessage.length
      ) {
        swal({
          title: "Oops!",
          text: "Your old password was entered incorrectly. Please enter it again.",
          icon: "error",
        });
        return;
      }
    }
  }

  EditPasswordHandler = (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword, isPasswordValid } =
      this.state;

    this.setState({
      isPasswordValid: validatePassword(this.state.newPassword),
    });

    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      swal({
        title: "Oops!",
        text: "Please fill all the fields",
        icon: "error",
      });

      return;
    }

    if (!isPasswordValid || newPassword !== confirmPassword) {
      const isError = !isPasswordValid
        ? "Your password must be 8 to 20 characters long with at least one numeric digit, one uppercase and one lowercase letter"
        : newPassword !== confirmPassword
        ? "Confirm Password should match password."
        : "";
      swal({
        title: "Oops!",
        text: isError,
        icon: "error",
      });
      return;
    }

    const data = {
      new_password2: newPassword,
      new_password1: confirmPassword,
      old_password: oldPassword,
    };
    this.props.updateUserPassword(data);
  };

  render() {
    return (
      <div className="common-dashboard user-profile change-password">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white">
            <Header />
            <div className="edit-profile-wrapper pt-5">
              <span
                onClick={this.goBackHandler}
                className="back-option text-dark font-weight-bold cursor-pointer mt-5 mb-3 d-none d-md-flex align-items-center"
              >
                <img src="/assets/images/back-arrow.svg" alt="back button" />{" "}
                Back
              </span>
              <Row className="d-flex align-items-center justify-content-center">
                <Col md={6} lg={4} className="mt-5 mt-md-0">
                  <div className="profile-content text-center mt-4">
                    <p className="fs-18 font-montserrat mb-4 pt-4 text-primary font-weight-bold">
                      Change Password
                    </p>
                    <div className="form-wraper d-flex pt-2 text-primary  justify-content-center align-items-center mt-4  my-5 mb-md-0 pb-2">
                      <Form>
                        <Form.Group className="with-placeholder">
                          <Form.Control
                            type="password"
                            placeholder=" "
                            name="password"
                            // required
                            onChange={(e) =>
                              this.setState({ oldPassword: e.target.value })
                            }
                          />
                          <span className="placeholder-text font-montserrat">
                            Current Password
                          </span>
                        </Form.Group>
                        <Form.Group className="with-placeholder">
                          <Form.Control
                            type="password"
                            placeholder=" "
                            name="confirm_password"
                            // required
                            onChange={(e) =>
                              this.setState({ newPassword: e.target.value })
                            }
                          />
                          <span className="placeholder-text font-montserrat">
                            New Password
                          </span>
                        </Form.Group>

                        <Form.Group className="with-placeholder mb-5">
                          <Form.Control
                            type="password"
                            placeholder=" "
                            name="confirm_new_password"
                            // required
                            onChange={(e) =>
                              this.setState({ confirmPassword: e.target.value })
                            }
                          />
                          <span className="placeholder-text font-montserrat">
                            Confirm New Password
                          </span>
                        </Form.Group>
                        {/* <p className="text-danger">{this.state.errorText}</p> */}
                        <Form.Group className="mb-4">
                          <Button
                            type="submit"
                            variant="warning"
                            className="fs-14 py-3 font-weight-bold border-6"
                            block
                            onClick={(e) => this.EditPasswordHandler(e)}
                          >
                            Change Password
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    changeUserPassword: state.change_password_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (data) => dispatch(change_password_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
