import swal from 'sweetalert'
import { uploadShowConstants } from "../../_constants/Admin/uploadShow.constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

//SAVE SHOW DETAILS
export const save_show_details_action = (data) => async (dispatch) => {
    dispatch({
        type: uploadShowConstants.UPLOAD_SHOW_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.uploadShowContent, data)
        if (response.status) {
            dispatch({
                type: uploadShowConstants.UPLOAD_SHOW_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadShowConstants.UPLOAD_SHOW_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//get Saved Show Details
export const get_saved_show_details_action = (id) => async (dispatch) => {
    dispatch({
        type: uploadShowConstants.GET_SAVED_SHOW_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getSavedShow.replace(':id', id));
        if (response.status) {
            dispatch({
                type: uploadShowConstants.GET_SAVED_SHOW_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadShowConstants.GET_SAVED_SHOW_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
//update show action
export const update_show_details_action = (id,data) => async (dispatch) => {
    dispatch({
        type: uploadShowConstants.UPDATE_SHOW_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.updateShow.replace(':id', id),data);
        if (response.status) {
            dispatch({
                type: uploadShowConstants.UPDATE_SHOW_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadShowConstants.UPDATE_SHOW_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
