import { videoConstants,homePageConstants  } from "../../_constants/User";

const initialState = {};
export const get_video_content_reducer = (state = initialState, action) => {
  switch (action.type) {
    case videoConstants.GET_VIDEO_CONTENT_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case videoConstants.GET_VIDEO_BY_ID_ACTION_SUCCESS:
    case videoConstants.GET_VIDEO_CONTENT_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case videoConstants.GET_VIDEO_CONTENT_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};

export const get_all_user_videos_reducer = (state = initialState, action) => {
  switch (action.type) {
    case videoConstants.GET_ALL_VIDEO_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case videoConstants.GET_ALL_VIDEO_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case videoConstants.GET_ALL_VIDEO_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};



export const get_expert_info_reducer = (state = initialState, action) => {
  switch (action.type) {
    case homePageConstants.GET_EXPERT_INFO_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case homePageConstants.GET_EXPERT_INFO_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case homePageConstants.GET_EXPERT_INFO_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};

