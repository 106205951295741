import React, { useRef } from "react";

import { Modal } from "antd";
import moment from "moment";
import {
  Button,
  FormGroup,
  FormControl,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import { LoaderBasic } from "../../common/Loader";
import { isEmptyObject } from "../../../../_helpers/validators";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import "./ContentModal.scss";
import { Pagination } from "antd";
export default ({
  isOpen,
  content,
  modalType,
  selectedItem,
  coverImageName,
  isApiLoading,
  onHandleClose,
  isAddApiLoading,
  coverImagePreview,
  handleChange,
  onAddClickHandler,
  onCancelClickHandler,
  handleCoverImageChange,
  handleSelectItemHandler,
  pagination,
  handlePagination,
  searchContentHandler,
  searchKey,
}) => {
  const filter = "update";
  const modalRef = useRef(null);
  let scrollToTopHandler = () => {
    modalRef &&
      modalRef.current &&
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <Modal
      style={{ top: 20 }}
      width={"90%"}
      title="Choose a content to post it on your homepage!"
      visible={isOpen}
      onOk={onHandleClose}
      onCancel={onHandleClose}
    >
     
      {selectedItem.isSelected ? (
        <div
          className="carousel-listing-wrapper d-flex flex-wrap"
          ref={modalRef}
        >
          <div className="left-content-wrapper">
            <h5 className="font-weight-semi mb-2">
              Selected{" "}
              {selectedItem.content_type === "youtube-recipe"
                ? "Recipe"
                : selectedItem.content_type === "video"
                ? "Video"
                : selectedItem.content_type === "show"
                ? "Show"
                : ""}
            </h5>
            <p className="font-smallest font-weight-400 ">
              {selectedItem.title && selectedItem.title}
            </p>
            <div className="add-btn-wrapper d-flex flex-wrap">
              <label className="cover-image btn btn-outline-dark text-center rounded mr-3 d-flex align-items-center jusfity-content-center">
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleCoverImageChange}
                  className={`d-none px-3 text-center`}
                />
                {/* ${
                          inputError.coverImage && inputError.coverImage.length
                            ? "input-invalid"
                            : ""
                        } */}
                {coverImageName.length
                  ? coverImageName.substring(0, 22)
                  : "Add Cover Image"}
              </label>

              <FormGroup className="rounded mb-0">
                <FormControl
                  placeholder="Add label (if required)"
                  className={`label px-2 py-3 px-md-4 bg-grey-3 text-center h-100`}
                  onChange={(e) => handleChange("labelText", e.target.value)}
                />
                {/* className={`${
                          inputError.labelText && inputError.labelText.length
                            ? "input-invalid px-4 mb-0 bg-grey-3 text-center h-100"
                            : "px-4 mb-0 bg-grey-3 text-center h-100"
                        }`} */}
              </FormGroup>

              <div className="right">
                <ul className="data-exports mt-md-2 d-flex flex-wrap">
                  <li onClick={() => handleChange("labelColor", "violet")}>
                    <label className="custom-square-radio" htmlFor="voilet">
                      <input type="radio" name="label-color" id="voilet" />
                      <span className="color-box bg-primary"></span>
                    </label>
                  </li>
                  <li
                    className="ml-2"
                    onClick={() => handleChange("labelColor", "yellow")}
                  >
                    <label className="custom-square-radio" htmlFor="yellow">
                      <input type="radio" name="label-color" id="yellow" />
                      <span className="color-box bg-warning"></span>
                    </label>
                  </li>
                  <li
                    className="ml-2"
                    onClick={() => handleChange("labelColor", "black")}
                  >
                    <label className="custom-square-radio" htmlFor="black">
                      <input type="radio" name="label-color" id="black" />
                      <span className="color-box bg-dark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="right-btns-wrapper ml-lg-5 mt-4 d-flex align-items-end flex-wrap">
            <Button
              variant="info"
              className="rounded text-center mr-3 mb-md-0 px-3 px-md-4 cursor-pointer"
              onClick={() => onAddClickHandler(modalType)}
              disabled={!isAddApiLoading ? false : true}
            >
              {isAddApiLoading ? <ContentLoader color="text-white" /> : "Add"}
            </Button>
            <Button
              variant="outline-dark"
              className="rounded px-2 px-md-4"
              onClick={onCancelClickHandler}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
      {coverImageName.length ? (
        <img src={coverImagePreview} alt="Cover Image" className="mt-3" />
      ) : null}
      {/* Pagination */}
      <div className="d-flex align-items-center justify-content-end mt-2">
        <div className="mb-0 search-bar flex-grow-1 form-group position-relative">
          <input
            placeholder="Search"
            className="text-grey-3 py-3 h-auto form-control"
            value={searchKey}
            onChange={(e) => {
              searchContentHandler(e);
            }}
          />
          <i className="fa fa-search fa-1x" aria-hidden="true"></i>
        </div>
        <Pagination
          simple
          onChange={handlePagination}
          showSizeChanger={false}
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
        />
      </div>
      {/* End Pagination */}

      <Table
        striped
        bordered
        hover
        responsive
        className="text-black listing-table mt-5 homepage-popup-table"
      >
        <thead className="bg-primary text-white">
          <tr className="text-nowrap">
            <th> </th>
            <th>#</th>
            <th>Title Image</th>
            <th>Content Name</th>
            <th>type</th>
            <th>No of Episode</th>
            <th>Upload date</th>
          </tr>
        </thead>

        <tbody className="font-smallest">
          {isApiLoading ? (
            <tr>
              <td colSpan="7" className="bg-white p-5 position-relative">
                <LoaderBasic />
              </td>
            </tr>
          ) : (
            <>
              {content && content.length ? (
                content.map((contentItem, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>
                        <div
                          onClick={(e) => {
                            handleSelectItemHandler(e, contentItem.content);
                            scrollToTopHandler();
                          }}
                          className="custom-control custom-checkbox dark"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="row-checkbox1"
                            readOnly={true}
                            checked={
                              selectedItem.isSelected &&
                              !isEmptyObject(selectedItem) &&
                              selectedItem.id === contentItem.content.id &&
                              selectedItem.content_type ===
                                contentItem.content.content_type
                                ? true
                                : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkbox-table1"
                          />
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={
                            contentItem.content &&
                            contentItem.content.title_image
                              ? contentItem.content.title_image
                              : "/assets/images/list-item-1.svg"
                          }
                          alt="list-item-1"
                          className="item-image rounded"
                        />
                      </td>
                      {contentItem.content &&
                      contentItem.content.content_type === "show" &&
                      filter === "update" ? (
                        <td className="listing-table-data">
                          <p className="font-smallest font-weight-semi">
                            {" "}
                            {contentItem.content &&
                              contentItem.content.title &&
                              contentItem.content.title}
                          </p>

                          {/* <Accordion>
                              <Card className="border-0">
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey="0"
                                  className="bg-white border-0 px-0 py-0"
                                >
                                  <p className="font-smallest font-weight-semi">
                                    {" "}
                                    {contentItem.content &&
                                      contentItem.content.title &&
                                      contentItem.content.title}
                                  </p>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body className="px-0">
                                    <ul>
                                      {contentItem.content &&
                                      contentItem.content.episodes &&
                                      contentItem.content.episodes.length ? (
                                        contentItem.content.episodes.map(
                                          (episode, i) => (
                                            <li
                                              className="d-flex mb-2"
                                              key={episode.id}
                                            >
                                              <label
                                                className="custom-control custom-checkbox dark  mt-2"
                                                onClick={(e) =>
                                                  handleSelectItemHandler(
                                                    e,
                                                    episode
                                                  )
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="episode1"
                                                  name="row-checkbox"
                                                  checked={
                                                    selectedItem.isSelected &&
                                                    !isEmptyObject(
                                                      selectedItem
                                                    ) &&
                                                    selectedItem.id ===
                                                      episode.id &&
                                                    selectedItem.content_type ===
                                                      episode.content_type
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="episode1"
                                                ></label>
                                              </label>
                                              <span className="bg-grey px-3 d-flex align-items-center rounded mx-2">
                                                {i + 1}
                                              </span>
                                              <div className="form-group mb-0 flex-grow-1">
                                                <input
                                                  type="text"
                                                  value={episode.title}
                                                  className="form-control rounded font-weight-normal"
                                                  placeholder="Episode Title"
                                                  disabled={true}
                                                />
                                              </div>
                                            </li>
                                          )
                                        )
                                      ) : (
                                        <div>No episodes released yet!</div>
                                      )}
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion> */}
                        </td>
                      ) : (
                        <td className="listing-table-data">
                          <p className="font-smallest font-weight-semi">
                            {" "}
                            {contentItem.content && contentItem.content.title}.
                          </p>
                        </td>
                      )}
                      <td>
                        <span className="font-weight-400">
                          {contentItem.content.content_type === "youtube-recipe"
                            ? "Recipe"
                            : contentItem.content.content_type === "video"
                            ? "Video"
                            : contentItem.content.content_type === "show"
                            ? "Show"
                            : ""}
                        </span>
                      </td>
                      <td>
                        <span className="font-weight-400">
                          {contentItem.content.content_type === "show"
                            ? contentItem.content &&
                              contentItem.content.episodes &&
                              contentItem.content.episodes.length
                            : "N/A"}
                        </span>
                      </td>
                      <td className="font-weight-bold">
                        {contentItem.content && contentItem.content.created
                          ? moment(contentItem.content.created).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7">
                    <h3 className="text-center text-danger py-3">
                      {modalType === "right"
                        ? "No Youtube Recipe is uploaded Yet!"
                        : "No Content is Uploaded Yet!"}
                    </h3>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
    </Modal>
  );
};
