import React, { Component } from "react";
import { Tab, Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
import RecipeIngredients from "./RecipeIngredients/RecipeIngredients";
import RecipeInstructions from "./RecipeInstructions/RecipeInstructions";
import RecipeNotes from "./RecipeNotes/RecipeNotes";
import "./RecipeDetails.scss";

class Recipe extends Component {
  state = {
    videoId: this.props && this.props.receipeData && this.props.receipeData.id
  }
  render() {
    let recipesCollection =
      this.props &&
      this.props.receipeData &&
      this.props.receipeData["connected_recipe"];

    return (
      <>
        {/*---------recipe tabs for desktop-------------- */}
        <div className="Recipe-tabs-wrapper inner-tabs mt-4 d-none d-lg-block">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills row">
              {recipesCollection &&
                recipesCollection.map((recipe, index) => (
                  <Nav.Item className="col-lg-4 mb-3 mb-lg-0">
                    <Nav.Link
                      eventKey={`${index === 0 ? "first" : recipe.id}`}
                      className="d-flex justify-content-between align-items-center mb-2"
                    >
                      Recipe {index + 1} : {recipe.name}
                      {/* <img src={"/assets/images/share.png"} alt="tabs-btn" /> */}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
            <Tab.Content>
              {recipesCollection && recipesCollection.length > 0 ? (
                recipesCollection.map((recipe, index) => (
                  <Tab.Pane eventKey={`${index === 0 ? "first" : recipe.id}`}>
                    <Row>
                      {recipe.ingredients.length ? (
                        <div className="col-lg-4 col-12">
                          {
                            recipe && recipe.recipe_image ? <div className="bg-grey-7 mt-2 p-2 rounded">
                            <img src={recipe && recipe.recipe_image} alt="" />
                          </div>:null
                          }
                          <RecipeIngredients
                            recipeIngredients={recipe.ingredients}
                            videoId={this.state && this.state.videoId}
                            history={this.props.history}
                          />
                        </div>
                      ) : null}
                      {recipe.instructions.length ? (
                        <div className="col-lg col-12">
                          <RecipeInstructions
                            instructions={recipe.instructions}
                            history={this.props.history}

                          />
                        </div>
                      ) : null}
                      {recipe.notes.length ? (
                        <div className="col-lg col-12">
                          <RecipeNotes notes={recipe.notes}
                            history={this.props.history}
                          />
                        </div>
                      ) : null}
                    </Row>
                  </Tab.Pane>
                ))
              ) : (
                <p className="text-danger">No recipe found!</p>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>

        {/*---------recipe tabs for mobile-------------- */}
        <div className="Recipe-tabs-wrapper inner-tabs mt-4 d-block d-lg-none">
          {/* // recipe tabs for mobile */}

          <Accordion defaultActiveKey="first">
            {recipesCollection &&
              recipesCollection.map((recipe, index) => (
                <Card className="border-0">
                  <Card.Header className="d-flex justify-content-between align-items-center p-0">
                    <Accordion.Toggle
                      as={Button}
                      variant=" "
                      eventKey={`${index === 0 ? "first" : recipe.id}`}
                      className="w-100 text-left clearfix py-2 px-3"
                    >
                      Recipe {index + 1} : {recipe.name}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse
                    eventKey={`${index === 0 ? "first" : recipe.id}`}
                  >
                    <Card.Body className="p-0">

                      {recipe.ingredients.length ? (
                        <div className="col-lg col-12">
                           {
                            recipe && recipe.recipe_image ? <div className="bg-grey-7 mt-2 p-2 rounded">
                            <img src={recipe && recipe.recipe_image} alt="" />
                          </div>:null
                          }
                          <RecipeIngredients
                            recipeIngredients={recipe.ingredients}
                            videoId={this.state && this.state.videoId}
                            history={this.props.history}
                          />
                        </div>
                      ) : null}
                      {recipe.instructions.length ? (
                        <div className="col-lg col-12">
                          <RecipeInstructions
                            instructions={recipe.instructions}
                            history={this.props.history}

                          />
                        </div>
                      ) : null}
                      {recipe.notes.length ? (
                        <div className="col-lg col-12">
                          <RecipeNotes notes={recipe.notes}
                            history={this.props.history}
                          />
                        </div>
                      ) : null}

                      {/* <RecipeIngredients
                        recipeIngredients={recipe.ingredients}
                      />
                      <RecipeInstructions instructions={recipe.instructions} />

                      <RecipeNotes notes={recipe.notes} /> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </div>
      </>
    );
  }
}

export default Recipe;
