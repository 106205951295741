import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get_app_info_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
export class AppAvilable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInfoContent: []
    };
  }
  componentDidMount() {
    this.getAppContentData();
  }
  getAppContentData = async () => {
    let data = await this.props.getAppContent();
    this.setState({ appInfoContent: data.data });
  };
  render() {
    let { appInfoContent } = this.state;
    
    return (
      <div className="app-avilable m-t-75 m-b-75">
        <Container>
          <Row>
            <Col md={7} className="order-2 order-md-1">
              <img src={appInfoContent.image} alt="" className="img-fluid w-100" />
            </Col>
            <Col
              md={5}
              className="d-flex align-items-center justify-content-center order-1 order-md-2"
            >
              <div className="mb-5 mb-md-0">
                <h2 className="fs-48 mb-3">App Available</h2>
                <p className="fs-18 text-dark-2">
                  {appInfoContent.content}
                </p>
                <div className="d-flex align-items-center mt-4 pt-2">
                  {appInfoContent.apple_store_link ? (
                    <a href={appInfoContent.apple_store_link} className="mr-2 cursor-pointer">
                      <img src="/assets/images/play-store-btn.png" alt="" />
                    </a>
                  ) : null}
                  {appInfoContent.google_store_link ? (
                    <a href={appInfoContent.google_store_link} className="cursor-pointer">
                      <img src="/assets/images/google-play.png" alt="" />
                    </a>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAppContent: () => dispatch(get_app_info_content_action())
});

export default connect(null, mapDispatchToProps)(AppAvilable);
