export const uploadShowConstants = {
    UPLOAD_SHOW_DETAILS_ACTION_REQUEST: 'UPLOAD_SHOW_DETAILS_ACTION_REQUEST',
    UPLOAD_SHOW_DETAILS_ACTION_SUCCESS: 'UPLOAD_SHOW_DETAILS_ACTION_SUCCESS',
    UPLOAD_SHOW_DETAILS_ACTION_FAILURE: 'UPLOAD_SHOW_DETAILS_ACTION_FAILURE',

    GET_SAVED_SHOW_DETAILS_ACTION_REQUEST:'GET_SAVED_SHOW_DETAILS_ACTION_REQUEST',
    GET_SAVED_SHOW_DETAILS_ACTION_SUCCESS:'GET_SAVED_SHOW_DETAILS_ACTION_SUCCESS',
    GET_SAVED_SHOW_DETAILS_ACTION_FAILURE:'GET_SAVED_SHOW_DETAILS_ACTION_FAILURE',

    UPDATE_SHOW_DETAILS_ACTION_REQUEST:'UPDATE_SHOW_DETAILS_ACTION_REQUEST',
    UPDATE_SHOW_DETAILS_ACTION_SUCCESS:'UPDATE_SHOW_DETAILS_ACTION_SUCCESS',
    UPDATE_SHOW_DETAILS_ACTION_FAILURE:'UPDATE_SHOW_DETAILS_ACTION_FAILURE'
}