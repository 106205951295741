import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { EditorState, convertFromRaw } from "draft-js";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  ListGroup,
  Button
} from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import TopTab from "../../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../../common/SidebarMenu/AdminSidebarMenu";
// import { hasOnlyNumber } from "../../../../../_helpers/validators";
import {
  add_connected_recipe_action,
  edit_connected_recipe_action,
  get_connected_recipe_action,
  search_category_action
} from "../../../../../_actions/Admin";
import ContentLoader from "../../../../Common/Loader/ContentLoader";
import { stateFromHTML } from "draft-js-import-html";
import { debounce } from "../../../../../_helpers/debounce";
import "./UploadRecipeInstructions.scss";

class UploadRecipeInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentState: convertFromRaw({
        entityMap: {},
        blocks: [
          {
            key: "637gr",
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ]
      }),
      editorState: EditorState.createEmpty(),
      notes: "",
      contentId: "",
      errorText: "",
      inputError: {
        recipeName: "",
        instructions: ""
      },
      recipeName: "",
      contentType: "",
      instructions: "",
      instructionsInHTML: "",
      toolbarConfig: {
        options: ["inline", "colorPicker"],
        inline: {
          inDropdown: false,
          bold: { className: "bg-grey-7 px-2" },
          italic: { className: "bg-grey-7 px-2" },
          underline: { className: "bg-grey-7 px-2" },
          strikethrough: { className: "d-none" },
          monospace: { className: "d-none" },
          superscript: { className: "d-none" },
          subscript: { className: "d-none" }
        },
        colorPicker: {
          colors: [
            "rgba(36, 170, 225, 1)",
            "rgba(135, 68, 154, 1)",
            "rgba(255, 164, 0, 1)"
          ],
          className: "bg-grey-7 px-2"
        }
      },
      ingredients: [
        {
          category: "",
          name: "",
          quantity: null,
          unit: "ltr",
          error: {}
        }
      ],
      loading: false,
      recipeLoading: false,
      initialInstructionsState: null,
      areAllDataSame: null,
      searchCategoryString: "",
      categoryTagSuggestion: ["fruit", "vegetable"],
      showSearchDropdownIndex: null,
      searchCategoryLoading: true,
      deleteRecipeIds: [],
      imageName: "",
      imageSrc: null,
      imageUrl: null
      // this.props.videoDetails !== null
      //   ? this.props.videoDetails["title_image"]
      //   : null,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.searchCategoryApi = debounce(this.loadSearchCategoryData);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { recipeType, recipeId } = this.props.match.params;
    if (recipeId !== undefined && recipeType === "edit-recipe")
      this.loadRecipeDetails();
  }

  loadRecipeDetails = async () => {
    const {
      contentType,
      contentId,
      recipeType,
      recipeId
    } = this.props.match.params;
    this.setState({ recipeLoading: true });
    const fetchRecipeInstructionsRes = await this.props.getUpdatedRecipe(
      recipeId
    );
    if (fetchRecipeInstructionsRes.data) {

      await this.setState({
        editorState: EditorState.createWithContent(
          stateFromHTML(fetchRecipeInstructionsRes.data.instructions)
        ),
        notes: fetchRecipeInstructionsRes.data.notes,
        contentId: fetchRecipeInstructionsRes.data.id,
        recipeName: fetchRecipeInstructionsRes.data.name,
        contentType: fetchRecipeInstructionsRes.data.content_type,
        instructions: fetchRecipeInstructionsRes.data.instructions,
        instructionsInHTML: fetchRecipeInstructionsRes.data.instructions,
        ingredients: fetchRecipeInstructionsRes.data.ingredients,
        initialInstructionsState: fetchRecipeInstructionsRes.data,
        areAllDataSame: true,
        imageUrl: fetchRecipeInstructionsRes.data.recipe_image
      });
    }

    this.setState({ recipeLoading: false });
  };

  onHandleAddMoreIngredients = () => {
    const { ingredients } = this.state;
    let lastIngredient = ingredients[ingredients.length - 1];
    // if (
    //   // lastIngredient.category !== "" &&
    //   lastIngredient && lastIngredient.name !== "" &&
    //   lastIngredient && lastIngredient.quantity !== null
    // ) {
    this.setState(
      prevState => ({
        ingredients: [
          ...prevState.ingredients,
          { category: "", name: "", quantity: "", error: {} }
        ]
      }),
      () => {
        this.checkForChanges();
      }
    );
    // } else {
    //   alert("Please fill all input fields");
    //   this.setState((prevState) => ({

    //     inputError: {
    //       ...prevState.inputError,
    //       ingredientsInputError: "Please fill all input fields",
    //     },
    //   }));
    // }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "recipeName") {
        if (!this.state.recipeName.length) {
          this.setState(prevState => ({
            inputError: {
              ...prevState.inputError,
              recipeName: "Recipe name is required!"
            }
          }));
        } else {
          this.setState(prevState => ({
            inputError: { ...prevState.inputError, recipeName: "" }
          }));
        }
      }
      this.checkForChanges();
    });
  };

  onEditorStateChange = editorState => {
    this.setState(
      {
        editorState
      },
      () => {
        let currentContentAsHTML = convertToHTML(
          editorState.getCurrentContent()
        );
        this.setState({ instructionsInHTML: currentContentAsHTML });
        this.checkForChanges();
      }
    );
  };

  handleInstructionsChange = contentState => {
    this.setState({ instructions: contentState.blocks[0].text }, () => {
      if (!this.state.instructions.length) {
        this.setState(prevState => ({
          inputError: {
            ...prevState.inputError,
            instructions: "Instructions are required!"
          }
        }));
      } else {
        this.setState(prevState => ({
          inputError: { ...prevState.inputError, instructions: "" }
        }));
      }
      this.checkForChanges();
    });
  };

  handleIngredientChange = (e, i) => {
    const { name, value } = e.target;

    this.setState(
      prevState => ({
        ingredients: prevState.ingredients.map(
          (ingredient, index) =>
            index === i ? { ...ingredient, [name]: value } : ingredient
        )
      }),
      () => {
        this.checkForChanges();
        if (name === "name") {
          if (!this.state.ingredients[i].name.length) {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientName: "Ingredient name is required!"
                      }
                    }
                    : ingredient
              )
            }));
            return;
          } else {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientName: ""
                      }
                    }
                    : ingredient
              )
            }));
            return;
          }
        }

        if (name === "quantity") {
          if (!this.state.ingredients[i].quantity.length) {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientQuantity: "Quantity is required!"
                      }
                    }
                    : ingredient
              )
            }));
            return;
          } else {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientQuantity: ""
                      }
                    }
                    : ingredient
              )
            }));
            return;
          }
        }

        if (name === "category") {
          if (!this.state.ingredients[i].category.length) {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientType: "Category is required!"
                      }
                    }
                    : ingredient
              )
            }));
            return;
          } else {
            this.setState(prevState => ({
              ingredients: prevState.ingredients.map(
                (ingredient, index) =>
                  index === i
                    ? {
                      ...ingredient,
                      error: {
                        ...prevState.ingredients[i].error,
                        ingredientType: ""
                      }
                    }
                    : ingredient
              )
            }));
            return;
          }
        }
      }
    );
  };

  checkForChanges = () => {
    const {
      editorState,
      notes,
      recipeName,
      instructions,
      instructionsInHTML,
      ingredients,
      initialInstructionsState,
      imageSrc
    } = this.state;

    const {
      contentType,
      contentId,
      recipeType,
      recipeId
    } = this.props.match.params;
    if (recipeType === "edit-recipe") {
      let checkForChangeArray = [];
      checkForChangeArray.push(initialInstructionsState.name === recipeName);
      for (
        let i = 0, len = initialInstructionsState.ingredients.length;
        i < len;
        i++
      ) {
        let ItemIndex = ingredients.findIndex(
          ingredient =>
            ingredient.name === initialInstructionsState.ingredients[i].name &&
            ingredient.category ===
            initialInstructionsState.ingredients[i].category &&
            parseInt(ingredient.quantity) ===
            parseInt(initialInstructionsState.ingredients[i].quantity)
        );
        if (ItemIndex === -1) checkForChangeArray.push(false);
        else checkForChangeArray.push(true);
      }
      checkForChangeArray.push(
        initialInstructionsState.ingredients.length === ingredients.length
      );
      checkForChangeArray.push(
        initialInstructionsState.instructions === instructionsInHTML
      );
      checkForChangeArray.push(initialInstructionsState.notes === notes);
      checkForChangeArray.push(initialInstructionsState.recipe_image === imageSrc);
      if (checkForChangeArray.includes(false))
        this.setState({ areAllDataSame: false });
      else this.setState({ areAllDataSame: true });
    }
  };

  handleDeleteIngredient = (i, item) => {
    let getId = item && item.id;
    this.setState(
      prevState => ({
        ingredients: prevState.ingredients.filter(
          (ingredient, index) => index !== i
        )
      }),
      () => {
        if (getId) {
          this.setState(prevState => ({
            deleteRecipeIds: [
              ...prevState.deleteRecipeIds,
              { id: getId, flag: "delete" }
            ]
          }));
        }

        this.checkForChanges();
      }
    );
  };

  onSaveClickHandler = async () => {
    const {
      notes,
      recipeName,
      ingredients,
      inputError,
      instructions,
      instructionsInHTML,
      imageSrc
    } = this.state;
    const { contentType, contentId } = this.props.match.params;

    if (recipeName === "" || instructions === "") {
      this.setState({
        errorText: "Recipe name and Instructions fields are mandatory!"
      });
      return;
    }

    // IF content ID and Content Type exist only then hit API
    if (contentType && contentId) {
      let formDataIngredients = ingredients.map(ingredient => {
        delete ingredient.error;
        return { ...ingredient, unit: "ltr" };
      });
      formDataIngredients = ingredients.filter(
        ingredient =>
          // ingredient.category !== "" &&
          ingredient.name !== "" 
          // && ingredient.quantity !== null
      );

      const detailsForm = new FormData();
      detailsForm.append("name", recipeName);
      detailsForm.append("instructions", instructionsInHTML);
      detailsForm.append("notes", notes);
      detailsForm.append(
        "content_type",
        contentType === "recipe"
          ? "youtube_recipe"
          : contentType === "video"
            ? "video"
            : contentType === "episode" ? "episode" : ""
      );
      detailsForm.append("ingredients", JSON.stringify(formDataIngredients));
      if (imageSrc) {
        detailsForm.append("recipe_image", imageSrc);
      }
      detailsForm.append(
        [
          contentType === "recipe"
            ? "youtube_recipe"
            : contentType === "video"
              ? "video"
              : contentType === "episode" ? "episode" : ""
        ],
        Number(contentId)
      );

      // const data = {
      //   name: recipeName,
      //   instructions: instructionsInHTML,
      //   notes,
      //   content_type:
      //     contentType === "recipe"
      //       ? "youtube_recipe"
      //       : contentType === "video"
      //         ? "video"
      //         : contentType === "episode" ? "episode" : "",
      //   [contentType === "recipe"
      //     ? "youtube_recipe"
      //     : contentType === "video"
      //       ? "video"
      //       : contentType === "episode" ? "episode" : ""]: Number(contentId),
      //   ingredients: ingredients.map(ingredient => {
      //     delete ingredient.error;
      //     return { ...ingredient, unit: "ltr" };
      //   }),
      //   recipe_image: imageSrc
      // };

      // data["ingredients"] = ingredients.filter(
      //   ingredient =>
      //     ingredient.category !== "" &&
      //     ingredient.name !== "" &&
      //     ingredient.quantity !== null
      // );

      this.setState(prevState => ({ loading: !prevState.loading }));

      const addConnectedRecipeRes = await this.props.onAddConnectedRecipe(
        detailsForm
      );
      if (
        contentType === "episode" &&
        addConnectedRecipeRes &&
        addConnectedRecipeRes.msg
      ) {
        this.setState(prevState => ({ loading: !prevState.loading }));
        this.props.history.push(
          `/update-episode-content/episode/${Number(contentId)}`
        );
        return;
      }
      if (
        addConnectedRecipeRes &&
        addConnectedRecipeRes.msg &&
        addConnectedRecipeRes.msg === "Successfully Created"
      )
        swal(`Recipe ${addConnectedRecipeRes.msg}`);
      this.setState(prevState => ({ loading: !prevState.loading }));
      this.props.history.push(
        `/update-content/${this.props.match.params["contentType"]}/${this.props
          .match.params["contentId"]}`
      );
    }
  };

  onEditRecipeHandler = async () => {
    const {
      notes,
      recipeName,
      ingredients,
      inputError,
      instructionsInHTML,
      deleteRecipeIds,
      imageSrc
    } = this.state;
    const {
      contentType,
      contentId,
      recipeType,
      recipeId
    } = this.props.match.params;

    if (
      (inputError.recipeName && inputError.recipeName.length) ||
      (inputError.instructions && inputError.instructions.length)
    ) {
      this.setState({
        errorText: "Recipe name and Instructions fields are mandatory!"
      });
      return;
    }

    if (ingredients.length) {
      ingredients.forEach((ingredient, i) => {
        if (!ingredient.category.length) {
          this.setState(prevState => ({
            ingredients: prevState.ingredients.map(
              (ingredient, index) =>
                index === i
                  ? {
                    ...ingredient,
                    error: {
                      ...prevState.ingredients[i].error,
                      ingredientType: "Category is required!"
                    }
                  }
                  : ingredient
            )
          }));
          return;
        }

        if (!ingredient.name.length) {
          this.setState(prevState => ({
            ingredients: prevState.ingredients.map(
              (ingredient, index) =>
                index === i
                  ? {
                    ...ingredient,
                    error: {
                      ...prevState.ingredients[i].error,
                      ingredientName: "Ingredient name is required!"
                    }
                  }
                  : ingredient
            )
          }));
        }

        if (!ingredient.quantity.length) {
          this.setState(prevState => ({
            ingredients: prevState.ingredients.map(
              (ingredient, index) =>
                index === i
                  ? {
                    ...ingredient,
                    error: {
                      ...prevState.ingredients[i].error,
                      ingredientQuantity: "Quantity is required!"
                    }
                  }
                  : ingredient
            )
          }));
        }
      });
    }

    //IF content ID and Content Type exist only then hit API
    if (recipeId && contentId) {
      if (deleteRecipeIds && deleteRecipeIds.length) {
        var ingredientsData = ingredients
          .map(ingredient => {
            delete ingredient.error;
            return { ...ingredient, unit: "ltr" };
          })
          .concat(deleteRecipeIds);
      } else {
        var ingredientsData = ingredients.map(ingredient => {
          delete ingredient.error;
          return { ...ingredient, unit: "ltr" };
        });
      }

      const detailsForm = new FormData();
      detailsForm.append("name", recipeName);
      detailsForm.append("instructions", instructionsInHTML);
      detailsForm.append("notes", notes);
      detailsForm.append(
        "content_type",
        contentType === "recipe"
          ? "youtube_recipe"
          : contentType === "video"
            ? "video"
            : contentType === "episode" ? "episode" : ""
      );
      detailsForm.append("ingredients", JSON.stringify(ingredientsData));
      if (imageSrc) {
        detailsForm.append("recipe_image", imageSrc);
      }
      detailsForm.append(
        [
          contentType === "recipe"
            ? "youtube_recipe"
            : contentType === "video"
              ? "video"
              : contentType === "episode" ? "episode" : ""
        ],
        Number(contentId)
      );

      const data = {
        name: recipeName,
        instructions: instructionsInHTML,
        notes,
        content_type:
          contentType === "recipe"
            ? "youtube_recipe"
            : contentType === "video"
              ? "video"
              : contentType === "episode" ? "episode" : "",
        [contentType === "recipe"
          ? "youtube_recipe"
          : contentType === "video"
            ? "video"
            : contentType === "episode" ? "episode" : ""]: Number(contentId),

        // ingredients: ingredients.map((ingredient) => {
        //   delete ingredient.error;
        //   return { ...ingredient, unit: "ltr" };
        // })
        ingredients: ingredientsData
      };

      const updateRecipeInstructions = await this.props.onEditConnectedRecipe(
        recipeId,
        detailsForm
      );
      if (
        updateRecipeInstructions &&
        updateRecipeInstructions.msg &&
        updateRecipeInstructions.msg.includes("Successfully Updated")
      ) {
        swal(`Recipe ${updateRecipeInstructions.msg}`);
        this.loadRecipeDetails();
      }
    }
  };

  cancelHandler(type) {
    if (type === "episode") {
      this.props.history.push(
        `/update-episode-content/${this.props.match.params[
        "contentType"
        ]}/${this.props.match.params["contentId"]}`
      );
    } else {
      this.props.history.push(
        `/update-content/${this.props.match.params["contentType"]}/${this.props
          .match.params["contentId"]}`
      );
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleAddition = (value, index) => {
    this.handleIngredientChange(
      {
        target: {
          name: "category",
          value: value
        }
      },
      index
    );
    this.setState({ showSearchDropdownIndex: null, searchCategoryString: "" });
  };

  handleSearch = (input, index) => {
    const array = [...this.state.ingredients];
    array[index] = { ...array[index], category: "" };
    this.setState({ ingredients: array });
    this.setState(
      {
        searchCategoryString: input.target.value,
        showSearchDropdownIndex: index
      },
      () => {
        if (this.state.searchCategoryString !== "") {
          this.setState({ searchCategoryLoading: true });
          this.searchCategoryApi();
        }
      }
    );
  };

  loadSearchCategoryData = async () => {
    const response = await this.props.onSearchCategory({
      search: this.state.searchCategoryString
    });

    this.setState({ categoryTagSuggestion: response && response.data });
    this.setState({ searchCategoryLoading: false });
  };

  handleUploadFile = (e, type) => {
    const { recipeType } = this.props.match.params;
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (type === "image" && uploadedFileType.includes("image")) {

      this.setState({
        imageSrc: file,
        imageName: file.name
      });


    } else {
      swal("Wrong File type encountered! Please try again");
      return;
    }
    this.checkForChanges();
  };
  render() {
    const {
      notes,
      errorText,
      recipeName,
      inputError,
      editorState,
      ingredients,
      toolbarConfig,
      loading,
      recipeLoading,
      ingredientCategory,
      searchCategoryString,
      categoryTagSuggestion,
      showSearchDropdownIndex,
      searchCategoryLoading,
      imageName,
      imageSrc
    } = this.state;
    const { recipeType } = this.props.match.params;

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
      
          <AdminSidebarMenu />
          <TopTab
            activeTab="Upload Content"
            tabCategoryName="contentManagment"
          />
          <div className="main-content  bg-white">
            <div className="UploadRecipeInstructions bg-white">
              <Container className="px-0 mt-4">
                <Row>
                  <Col sm={3}>
                    { }
                    <span
                      onClick={
                        this.props.match.params.contentType == "episode"
                          ? () =>
                            this.props.history.push(
                              `/update-episode-content/episode/${Number(
                                this.props.match.params.contentId
                              )}`
                            )
                          : () =>
                            this.props.history.push(
                              `/update-content/${this.props.match.params[
                              "contentType"
                              ]}/${this.props.match.params["contentId"]}`
                            )
                      }
                      className="d-flex align-items-center font-weight-bold cursor-pointer"
                    >
                      <img src={"/assets/images/back-arrow.svg"} alt="Back" />
                      Back
                    </span>
                  </Col>

                  <Col lg={6} md={9}>
                    <div className="recipe-instructions-wrapper">
                      <h2 className="mb-4">Upload Recipe Instructions</h2>
                      {recipeLoading
                        ? <ContentLoader color="text-warning" />
                        : <div className="content mt-5">
                          <Form.Group className="">
                            <Form.Control
                              type="text"
                              name="recipeName"
                              value={recipeName}
                              onChange={this.handleChange}
                              placeholder="Recipe Name"
                              autocomplete="off"
                              className={`fs-18 font-weight-bold bg-grey-4 rounded mb-0 p-4 ${inputError.recipeName &&
                                inputError.recipeName.length
                                ? "input-invalid"
                                : ""}`}
                              required
                            />
                            {inputError.recipeName &&
                              inputError.recipeName.length &&
                              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                {inputError.recipeName}
                              </p>}
                          </Form.Group>

                          {/* upload image section */}
                          <Form.Group className="bg-grey-4 p-3">
                            <Form.Label className="mb-2 font-weight-bold">
                              Recipe Image
                            </Form.Label>
                            <label
                              htmlFor="titleImage"
                              className="btn btn-block text-grey-5 bg-white rounded"
                            >
                              <input
                                type="file"
                                className="d-none invisible"
                                id="titleImage"
                                name="videoImage"
                                onChange={e =>
                                  this.handleUploadFile(e, "image")}
                                accept="image/png, image/jpg, image/jpeg"
                                required
                              />
                              {imageName ? imageName : <>
                                <span className="fa fa-upload mr-3"></span>Add Title Image
                              </>}
                            </label>
                            {this.state.imageUrl
                              ? <div className="text-center mt-2">
                                <img
                                className="mt-3"
                                  src={imageSrc ? URL.createObjectURL(imageSrc) : this.state.imageUrl}
                                  // src={ this.state.imageUrl}
                                  alt="upload"
                                />
                              </div>
                              : null}
                          </Form.Group>

                          <Form.Group className="bg-grey-4  p-3">
                            <Form.Label className="fs-14 font-weight-bold text-grey-5 mb-4">
                              Ingredients
                            </Form.Label>
                            {ingredients && ingredients.length
                              ? ingredients.map((item, index) =>
                                <Form.Row
                                  key={index}
                                  className="align-items-start mb-3"
                                >
                                  <Col xs={4}>
                                    <Form.Control
                                      name="category"
                                      placeholder="Search Category"
                                      autocomplete="off"
                                      value={
                                        item.category
                                          ? item.category
                                          : showSearchDropdownIndex ===
                                            index
                                            ? searchCategoryString
                                            : ""
                                      }
                                      onChange={e =>
                                        this.handleSearch(e, index)}
                                      className={`bg-white font-weight-semi py-2 h-auto text-capitalize`}
                                    />
                                    {showSearchDropdownIndex === index
                                      ? <div
                                        className="position-absolute border shadow-sm"
                                        style={{
                                          top: "100%",
                                          left: "5px",
                                          zIndex: 9,
                                          minWidth: "100%"
                                        }}
                                      >
                                        {searchCategoryLoading
                                          ? <button
                                            className={`bg-white font-weight-semi h-auto tag-button`}
                                          >
                                            Loading...
                                          </button>
                                          : categoryTagSuggestion &&
                                            categoryTagSuggestion.length ===
                                            0
                                            ? searchCategoryString &&
                                              searchCategoryString.length ===
                                              0
                                              ? null
                                              : <button
                                                className={`bg-white font-weight-semi  h-auto tag-button`}
                                                onClick={e =>
                                                  this.handleAddition(
                                                    searchCategoryString,
                                                    index
                                                  )}
                                              >
                                                Add{" "}
                                                {searchCategoryString}
                                              </button>
                                            : categoryTagSuggestion &&
                                            categoryTagSuggestion.map(
                                              (item, i) =>
                                                <button
                                                  className={`bg-white text-left font-weight-semi h-auto tag-button`}
                                                  onClick={e =>
                                                    this.handleAddition(
                                                      item,
                                                      index
                                                    )}
                                                  key={i}
                                                >
                                                  {item}
                                                </button>
                                            )}
                                      </div>
                                      : null}
                                    {item.error &&
                                      item.error.ingredientType &&
                                      item.error.ingredientType.length &&
                                      <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                        {item.error.ingredientType}
                                      </p>}
                                  </Col>
                                  <Col xs={4}>
                                    <Form.Group className="mb-0">
                                      <Form.Control
                                        type="text"
                                        defaultValue={""}
                                        value={item.name}
                                        name="name"
                                        autocomplete="off"
                                        onChange={e =>
                                          this.handleIngredientChange(
                                            e,
                                            index
                                          )}
                                        placeholder="Item Name"
                                        className={`bg-white font-weight-semi py-2 h-auto ${item.error &&
                                          item.error.ingredientName &&
                                          item.error.ingredientName.length
                                          ? "input-invalid"
                                          : ""}`}
                                      />
                                      {item.error &&
                                        item.error.ingredientName &&
                                        item.error.ingredientName.length &&
                                        <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                          {item.error.ingredientName}
                                        </p>}
                                    </Form.Group>
                                  </Col>
                                  <Col xs={3}>
                                    <Form.Group className="mb-0">
                                      <Form.Control
                                        type="text"
                                        defaultValue={""}
                                        value={item.quantity}
                                        name="quantity"
                                        autocomplete="off"
                                        onChange={e =>
                                          this.handleIngredientChange(
                                            e,
                                            index
                                          )}
                                        placeholder="Quantity"
                                        className={`bg-white font-weight-semi py-2 h-auto ${item.error &&
                                          item.error.ingredientQuantity &&
                                          item.error.ingredientQuantity.length
                                          ? "input-invalid"
                                          : ""}`}
                                      />
                                      {/* {item.error &&
                                        item.error.ingredientQuantity &&
                                        item.error.ingredientQuantity
                                          .length &&
                                        <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                          {item.error.ingredientQuantity}
                                        </p>} */}
                                    </Form.Group>
                                  </Col>
                                  {index !== 0 &&
                                    <Col xs={1}>
                                      <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                          this.handleDeleteIngredient(
                                            index,
                                            item
                                          )}
                                      >
                                        <img
                                          src={
                                            "/assets/images/delete-content.svg"
                                          }
                                          alt="Delete"
                                        />
                                      </div>
                                    </Col>}
                                </Form.Row>
                              )
                              : null}

                            {/* =========text color not taking ==========*/}
                            <div>
                              <button
                                onClick={this.onHandleAddMoreIngredients}
                                className="btn btn-outline-info rounded px-3 py-2 mt-4 font-smallest font-weight-semi bg-white text-grey-4"
                              >
                                Add more
                              </button>
                            </div>
                          </Form.Group>

                          <Form.Group className="bg-grey-4  p-3">
                            <Form.Label className="fs-14 font-weight-bold text-grey-5 mb-3 form-label">
                              Instructions
                            </Form.Label>
                            <Editor
                              editorState={editorState}
                              wrapperClassName="custom-editor-wrapper pt-2 pb-4"
                              editorClassName="custom-editor bg-white px-3"
                              toolbarClassName="custom-editor-toolbar bg-transparent px-0"
                              onEditorStateChange={this.onEditorStateChange}
                              onContentStateChange={
                                this.handleInstructionsChange
                              }
                              toolbar={toolbarConfig}
                            />
                            {inputError.instructions &&
                              inputError.instructions.length &&
                              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                                {inputError.instructions}
                              </p>}
                          </Form.Group>

                          <Form.Group className="bg-grey-4  p-3">
                            <Form.Label className="fs-14 font-weight-bold text-grey-5 mb-3 form-label">
                              Notes
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="notes"
                              value={notes}
                              onChange={this.handleChange}
                              rows={11}
                              className="bg-white fs-smallest font-weight-medium font-poppins"
                              placeholder="Notes"
                            />
                          </Form.Group>
                          {errorText.length
                            ? <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                              {errorText}
                            </p>
                            : ""}
                          <div className="button-group d-flex justify-content-end fs-14 font-smallest font-weight-semi mt-3">
                            <button
                              className="btn btn-outline-dark rounded px-3 py-2 text-black"
                              onClick={() => {
                                this.cancelHandler(
                                  this.props.match.params["contentType"]
                                );
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-primary rounded px-3 py-2 ml-2"
                              onClick={
                                recipeType === "edit-recipe"
                                  ? this.onEditRecipeHandler
                                  : this.onSaveClickHandler
                              }
                              disabled={this.state.areAllDataSame}
                            >
                              {loading
                                ? <ContentLoader />
                                : recipeType === "edit-recipe"
                                  ? "Save Changes"
                                  : "Save"}
                            </button>
                          </div>
                        </div>}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    savedContent: state.content_manager_reducer,
    error: state.add_connected_recipe_reducer.error,
    updatedRecipe: state.get_connected_recipe_reducer,
    connectedRecipe: state.add_connected_recipe_reducer.connectedRecipes
  };
};

const mapDispatchToProps = dispatch => ({
  getUpdatedRecipe: id => dispatch(get_connected_recipe_action(id)),
  onAddConnectedRecipe: data => dispatch(add_connected_recipe_action(data)),
  onSearchCategory: data => dispatch(search_category_action(data)),
  onEditConnectedRecipe: (id, data) =>
    dispatch(edit_connected_recipe_action(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  UploadRecipeInstructions
);
