import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import "./ProductDisplay.scss";

class ProductDisplay extends Component {
  render() {
    return (
      <div className="common-dashboard product-wrapper">
        <Container className="p-0">
          <SidebarMenu />

          <div className="main-content bg-white">
            <Header />

            {/*==================== cookbook deyails page============= */}

            <div className="product-detail">
              <a href="/collections" className="align-items-center d-none d-md-flex">
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-md-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row className="mt-5">
                <Col lg={6} className="px-4 py-md-0 text-center">
                  <img
                    src="/assets/images/hoody-3.svg"
                    alt="product-img"
                    className="left-img img-fit border-6"
                  />
                </Col>
                <Col lg={6} className="px-3 px-md-4">
                  <h3 className="fs-24 mb-4 mb-md-3 mt-5 mt-lg-0 text-center text-md-left">
                    Make Oxtails Cheap again Hoodies
                  </h3>
                  <h3 className="text-warning mb-4 text-center text-md-left">
                    $49.00
                  </h3>

                  {/* ==========product color selector list==============*/}
                  <ul className="color-list d-flex flex-wrap my-3 text-center justify-content-center justify-content-md-start">
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="grey">
                        <input
                          type="radio"
                          name="label-color"
                          id="grey"
                          className="border-6"
                          checked
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-1.svg"
                            alt="color-1"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="red">
                        <input
                          type="radio"
                          name="label-color"
                          id="red"
                          className="border-6"
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-2.svg"
                            alt="color-2"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="green">
                        <input
                          type="radio"
                          name="label-color"
                          id="green"
                          className="border-6"
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-3.svg"
                            alt="color-3"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="blue">
                        <input
                          type="radio"
                          name="label-color"
                          id="blue"
                          className="border-6"
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-1.svg"
                            alt="color-1"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="orange">
                        <input
                          type="radio"
                          name="label-color"
                          id="orange"
                          className="border-6"
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-2.svg"
                            alt="color-2"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-color-radio" htmlFor="black">
                        <input
                          type="radio"
                          name="label-color"
                          id="black"
                          className="border-6"
                        />
                        <span className="color-box border-6">
                          <img
                            src="/assets/images/hoody-3.svg"
                            alt="color-3"
                            className="rounded"
                          />
                        </span>
                      </label>
                    </li>
                  </ul>

                  {/* =================product size selector============== */}

                  <ul className="size-list font-smallest text-grey-4 justify-content-center justify-content-md-start d-flex flex-wrap my-4 text-center font-weight-medium">
                    <li className="mr-2">
                      <label className="custom-size-radio" htmlFor="small">
                        <input
                          type="radio"
                          name="label-size"
                          id="small"
                          className="rounded"
                          checked
                        />
                        <span className="size-box rounded">S</span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-size-radio" htmlFor="medium">
                        <input
                          type="radio"
                          name="label-size"
                          id="medium"
                          className="rounded"
                        />
                        <span className="size-box rounded">M</span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-size-radio" htmlFor="large">
                        <input
                          type="radio"
                          name="label-size"
                          id="large"
                          className="rounded"
                        />
                        <span className="size-box rounded">L</span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label className="custom-size-radio" htmlFor="extraLarge">
                        <input
                          type="radio"
                          name="label-size"
                          id="extraLarge"
                          className="rounded"
                        />
                        <span className="size-box rounded">XL</span>
                      </label>
                    </li>
                    <li className="mr-2">
                      <label
                        className="custom-size-radio"
                        htmlFor="DoubleLarge"
                      >
                        <input
                          type="radio"
                          name="label-size"
                          id="DoubleLarge"
                          className="rounded"
                        />
                        <span className="size-box rounded">XXL</span>
                      </label>
                    </li>
                  </ul>

                  <div className="item-desc mb-4 fs-16 font-weight-bold">
                    <p className="mb-1">
                      - 50% Cotton 50% Polyester (fiber content may vary for
                      different colors)
                    </p>
                    <p className="mb-1">
                      - Medium-heavy fabric (8.0 oz/yd² (271 g/m²))
                    </p>
                    <p className="mb-1">- Loose fit</p>
                    <p className="mb-1">- Sewn in label</p>
                    <p className="mb-1">- Runs true to size</p>
                  </div>

                  <div className="bottom-btns d-flex justify-content-center justify-content-md-start">
                    <Button
                      variant="outline-warning"
                      className="rounded px-4 px-md-5 py-3 mr-3"
                    >
                      Add to cart
                    </Button>
                    <Button
                      variant="outline-warning"
                      className="rounded px-4 px-md-5 py-3"
                    >
                      Buy Now
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default ProductDisplay;
