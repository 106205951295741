import React, { Component } from "react";
import { Container, Row, Col, Button, Dropdown, Modal } from "react-bootstrap";
import "./ViewGroceryList.scss";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import {
  get_grocery_list_byid_action,
  add_item_to_grocery_list_action,
  delete_item_from_grocery_list_action,
  toggle_checkbox_action,
  delete_items_by_recipe_from_grocery_list_action,
} from "../../../_actions/User/grocery.action";
import { connect } from "react-redux";
import swal from "sweetalert";
import { search_category_action_user } from "../../../_actions/User";
import { debounce } from "../../../_helpers/debounce";
class ViewGroceryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grocery_list_id: null,
      successMessage: "",
      listItems: [],
      itemsList: [],
      children: [
        {
          item_name: "",
          quantity: null,
          category: "",
          // unit: "",
          grocery_list: null
        }
      ],
      categoryTagSuggestion: ["fruit", "vegetable"],
      searchCategoryLoading: false,
      showSearchDropdownIndex: null,
      searchCategoryString: "",
      deleteRecipeItemsModal: false,
      deleteGroceryListId: null,
      deleteRecipeId: null,
      filterRecipeNames: []
    };
    this.searchCategoryApi = debounce(this.loadSearchCategoryData);
  }
  componentDidMount = async () => {
    let listId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.replace("/view-grocery-list/", "");
    let res = await this.props.getGroceryListById(listId);
    this.setState({
      grocery_list_id: listId,
      children: [
        {
          item_name: "",
          quantity: null,
          category: "",
          // unit: "",
          grocery_list: listId
        }
      ]
    });

    let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
      let obj = {};
      listItems && listItems.forEach((listItem) => {
        if (obj[listItem.category.toUpperCase()]) {
          obj[listItem.category.toUpperCase()] = [
            ...obj[listItem.category.toUpperCase()],
            { ...listItem }
          ];
        } else {
          obj[listItem.category.toUpperCase()] = [{ ...listItem }];
        }
      });
      return obj;
    };
    this.setState({
      listItems: changedListItems,
      itemsList: formatListItems(changedListItems)
    });
    this.filterRecipeNames();
  };

  routeHandler = () => {
    this.props.history.push("/add-grocery-list");
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let isEmpty = "";
    let isValid = "";

    this.state.children.forEach((obj) =>
      !obj.item_name
        ? (isEmpty = "Item Name")
        : !obj.quantity
          ? (isEmpty = "Quantity")
          // : !obj.unit
          // ? (isEmpty = "Unit")
          : !obj.category
            ? (isEmpty = "Category")
            : (isEmpty = "")
    );
    if (isEmpty.length > 0) {
      swal(`${isEmpty} is required`);
      return;
    }

    // this.state.children.forEach((obj) =>
    //   isNaN(obj.quantity) == true
    //     ? (isValid = "Please enter valid Quantity")
    //     : obj.quantity <= 0
    //     ? (isValid = "Please enter valid Quantity")
    //     : (isValid = "")
    // );

    // if (isValid.length > 0) {
    //   swal(`${isValid}`);
    //   return;
    // }

    let res1 = await this.props.addItemToGroceryList(this.state.children[0]);
    let res = await this.props.getGroceryListById(this.state.grocery_list_id);

    let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
      let obj = {};
      listItems.forEach((listItem) => {
        if (obj[listItem.category.toUpperCase()]) {
          obj[listItem.category.toUpperCase()] = [
            ...obj[listItem.category.toUpperCase()],
            { ...listItem }
          ];
        } else {
          obj[listItem.category.toUpperCase()] = [{ ...listItem }];
        }
      });
      return obj;
    };
    this.setState({
      listItems: changedListItems,
      itemsList: formatListItems(changedListItems)
    });

    if (res1.msg == "Successfully Submitted") {
      this.setState({
        successMessage: "Item added successfully to the list",

        children: [
          {
            item_name: "",
            quantity: "",
            category: "",
            // unit: "",
            grocery_list: this.state.grocery_list_id
          }
        ]
      });
    } else {
      swal("Something went wrong.Please try again later!");
    }
  };

  onDeleteItem = async (id) => {
    let res1 = await this.props.deleteItemFromGroceryList(id);
    let res = await this.props.getGroceryListById(this.state.grocery_list_id);
    /*if(res1.msg==""){
  this.setState({successMessage:"Item deleted successfully from the list"})
  }else{
    swal("Something went wrong.Please try again later!")
  } */
    let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
      let obj = {};
      listItems.forEach((listItem) => {
        if (obj[listItem.category.toUpperCase()]) {
          obj[listItem.category.toUpperCase()] = [
            ...obj[listItem.category.toUpperCase()],
            { ...listItem }
          ];
        } else {
          obj[listItem.category.toUpperCase()] = [{ ...listItem }];
        }
      });
      return obj;
    };
    this.setState({
      listItems: changedListItems,
      itemsList: formatListItems(changedListItems),
      successMessage: "Item deleted successfully from the list"
    });
  };

  appendChild = () => {
    this.setState({
      children: [
        ...this.state.children,
        {
          item_name: "",
          quantity: null,
          category: "",
          // unit: ""
        }
      ]
    });
  };

  deleteChild = (e, index) => {
    e.stopPropagation();
    let array = [...this.state.children];
    array.splice(index, 1);
    this.setState({ children: array });
  };

  onFieldChange = (index) => (e) => {
    const array = [...this.state.children];
    const { name, value } = e.target;
    array[index] = { ...array[index], [name]: value };

    this.setState({ children: array, [name]: value });
  };

  handleCheck = async (item) => {
    if (item.purchased == true) {
      const data = { purchased: false };
      await this.props.toggleCheckbox(item.id, data);
      let res = await this.props.getGroceryListById(this.state.grocery_list_id);
      let changedListItems = res && res.data && res.data.grocery_items;

      let formatListItems = (listItems) => {
        let obj = {};
        listItems.forEach((listItem) => {
          if (obj[listItem.category.toUpperCase()]) {
            obj[listItem.category.toUpperCase()] = [
              ...obj[listItem.category.toUpperCase()],
              { ...listItem }
            ];
          } else {
            obj[listItem.category.toUpperCase()] = [{ ...listItem }];
          }
        });
        return obj;
      };
      this.setState({
        listItems: changedListItems,
        itemsList: formatListItems(changedListItems)
      });
      return;
    } else {
      const data = { purchased: true };
      await this.props.toggleCheckbox(item.id, data);
      let res = await this.props.getGroceryListById(this.state.grocery_list_id);
      let changedListItems = res && res.data && res.data.grocery_items;

      let formatListItems = (listItems) => {
        let obj = {};
        listItems.forEach((listItem) => {
          if (obj[listItem.category.toUpperCase()]) {
            obj[listItem.category.toUpperCase()] = [
              ...obj[listItem.category.toUpperCase()],
              { ...listItem }
            ];
          } else {
            obj[listItem.category.toUpperCase()] = [{ ...listItem }];
          }
        });
        return obj;
      };
      this.setState({
        listItems: changedListItems,
        itemsList: formatListItems(changedListItems)
      });
    }
  };

  loadSearchCategoryData = async () => {
    const response = await this.props.onSearchCategory({
      search: this.state.searchCategoryString,
    });

    this.setState({ categoryTagSuggestion: response && response.data });
    this.setState({ searchCategoryLoading: false });
  };

  handleSearch = (input, index) => {
    const array = [...this.state.children];
    array[index] = { ...array[index], category: "" };
    this.setState({ children: array });
    this.setState(
      {
        searchCategoryString: input.target.value,
        showSearchDropdownIndex: index,
      },
      () => {
        if (this.state.searchCategoryString !== "") {
          this.setState({ searchCategoryLoading: true });
          this.searchCategoryApi();
        }
      }
    );
  };
  handleAddition = (value, index) => {
    const name = "category"
    const array = [...this.state.children];
    array[index] = { ...array[index], [name]: value };
    this.setState({ showSearchDropdownIndex: null, searchCategoryString: "", children: array, [name]: value });
  };
  deleteRecipeItemsModalShow = (groceryListId, recipeId) => {
    this.setState({
      deleteRecipeItemsModal: true,
      deleteGroceryListId: groceryListId, deleteRecipeId: recipeId,
    })
  }
  deleteRecipeItemsModalHide = (groceryListId, recipeId) => {
    this.setState({
      deleteRecipeItemsModal: false,
      deleteGroceryListId: null,
      deleteRecipeId: null,
    })
  }
  handleDeleteItemsByRecipe = async () => {
    const { deleteGroceryListId, deleteRecipeId } = this.state
    await this.props.deleteItemsByRecipeFromGroceryList(deleteGroceryListId, deleteRecipeId);
    // window.location.reload();
    let res = await this.props.getGroceryListById(this.state.grocery_list_id);

    let changedListItems = res && res.data && res.data.grocery_items;

    let formatListItems = (listItems) => {
      let obj = {};
      listItems.forEach((listItem) => {
        if (obj[listItem.category.toUpperCase()]) {
          obj[listItem.category.toUpperCase()] = [
            ...obj[listItem.category.toUpperCase()],
            { ...listItem }
          ];
        } else {
          obj[listItem.category.toUpperCase()] = [{ ...listItem }];
        }
      });
      return obj;
    };
    this.setState({
      listItems: changedListItems,
      itemsList: formatListItems(changedListItems),
      deleteRecipeItemsModal: false,
      deleteGroceryListId: null, deleteRecipeId: null,
    });
    this.filterRecipeNames();
  }
  filterRecipeNames = () => {
    const { listItems, itemsList } = this.state;
    const uniqueIds = [];
    const uniqueList = [];

    const uniqueEmployees = listItems.filter(element => {
      const isDuplicate = uniqueIds.includes(element && element.related_recipe);

      if (!isDuplicate) {
        uniqueIds.push(element && element.related_recipe);
        uniqueList.push(element)

        return true;
      }

      return false;
    });
    this.setState({
      filterRecipeNames: uniqueList,
    })

  }



  render() {
    const listDetail =
      this.props && this.props.listDetail && this.props.listDetail.data;

    const { successMessage, listItems, itemsList, categoryTagSuggestion, searchCategoryString, showSearchDropdownIndex, searchCategoryLoading, filterRecipeNames } = this.state;
    return (
      <div className="common-dashboard grocery-creation grocery-list">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />
          <div className="main-content grocery-list-content grocery-creation-content bg-white">
            <Header />
            <div className="grocery-list-wrapper grocery-creation-wrapper">
              <a
                href="/grocery-lists"
                className="d-none d-md-flex align-items-center pt-5"
              >
                <img
                  src="/assets/images/back-arrow.svg"
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </a>
              <Row>
                <Col lg={8} xl={6} className="mb-5">
                  <div className="grocery-cards grocery-list-wrapper mb-4">
                    <h3 className="font-weight-bold text-capitalize font-montserrat pt-4 mb-3">
                      {listDetail ? listDetail && listDetail.list_name : null}
                    </h3>
                    <div className="top-actions mb-5">

                      {this.state.children.map((element, index) => (
                        <>
                          <form action="#" className="" key={index}>
                            <div className="add-item d-flex  justify-content-between flex-wrap flex-sm-nowrap align-items-end">
                              <div className="item-name-input mr-0 mr-sm-2">
                                <h4 className="category-title  fs-18 mb-2">
                                  Item Name
                                </h4>
                                <input
                                  type="text"
                                  placeholder="Item Name"
                                  className="form-control"
                                  name="item_name"
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].item_name}
                                  required
                                />
                              </div>
                              <div className="item-name-input mr-0 mr-sm-2">
                                <h4 className="fs-18 mb-2">Qty</h4>
                                <input
                                  type="text"
                                  placeholder="Qty"
                                  className="form-control"
                                  name="quantity"
                                  onChange={this.onFieldChange(index)}
                                  value={this.state.children[index].quantity}
                                  required
                                />
                              </div>


                              <div className="item-name-input mr-0 mr-sm-2 position-relative">
                                <input
                                  name="category"
                                  placeholder="Search Category"
                                  autoComplete="off"
                                  value={
                                    this.state.children[index].category
                                      ? this.state.children[index].category
                                      : searchCategoryString
                                  }
                                  onChange={(e) => this.handleSearch(e, index)}
                                  type="text"
                                  className="form-control"

                                />
                                {showSearchDropdownIndex === index ? (
                                  <div className="bg-white border position-absolute" style={{ top: "100%", left: 0, minWidth: '100%' }}>
                                    {searchCategoryLoading ? (
                                      <span
                                        className={`bg-white font-weight-semi  h-auto tag-button`}
                                      >
                                        Loading...
                                      </span>
                                    ) : categoryTagSuggestion &&
                                      categoryTagSuggestion.length ===
                                      0 ? (
                                      searchCategoryString.length ===
                                        0 ? null : (
                                        <button
                                          className={`bg-white font-weight-semi text-left h-auto tag-button`}
                                          onClick={(e) =>
                                            this.handleAddition(
                                              searchCategoryString,
                                              index
                                            )
                                          }
                                        >
                                          Add {searchCategoryString}
                                        </button>
                                      )
                                    ) : (
                                      categoryTagSuggestion && categoryTagSuggestion.map(
                                        (item, i) => (
                                          <button
                                            className={`bg-white font-weight-semi text-left h-auto tag-button`}
                                            onClick={(e) =>
                                              this.handleAddition(
                                                item,
                                                index
                                              )
                                            }
                                            key={i}
                                          >
                                            {item}
                                          </button>
                                        )
                                      )
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="action mt-2 mt-sm-0"
                                onClick={this.handleSubmit}
                              >
                                <button className="btn btn-primary  btn-block py-1 px-3 ml-sm-2 rounded" style={{ height: '35px' }}>
                                  Add
                                </button>
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              {index > 0 && (
                                <div className="remove-item">
                                  <a
                                    onClick={(e) => this.deleteChild(e, index)}
                                    className="text-red"
                                  >
                                    <span className="fa fa-minus-circle"></span>
                                  </a>
                                </div>
                              )}
                            </div>
                          </form>
                          <br />
                        </>
                      ))}
                      {successMessage.length ? (
                        <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
                          {successMessage}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    {/*****************code for item listing starts*****************/}

                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h4>Delete Items by Recipe</h4>

                      <Dropdown>
                        <Dropdown.Toggle variant="" className="border py-2 px-3" id="dropdown-basic">
                          Select Recipe
                        </Dropdown.Toggle>

                        <Dropdown.Menu>

                          {filterRecipeNames && filterRecipeNames.length ? filterRecipeNames && filterRecipeNames.map((item) => (
                            <>
                              {
                                item && item.related_recipe !== null ? <>
                                  <div className="dropdown-item d-flex align-items-start mr-4">{item && item.related_recipe_data && item.related_recipe_data.name}

                                    <span className="ml-auto d-inline-block px-2 rounded cursor-pointer bg-danger text-white"
                                      onClick={() => this.deleteRecipeItemsModalShow(item && item.grocery_list, item && item.related_recipe)}
                                    >
                                      <span className="fa fa-trash"></span>
                                    </span>

                                  </div>
                                </> :

                                  null
                              }
                            </>




                          )) :
                            <div className="dropdown-item font-weight-semi d-flex align-items-start mr-4 text-danger text-center">
                              No Recipes Found
                            </div>}
                          {/* {listItems.length
                            ? Object.keys(itemsList).map((key) => {
                              return itemsList[key].length ? (
                                <>
                                  {itemsList[key].map((item) => (
                                    <>
                                      {item && item.related_recipe !== null ? <>
                                        <div className="dropdown-item d-flex align-items-start mr-4">{item && item.related_recipe_data && item.related_recipe_data.name}

                                          <span className="ml-auto d-inline-block px-2 rounded cursor-pointer bg-danger text-white"
                                            onClick={() => this.deleteRecipeItemsModalShow(item && item.grocery_list, item && item.related_recipe)}
                                          >
                                            <span className="fa fa-trash"></span>
                                          </span>

                                        </div>
                                      </> : null}

                                    </>


                                  ))}
                                </>
                              ) : null;
                            })
                            : null} */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="row nowrap">
                      <div className="col-3 border-bottom pb-2 mb-3">
                        <h5>Item</h5>
                      </div>
                      <div className="col-3 border-bottom pb-2 mb-3">
                        <h5>Recipe</h5>
                      </div>
                      <div className="col-3 border-bottom pb-2 mb-3">
                        <h5>Quantity</h5>
                      </div>
                      <div className="col-1 border-bottom pb-2 mb-3">
                        <h5>Mark</h5>
                      </div>
                      <div className="col-2 border-bottom pb-2 mb-3">
                        <h5>Delete</h5>
                      </div>
                    </div>

                    {listItems.length
                      ? Object.keys(itemsList).map((key) => {
                        return itemsList[key].length ? (
                          <>
                            <div className="category mb-4">
                              <h4 className="category-title text-grey-4 fs-18 mb-3">
                                {key}
                              </h4>

                              {itemsList[key].map((item, i) => (
                                <>
                                  <div className="item row d-flex align-items-start">
                                    <div className="col-3">
                                      <h5
                                        className={
                                          item.purchased == true
                                            ? "text-deleted text-capitalize text-danger font-weight-semi"
                                            : "category-title text-capitalize"
                                        }
                                      >
                                        {item.item_name}{" "}
                                      </h5>
                                    </div>

                                    <div className="col-3 text-capitalize text-primary font-weight-medium">
                                      {item && item.related_recipe_data && item.related_recipe_data.name}
                                    </div>
                                    <div className="col-3">
                                      <p className="qty text-grey-4  mb-0">
                                        {item.quantity} {item.unit}
                                      </p>
                                    </div>
                                    <div className="col-1">
                                      <label
                                        htmlFor={item.id}
                                        className="custom-control custom-checkbox "
                                        onChange={() =>
                                          this.handleCheck(item)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={item.id}
                                          name=""
                                          checked={
                                            item.purchased == true
                                              ? true
                                              : false
                                          }
                                        />
                                        <span
                                          className="custom-control-label cursor-pointer"
                                          for={item.id}
                                        ></span>
                                      </label>
                                    </div>
                                    <div className="col-2">
                                      <span
                                        className="cursor-pointer fa fa-minus-circle fs-18"
                                        onClick={() =>
                                          this.onDeleteItem(item.id)
                                        }
                                      ></span>
                                    </div>


                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null;
                      })
                      : <div className="font-weight-semi text-danger text-center">
                        Grocery List Found
                      </div>}

                    {/********************code for item listing ends***********************/}
                  </div>
                </Col>
              </Row>
              <a href="/grocery-lists"
                className="fs-14 btn-add-grocery-list d-flex align-items-center btn btn-primary px-4 py-1 cursor-pointer position-fixed"
                onClick={this.routeHandler}
              >
                Done
              </a>
            </div>
          </div>
        </Container>
        <Modal size="sm"
          show={this.state && this.state.deleteRecipeItemsModal} centered onHide={this.deleteRecipeItemsModalHide} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Recipe Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete all items in this recipe</Modal.Body>
          <Modal.Footer>

            <Button variant="danger" className="py-2" onClick={this.handleDeleteItemsByRecipe}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listDetail: state.get_grocery_list_reducer.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGroceryListById: (id) => dispatch(get_grocery_list_byid_action(id)),
  addItemToGroceryList: (data) =>
    dispatch(add_item_to_grocery_list_action(data)),
  deleteItemFromGroceryList: (id) =>
    dispatch(delete_item_from_grocery_list_action(id)),
  toggleCheckbox: (id, data) => dispatch(toggle_checkbox_action(id, data)),
  onSearchCategory: (data) => dispatch(search_category_action_user(data)),
  deleteItemsByRecipeFromGroceryList: (groceryListId, recipeId) => dispatch(delete_items_by_recipe_from_grocery_list_action(groceryListId, recipeId)),


});

export default connect(mapStateToProps, mapDispatchToProps)(ViewGroceryList);
