import React, { Component } from "react";
import {
  Container,
  FormControl,
  Table,
  FormGroup,
  Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import "../UserData/UserData.scss";
import { Pagination } from "antd";
import swal from "sweetalert";
import moment from "moment";

import {
  get_csv_data_action,
  get_next_user_details_action,
  get_prev_user_details_action,
  get_user_details_action,
  search_user_details_action,
} from "../../../../_actions/Admin";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import TopTab from "../../common/TopTab/TopTab";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import "./UserData.scss";
import { debounce } from "../../../../_helpers/debounce";
class UserData extends Component {
  constructor(props) {
    super(props);

    this.search = debounce(this.setUserData);
  }

  state = {
    userData: null,
    // entriesPerPage: 25,
    // currentPageNumber: 1,
    // maxPages: 1,
    selectAll: false,
    deselectAll: false,
    // prevDataLink: null,
    // nextDataLink: null,
    searchString: "",
    userArray: [],
    checkedUsersIds: [],
    loadingUserData: false,
    exportLoader: false,
    usersSortOrder: "",
    // TotalPages: null,
    filterType: {
      plan: "",
      status: "",
    },
    pagination: {
      next: "",
      total: 1,
      current: 1,
      pageSize: 25,
      previous: "",
    },
    promiseStack: [],
    searchText: null, // stack of chars
  };

  componentDidMount() {
    this.setUserData();
  }

  // handleSearch = (e) => {
  //   e.preventDefault();
  //   let searchVal = e.target.value;
  //   if (searchVal.length === "" || searchVal.length >= 2) {
  //     this.setState((prevState) => ({
  //       userData: [],
  //       userArray: [],
  //       pagination: {
  //         next: "",
  //         total: 1,
  //         current: 1,
  //         pageSize: 25,
  //         previous: "",
  //       },
  //     }));
  //     this.setState({ searchString: searchVal }, () => {
  //       this.search();
  //     });
  //   }
  // };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      userData: [],
      userArray: [],
      pagination: {
        next: "",
        total: 1,
        current: 1,
        pageSize: 25,
        previous: "",
      },
    }));
    this.setState({ searchString: e.target.value }, () => {
      this.search();
    });
  };


  checkSearch = () => {
    let val = this.state.promiseStack.filter(
      (item) => item.searchKey === this.state.searchString
    );

    if (val && val.length) {
      const loadUserDataRes = val[0].response;
      this.setState((prevState) => ({
        userData: loadUserDataRes.results,
        userArray: loadUserDataRes.results,
        pagination: {
          ...prevState.pagination,
          total: loadUserDataRes.count === 0 ? 1 : loadUserDataRes.count,
          next: loadUserDataRes.next,
          previous: loadUserDataRes.previous,
        },
      }));
    }
  };

  setUserData = async () => {
    this.setState({ loadingUserData: true, exportLoader: true });

    // get all user details and set data, prev and next link in state

    const { searchString, filterType } = this.state;
    let loadUserDataRes = {};
    if (searchString && searchString !== "") {
      let data = { search_name_email: searchString };
      loadUserDataRes = await this.props.searchUserDetails(
        this.state.pagination.current,
        data
      );

    } else if (filterType.plan !== "" || filterType.status !== "") {
      let data = filterType;
      loadUserDataRes = await this.props.searchUserDetails(
        this.state.pagination.current,
        data
      );
    } else {
      loadUserDataRes = await this.props.getUserDetails(
        this.state.pagination.current
      );
    }

    if (loadUserDataRes && loadUserDataRes.results && loadUserDataRes.results.length) {
      let newStack = this.state.promiseStack;
      newStack.push({
        searchKey: searchString,
        response: loadUserDataRes,
      });

      this.setState({ promiseStack: newStack }, () => {
        this.checkSearch();
      });

    } else if (loadUserDataRes && loadUserDataRes.msg) {
      swal(loadUserDataRes.msg);
    }

    this.setState({ loadingUserData: false, exportLoader: false });
  };

  // setMaxPagesValue = () => {
  //   // calculate max number of pages required to accommodate all the user details
  //   const { entriesPerPage, TotalPages } = this.state;
  //   const remainder = TotalPages % entriesPerPage;
  //   const quotient = TotalPages / entriesPerPage;
  //   let maxPagesNumber =
  //     remainder > 0 ? parseInt((quotient + 1).toString(), 10) : quotient;
  //   this.setState({ maxPages: maxPagesNumber }, () => {
  //     // manage current page number if it exceeds the max page capacity.
  //     const { currentPageNumber, maxPages } = this.state;
  //     if (currentPageNumber > maxPages)
  //       this.setState({ currentPageNumber: this.state.maxPages });
  //     if (currentPageNumber < 1) this.setState({ currentPageNumber: 1 });
  //   });
  // };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: parseInt(value) }, () => this.setMaxPagesValue());
  };

  // debouncePatch = debounce(this.handleSearch, 1000);




  handleFilterChange = (type, value) => {
    const { filterType } = this.state;
    let filter = filterType;
    value === "" ? delete filter[type] : (filter[type] = value);
    this.setState({ filterType: filter }, () => {
      this.setUserData();
    });
  };

  // searchUsers = () => {
  //   const { searchString, userData } = this.state;
  //   const filterBy = (stringPassed) => {
  //     const lowerCaseStringValue = stringPassed.toLowerCase();
  //     return (user) =>
  //       Object.keys(user) &&
  //       Object.keys(user).some(() => {
  //         return (
  //           user.first_name.toLowerCase().indexOf(lowerCaseStringValue) !==
  //             -1 ||
  //           user.last_name.toLowerCase().indexOf(lowerCaseStringValue) !== -1 ||
  //           user.email.toLowerCase().indexOf(lowerCaseStringValue) !== -1
  //         );
  //       });
  //   };
  //   const searchedUsers = userData.filter(filterBy(searchString));
  //   this.setState({ userArray: searchedUsers });
  // };

  // searchUsers = async () => {
  //   const { searchString, userData } = this.state;
  //   if (searchString !== "") {
  //     this.setState({ exportLoader: true });
  //     let data = { search_name_email: searchString };
  //     let searchData = await this.props.searchUserDetails(data);

  //     const searchedRecipes = searchData && searchData.results;
  //     this.setState({ userArray: searchedRecipes, exportLoader: false });
  //   } else {
  //     this.setState({ userArray: userData });
  //   }
  // };

  // getFilteredUsersApi = async () => {
  //   const { filterType, userArray } = this.state;
  //   this.setState({ loadingUserData: true });
  //   this.setState({ exportLoader: true });
  //   let searchData = await this.props.searchUserDetails(1, filterType);

  //   const searchedRecipes = searchData && searchData.results;

  //   this.setState({
  //     userArray: searchedRecipes,
  //     exportLoader: false,
  //     userData: searchedRecipes,
  //   });
  //   this.setState({ loadingUserData: false });

  // };

  sortUsers = (type) => {
    const { userData } = this.state;
    this.setState({ usersSortOrder: type });
    let sortedUserArray = userData;
    if (type === "asc")
      sortedUserArray = sortedUserArray.sort((a, b) => {
        return new Date(a["created"]) - new Date(b["created"]);
      });
    else if (type === "dec")
      sortedUserArray = sortedUserArray.sort((a, b) => {
        return new Date(b["created"]) - new Date(a["created"]);
      });
    this.setState({ userArray: sortedUserArray });
  };

  // handlePageNumberChange = async (type) => {
  //   const {
  //     currentPageNumber,
  //     prevDataLink,
  //     nextDataLink,
  //     userData,
  //     maxPages,
  //   } = this.state;
  //   if (type === "decrease") {
  //     if (currentPageNumber > 1)
  //       // decrease page number while it is greater than 0
  //       this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
  //     // hit the prev data api to fetch data of users
  //     if (prevDataLink !== null) {
  //       this.setState({ loadingUserData: true });
  //       const prevUserDataRes = await this.props.getPrevUserDetails(
  //         prevDataLink
  //       );
  //       if (prevUserDataRes) {
  //         const prevUserDataArray = prevUserDataRes.results;
  //         prevUserDataArray.push(...userData);
  //         this.setState({
  //           userData: prevUserDataArray,
  //           prevDataLink: prevUserDataRes.previous,
  //         });
  //       }
  //       this.setState({ loadingUserData: false });
  //     }
  //   } else if (type === "increase") {
  //     if (currentPageNumber < maxPages) {
  //       // decrease page number while it is greater than 0
  //       this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
  //       if (nextDataLink !== null) {
  //         this.setState({ loadingUserData: true });
  //         const nextUserDataRes = await this.props.getNextUserDetails(
  //           nextDataLink
  //         );
  //         if (nextUserDataRes) {
  //           const nextUserDataArray = userData;
  //           nextUserDataArray.push(...nextUserDataRes.results);
  //           this.setState({
  //             userData: nextUserDataArray,
  //             nextDataLink: nextUserDataRes.next,
  //           });
  //         }
  //         this.setState({ loadingUserData: false });
  //       }
  //     }
  //   }
  // };

  updateCheckedUsers = (e, id) => {
    const { userData } = this.state;
    const { checked } = e.target;
    const checkedUsersIds = this.state.checkedUsersIds;

    if (checked === true) {
      const user = userData.filter((item) => item.id === id);

      user[0] && checkedUsersIds.push(user[0].id);
    } else if (checked === false) {
      checkedUsersIds.filter((userId) => userId !== id);
    }
    this.setState({ checkedUsersIds });
  };

  addAllIds = () => {
    if (this.state.selectAll) {
      const ids = this.state.userData.map((user) => user["id"]);
      this.setState({ checkedUsersIds: ids });
    } else if (!this.state.selectAll) {
      this.setState({ checkedUsersIds: [] });
    }
  };

  exportCsv = async () => {
    this.setState({ exportLoader: true });
    const { checkedUsersIds, userData } = this.state;
    let data = {};
    // if (checkedUsersIds.length > 0) data["users_list"] = checkedUsersIds;
    data["users_list"] = checkedUsersIds.map((user) => user);
    const csvRes = await this.props.getCsvData(data);
    if (csvRes) {
      const csvData = new Blob([csvRes], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(csvData, "UserDetails.csv");
    }
    this.setState({ exportLoader: false });
  };

  handlePagination = async (page) => {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, current: page },
      }),
      async () => {
        await this.setUserData();
      }
    );
  };

  render() {
    const {
      // entriesPerPage,
      // currentPageNumber,
      // maxPages,
      searchString,
      userArray,
      selectAll,
      deselectAll,
      loadingUserData,
      exportLoader,
      usersSortOrder,
      filterType,
      checkedUsersIds,
      pagination,
    } = this.state;
    let users = userArray;

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu
            activeTab="User Data"
            tabCategoryName="userDataReports"
          />
          <TopTab activeTab="User Data" tabCategoryName="userDataReports" />
          <div className="main-content  bg-white">
            <div className="user-data bg-grey-6">
              <Container className="p-0">
                <div className="user-data-wrapper bg-white">
                  <h1 className="mb-5">User Information</h1>

                  {/* search and filters ========== */}
                  <div className="search-and-filter d-flex mb-3">
                    <FormGroup className="mb-0 search-bar flex-grow-1 position-relative">
                      <FormControl
                        onChange={this.handleSearch}
                        value={searchString}
                        placeholder="Search"
                        className="text-grey-3 h-100"

                      />
                      <i className="fa fa-search fa-1x" aria-hidden="true"></i>
                    </FormGroup>

                    <div className="search-filter ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="filter-toggler py-2 d-flex align-items-center justify-content-between bg-grey-3"
                        >
                          Filter
                          <img
                            src={"/assets/images/dropdown.svg"}
                            alt="dropdown"
                            className="ml-3 mr-1"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="font-smallest font-weight-bold bg-grey border-0 p-4 h-auto rounded">
                          <ul>
                            <li
                              className={
                                usersSortOrder === "asc"
                                  ? "cursor-pointer mb-2 text-primary text-nowrap"
                                  : "cursor-pointer mb-2"
                              }
                              onClick={() => this.sortUsers("asc")}
                            >
                              Sort by Joining date (Ascending)
                            </li>
                            <li
                              className={
                                usersSortOrder === "dec"
                                  ? "cursor-pointer mb-2 text-primary text-nowrap"
                                  : "cursor-pointer mb-2 text-nowrap"
                              }
                              onClick={() => this.sortUsers("dec")}
                            >
                              Sort by Joining date (Descending)
                            </li>
                            <li className="d-flex mb-2">
                              <p>Filter by Plan :</p>
                              <ul className="ml-3">
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planMonthly"
                                    name=""
                                    checked={filterType["plan"] === "monthly"}
                                    onClick={() =>
                                      filterType["plan"] === "monthly"
                                        ? this.handleFilterChange("plan", "")
                                        : this.handleFilterChange(
                                          "plan",
                                          "monthly"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planMonthly"
                                  >
                                    Monthly
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planAnnually"
                                    name=""
                                    checked={filterType["plan"] === "annual"}
                                    onClick={() =>
                                      filterType["plan"] === "annual"
                                        ? this.handleFilterChange("plan", "")
                                        : this.handleFilterChange(
                                          "plan",
                                          "annual"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planAnnually"
                                  >
                                    Annually
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="planSingle"
                                    name=""
                                    checked={
                                      filterType["plan"] === "per_product"
                                    }
                                    onClick={() =>
                                      filterType["plan"] === "per_product"
                                        ? this.handleFilterChange("plan", "")
                                        : this.handleFilterChange(
                                          "plan",
                                          "per_product"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="planSingle"
                                  >
                                    Single
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="noPlan"
                                    name=""
                                    checked={filterType["plan"] === "free"}
                                    onClick={() =>
                                      filterType["plan"] === "free"
                                        ? this.handleFilterChange("plan", "")
                                        : this.handleFilterChange(
                                          "plan",
                                          "free"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="noPlan"
                                  >
                                    No Plan
                                  </label>
                                </li>
                              </ul>
                            </li>
                            <li className="d-flex mb-2">
                              <p className="text-nowrap">Filter by Status :</p>
                              <ul className="ml-3">
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="statusActive"
                                    name="row-checkbox"
                                    checked={filterType["status"] === "active"}
                                    onClick={() =>
                                      filterType["status"] === "active"
                                        ? this.handleFilterChange("status", "")
                                        : this.handleFilterChange(
                                          "status",
                                          "active"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="statusActive"
                                  >
                                    Active
                                  </label>
                                </li>
                                <li className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="statusInActive"
                                    name="row-checkbox"
                                    checked={
                                      filterType["status"] === "inactive"
                                    }
                                    onClick={() =>
                                      filterType["status"] === "inactive"
                                        ? this.handleFilterChange("status", "")
                                        : this.handleFilterChange(
                                          "status",
                                          "inactive"
                                        )
                                    }
                                  />
                                  <label
                                    className="custom-control-label text-nowrap"
                                    htmlFor="statusInActive"
                                  >
                                    In-active
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="ml-2 export">
                      <button
                        className="btn btn-primary Export-btn py-2 rounded h-100"
                        onClick={() => this.exportCsv()}
                      >
                        {!exportLoader ? (
                          "Export as CSV"
                        ) : (
                          <ContentLoader color="text-white" />
                        )}
                      </button>
                    </div>
                  </div>
                  {/* search and filters ========== */}

                  <div className="d-flex align-items-center jusfity-content-between my-3">
                    {/* data export */}
                    <ul className="data-exports d-flex flex-wrap mb-4">
                      <li>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="SelectAll"
                            name="selectAll"
                            defaultChecked={false}
                            checked={selectAll}
                            onClick={() =>
                              this.setState(
                                {
                                  selectAll: !this.state.selectAll,
                                  deselectAll: false,
                                },
                                () => this.addAllIds()
                              )
                            }
                          />
                          <label
                            className="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                            htmlFor="SelectAll"
                          >
                            Select all
                          </label>
                        </div>
                      </li>
                      {/* <li className="mr-2">
                      <div className="custom-control custom-radio ml-3">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="DeselectAll"
                          name="deselectAll"
                          defaultChecked={false}
                          checked={deselectAll}
                          onClick={() =>
                            this.setState({
                              deselectAll: !this.state.deselectAll,
                              selectAll: false,
                              checkedUsersIds: deselectAll
                                ? []
                                : checkedUsersIds,
                            })
                          }
                        />
                        <label
                          className="custom-control-label ml-2 font-montserrat font-weight-400 text-grey-2 fs-14"
                          htmlFor="DeselectAll"
                        >
                          Deselect All
                        </label>
                      </div>
                    </li> */}

                    </ul>
                    {/* data export */}

                    <div className="count-filters-and-pagination d-flex justify-content-end mb-4 ml-auto">
                      {/* <div className="count-filter d-flex align-items-center">
                      <p className="font-smallest opacity-half font-weight-semi mr-2 mb-0">
                        Entries per page :
                      </p>
                      <select
                        id="#"
                        className="border"
                        name="entriesPerPage"
                        value={entriesPerPage}
                        onChange={this.handleChange}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                    </div> */}
                      <Pagination
                        simple
                        onChange={this.handlePagination}
                        showSizeChanger={false}
                        current={pagination && pagination.current}
                        pageSize={pagination && pagination.pageSize}
                        total={pagination && pagination.total}
                      />
                    </div>
                  </div>
                  <div
                    style={{ minHeight: "400px" }}
                    className="table-responsive position-relative"
                  >
                    <Table borderless className="text-black">
                      <thead>
                        <tr className="text-nowrap">
                          <th> </th>
                          <th>#</th>
                          <th>Name</th>
                          <th>E-mail</th>
                          <th>Joining Date</th>
                          <th>Plan</th>
                          <th>Subscription Status</th>
                          <th>Source of joining</th>
                          <th>App information</th>
                        </tr>
                      </thead>
                      <tbody className="font-smallest position-relative">
                        {loadingUserData ? (
                          <div
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <div
                              className="spinner-border position-absolute text-warning"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (users && users.length ? (
                          users !== null &&
                          users.map((user) => (
                            <tr key={user.id}>
                              <td className="">
                                <div className="custom-control custom-checkbox">

                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onChange={(e) =>
                                      this.updateCheckedUsers(e, user.id)
                                    }
                                    id={user.id}
                                    name="row-checkbox1"
                                    checked={
                                      (selectAll && !deselectAll) ||
                                      (!deselectAll && null)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={user.id}
                                  />
                                </div>
                              </td>
                              <td>{user.id}</td>
                              <td>{`${user.first_name} ${user.last_name}`}</td>
                              <td>{user.email}</td>
                              <td>
                                {moment(user["created"]).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>
                                <span className="text-danger-2 font-weight-bold">
                                  {user["plan"]
                                    ? user["plan"]
                                    : "Not Available"}
                                </span>
                              </td>
                              <td>
                                <span className="text-danger-2 font-weight-bold">
                                  {user["subscription"]
                                    ? user["subscription"]
                                    : "Not Available"}
                                </span>
                              </td>
                              <td className="font-weight-bold">
                                {user["provider"]}
                              </td>
                              <td className="text-danger-2 font-weight-bold">
                                {user["app_info"]
                                  ? user["app_info"]
                                  : "Not Available"}
                              </td>
                            </tr>
                          ))
                        ) : (<tr>
                          <td colSpan="10">
                            <div className="text-center ">
                              <h4 className="text-danger">No Data Found</h4>
                            </div>
                          </td>
                        </tr>
                        )
                        )}

                      </tbody>
                    </Table>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (page) => dispatch(get_user_details_action(page)),
  searchUserDetails: (page, data) =>
    dispatch(search_user_details_action(page, data)),
  getPrevUserDetails: (data) => dispatch(get_prev_user_details_action(data)),
  getNextUserDetails: (data) => dispatch(get_next_user_details_action(data)),
  getCsvData: (data) => dispatch(get_csv_data_action(data)),
});

export default connect(null, mapDispatchToProps)(UserData);
