import React, { Component } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import "./WatchShow.scss";

import CommentsRecipeTabs from "../../common/CommentsRecipeTabs/CommentsRecipeTabs";
import Header from "../../common/Header/Header";
import SubscriptionPopup from "../../common/SubscriptionPopup/SubscriptionPopup";
import EpisodesList from "../EpisodesList/EpisodesList";
import { get_show_details_actions } from "../../../../_actions/User/show.action";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import { get_user_episode_details_action } from "../../../../_actions/Admin/uploadEpisode.action";
import VideoPlayer from "../../common/VideoPlayer/VideoPlayer";
import { get_pricing_action } from "../../../../_actions/User";
import {
  add_bookmark_to_post_action,
  hit_like_action,
} from "../../../../_actions/User/post.action";
import { HeaderNew } from "../../HomePageNew/HeaderNew/HeaderNew";

class WatchShow extends Component {
  state = {
    loadingShowDetails: false,
    showDetails: [],
    episodeDetails: [],
    userObject: null,
    copySuccess: "copy",
    pricing: [],
  };
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.mountShowDetails();
    this.getPricingDetailsData();
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState({
      userObject: userObject,
    });
  };

  mountShowDetails = async () => {
    const { id } = this.props.match.params;
    if (!id) return;

    this.setState({ loadingShowDetails: true });

    const getEpisodeDetailsRes = await this.props.getEpisodeDetail(id);
    if (getEpisodeDetailsRes && getEpisodeDetailsRes.data) {
      this.setState({ episodeDetails: getEpisodeDetailsRes.data });
      if (getEpisodeDetailsRes.data && getEpisodeDetailsRes.data.show) {
        const getShowDetailsRes = await this.props.getShowDetails(
          getEpisodeDetailsRes.data.show
        );
        if (getShowDetailsRes)
          await this.setState({ showDetails: getShowDetailsRes });
      }

      this.setState({ loadingShowDetails: false });
    }
  };

  checkForLikeAndBookmark = async (id) => {
    const episodeDetails = await this.props.getEpisodeDetail(id);
    this.setState({
      episodeDetails: episodeDetails.data,
    });
  };

  likeShow = async (id) => {
    if (!id) return;
    const data = {
      content_type: "episode",
      id,
    };
    await this.props.hitLike(data);
    await this.checkForLikeAndBookmark(id);
  };

  bookmarkShow = async (id) => {
    if (!id) return;
    const data = {
      content_type: "episode",
      id,
    };
    await this.props.addBookmarkAction(data);
    await this.checkForLikeAndBookmark(id);
  };
  copyToClipboard = (e) => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    this.setState({ copySuccess: "Copied!" });
  };
  getPricingDetailsData = async () => {
    let data = await this.props.getPricingDetails();

    if (data && data.data && data.data.data) {
      this.setState({
        pricing: data && data.data && data.data.data
      });
    }
    // if (data && data.data && data.data.data) {
    //   this.setState({
    //     pricing: data && data.data && data.data.data
    //   });
    // }
  };
  render() {
    const {
      loadingShowDetails,
      showDetails,
      episodeDetails,
      userObject,
      pricing,
    } = this.state;

    return (
      <div className="common-dashboard watch-show-wrapper">
        {loadingShowDetails ? (
          <div style={{ margin: "50px auto 0", textAlign: "center" }}>
            <ContentLoader color="text-warning" />
          </div>
        ) : (
          <Container className="p-0">
            {/* <SidebarMenu
                                alertNewNotification={(payload) => this.openNewNotification(payload)}
                                history={this.props.history}
                            /> */}
            {userObject ? (
              <>
                {episodeDetails && pricing ? (
                  <SubscriptionPopup
                    details={episodeDetails}
                    pricing={pricing}
                  />
                ) : null}
              </>
            ) : null}

            <div className="main-content bg-white">
              {userObject ? (
                <Header className="pt-2" />
              ) : (
                <HeaderNew history={this.props.history} />
              )}

              {!showDetails && !episodeDetails ? (
                <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                  <ContentLoader color="text-warning" />
                </div>
              ) : showDetails &&
                Object.keys(showDetails).length &&
                episodeDetails &&
                Object.keys(episodeDetails).length ? (
                <>
                  {/* video box*/}
                  <Row className="video-box">
                    <Col md={8} lg={9}>
                      {episodeDetails && episodeDetails.video_playback_id ? (
                        <VideoPlayer
                          playbackId={
                            episodeDetails && episodeDetails.video_playback_id
                          }
                        />
                      ) : null}

                      <div className="episode-wrapper d-flex justify-content-between mt-2 flex-wrap">
                        <h3 className="show-name flex-grow-1 mb-3 font-weight-semi">
                          {showDetails["title"]}:
                          {episodeDetails && episodeDetails["title"]}
                        </h3>

                        {/* episode name and user action */}
                        {userObject ? (
                          <>
                            {" "}
                            <div className="user-action-btn d-flex align-items-center">
                              <a>
                                {/* like icon */}
                                {episodeDetails &&
                                  episodeDetails["likes"] &&
                                  episodeDetails["likes"].includes(
                                    userObject.user.id
                                  ) ? (
                                  <span
                                    onClick={() =>
                                      this.likeShow(
                                        episodeDetails && episodeDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart mr-2 mr-md-4 text-danger fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.likeShow(
                                        episodeDetails && episodeDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart-o mr-2 mr-md-4 text-black fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                              {/* share icon */}
                              {/* <a>
                                <img
                                  className="mr-2 mr-md-4"
                                  src={"/assets/images/share.png"}
                                  alt="share"
                                />
                              </a> */}
                              <Dropdown className="d-inline-block">
                                <Dropdown.Toggle
                                  variant=""
                                  className="px-0 btn-share"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={"/assets/images/share.png"}
                                    alt="share"
                                    className="mr-2 mr-md-4"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div
                                    className="py-1 px-2 cursor-pointer"
                                    onClick={(e) => this.copyToClipboard(e)}
                                  >
                                    <span className="fa fa-clone mr-2"></span>
                                    {this.state.copySuccess}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                              <a>
                                {/* bookmark icon */}
                                {episodeDetails &&
                                  episodeDetails["bookmarks"] &&
                                  episodeDetails["bookmarks"].includes(
                                    userObject.user.id
                                  ) ? (
                                  <span
                                    onClick={() =>
                                      this.bookmarkShow(
                                        episodeDetails && episodeDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark text-warning fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.bookmarkShow(
                                        episodeDetails && episodeDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark-o text-black fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={4} lg={3} className="d-none d-md-block">
                      <h3 className="mb-4">{showDetails["title"]}</h3>
                      {/* suggestions list */}
                      {showDetails &&
                        showDetails["episodes_count"] > 0 &&
                        showDetails["episodes"] &&
                        showDetails["episodes"].length ? (
                        <EpisodesList
                          episodes={showDetails["episodes"].filter(
                            (episode) =>
                              episode.id !==
                              parseInt(this.props.match.params.id)
                          )}
                          history={this.props.history}
                        />
                      ) : (
                        <p className="text-danger">
                          oops! No Episodes to show!
                        </p>
                      )}
                    </Col>
                  </Row>
                  {/* {userObject ? ( */}
                  <>
                    {this.state.loading && !episodeDetails ? (
                      <div
                        style={{ margin: "50px auto 0", textAlign: "center" }}
                      >
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : (
                      <div className="recipe-desc-wrapper">
                        <CommentsRecipeTabs details={episodeDetails} />
                      </div>
                    )}
                  </>
                  {/* // ) : null} */}
                </>
              ) : (
                <div className="video-box text-center">
                  <p className="text-danger-2 mt-5 font-weight-semi">
                    oops! No show details found!
                  </p>
                </div>
              )}
            </div>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getEpisodeDetails: state.get_user_episode_details_reducer.data,
    getUserShowDetails: state.get_show_details_reducer.data,
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getShowDetails: (id) => dispatch(get_show_details_actions(id)),
  getEpisodeDetail: (id) => dispatch(get_user_episode_details_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data)),
  getPricingDetails: () => dispatch(get_pricing_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchShow);
