import React from 'react';
import { DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
class DateRange extends React.Component {
  state = {
    startValue: this.props.startDate !== null ? moment(this.props.startDate) : null,
    endValue: this.props.endDate !== null ? moment(this.props.endDate) : null,
    endOpen: false,
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    }, () => {
      this.props.updateDate(this.state)
    });
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  render() {
    const { startValue, endValue, endOpen } = this.state;
    return (
      <Space>
        <DatePicker
          disabledDate={this.disabledStartDate}
          showTime
          format="DD-MM-YYYY"
          value={startValue}
          placeholder="Start date"
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange} className="bg-grey-3 border-0"
        />
        <DatePicker
          disabledDate={this.disabledEndDate}
          showTime
          format="DD-MM-YYYY"
          value={endValue}
          placeholder="End date"
          onChange={this.onEndChange}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange} className="bg-grey-3 border-0"
        />
      </Space>
    );
  }
}

export default DateRange;