import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";
import { postConstants } from "../../_constants/User/post.constants";

export const add_post_action = (data) => async (dispatch) => {
    dispatch({
        type: postConstants.ADD_POST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.post.postRoute, data)
        if (response.status) {
            dispatch({
                type: postConstants.ADD_POST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.ADD_POST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_all_user_posts_action = () => async (dispatch) => {
    dispatch({
        type: postConstants.GET_ALL_POSTS_ACTIONS_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.post.postRoute)
        if (response.status) {
            dispatch({
                type: postConstants.GET_ALL_POSTS_ACTIONS_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.GET_ALL_POSTS_ACTIONS_FAILURE,
            payload: e.msg
        })
    }
}

export const get_status_by_id_action = (id) => async (dispatch) => {
    dispatch({
        type: postConstants.GET_STATUS_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.post.postRoute}${id}/`)
        if (response.status) {
            dispatch({
                type: postConstants.GET_STATUS_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.GET_STATUS_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const delete_post_by_id_action = (id) => async (dispatch) => {
    dispatch({
        type: postConstants.DELETE_POST_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(`${config.post.postRoute}${id}/`)
        if (response.status) {
            dispatch({
                type: postConstants.DELETE_POST_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.DELETE_POST_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const update_post_by_id_action = (data, id) => async (dispatch) => {
    dispatch({
        type: postConstants.UPDATE_POST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.patch(`${config.post.postRoute}${id}/`, data)
        if (response.status) {
            dispatch({
                type: postConstants.UPDATE_POST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.UPDATE_POST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const hit_like_action = (data) => async (dispatch) => {
    dispatch({
        type: postConstants.HIT_LIKE_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(`${config.post.like}`, data)
        if (response.status) {
            dispatch({
                type: postConstants.HIT_LIKE_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.HIT_LIKE_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const add_comment_action = (data) => async (dispatch) => {
    dispatch({
        type: postConstants.ADD_COMMENT_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(`${config.post.comment}`, data)
        if (response.status) {
            dispatch({
                type: postConstants.ADD_COMMENT_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.ADD_COMMENT_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_comments_by_id_action = (data, id) => async (dispatch) => {
    dispatch({
        type: postConstants.GET_COMMENTS_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(`${config.post.comment}${id}/`, data)
        if (response.status) {
            dispatch({
                type: postConstants.GET_COMMENTS_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.GET_COMMENTS_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const delete_comment_by_id_action = (id) => async (dispatch) => {
    dispatch({
        type: postConstants.DELETE_COMMENTS_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(`${config.post.comment}${id}/`)
        if (response.status) {
            dispatch({
                type: postConstants.DELETE_COMMENTS_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.DELETE_COMMENTS_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const add_bookmark_to_post_action = (data) => async (dispatch) => {
    dispatch({
        type: postConstants.BOOKMARK_POST_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(`${config.post.bookmark}`, data)
        if (response.status) {
            dispatch({
                type: postConstants.BOOKMARK_POST_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.BOOKMARK_POST_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_saved_posts_action = () => async (dispatch) => {
    dispatch({
        type: postConstants.GET_SAVED_POSTS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.post.savedPosts}`)
        if (response.status) {
            dispatch({
                type: postConstants.GET_SAVED_POSTS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.GET_SAVED_POSTS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//get_saved_contents_action

export const get_saved_contents_action = () => async (dispatch) => {
    dispatch({
        type: postConstants.GET_SAVED_CONTENTS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.post.savedContents}`)
        if (response.status) {
            dispatch({
                type: postConstants.GET_SAVED_CONTENTS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.GET_SAVED_CONTENTS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
//report_item
export const report_item_action = (data) => async (dispatch) => {
    dispatch({
        type: postConstants.REPORT_ITEM_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.post.itemReport, data)
        if (response.status) {
            dispatch({
                type: postConstants.REPORT_ITEM_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: postConstants.REPORT_ITEM_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const update_comment_action = (id, data) => async (dispatch) => {
    dispatch({
        type: postConstants.UPDATE_COMMENTS_BY_ID_ACTION_REQUEST,
    });
    try {
        const response = await axiosInstance.put(`${config.post.comment}${id}/`, data)
        if (response.status) {
            dispatch({
                type: postConstants.UPDATE_COMMENTS_BY_ID_ACTION_SUCCESS,
                payload: response.data,
            });
            return response.data;
        } else {
            return false;
        }
    } catch (e) {
        dispatch({
            type: postConstants.UPDATE_COMMENTS_BY_ID_ACTION_FAILURE,
            payload: e.msg,
        });
    }
};