import swal from "sweetalert";
import config from "../../Config/config";
import { reportsConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";

// Get Reported Content
export const get_all_reported_content_action = (page) => async (dispatch) => {
  dispatch({
    type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.reports.reportItems}?page=${page ? page : 1}`
    );
    if (response.status) {
      dispatch({
        type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    swal(e.msg);
    dispatch({
      type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_filtered_reports_action = (data) => async (dispatch) => {
  dispatch({
    type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      config.reports.reportItemsSearch,
      data
    );
    if (response.status) {
      dispatch({
        type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_SUCCESS,
        payload: { data: { results: response.data.data } },
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    swal(e.msg);
    dispatch({
      type: reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_reported_post_by_id_action = (id) => async (dispatch) => {
  dispatch({
    type: reportsConstants.GET_REPORTED_POST_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      config.reports.reportedPostById.replace(":id", id)
    );
    if (response.status) {
      dispatch({
        type: reportsConstants.GET_REPORTED_POST_ACTION_SUCCESS,
        payload: response.data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    swal(e.msg);
    dispatch({
      type: reportsConstants.GET_REPORTED_POST_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_reported_comment_by_id_action = (id) => async (dispatch) => {
  dispatch({
    type: reportsConstants.GET_REPORTED_COMMENT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      config.reports.reportedCommentById.replace(":id", id)
    );
    if (response.status) {
      dispatch({
        type: reportsConstants.GET_REPORTED_COMMENT_ACTION_SUCCESS,
        payload: response.data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    swal(e.msg);
    dispatch({
      type: reportsConstants.GET_REPORTED_COMMENT_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const take_on_reported_post_action = (id, data) => async (dispatch) => {
  dispatch({
    type: reportsConstants.TAKE_ON_REPORT_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      config.reports.reportAction.replace(":id", id), data
    );
    if (response.status) {
      dispatch({
        type: reportsConstants.TAKE_ON_REPORT_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    swal(e.msg);
    dispatch({
      type: reportsConstants.TAKE_ON_REPORT_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};
