import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";
import {livestreamConstants} from "../../_constants/User/livestream.constants";

export const get_current_livestream_playbackid_action = () => async (dispatch) => {
    dispatch({
        type: livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.livestream.playbackId)
        if (response.status) {
            dispatch ({
                type: livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


export const add_comment_live_stream_action= (data) => async (dispatch) => {
    dispatch({
        type: livestreamConstants.ADD_COMMENT_LIVESTREAM_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.livestream.addComment,data)
        if (response.status) {
            dispatch ({
                type: livestreamConstants.ADD_COMMENT_LIVESTREAM_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: livestreamConstants.ADD_COMMENT_LIVESTREAM_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

/**get comments list */
export const get_comments_list_action = (id) => async (dispatch) => {
    dispatch({
        type: livestreamConstants.GET_COMMENTS_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.livestream.getCommentList.replace(':id', id))
        if (response.status) {
            dispatch ({
                type: livestreamConstants.GET_COMMENTS_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: livestreamConstants.GET_COMMENTS_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
/**get Livestream viewers count */
// export const get_livestream_viewers_count_action = (id) => async (dispatch) => {
//     dispatch({
//         type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST
//     })
//     try {
//         const response = await axiosInstance.get(config.livestream.getLiveStreamViewersCount.replace(":id",id))
//         if (response.status) {
//             dispatch ({
//                 type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS,
//                 payload: response.data
//             })
//             return response.data
//         } else {
//             return false
//         }
//     } catch (e) {
//         dispatch({
//             type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE,
//             payload: e.msg
//         })
//     }
// }

export const get_livestream_viewers_count_action = (id) => async (dispatch) => {
    dispatch({
        type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.livestream.getLiveStreamViewersCount.replace(":id",id))
        if (response.status) {
            dispatch ({
                type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

/**get Livestream viewers count */
export const get_mux_livestream_viewers_count_action = (id) => async (dispatch) => {
    dispatch({
        type: livestreamConstants.GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.livestream.getMuxLiveStreamViewersCount.replace(":id",id))
        if (response.status) {
            dispatch ({
                type: livestreamConstants.GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS,
                payload: response.data
            })
          
            return response.data
        } else {
            return false
        }
    } catch (e) {
  
        dispatch({
            type: livestreamConstants.GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}