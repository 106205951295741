import React, { Component } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import swal from "sweetalert";
import {connect} from "react-redux";
import "./ResetPassword.scss";
import {validatePassword} from '../../../_helpers/validators'
import {reset_user_password_action} from "../../../_actions/Common";

class ResetPassword extends Component {
    state = {
        passwordInfoModal: false,
        password: '',
        confirm_password: '',
        isPasswordValid: true
    };

    passwordInfoModalClose = () => {
        this.setState({
            passwordInfoModal: !this.state.passwordInfoModal,
        });
    };

    handlePropagation(event) {
        event.stopPropagation();
    }

    handleSubmit = async(e) => {
        e.preventDefault()

        const { password, confirm_password, isPasswordValid } = this.state
        const { uid, token } = this.props.match.params

        if (!isPasswordValid || password !== confirm_password) {
            if (!isPasswordValid)
                this.passwordInfoModalClose()
            else
                swal('Confirm Password should match password.')
            return
        }

        if (!password || !confirm_password) {
            if (!password)
                this.passwordInfoModalClose()
            if (!confirm_password)
                swal('Confirm Password should match password.')
            return
        }

        if (isPasswordValid && password === confirm_password) {
            const data = {
                new_password1: password,
                new_password2: confirm_password,
                uid,
                token
            }
            const resetRes = await this.props.resetPassword(data)
            if(resetRes !== null) {
                this.props.history.push('/login')
            }
        } else {
            this.passwordInfoModalClose()
            return
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => {            
            if (name === "password") {
                this.setState({ isPasswordValid: validatePassword(this.state.password) })
            }
        })
    }
    
    render() {
        const { password, confirm_password, isPasswordValid } = this.state
        return (
            <div className="common-login forget-password-wrapper d-flex align-items-center jusfity-content-center">
                <Container className="p-0">

                    <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">

                        <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 py-5 mb-md-0  position-relative">

                            {/* desktop back button  */}
                            <button onClick={() => this.props.history.push('/login')} className="border-0 bg-transparent btn-back p-2 d-none d-md-inline-block">  <img src="/assets/images/back-btn.svg" alt="back-arrow" /></button>

                            <Form action="#" className="px-2 px-sm-0">

                                {/* mobile back button  */}
                                <button className="border-0 bg-transparent btn-back p-2 d-md-none d-inline-block">  <img src="/assets/images/back-btn.svg" alt="back-arrow" /></button>

                                <h2 className="form-title fs-24 font-poppins mb-4 mt-4 mt-md-0 pl-2">Reset Password</h2>
                                <Form.Group className='with-placeholder'>
                                    <Form.Control
                                        type="password"
                                        placeholder=" "
                                        name="password"
                                        value={password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <span className="placeholder-text fs-14">Password</span>
                                </Form.Group>
                                {
                                    password.length && !isPasswordValid ?
                                        <p className="fs-14 text-danger-2 font-source-sans text-center font-weight-semi">Your password must be 8 to 20 characters long with at least one numeric digit, one uppercase and one lowercase letter.</p>
                                        : null
                                }
                                <Form.Group className='with-placeholder'>
                                    <Form.Control
                                        type="password"
                                        placeholder=" "
                                        name="confirm_password"
                                        value={confirm_password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <span className="placeholder-text fs-14">Confirm Password</span>
                                </Form.Group>
                                {
                                    confirm_password !== password && confirm_password.length ?
                                        <p className="fs-14 text-danger-2 font-source-sans text-center font-weight-semi">Confirm Password should match password.</p>
                                        : null
                                }
                                <Form.Group className="mb-4 pt-2">
                                    <Button
                                        type="submit"
                                        variant="warning"
                                        className="fs-16"
                                        onClick={this.handleSubmit}
                                        block
                                    >
                                        Reset password
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="display-img  order-1 order-md-2 d-none d-md-block">
                            <img src="/assets/images/set-password.svg" alt="login bg" />
                        </div>
                    </div>
                </Container>


                {/* password requirement popup add active class to show*/}
                <div
                    onClick={this.passwordInfoModalClose}
                    className={
                        this.state.passwordInfoModal
                            ? "password-reset-info-popup position-fixed active w-100 h-100 bg-transparent-dark justify-content-center align-items-center"
                            : "password-reset-info-popup position-fixed  w-100 h-100 bg-transparent-dark justify-content-center align-items-center"
                    }
                >
                    <div className="popup-wrappper bg-white position-absolute p-3 " onClick={this.handlePropagation}>
                        <h6 className="pl-1 py-3">Password Requirement</h6>
                        <ul className="font-smallest">
                            <li className="text-success-2  mb-2"><span className="fa fa-check mr-2"></span> Password must contain a number</li>
                            <li className="ml-4 mb-2">Password must contain an UpperCase and LowerCase</li>
                            <li className="text-danger-3"><span className="fa fa-times mr-2"></span> Atleast 8 characters Long</li>
                        </ul>
                    </div>
                </div>

            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (data) => dispatch(reset_user_password_action(data))
})

export default connect(null, mapDispatchToProps)(ResetPassword);