import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { Container, Form, Row, Col, Modal } from "react-bootstrap";
import "./AllVideos.scss";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import NewEpisodes from "../common/NewEpisodes/NewEpisodes";
import {
  get_all_user_videos_action,
  get_carousel_content_action,
  video_search_action
} from "../../../_actions/User";
import ContentLoader from "../../Common/Loader/ContentLoader";
import "./AllVideos.scss";
import { get_announcement_table_list_action } from "../../../_actions/Admin";
import TopNotification from "../common/TopNotification/TopNotification";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";
import Login from "../../Common/Login/Login";
import { debounce } from "../../../_helpers/debounce";

class AllVideos extends Component {
  constructor(props) {
    super(props)
    this.search = debounce(this.searchHandler)
  }
  state = {
    LoginModal: false,
    videoLoading: false,
    videosTableData: [],
    newAddition: null,
    loading: false,
    searchResultData: [],
    searchStatus: false,
    searchEnabled: false,
    updatingAnnouncements: false,
    topNotifications: [],
    pagination: {
      pageSize: 25,
      current: sessionStorage.getItem('currentVideoPagination') ? Number(sessionStorage.getItem('currentVideoPagination')) : 1,
      total: 0
    },
    userObject: null,
    searchText: null, // stack of chars
    promiseStack: [],
  };

  componentDidMount() {
    this.mountVideoTableData();
    let userObject = JSON.parse(localStorage.getItem("user"));

    this.setState(
      {
        userObject: userObject
      },
      () => {
        if (userObject) {
          this.setContents();
          this.mountOrUpdateAnnouncements();
        }
      }
    );
  }
  LoginModalHandler = () => {
    this.setState({
      LoginModal: !this.state.LoginModal
    });
  };
  mountVideoTableData = async () => {
    const { pagination } = this.state;
    this.setState({ videoLoading: true });

    const getAllVideoRes = await this.props.getAllUserSideVideos(
      pagination.current
    );
    if (getAllVideoRes && getAllVideoRes.data && getAllVideoRes.data.results)
      this.setState((prevState) => ({
        videosTableData: getAllVideoRes.data.results,
        pagination: {
          ...prevState.pagination,
          total: getAllVideoRes.data.count
        }
      }));

    this.setState({ videoLoading: false });
    sessionStorage.removeItem("currentVideoPagination");
    window.scrollTo(0, 0);
  };

  setContents = async () => {
    this.setState({ loading: true });
    const carouselContentRes = await this.props.getCarouselContent();

    let LatestUpdated = [];
    if (carouselContentRes.data && carouselContentRes.data.length) {
      carouselContentRes.data.forEach((contentItem) => {
        if (
          contentItem["row_type"] === "update" &&
          contentItem["content_type"] &&
          contentItem["content_type"] === "video"
        ) {
          LatestUpdated = [...LatestUpdated, { ...contentItem }];
        }
      });
    }
    this.setState({
      newAddition: LatestUpdated.length
        ? LatestUpdated.sort((a, b) => a.priority - b.priority)
        : []
    });
    this.setState({ loading: false });
  };

  routeHandler = (id) => {
    this.props.history.push(`/video/${id}`);
    sessionStorage.setItem('currentVideoPagination', this.state && this.state.pagination && this.state.pagination.current);
  };


  handleSearch = (e) => {
    e.preventDefault();
    let searchVal = e.target.value;
    let searchKeyword = { search: searchVal };
    // eslint-disable-next-line no-lone-blocks
    if (searchVal.length === "" || searchVal.length >= 2) {
      this.setState({ searchEnabled: true, searchResultData: [] });
      this.setState({ searchStatus: true, searchText: searchVal }, () => {
        this.search(searchKeyword);
      });
    } else {
      this.setState({ searchEnabled: false });
    }
  };

  checkSearch = () => {
    let val = this.state.promiseStack.filter(
      (item) => item.searchKey === this.state.searchText
    );
    if (val && val.length) {
      this.setState({ searchResultData: val[0].response });
    }
  };

  // search handler ===========
  searchHandler = async (searchKeyword) => {
    let searchResult = await this.props.videoSearchResultData(searchKeyword);
    let searchResultData =
      searchResult && searchResult.data && searchResult.data?.results;

    if (
      searchResultData &&
      searchResultData.length
    ) {
      let newStack = this.state.promiseStack;
      newStack.push({
        searchKey: searchKeyword.search,
        response: searchResultData,
      });
      this.setState({ promiseStack: newStack }, () => {
        this.checkSearch();
      });
    }
    this.setState({ searchStatus: false })
  };



  mountOrUpdateAnnouncements = async () => {
    await this.setState({ updatingAnnouncements: true, topNotifications: [] });

    // re-fetch announcement details and show it again
    const getAnnouncementDetailsRes = await this.props.getAnnouncementData(
      "user"
    );
    if (
      getAnnouncementDetailsRes &&
      getAnnouncementDetailsRes.type &&
      getAnnouncementDetailsRes.type === "user"
    ) {
      if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.data
      ) {
        this.setState({
          topNotifications: getAnnouncementDetailsRes.response.data
        });
      }
    }

    this.setState({ updatingAnnouncements: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title)
    });
    this.mountOrUpdateAnnouncements();
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountVideoTableData();
      }
    );
  };
  handlePremiumRedirect = (ItemId) => {
    localStorage.setItem("videoId", ItemId);

    this.setState({ LoginModal: !this.state.LoginModal });
  };

  render() {
    const {
      videoLoading,
      videosTableData,
      pagination,
      searchResultData,
      userObject,
      searchStatus
    } = this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          {/* <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          /> */}
          <div className="main-content bg-white position-relative">
            {userObject ? (
              <Header activeMenuItem="videos" searchType="videos" />
            ) : (
              <HeaderNew activeMenuItem="videos" history={this.props.history} />
            )}

            {/* search handler */}
            <div className="top-search-wrapper flex-grow-1 mb-4 mt-4 mr-0 mr-sm-2 mr-xl-5">
              <Form.Group className="mb-0 h-100 position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search Videos..."
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.handleSearch(e)}
                />
                <i className="fa fa-search fa-1x" aria-hidden="true"></i>
              </Form.Group>
            </div>

            {/* Announcement notifications */}
            {userObject ? (
              <>
                {this.state.topNotifications.length > 0 ? (
                  <TopNotification
                    updatingAnnouncements={this.state.updatingAnnouncements}
                    notifications={
                      this.state.topNotifications.length > 0
                        ? this.state.topNotifications
                        : []
                    }
                  />
                ) : this.state.updatingAnnouncements ? (
                  <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                    <ContentLoader color="text-warning" />
                  </div>
                ) : (
                  <p className="text-primary font-weight-medium">No notifications to show!</p>
                )}
              </>
            ) : null}

            {/* all video wrapper */}
            <div className="all-video-wrapper mt-3 mt-md-5">
              <NewEpisodes
                loading={this.state.loading}
                newVideos={this.state.newAddition}
                history={this.props.history}
              />
              {this.state.searchEnabled ? (
                <>
                  {searchStatus ? (
                    <div className="text-center">
                      <ContentLoader color="text-warning mx-auto mt-5" />
                    </div>
                  ) : (


                    <Row className="search enabled">
                      {Array.isArray(searchResultData) &&
                        searchResultData.length === 0 ? (
                        <div className="text-center p-4 w-100">
                          <img
                            src={"/assets/images/error.png"}
                            alt="no videos"
                            className="p-3"
                          />
                          <p className="text-danger font-weight-bold">
                            Oops! Result Not Found
                          </p>
                        </div>
                      ) : (
                        Array.isArray(searchResultData) &&
                        searchResultData.map((video) => (
                          <Col
                            key={video.content.id}
                            className="col-6 col-md-3 mb-5"
                          >
                            {userObject ? (
                              <div
                                className="video-box cursor-pointer"
                                onClick={() => {
                                  this.routeHandler(video.content.id);
                                }}
                              >
                                <div className="position-relative">
                                  <img
                                    src={video.content["title_image"]}
                                    alt="slider-img-1"
                                    className="border-6 img-fit w-100"
                                  />
                                  {video &&
                                    video.content &&
                                    video.content.is_premium ? (
                                    <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                                      <img
                                        src="../assets/images/royal-crown.png"
                                        alt="crown"
                                        className="mr-1"
                                      />
                                      Premium
                                    </span>
                                  ) : null}
                                </div>
                                <h4 className="title font-weight-semi my-2">
                                  {video.content.title}
                                </h4>
                              </div>
                            ) : (
                              <div
                                className="video-box cursor-pointer"
                                onClick={() => {
                                  video && video.content && video.content.is_premium
                                    ? this.handlePremiumRedirect(video.content.id)
                                    : this.routeHandler(video.content.id);
                                }}
                              >
                                <div className="position-relative">
                                  <img
                                    src={video.content["title_image"]}
                                    alt="slider-img-1"
                                    className="border-6 img-fit w-100"
                                  />
                                  {video &&
                                    video.content &&
                                    video.content.is_premium ? (
                                    <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                                      <img
                                        src="../assets/images/royal-crown.png"
                                        alt="crown"
                                        className="mr-1"
                                      />
                                      Premium
                                    </span>
                                  ) : null}
                                </div>
                                <h4 className="title font-weight-semi my-2">
                                  {video.content.title}
                                </h4>
                              </div>
                            )}
                          </Col>
                        ))
                      )}
                    </Row>
                  )}



                </>
              ) : (
                <>
                  <h3 className="font-weight-semi pt-4 mb-3">All Videos</h3>

                  <Row className="all-videos-row">
                    {videoLoading ? (
                      <ContentLoader color="text-warning mx-auto mt-5" />
                    ) : videosTableData && videosTableData.length ? (
                      videosTableData &&
                      videosTableData.map((video) => (
                        <Col key={video.id} className="col-6 col-md-3 mb-5">
                          {userObject ? (
                            <div
                              className="video-box cursor-pointer"
                              onClick={() => {
                                this.routeHandler(video.id);
                              }}
                            >
                              <div className="position-relative">
                                <img
                                  src={video["title_image"]}
                                  alt="slider-img-1"
                                  className="border-6 img-fit w-100"
                                />
                                {video && video.is_premium ? (
                                  <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                                    <img
                                      src="../assets/images/royal-crown.png"
                                      alt="crown"
                                      className="mr-1"
                                    />
                                    Premium
                                  </span>
                                ) : null}
                              </div>

                              <h4 className="title font-weight-semi my-2">
                                {video.title}
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="video-box cursor-pointer"
                              onClick={() => {
                                video && video.is_premium
                                  ? this.handlePremiumRedirect(video.id)
                                  : this.routeHandler(video.id);
                              }}
                            >
                              <div className="position-relative">
                                <img
                                  src={video["title_image"]}
                                  alt="slider-img-1"
                                  className="border-6 img-fit w-100"
                                />
                                {video && video.is_premium ? (
                                  <span className="d-flex align-items-center bg-warning px-2 text-white premium-tag position-absolute">
                                    <img
                                      src="../assets/images/royal-crown.png"
                                      alt="crown"
                                      className="mr-1"
                                    />
                                    Premium
                                  </span>
                                ) : null}
                              </div>

                              <h4 className="title font-weight-semi my-2">
                                {video.title}
                              </h4>
                            </div>
                          )}
                        </Col>
                      ))
                    ) : (
                      <div className="col-12 mt-4 py-3 py-md-4 text-center ">
                        <img
                          src={"/assets/images/novideos-home.png"}
                          alt="no videos"
                          className="border border-danger rounded"
                        />
                        <h4 className="text-danger">No Videos to Show</h4>
                      </div>
                    )}
                  </Row>
                  <Pagination
                    current={pagination.current}
                    showSizeChanger={false}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={this.onHandlePaginationChange}
                  />
                </>
              )}
            </div>
          </div>
        </Container>

        {/*  popup  */}
        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.LoginModal}
          onHide={this.LoginModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content p-0 position-relative">
            <span
              onClick={this.LoginModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <Login history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserSideVideos: (page) => dispatch(get_all_user_videos_action(page)),
  getCarouselContent: () => dispatch(get_carousel_content_action()),
  videoSearchResultData: (data) => dispatch(video_search_action(data)),
  getAnnouncementData: (userType) =>
    dispatch(get_announcement_table_list_action(userType))
});

export default connect(null, mapDispatchToProps)(AllVideos);
