import { uploadVideoConstants } from "../../_constants/Admin/uploadVideo.constants";

const initialState = {}
export const save_video_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadVideoConstants.CREATE_MUX_URL_ACTION_SUCCESS:
            return {
                ...state,
                authenticatedUrl: action.payload
            }
        case uploadVideoConstants.GET_PLAYBACK_ASSET_ID_ACTION_SUCCESS: 
            return {
                ...state,
                muxAssetsData: action.payload
            }
        case uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case uploadVideoConstants.UPLOAD_VIDEO_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const publish_video_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadVideoConstants.PUBLISH_VIDEO_ACTION_SUCCESS:
            return {
                publishedVideo: action.payload
            }
        case uploadVideoConstants.PUBLISH_VIDEO_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}