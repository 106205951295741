import {uploadShowConstants} from '../../_constants/Admin/uploadShow.constants'
const initialState = {}

export const update_show_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadShowConstants.UPDATE_SHOW_DETAILS_ACTION_SUCCESS:
            return {
                updatedShow: action.payload
            }
        case uploadShowConstants.UPDATE_SHOW_DETAILS_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}


export const saved_show_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadShowConstants.GET_SAVED_SHOW_DETAILS_ACTION_SUCCESS:
            return {
                updatedShow: action.payload
            }
        case uploadShowConstants.GET_SAVED_SHOW_DETAILS_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}