import React, { Component } from "react";
import { Container, Tabs, Tab, Row, Col, Dropdown, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import "./CommunityTimeline.scss";
import {
  get_all_user_posts_action,
  hit_like_action,
  delete_post_by_id_action,
  report_item_action,
} from "../../../_actions/User/post.action";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import ContentLoader from "../../Common/Loader/ContentLoader";
import swal from "sweetalert";
import CommunityContentBox from "./CommunityContentBox/CommunityContentBox";

class CommunityTimeline extends Component {
  state = {
    postsLoading: false,
    posts: [],
    statusPosts: [],
    photoPosts: [],
    videoPosts: [],
    recipePosts: [],
    userId: null,
    reportReason: null,
  };

  // routeHandler = (id, type) => {
  //   if (type === "status") this.props.history.push(`/post-status/${id}`);
  //   else this.props.history.push(`/view-post/${id}`);
  // };

  routeHandler = (id, type) => {
    if (type === "status") {
      this.props.history.push({
        pathname: `/post-status/${id}`,
        state: { backPageUrl: "userPosts" },
      });
    } else {
      this.props.history.push({
        pathname: `/view-post/${id}`,
        state: { backPageUrl: "userPosts" },
      });
    }
  };

  componentDidMount() {
    this.mountPostsData();
  }

  mountPostsData = async () => {
    this.setState({ postsLoading: true });

    const postsDataRes = await this.props.getAllUserPosts();

    if (postsDataRes && postsDataRes.data) {
      await this.setState({
        posts: postsDataRes.data,
        statusPosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "status"
        ),
        photoPosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "photo"
        ),
        videoPosts: postsDataRes.data.filter(
          (post) =>
            post["post_type"] === "video" && post["video_playback_id"] !== null
        ),
        recipePosts: postsDataRes.data.filter(
          (post) => post["post_type"] === "recipe"
        ),
      });
    }

    this.setState({ postsLoading: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title),
    });
  };

  likePost = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id,
    };
    await this.props.hitLike(data);
    this.mountPostsData();
  };

  reportPost = async (id, type) => {
    let data = {
      post: id,
      report_type: this.state.reportReason,
    };
    const res = await this.props.reportItem(data);
    swal(res && res.msg);
  };

  reportReason = (reason) => {
    this.setState({ reportReason: reason });
  };

  deletePost = async (id, type) => {
    if (!id) return;
    let redirectRoute = "";
    switch (type) {
      case "photo":
        redirectRoute = "Photos";
        break;
      case "video":
        redirectRoute = "Videos";
        break;
      case "recipe":
        redirectRoute = "Recipe";
        break;
      default:
        redirectRoute = "Status";
    }
    await this.props.deletePostById(id);
    swal("", "Post Deleted Successfully!", "success");
    this.mountPostsData();
    this.props.history.push(`/community-timeline/${redirectRoute}`);
  };
  render() {
    const {
      postsLoading,
      posts,
      photoPosts,
      videoPosts,
      statusPosts,
      recipePosts,
    } = this.state;
    const currentUserId = JSON.parse(localStorage.getItem("user")).user.id;
    let filteredCurrentUserPhotos = photoPosts;
    let filteredCurrentUserVideos = videoPosts;
    let filteredCurrentUserRecipes = recipePosts;
    let filteredCurrentUserStatus = statusPosts;

      let allPosts =filteredCurrentUserPhotos.concat(filteredCurrentUserVideos,filteredCurrentUserRecipes,filteredCurrentUserStatus )


    return (
      <>
        <Header activeMenuItem="community" />
        <div className="common-dashboard CommunityTimeline-wrapper">
          <div className="main-content bg-white">
            <Row>
              <Col md={12} className="order-2 order-md-1 pl-1">
                <div className="UserPosts-tabs pl-3">
                  {/* all tab */}
                  <Tab.Container
                    className=" community-tabs"
                    defaultActiveKey={this.props.match.params.postType || "All"}
                    id="uncontrolled-tab-example"
                  >
                    <Nav className="comments-recipe-tab nav-tabs mt-3 mt-md-0">
                      <Nav.Item>
                        <Nav.Link eventKey="All">All</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Photos">
                          <i className="fa fa-camera" aria-hidden="true"></i>
                          <span className="d-inline-block ml-1">
                            Photos
                          </span>{" "}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Recipe">
                          <i className="fa fa-cutlery" aria-hidden="true"></i>
                          <span className="d-inline-block ml-1">
                            Recipe
                          </span>{" "}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Status">
                          <i className="fa fa-quote-left" aria-hidden="true"></i>
                          <span className="d-inline-block ml-1">
                            Status
                          </span>{" "}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="All">
                        <Row className="mt-4 mt-md-5 p-0">
                          {postsLoading ? (
                            <div
                              style={{
                                margin: "50px auto 0",
                                textAlign: "center",
                              }}
                            >
                              <ContentLoader color="text-warning" />
                            </div>
                          ) : posts && posts.length > 0 ? (
                            posts &&
                            posts.map((post) => (
                              <Col
                                key={post.id}
                                md={10}
                                className="mb-5 offset-md-1"
                              >
                                <CommunityContentBox
                                  className="text-center"
                                  post={post}
                                  likesArray={post["likes"]}
                                  likesCount={post["likes_count"]}
                                  bookmarkArray={post["bookmarks"]}
                                  redirect={() =>
                                    this.routeHandler(
                                      post.id,
                                      post["post_type"]
                                    )
                                  }
                                  userimg={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["profile_image"]
                                  }
                                  firstname={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["first_name"]
                                  }
                                  lastname={post &&
                                    post["user_details"] &&
                                    post["user_details"]["last_name"]}
                                  thumbnail={post && post["image"]}
                                  commentsCount={
                                    `${post && post["comments_count"]}` +
                                    " " +
                                    `${
                                      post &&
                                      post["comments_count"] &&
                                      post["comments_count"] > 1
                                        ? "comments"
                                        : "comment"
                                    }`
                                  }
                                  statusText={post && post["status_text"]}
                                  history={this.props.history}
                                  delete={
                                    post["user_details"].id === currentUserId
                                      ? "Delete"
                                      : null
                                  }
                                  deletePost={() =>
                                    this.deletePost(post.id, post["post_type"])
                                  }
                                  edit={
                                    post["user_details"].id === currentUserId
                                      ? "Edit"
                                      : null
                                  }
                                  editPost={() =>
                                    this.props.history.push(
                                      `/add-post/${post.id}`
                                    )
                                  }
                                  report={
                                    post["user_details"].id !== currentUserId
                                      ? "Report"
                                      : null
                                  }
                                  reportPost={() =>
                                    this.reportPost(post.id, post["post_type"])
                                  }
                                  reportReason={this.reportReason}
                                />
                              </Col>
                            ))
                          ) : (
                            <>
                              <Col>
                                <div className="text-center p-2 mt-4 mt-lg-5">
                                  <img
                                    src={"../assets/images/no-posts.png"}
                                    alt="all-posts"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Photos">
                        <Row className="mt-4 mt-md-5 p-0">
                          {postsLoading ? (
                            <div
                              style={{
                                margin: "50px auto 0",
                                textAlign: "center",
                              }}
                            >
                              <ContentLoader color="text-warning" />
                            </div>
                          ) : photoPosts && photoPosts.length > 0 ? (
                            photoPosts &&
                            photoPosts.map((post) => (
                              <Col
                                key={post.id}
                                md={10}
                                className="mb-5 offset-md-1"
                              >
                                <CommunityContentBox
                                  post={post}
                                  likesArray={post["likes"]}
                                  likesCount={post["likes_count"]}
                                  bookmarkArray={post["bookmarks"]}
                                  redirect={() =>
                                    this.routeHandler(
                                      post.id,
                                      post["post_type"]
                                    )
                                  }
                                  userimg={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["profile_image"]
                                  }
                                  firstname={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["first_name"]
                                  }
                                  lastname={post &&
                                    post["user_details"] &&
                                    post["user_details"]["last_name"]}
                                  thumbnail={post && post["image"]}
                                  commentsCount={
                                    `${post && post["comments_count"]}` +
                                    " " +
                                    `${
                                      post &&
                                      post["comments_count"] &&
                                      post["comments_count"] > 1
                                        ? "comments"
                                        : "comment"
                                    }`
                                  }
                                  statusText={post && post["status_text"]}
                                  history={this.props.history}
                                  delete={
                                    post["user_details"].id === currentUserId
                                      ? "Delete"
                                      : null
                                  }
                                  deletePost={() =>
                                    this.deletePost(post.id, post["post_type"])
                                  }
                                  edit={
                                    post["user_details"].id === currentUserId
                                      ? "Edit"
                                      : null
                                  }
                                  editPost={() =>
                                    this.props.history.push(
                                      `/add-post/${post.id}`
                                    )
                                  }
                                  report={
                                    post["user_details"].id !== currentUserId
                                      ? "Report"
                                      : null
                                  }
                                  reportPost={() =>
                                    this.reportPost(post.id, post["post_type"])
                                  }
                                  reportReason={this.reportReason}
                                />
                              </Col>
                            ))
                          ) : (
                            <>
                              <Col>
                                <div className="text-center p-2 mt-4 mt-lg-5">
                                  <img
                                    src={"../assets/images/no-photos.png"}
                                    alt="aaaa"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Recipe">
                        <Row className="mt-4 mt-md-5 p-0">
                          {postsLoading ? (
                            <div
                              style={{
                                margin: "50px auto 0",
                                textAlign: "center",
                              }}
                            >
                              <ContentLoader color="text-warning" />
                            </div>
                          ) : recipePosts && recipePosts.length > 0 ? (
                            recipePosts.map((post) => (
                              <Col
                                key={post.id}
                                md={10}
                                className="mb-5 offset-md-1"
                              >
                                <CommunityContentBox
                                  post={post}
                                  likesArray={post["likes"]}
                                  likesCount={post["likes_count"]}
                                  bookmarkArray={post["bookmarks"]}
                                  redirect={() =>
                                    this.routeHandler(
                                      post.id,
                                      post["post_type"]
                                    )
                                  }
                                  userimg={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["profile_image"]
                                  }
                                  firstname={
                                    post &&
                                    post["user_details"] &&
                                    post["user_details"]["first_name"]
                                  }
                                  lastname={post &&
                                    post["user_details"] &&
                                    post["user_details"]["last_name"]}
                                  thumbnail={post && post["image"]}
                                  commentsCount={
                                    `${post && post["comments_count"]}` +
                                    " " +
                                    `${
                                      post &&
                                      post["comments_count"] &&
                                      post["comments_count"] > 1
                                        ? "comments"
                                        : "comment"
                                    }`
                                  }
                                  statusText={post && post["status_text"]}
                                  history={this.props.history}
                                  delete={
                                    post["user_details"].id === currentUserId
                                      ? "Delete"
                                      : null
                                  }
                                  deletePost={() =>
                                    this.deletePost(post.id, post["post_type"])
                                  }
                                  edit={
                                    post["user_details"].id === currentUserId
                                      ? "Edit"
                                      : null
                                  }
                                  editPost={() =>
                                    this.props.history.push(
                                      `/add-post/${post.id}`
                                    )
                                  }
                                  report={
                                    post["user_details"].id !== currentUserId
                                      ? "Report"
                                      : null
                                  }
                                  reportPost={() =>
                                    this.reportPost(post.id, post["post_type"])
                                  }
                                  reportReason={this.reportReason}
                                />
                              </Col>
                            ))
                          ) : (
                            <Col>
                              <div className="text-center p-2 mt-4 mt-lg-5">
                                <img
                                  src="/assets/images/no-recepies.png"
                                  alt=""
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Status">
                        <Row className="mt-4 mt-md-5 p-0">
                          {postsLoading ? (
                            <div
                              style={{
                                margin: "50px auto 0",
                                textAlign: "center",
                              }}
                            >
                              <ContentLoader color="text-warning" />
                            </div>
                          ) : statusPosts && statusPosts.length > 0 ? (
                            statusPosts.map((post) => (
                              <Col
                                key={post.id}
                                md={10}
                                className="mb-5 offset-md-1"
                              >
                                <div className="chatboard-status bg-grey border-radius-10">
                                  <CommunityContentBox
                                    className="border-0"
                                    post={post}
                                    likesArray={post["likes"]}
                                    likesCount={post["likes_count"]}
                                    bookmarkArray={post["bookmarks"]}
                                    redirect={() =>
                                      this.routeHandler(
                                        post.id,
                                        post["post_type"]
                                      )
                                    }
                                    userimg={
                                      post &&
                                      post["user_details"] &&
                                      post["user_details"]["profile_image"]
                                    }
                                    firstname={
                                      post &&
                                      post["user_details"] &&
                                      post["user_details"]["first_name"]
                                    }
                                    lastname={post &&
                                      post["user_details"] &&
                                      post["user_details"]["last_name"]}
                                    statusText={post && post["status_text"]}
                                    commentsCount={
                                      `${post && post["comments_count"]}` +
                                      " " +
                                      `${
                                        post &&
                                        post["comments_count"] &&
                                        post["comments_count"] > 1
                                          ? "comments"
                                          : "comment"
                                      }`
                                    }
                                    delete={
                                      post["user_details"].id === currentUserId
                                        ? "Delete"
                                        : null
                                    }
                                    deletePost={() =>
                                      this.deletePost(
                                        post.id,
                                        post["post_type"]
                                      )
                                    }
                                    edit={
                                      post["user_details"].id === currentUserId
                                        ? "Edit"
                                        : null
                                    }
                                    editPost={() =>
                                      this.props.history.push(
                                        `/add-post/${post.id}`
                                      )
                                    }
                                    report={
                                      post["user_details"].id !== currentUserId
                                        ? "Report"
                                        : null
                                    }
                                    reportPost={() =>
                                      this.reportPost(
                                        post.id,
                                        post["post_type"]
                                      )
                                    }
                                    reportReason={this.reportReason}
                                  />
                                </div>
                              </Col>
                            ))
                          ) : (
                            <Col>
                              <div className="text-center p-2 mt-4 mt-lg-5">
                                <img
                                  src="/assets/images/no-status.png"
                                  alt=""
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
              {/* <Col md={2} className="order-1 order-md-2">
                <Dropdown className="sorting">
                  <Dropdown.Toggle
                    variant=""
                    className="filter-toggler py-2 d-flex align-items-center justify-content-between bg-grey-3"
                  >
                    <span className="text-grey-4">Sort </span>
                    <img
                      src="/assets/images/filter.png"
                      alt="dropdown"
                      className="ml-3"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="h-auto border-0">
                    <Dropdown.Item href="#" className="font-weight-semi">
                      Newest
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="font-weight-semi">
                      Most Popular
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col> */}
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserPosts: () => dispatch(get_all_user_posts_action()),
  hitLike: (data) => dispatch(hit_like_action(data)),
  deletePostById: (id) => dispatch(delete_post_by_id_action(id)),
});
export default connect(null, mapDispatchToProps)(CommunityTimeline);
