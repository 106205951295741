import React, { Component } from "react";
import { Tab, Row, Col, Nav, Accordion, Card, Button } from "react-bootstrap";
import { Prev } from "react-bootstrap/esm/PageItem";
import "./HelpTabs.scss";

class HelpTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultActiveKey: "",
    };
  }

  render() {
    let faqData = this.props && this.props.data && this.props.data.data;
    return (
      <>
        <Tab.Container id="FaqTabs" defaultActiveKey="first">
          <Row className="mt-4">
            <>
              {/* left  */}
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  {faqData && faqData.length ? (
                    faqData.map((data, index) => (
                      <Nav.Item className="mb-4">
                        <Nav.Link
                          eventKey={`${index == 0 ? "first" : data.id}`}
                          className="py-4"
                        >
                          {data.topic}
                        </Nav.Link>
                      </Nav.Item>
                    ))) : (
                    <h4 className="text-danger-2">No FAQ's yet!</h4>
                  )}
                </Nav>
              </Col>

              {/* right */}
              <Col sm={8}>
                <Tab.Content>
                  {faqData &&
                    faqData.map((data, index) => (
                      <Tab.Pane eventKey={`${index == 0 ? "first" : data.id}`}>
                        <div className="inner-accordion">
                          <p className="fs-16 font-weight-semi bg-grey py-3 px-4 rounded">
                            {data.topic}
                          </p>
                          {data &&
                            data.subtopics &&
                            data.subtopics.map((subtopicsdata) => (
                              <Accordion>
                                <Card className="border-0">
                                  <Card.Header>
                                    <Accordion.Toggle
                                      as={Button}
                                      block
                                      variant="link"
                                      eventKey="0"
                                      className="p-0 d-flex jusfity-content-between align-items-center w-100"
                                    >
                                      <h5 className="text-left w-100">
                                        {subtopicsdata.subtopic}
                                      </h5>
                                      <i
                                        className="fa fa-angle-down font-weight-bold fa-2x text-dark"
                                      ></i>
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      <div
                                        className="topic-desc text-grey-4 font-weight-medium fs-14"
                                        dangerouslySetInnerHTML={{
                                          __html: subtopicsdata.answer,
                                        }}
                                      ></div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            ))}
                        </div>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </>
          </Row>
        </Tab.Container>
      </>
    );
  }
}
export default HelpTabs;
