import swal from 'sweetalert'
import { uploadEpisodeConstants } from "../../_constants/Admin/uploadEpisode.constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

//SAVE SHOW DETAILS
export const save_episode_datails_action = (data) => async (dispatch) => {
    dispatch({
        type: uploadEpisodeConstants.UPLOAD_EPISODE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.content.uploadEpisodeContent, data)
        if (response.status) {
            dispatch({
                type: uploadEpisodeConstants.UPLOAD_EPISODE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadEpisodeConstants.UPLOAD_EPISODE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//Get episode detail

export const get_episode_details_action = (id) => async (dispatch) => {
    dispatch({
        type: uploadEpisodeConstants.GET_EPISODE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getEpisodeContent.replace(':id', id))
        if (response.status) {
            dispatch({
                type: uploadEpisodeConstants.GET_EPISODE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadEpisodeConstants.GET_EPISODE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_user_episode_details_action = (id) => async (dispatch) => {
    dispatch({
        type: uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.content.getUserSideEpisodeContent.replace(':id', id))
        if (response.status) {
            dispatch({
                type: uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadEpisodeConstants.GET_USER_SIDE_EPISODE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//update episode detail
export const update_episode_details_action = (id,data) => async (dispatch) => {
    dispatch({
        type: uploadEpisodeConstants.UPDATE_EPISODE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.content.updateEpisodeContent.replace(':id', id),data)
        if (response.status) {
            dispatch({
                type: uploadEpisodeConstants.UPDATE_EPISODE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadEpisodeConstants.UPDATE_EPISODE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//delete episode detail

export const delete_episode_details_action = (id) => async (dispatch) => {
    dispatch({
        type: uploadEpisodeConstants.DELETE_EPISODE_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.content.deleteEpisodeContent.replace(':id', id))
        if (response.status) {
            dispatch({
                type: uploadEpisodeConstants.DELETE_EPISODE_DETAILS_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: uploadEpisodeConstants.DELETE_EPISODE_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}