import { profileConstants } from "../../_constants/User/";

const initialState = {};
export const get_profile_content_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.GET_PROFILE_CONTENT_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case profileConstants.GET_PROFILE_CONTENT_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case profileConstants.GET_PROFILE_CONTENT_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};

export const edit_user_name_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.EDIT_USER_NAME_ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };

    case profileConstants.EDIT_USER_NAME_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};


export const edit_user_image_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.EDIT_USER_NAME_ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };

    case profileConstants.EDIT_USER_NAME_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};




export const change_password_reducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.CHANGE_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };

    case profileConstants.CHANGE_PASSWORD_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
