import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "react-bootstrap";

import Header from "../common/Header/Header";

import "./DataPolicy.scss";

import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";

class TermsConditions extends Component {
  state = {
    userObject: null,
  };

  componentDidMount() {
    let userObject = JSON.parse(localStorage.getItem("user"));

    this.setState({
      userObject: userObject,
    });
  }

  render() {
    const { userObject } = this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          <div className="main-content bg-white position-relative">
            {userObject ? (
              <Header activeMenuItem="videos" searchType="videos" />
            ) : (
              <HeaderNew activeMenuItem="videos" history={this.props.history} />
            )}

            <div className="mt-4 row justify-content-center">
              <div className="col-lg-10">
                <div className="px-3  py-4 mb-4 bg-primary">
                  <h1 className="text-white">Data Policy</h1>
                </div>
                <div>
                  <h2 className="text-primary mb-3">Welcome to DariusCooks!</h2>

                  <p className="data-policy">
                    1. If you want your data deleted, mail to -{" "}
                    <a
                      className="text-underline font-weight-semi"
                      href="admin@dariuscooks.com"
                    >
                      admin@dariuscooks.com
                    </a>
                  </p>
                  <p className="data-policy">
                    2. Include your email with which you created your account on
                    our app and please provide us a brief summary of what needs
                    to be deleted.
                  </p>
                  <p className="data-policy">
                    3. We will review your account and remove the requested
                    data. You will receive a confirmation email for the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(TermsConditions);
