import React, { Component } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import "./ContactUs.scss";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";
import Header from "../common/Header/Header";

class ContactUs extends Component {
  state = {
    userObject: null
  };
  componentDidMount() {
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState(
      {
        userObject: userObject
      }
    );
  }

  render() {
    const { userObject } = this.state;
    return (
      <div className="common-dashboard view-video-wapper">
        {userObject ? (
          <Header searchType="shows" history={this.props.history} activeMenuItem="shows" />
        ) : (
          <HeaderNew activeMenuItem="shows" history={this.props.history} />
        )}
        {/* <div className="subscribe-for-update overflow-hidden bg-grey-10 border-radius-10">
          <Container className="px-md-0">
            <Row className="no-gutters align-items-center">
              <Col
                md={7}
                className="py-3 d-flex align-items-center justify-content-center order-md-1 order-2"
              >
                <div className="px-2">
                  <h2 className="fs-48">Never miss an update !</h2>
                  <p className="font-poppins fs-18 text-dark-2 mt-3 mb-4">
                    Subscribe to get our latest content by email.
                  </p>
                  <div className="d-flex">
                    <Form className="d-flex">
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="search"
                          placeholder="Email Address"

                        />
                      </Form.Group>

                      <Button className="btn rounded primary ml-2 subscribe">
                        Subscribe
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col md={5} className="order-md-2 order-1">
                <div className="img-wrapper text-right">
                  <img src="/assets/images/subscribe-for-updates.png" alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <div className="contact-us bg-grey-10">
          <Container>
            <Row>
              <Col md={6}>
                <div className="px-3 py-4 p-md-5">
                  <h2 className="text-primary">DariusCooks</h2>
                  <p className="fs-18 text-dark-2 font-poppins mt-4">
                    We provide you with the latest breaking news and videos
                    straight from the entertainment industry.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="p-3 p-md-5 mt-3 mt-md-0">
                  <ul className="bottom-menu d-flex flex-wrap alignt-items-center fs-18 font-weight-semi font-poppins text-dark-2">
                    <li className="mr-5">
                      <a href="/"> Home </a>
                    </li>
                    <li className="mr-5">
                      <a href="/all-recipes"> Recipies </a>
                    </li>
                    <li className="mr-5">
                      <a href="/all-shows">Shows</a>
                    </li>
                    <li className="mr-5">
                      <a href="/shopping-store"> Store </a>
                    </li>
                  </ul>
                  <p className="font-poppins fs-18 font-weight-semi text-dark-2 mt-4">
                    Contact Us:{" "}
                    <a
                      href="mailto:info@dariuscooks.tv"
                      className="text-primary font-weight-400"
                    >
                      info@dariuscooks.tv
                    </a>
                  </p>
                  <ul className="social-media-icons mt-4 d-flex align-items-center">
                    <li>
                      <a href="https://www.facebook.com/DariusCooksToo" target="_blank" className="d-inline-block" title="facebook">
                        <img
                          src="/assets/images/fb-icon-group.png"
                          alt="facebook"
                        />
                      </a>
                    </li>
                    <li className="ml-2 ml-lg-4">
                      {" "}
                      <a href="https://www.instagram.com/dariuscooks/" target="_blank" className="d-inline-block" title="instagram">
                        <img
                          src="/assets/images/insta-icon-group.png"
                          alt="instagram"
                        />
                      </a>
                    </li>
                    <li className="ml-2 ml-lg-4">
                      <a href="https://twitter.com/dariusexposer/" target="_blank" className="d-inline-block" title="twitter">
                        <img
                          src="/assets/images/twitter-icon-group.png"
                          alt="twitter"
                        />
                      </a>
                    </li>
                    <li className="ml-2 ml-lg-4">
                      <a href="https://www.youtube.com/channel/UCYYF1Wr40hFrURtZBRIP3hw" target="_blank" className="d-inline-block" title="youtube">
                        <img
                          src="/assets/images/youtube-icon-group.png"
                          alt="youtube"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}



export default ContactUs;