import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { get_features_info_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
import './ExistingFeatures.scss';
export class ExistingFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuresContent: [],
    };
  }
  componentDidMount() {
    this.getfeaturesContentData();
  }
  getfeaturesContentData = async () => {
    let data = await this.props.getfeaturesContent();
    this.setState({ featuresContent: data?.data });
  };
  render() {
    let { featuresContent } = this.state;

    return (
      <div className="existing-features">
        <Container>
          <h2 className="fs-48">Get many of the existing features</h2>
          <p className="fs-18 font-poppins text-dark-2 my-4">
            {featuresContent && featuresContent.description}
          </p>
          <Row className="py-2">
            {featuresContent &&
              featuresContent.features &&
              featuresContent.features.map((features) => (
                <Col lg={3} sm={6} className="mb-4" key={features && features.id}>
                
                  <div className="feature-box bg-grey-10 h-100 p-4 border-radius-20  text-center">
                    {features?.rank === 1 ? (
                      <span className="d-inline-block bg-primary p-2 border-radius-10">
                        <img src="/assets/images/go-live.png" alt="" />
                      </span>
                    ) : features?.rank === 2 ? (
                      <span className="d-inline-block bg-primary p-2 border-radius-10">
                        <img src="/assets/images/shows-icon.png" alt="" />
                      </span>
                    ) : features?.rank === 3 ? (
                      <span className="d-inline-block bg-primary p-2 border-radius-10">
                        <img src="/assets/images/video-icon.png" alt="" />
                      </span>
                    ) : features?.rank === 4 ? (
                      <span className="d-inline-block bg-primary p-2 border-radius-10">
                        <img src="/assets/images/store-icon.png" alt="" />
                      </span>
                    ) : null}

                    <h3 className="my-3">{features?.title}</h3>
                    <p className="fs-16">{features?.description}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getfeaturesContent: () => dispatch(get_features_info_content_action()),
});

export default connect(null, mapDispatchToProps)(ExistingFeatures);
