import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import "./WatchTutorials.scss";
import { get_free_youtube_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
export class WatchTutorials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideoModal: false,
      getFreeYoutubeContent: [],
      playingVideoUrl: null,
    };
  }
  componentDidMount() {
    this.getFreeYoutubeContentData();
  }
  getFreeYoutubeContentData = async () => {
    let data = await this.props.getFreeYoutubeContent();
    this.setState({ getFreeYoutubeContent: data.data });
  };
  playVideoModalHandler = (url) => {
    this.setState({
      playingVideoUrl: url,
      playVideoModal: !this.state.playVideoModal,
    });
  };
  render() {
    let { getFreeYoutubeContent } = this.state;
    let displayVideoUrl =
      getFreeYoutubeContent &&
      getFreeYoutubeContent[
        Math.floor(Math.random() * getFreeYoutubeContent.length)
      ] &&
      getFreeYoutubeContent[
        Math.floor(Math.random() * getFreeYoutubeContent.length)
      ].youtube_link;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="watch-tutorials">
        <Container>
          <h2 className="fs-48 my-5">
            Watch the free video tutorials on Youtube
          </h2>
          <div className="watch-tutorials-banner pb-3">
            <ReactPlayer
              url={displayVideoUrl}
              width="100%"
              controls={true}
              muted={true}
              playing={false}
              height="100%"
              className="react-player"
            />
          </div>

          <div className="mt-5 tutorial-slider-wrapper">
            <Slider {...settings}>
              {getFreeYoutubeContent &&
                getFreeYoutubeContent.map((recipe) => (
                  <div
                    className="tutorials-wrapper border-radius-20 position-relative"
                    key={recipe && recipe.id}
                  >
                    {/* premium content icon will come after adding active class */}
                    {/* <span className="premium-icon active position-absolute">
                      <img
                        src="/assets/images/premium-icon.svg"
                        alt="premium-icon"
                        className="img-fit img-fluid"
                      />
                    </span> */}
                    <span className="tutorials-img-wrapper">
                      {recipe.title_image ? (
                        <img
                          src={recipe.title_image}
                          alt={recipe.title}
                          className="tutotials-img img-fit img-fluid"
                        />
                      ) : (
                        <img
                          src={"/assets/images/placeholder-recipe.jpg"}
                          alt={recipe.title}
                          className="tutotials-img img-fit img-fluid"
                        />
                      )}
                    </span>
                    <div className="tutorials-content-wrapper p-4 bg-grey-10">
                      <div className="top-section d-flex align-items-center justify-content-between">
                        <h4 className="recipe-title">{recipe.title}</h4>
                        <p className="fs-14 text-nowrap text-grey-4 font-weight-medium mb-0">
                          {recipe.updated.substring(0, 10)}
                        </p>
                      </div>
                      <p className="fs-16 text-dark-2 mt-3">
                        {recipe.description}
                      </p>

                      <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                        {/* <p className="dish-name text-dark-2 fs-16 font-weight-bold">
                          Main Dish
                        </p> */}
                        <div
                          className="play-button-img cursor-pointer"
                          onClick={() =>
                            this.playVideoModalHandler(recipe.youtube_link)
                          }
                        >
                          <img src="/assets/images/play-btn.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </Container>

        {/*  popup  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.playVideoModal}
          onHide={this.playVideoModalHandler}
          className="text-center signup-email"
        >
          <Modal.Body className="delete-action-popup-content position-relative">
            <span
              onClick={this.playVideoModalHandler}
              className="position-absolute close-modal-option bg-primary rounded-circle d-flex align-items-center justify-content-center cursor-pointer text-white"
              style={{
                width: "30px",
                height: "30px",
                right: "10px",
                left: "auto",
                top: "8px",
              }}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            <div className="py-4 mt-2">
              <ReactPlayer
                url={this.state.playingVideoUrl}
                width="100%"
                controls={true}
                muted={true}
                playing={false}
                height={400}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFreeYoutubeContent: () => dispatch(get_free_youtube_content_action()),
});

export default connect(null, mapDispatchToProps)(WatchTutorials);
