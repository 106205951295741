import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";

import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import ContentBox from "../common/ContentBox/ContentBox";
import CommentsSearch from "../common/CommentsRecipeTabs/Comments/CommentsSearch/CommentsSearch";
import CommentsCard from "../common/CommentsRecipeTabs/Comments/CommentsCard/CommentsCard";
import {
  delete_post_by_id_action,
  get_comments_by_id_action,
  get_status_by_id_action,
  report_item_action,
  hit_like_action
} from "../../../_actions/User/post.action";
import "./ViewPost.scss";
import ContentLoader from "../../Common/Loader/ContentLoader";
import ReactHtmlparser from "react-html-parser";
import swal from "sweetalert";

class ViewPost extends Component {
  state = {
    postLoading: false,
    post: [],
    commentsLoading: false,
    comments: [],
    gotCommentError: false,
    reportReason: null,
    commentsCount: null
  };

  componentDidMount() {
    this.mountPostData();
    this.mountComments();
  }

  mountPostData = async () => {
    this.setState({ postLoading: true });
    const getPostRes = await this.props.getPostData(
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    );

    if (getPostRes && getPostRes.data) {
      this.setState({
        post: getPostRes.data,
        commentsCount:
          getPostRes && getPostRes.data && getPostRes.data.comments_count
      });
    } else this.props.history.push("/community-timeline");

    this.setState({ postLoading: false });
  };

  mountComments = async () => {
    this.setState({ commentsLoading: true });

    const postId =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    const data = {
      comment_type: "user_post"
    };
    const getCommentsRes = await this.props.getCommentsAction(data, postId);
    if (getCommentsRes && getCommentsRes.data) {
      this.setState({
        comments: getCommentsRes.data,
        gotCommentError: false
      });
    } else this.setState({ gotCommentError: true });

    this.setState({ commentsLoading: false });
  };

  deletePost = async (id, type) => {
    if (!id) return;
    let redirectRoute = "";
    switch (type) {
      case "photo":
        redirectRoute = "Photos";
        break;
      case "video":
        redirectRoute = "Videos";
        break;
      case "recipe":
        redirectRoute = "Recipe";
        break;
      default:
        redirectRoute = "Status";
    }
    await this.props.deletePostById(id);
    this.props.history.push(`/community-timeline/${redirectRoute}`);
  };

  likePost = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id
    };
    await this.props.hitLike(data);
    await this.mountPostData();
  };

  refreshComments = async () => {
    const getPostRes = await this.props.getPostData(
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    );

    if (getPostRes && getPostRes.data) {
      this.setState({
        commentsCount:
          getPostRes && getPostRes.data && getPostRes.data.comments_count
      });
    }
    await this.mountComments();

  };

  goBack = (type) => {
    let redirectRoute;

    switch (type) {
      case "video":
        redirectRoute = "Videos";
        break;
      case "photo":
        redirectRoute = "Photos";
        break;
      case "recipe":
        redirectRoute = "Recipe";
        break;
      default:
        redirectRoute = "Status";
    }
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.backPageUrl === "chatboard") {
      this.props.history.push(`/chatboard/${redirectRoute}`);
    } else if (this.props && this.props.location && this.props.location.state && this.props.location.state.backPageUrl === "userNotifications") {
      this.props.history.push(`/notifications`);
    } else {
      this.props.history.push(`/community-timeline/${redirectRoute}`);
    }
  };

  handleBack = (post) => {
    let state = this.props && this.props.location && this.props.location.state;
    if (state && state.detail) {
      this.props.history.goBack();
    } else {
      this.goBack(post["post_type"]);
    }
  };
  reportPost = async (id, type) => {
    let data = {
      post: id,
      report_type: this.state.reportReason
    };
    const res = await this.props.reportItem(data);
    swal(res && res.msg);
  };
  reportReason = (reason) => {
    this.setState({ reportReason: reason });
  };
  render() {
    const {
      commentsLoading,
      gotCommentError,
      postLoading,
      comments,
      post,
      commentsCount
    } = this.state;
    const currentUserId = JSON.parse(localStorage.getItem("user")).user.id;

    return (
      <div className="common-dashboard post-wrapper">
        <Container className="p-0">
          <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          />

          <div className="main-content bg-white">
            <Header />

            {/*==================== cookbook details page============= */}

            {postLoading ? (
              <div
                style={{
                  margin: "50px auto 0",
                  textAlign: "center"
                }}
              >
                <ContentLoader color="text-warning" />
              </div>
            ) : post && Object.keys(post).length > 0 ? (
              <div className="post">
                {/* back button */}
                <a className="align-items-center d-none d-md-flex">
                  <img
                    src={"/assets/images/back-arrow.svg"}
                    className="ml-md-n2"
                    onClick={() => this.goBack(post["post_type"])}
                    alt="back"
                  />
                  <span
                    className="text-dark fs-16 font-weight-bold ml-1"
                    onClick={() => this.goBack(post["post_type"])}
                  >
                    Back
                  </span>
                </a>
                <Row className="pt-4">
                  <Col md={6}>
                    <ContentBox
                      post={post}
                      likesArray={post["likes"]}
                      likesCount={post["likes_count"]}
                      bookmarkArray={post["bookmarks"]}
                      userimg={
                        post &&
                        post["user_details"] &&
                        post["user_details"]["profile_image"]
                      }
                      username={
                        post &&
                        post["user_details"] &&
                        post["user_details"]["first_name"]
                      }
                      thumbnail={post && post["image"]}
                      videoId={post && post["video_playback_id"]}
                      className="p-0"
                      delete={
                        post["user_details"].id === currentUserId
                          ? "Delete"
                          : null
                      }
                      deletePost={() =>
                        this.deletePost(post.id, post["post_type"])
                      }
                      edit={
                        post["user_details"].id === currentUserId
                          ? "Edit"
                          : null
                      }
                      editPost={() =>
                        this.props.history.push(`/add-post/${post.id}`)
                      }
                      report={
                        post["user_details"].id !== currentUserId
                          ? "Report"
                          : null
                      }
                      reportPost={() =>
                        this.reportPost(post.id, post["post_type"])
                      }
                      reportReason={this.reportReason}
                      redirect={() => this.props.history.push("#!")}
                      commentsCount={
                        `${commentsCount}` +
                        " " +
                        `${commentsCount > 1 ? "comments" : "comment"}`
                      }
                    />
                    <p className="mt-2 text-grey-3">
                      {post && post["description"]}
                    </p>
                  </Col>

                  {/* Steps for follow */}
                  <Col
                    md={6}
                    className={`pl-md-5 pt-md-5 pt-3 ${this.state.post &&
                        this.state.post["post_type"] &&
                        this.state.post["post_type"] === "recipe"
                        ? ""
                        : "d-none"
                      }`}
                  >
                    <h6 className="font-montserrat bg-primary rounded-pill py-2 px-3 text-white d-inline-block mt-4">
                      Steps
                    </h6>
                    <p className="desc font-smallest font-weight-semi mt-4 text-grey-2">
                      {post &&
                        (post["description"] ||
                          ReactHtmlparser(post["instructions"]))}
                    </p>
                  </Col>

                  {/* comment section */}
                  <Col md={6} className="pl-md-5">
                    <CommentsSearch
                      post={post}
                      refreshComments={() => this.refreshComments()}
                    />
                    {commentsLoading ? (
                      <div className="text-center">
                        <ContentLoader color="text-warning" />
                      </div>
                    ) : gotCommentError ? (
                      <p className="text-warning">
                        Cannot fetch comments! refresh the page.
                      </p>
                    ) : (
                      <>
                        <CommentsCard
                          refreshComments={() => this.refreshComments()}
                          comments={comments}
                          delete="Delete"
                          report="Report"
                        />
                      </>
                    )}
                    {/*<CommentsCard commentimg="/assets/images/shows-item-1.svg" delete="Delete" report="Report"/>*/}
                    {/*<CommentsCard delete="Delete" report="Report"/>*/}
                  </Col>
                </Row>
              </div>
            ) : (
              <p className="text-danger">Post No Found!</p>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPostData: (id) => dispatch(get_status_by_id_action(id)),
  deletePostById: (id) => dispatch(delete_post_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  getCommentsAction: (data, id) =>
    dispatch(get_comments_by_id_action(data, id)),
  reportItem: (data) => dispatch(report_item_action(data))
});

export default connect(null, mapDispatchToProps)(ViewPost);
