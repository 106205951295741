import { headerSearchConstants } from "../../_constants/User";

const initialState = {};

export const header_search_result_reducer = (state = initialState, action) => {
    switch (action.type) {
        case headerSearchConstants.HEADER_SEARCH_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case headerSearchConstants.HEADER_SEARCH_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case headerSearchConstants.HEADER_SEARCH_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};

export const header_search_query_result_reducer = (state = initialState, action) => {
    switch (action.type) {
        case headerSearchConstants.HEADER_SEARCH_QUERY_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case headerSearchConstants.HEADER_SEARCH_QUERY_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case headerSearchConstants.HEADER_SEARCH_QUERY_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};

export const video_search_result_reducer = (state = initialState, action) => {
    switch (action.type) {
        case headerSearchConstants.VIDEO_SEARCH_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case headerSearchConstants.VIDEO_SEARCH_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case headerSearchConstants.VIDEO_SEARCH_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};

export const recipe_search_result_reducer = (state = initialState, action) => {
    switch (action.type) {
        case headerSearchConstants.RECIPE_SEARCH_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case headerSearchConstants.RECIPE_SEARCH_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case headerSearchConstants.RECIPE_SEARCH_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};
export const shows_search_result_reducer = (state = initialState, action) => {
    switch (action.type) {
        case headerSearchConstants.SHOW_RECIPE_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case headerSearchConstants.SHOW_SEARCH_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload,
            };
        case headerSearchConstants.SHOW_SEARCH_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload,
            };
        default:
            return state;
    }
};