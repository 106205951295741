import React, { Component } from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import "./SubscribeForUpdate.scss";

export class SubscribeForUpdate extends Component {
  // options = {
  //   method: "POST",
  //   headers: { accept: "application/json", "content-type": "application/json" },
  // };
  // onFormClick = async () => {
  //   await fetch("https://api.omnisend.com/v3/events", this.options)
  //     .then((response) => response.json())
  //     .then((response) => console.log(response))
  //     .catch((err) => console.error(err));
  // };
  render() {
    return (
      <div className="subscribe-for-update overflow-hidden bg-grey-10 border-radius-10">
        <Container className="px-md-0">
          <Row className="no-gutters align-items-center">
            <Col
              md={7}
              className="py-3 d-flex align-items-center justify-content-center order-md-1 order-2"
            >
              <div className="px-2">
                <h2 className="fs-48">Never miss an update !</h2>
                <p className="font-poppins fs-18 text-dark-2 mt-3 mb-4">
                  Subscribe to get our latest content by email.
                </p>
                <div className="d-flex">
                  <Form className="d-flex">
                    <Form.Group className="mb-0">
                      <Form.Control type="search" placeholder="Email Address" />
                    </Form.Group>

                    <Button className="btn rounded primary ml-2 subscribe">
                      Subscribe
                    </Button>
                  </Form>
                  {/* <iframe url="https://omniform1.com/forms/v1/landingPage/631b955848c164e108e0c278/63c2fd067fb50e39573d799c"></iframe> */}
                </div>
              </div>
            </Col>
            <Col md={5} className="order-md-2 order-1">
              <div className="img-wrapper text-right">
                <img src="/assets/images/subscribe-for-updates.png" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SubscribeForUpdate;
