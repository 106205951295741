import React, { Component } from "react";
import { Pagination } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ReactHtmlparser from "react-html-parser";

import "./Notifications.scss";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { get_notification_action } from "../../../_actions/User/notification.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import FullPageLoader from "../../Common/Loader/FullPageLoader";

class Notifications extends Component {
  state = {
    loading: false,
    notifications: [],
    pagination: {
      pageSize: 25,
      current: 1,
      total: 0
    }
  };

  
  componentDidMount() {
    this.mountNotifications();
  }

  mountNotifications = async () => {
    const { pagination } = this.state;

    this.setState({ loading: true });

    const notificationRes = await this.props.mountNotification(
      pagination.current
    );

    if (notificationRes)
      this.setState((prevState) => ({
        notifications:
          notificationRes &&
          notificationRes.data &&
          notificationRes.data.results,
        pagination: {
          ...prevState.pagination,
          total:
            notificationRes &&
            notificationRes.data &&
            notificationRes.data.count
        }
      }));

    this.setState({ loading: false });
  };
  notificationRedirectionHandler = (data) => {
    let post = data.notification;
    let notificationtType = data.notification.type;

    // if notification is relative to comment on post or post like
    if (
      notificationtType === "post_comment" ||
      notificationtType === "post_like"
    ) {
      let postType = post.related_post_details.post_type;
      let postId = post.related_post;
      if (postType === "status") {
        this.props.history.push({
          pathname: `/post-status/${postId}`,
          state: { backPageUrl: "userNotifications" }
        });
      } else {
        this.props.history.push({
          pathname: `/view-post/${postId}`,
          state: { backPageUrl: "userNotifications" }
        });
      }
    }

    // if notification is an annoucment
    if (notificationtType === "announcement") {
      let postType = post.related_content_details.published_item_type;
      let postId = post.related_content_details.published_item_id;
      if (postType === "video") {
        this.props.history.push(`/video/${postId}`);
      } else if (postType === "episode") {
        this.props.history.push(`/watch-show/${postId}`);
      } else if (postType === "youtube-recipe") {
        this.props.history.push(`/recipe/${postId}`);
      } else if (postType === "show") {
        this.props.history.push(`/view-show/${postId}`);
      } else if (
        data.notification &&
        data.notification.message === "Darius is going live"
      ) {
        this.props.history.push({
          pathname: `/join-livestream`,
          state: { backPageUrl: "userNotifications" }
        });
      }
    }
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountNotifications();
      }
    );
  };
  
  onBackClick = () => { 
    this.props.history.goBack();
  };

  render() {
    const { loading, notifications, pagination } = this.state;

    return (
      <div className="common-dashboard user-notification-wrapper">
        <Container className="p-0">
          <SidebarMenu />
          <div className="main-content bg-white position-relative">
            <Header />
            <div className="notifications">
              <div onClick={this.onBackClick} className="d-flex align-items-center cursor-pointer">
                <img
                  src={"/assets/images/back-arrow.svg"} 
                  alt="back"
                  className="ml-n2"
                />
                <span className="text-dark fs-16 font-weight-bold ml-1">
                  Back
                </span>
              </div>
              <Row className="mt-4">
                <Col md={8} className="ml-md-3 font-weight-bold">
                

                  {loading ? (
                    <FullPageLoader color={"text-warning"} />
                  ) : (
                    notifications &&
                    notifications.map((notification) => (
                      <>
                      
                        <div
                          key={notification.id}
                          className="notification-box bg-grey-4 d-flex align-items-center p-2 p-md-3 border-6 mb-3 cursor-pointer"
                          onClick={() =>
                            this.notificationRedirectionHandler({
                              notification
                            })
                          }
                        >
                          <p className=" mb-0 flex-grow-1">
                            {ReactHtmlparser(notification.message)}
                            {notification &&
                            notification.related_content_details &&
                            notification.related_content_details
                              .announcement_item_details &&
                            notification.related_content_details
                              .announcement_item_details.title ? (
                              <em>
                                {" "}
                                - "
                                {notification &&
                                  notification.related_content_details &&
                                  notification.related_content_details
                                    .announcement_item_details &&
                                  notification.related_content_details
                                    .announcement_item_details.title}
                                "
                              </em>
                            ) : null}
                          </p>
                          {notification &&
                          notification.related_post_details &&
                          notification.related_post_details.image ? (
                            <div className="related-img ml-2">
                              <img
                                src={
                                  notification &&
                                  notification.related_post_details &&
                                  notification.related_post_details.image
                                }
                                alt="post images"
                                className="rounded"
                              />
                            </div>
                          ) : null}

                          {notification &&
                          notification.related_content_details &&
                          notification.related_content_details
                            .announcement_item_details &&
                          notification.related_content_details
                            .announcement_item_details.title_image ? (
                            <div className="related-img ml-2">
                              <img
                                src={
                                  notification &&
                                  notification.related_content_details &&
                                  notification.related_content_details
                                    .announcement_item_details &&
                                  notification.related_content_details
                                    .announcement_item_details.title_image
                                }
                                alt="post images"
                                className="rounded"
                              />
                            </div>
                          ) : null}
                        </div>
                      </>
                    ))
                  )}
                </Col>
              </Row>
              <div className="pl-2">
                <Pagination
                  current={pagination.current}
                  showSizeChanger={false}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  onChange={this.onHandlePaginationChange}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mountNotification: (page) => dispatch(get_notification_action(page))
});

export default connect(null, mapDispatchToProps)(Notifications);
