import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Modal,
  Form
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./RecipesSection.scss";
import NewestRecipe from "./NewestRecipe/NewestRecipe";
import PopularRecipe from "./PopularRecipe/PopularRecipe";
import SeasonalRecipe from "./SeasonalRecipe/SeasonalRecipe";
import VeganRecipe from "./VeganRecipe/VeganRecipe";
import { get_recipe_category_titles_action, update_recipe_category_title_action } from "../../../../../_actions/Admin/homePage.action";
import { get_categories_section_title_action } from "../../../../../_actions/User";
import { connect } from "react-redux";
export class RecipesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rankOneData: null,
      rankTwoData: null,
      rankThreeData: null,
      rankFourData: null,
      sectionTitle: "",
    };
  }
  componentDidMount() {
    this.getRecipeCategoryTitles();
    this.getSectionTitle();
  }

  getRecipeCategoryTitles = async () => {
    await this.props.getCategoryTitles();
  };


  getSectionTitle = async () => {
    const data = await this.props.getRecipeCategoryTitle();
    let title = data && data.data && data.data.title;
    this.setState({
      sectionTitle: title,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.recipeCategoryTitleData !== this.props.recipeCategoryTitleData
    ) {
      let titles = this.props && this.props.recipeCategoryTitleData;
      let rankOneData =
        titles &&
        titles.filter((title) => title.rank === 1) &&
        titles.filter((title) => title.rank === 1)[0];

      let rankTwoData =
        titles &&
        titles.filter((title) => title.rank === 2) &&
        titles.filter((title) => title.rank === 2)[0];

      let rankThreeData =
        titles &&
        titles.filter((title) => title.rank === 3) &&
        titles.filter((title) => title.rank === 3)[0];

      let rankFourData =
        titles &&
        titles.filter((title) => title.rank === 4) &&
        titles.filter((title) => title.rank === 4)[0];
      this.setState({
        rankOneData: rankOneData,
        rankTwoData: rankTwoData,
        rankThreeData: rankThreeData,
        rankFourData: rankFourData
      });
    }

    if (
      prevProps.recipeCategorySectionTitle !== this.props.recipeCategorySectionTitle
    ) {
      let title = this.props && this.props.recipeCategorySectionTitle && this.props.recipeCategorySectionTitle.data && this.props.recipeCategorySectionTitle.data.title;
      this.setState({
        sectionTitle: title
      });
    }
  }

  addSectionTitle = (e) => {
    const value = e.target.value;
    const data = { "title": value }
    this.props.updateRecipeCategoryTitle(data)
  }
  render() {
    let { rankOneData, rankTwoData, rankThreeData, rankFourData, sectionTitle } = this.state;

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="recipe-section  mt-3">
        <Container>
          <div className="recipe-bottom-section ">
            <input type="text" placeholder="Enter Title here..."
              defaultValue={sectionTitle}
              className="form-control w-100 font-weight-semi border-0 fs-36"
              onBlur={(e) => this.addSectionTitle(e)}
            />
            <div className="recipe-type  font-poppins  my-5">
              <Tabs
                defaultActiveKey="NEWEST"
                className="tab-menu position-static justify-content-around"
              >
                <Tab eventKey="NEWEST" title={rankOneData && rankOneData.name}>
                  <NewestRecipe history={this.props.history} />
                </Tab>
                <Tab eventKey="POPULAR" title={rankTwoData && rankTwoData.name}>
                  <PopularRecipe history={this.props.history} />
                </Tab>
                <Tab
                  eventKey="SEASONAL"
                  title={rankThreeData && rankThreeData.name}
                >
                  <SeasonalRecipe history={this.props.history} />
                </Tab>
                <Tab eventKey="VEGAN" title={rankFourData && rankFourData.name}>
                  <VeganRecipe history={this.props.history} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    recipeCategoryTitleData:
      state.update_top_left_home_reducer.recipeCategoryTitleData,
    recipeCategorySectionTitle:
      state.update_top_left_home_reducer.categorySectionTitle,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCategoryTitles: () => dispatch(get_recipe_category_titles_action()),
  updateRecipeCategoryTitle: (data) => dispatch(update_recipe_category_title_action(data)),
  getRecipeCategoryTitle: () => dispatch(get_categories_section_title_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipesSection);
