import React, { Component } from "react";

export class CopyRight extends Component {
  render() {
    return (
      <div className="copyright">
        <div className="container">
          <div className="bg-primary text-white py-4">
            <p className="  px-1 fs-20 font-weight-semi text-center font-poppins">
              © COPYRIGHT 2023. DARIUS COOKS TV
            </p>
            <ul className="d-flex justify-content-center">
              <li>
                <a href="/privacy" className="text-white">
                  Privacy Policy
                </a>
              </li>
              <li className="ml-3">
                <a href="/terms-conditions" className="text-white">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default CopyRight;
