import swal from 'sweetalert';
import { pricingConstants } from '../../_constants/User';
import axiosInstance from '../../_helpers/axiosInstance';
import history from "../../_helpers/history";
import config from "../../Config/config";


//for single product
export const get_pricing_action = () => async (dispatch) => {
  dispatch({
    type: pricingConstants.GET_VIDEO_PRICING_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.pricingDetails);
    
    if (response.status) {
      dispatch({
        type: pricingConstants.GET_VIDEO_PRICING_ACTION_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: pricingConstants.GET_VIDEO_PRICING_ACTION_FAILURE,
    });
    swal(e.msg)
  }
};

