import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App/App";
import { store } from "./_helpers";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
