import {videoConstants} from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// get receipe content from backend
export const get_video_content_action = () => async (dispatch) => {
    dispatch({
        type: videoConstants.GET_VIDEO_CONTENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userVideo.getVideoContent)
        if (response.status) {
            dispatch ({
                type: videoConstants.GET_VIDEO_CONTENT_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: videoConstants.GET_VIDEO_CONTENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_video_by_id_action = (id) => async (dispatch) => {
    dispatch({
        type: videoConstants.GET_VIDEO_BY_ID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userVideo.getVideoById.replace(":id", id))
        if (response.status) {
            dispatch ({
                type: videoConstants.GET_VIDEO_BY_ID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: videoConstants.GET_VIDEO_BY_ID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_all_user_videos_action = (page) => async (dispatch) => {
    dispatch({
        type: videoConstants.GET_ALL_VIDEO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userVideo.getAllVideo.replace(":page", page))
        if (response.status) {
            dispatch ({
                type: videoConstants.GET_ALL_VIDEO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: videoConstants.GET_ALL_VIDEO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


export const get_all_suggested_videos_action = () => async (dispatch) => {
    dispatch({
        type: videoConstants.GET_SUGGESTED_VIDEO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userVideo.getSuggestedVideos)
        if (response.status) {
            dispatch ({
                type: videoConstants.GET_SUGGESTED_VIDEO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: videoConstants.GET_SUGGESTED_VIDEO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}