const dev = {
  apiUrl: process.env.REACT_APP_BACKEND_API_URL,
  // auth endpoints
  auth: {
    login: "/users/rest-auth/login/",
    signup: "/users/rest-auth/registration/",
    googleAuth: "/users/rest-auth/google/",
    facebookAuth: "/users/rest-auth/facebook/",
    logout: "/users/rest-auth/logout/",
    handleFCMToken: "/notifier/onesignal-register/",
    resetPassword: "/users/password-reset-confirm/",
    forgotPassword: "/users/rest-auth/password/reset/"
  },

  //For user related APIs
  users: {
    getUserDetails: "/users/all-users/",
    getCsvData: "/users/export_users/",
    getCarouselContents: "/admin/carousel-content/"
  },

  //For carousel items
  carousel: {
    carouselItems: "/admin/carousel-content/",
    dashboardItems: "/admin/dashboard-items/",
    dashboardItemsById: "/admin/dashboard-items/:id/",
    publishedContent: "/admin/all-published-content/"
  },

  //For reports
  reports: {
    reportedPostById: "/user/posts/:id/",
    reportItems: "/user/report-item-list/",
    reportItemsSearch: "/user/report-item-search/",
    reportAction: "/user/reported-item-action/:id/",
    reportedCommentById: "/user/comment-with-details/:id/"
  },

  //For content management APIs
  content: {
    saveRecipeVideo: "/admin/youtube-recipe/",
    uploadVideoContent: "/admin/video/",
    getVideoDetails: "/admin/video/:id/",
    updateVideoContent: "/admin/video/:id/",
    updateRecipeContent: "/admin/youtube-recipe/",
    addConnectedRecipe: "/admin/connected-recipe/",
    getSavedRecipeVideo: "/admin/youtube-recipe/:id/",
    editConnectedRecipe: "/admin/connected-recipe/:id/",
    getConnectedRecipeById: "/admin/connected-recipe/:id/",
    getRecipeListing: "admin/all-content/",
    editRecipeListing: "",
    deleteRecipeListing: "/admin/:type/:id/",
    uploadShowContent: "/admin/show/",
    uploadEpisodeContent: "/admin/episode/",
    getSavedShow: "/admin/show/:id/",
    updateShow: "/admin/show/:id/",
    getEpisodeContent: "/admin/episode/:id/",
    getUserSideEpisodeContent: "/admin/user/episode/:id/",
    updateEpisodeContent: "/admin/episode/:id/",
    deleteEpisodeContent: "/admin/episode/:id/",
    fetchFaqTableData: "/faqs/user-faqs/",
    deleteFaqTopic: "/faqs/faq-topic/",
    getTopicDetails: "/faqs/faq-topic-details/",
    updateTopic: "/faqs/faq-topic/",
    updateSubTopic: "/faqs/faq-subtopic/",
    newTopicAddition: "/faqs/faq-subtopic/",
    deleteSubtopic: "/faqs/faq-subtopic/",
    createFaq: "/faqs/faq-topic/",
    announcementApi: "/announcements/admin/",
    announcementSearchApi: "/announcements/admin/search/",
    userSideAnnouncements: "/announcements/",
    searchFaq: "/faqs/faq-topic-search/",
    addTags: "/adminpanel/tag-item-mapping/"
  },

  // user faqs
  userfaq: {
    getFAQContent: "/faqs/user-faqs/"
  },
  // user recipes
  userRecipe: {
    getRecipeContent: "/admin/user/youtube-recipe/105/",
    getRecipeById: "/admin/user/youtube-recipe/:id/",
    getAllRecipe: "/admin/user/youtube-recipe/?page=:page"
  },
  // user videos
  userVideo: {
    getVideoContent: "/admin/user/video/67/",
    getVideoById: "/admin/user/video/:id/",
    getAllVideo: "/admin/user/video/?page=:page",
    getSuggestedVideos:"/homepage/suggested-videos/"
  },
  // user shows
  userShow: {
    getAllShows: "admin/user/show/",
    singleShowDetails: "/admin/user/show/"
  },

  //user Grocery
  userGrocery: {
    getAllgrocery: "/grocery/list/",
    addGroceryList: "/grocery/list/",
    getGroceryListById: "/grocery/list/:id/",
    addItemToGroceryList: "/grocery/item/",
    deleteItemFromGroceryList: "/grocery/item/:id/",
    toggleCheckbox: "/grocery/item/:id/",
    removeGroceryList: "/grocery/list/:id/",
    addGroceryItemToList: "/grocery/list/:id/",
    deleteItemsByRecipeFromGroceryList: "/grocery/grocery-recipe-delete/:grocery_list_id/:recipe_id/",
    

  },

  // user profile
  userProfile: {
    profileDetails: "/users/user-detail/",
    editProfileImg: "/users/user-profile/",
    changePassword: "/users/rest-auth/password/change/"
  },
  // usera header search
  userHeaderSearch: {
    headerSearchResult: "/admin/carousel-content/",
    headerSearchQueryResult: "/user/typeahead-search/",
    videoSearchResult: "/admin/user/video/",
    showSearchResult: "admin/user/show/?page=:page",
    // showSearchResult: "admin/user/show/",
    showSearch: "admin/user/show/",
    recipeSearchResult: "/admin/typeahead/recipes/",
    // recipeSearchResult: "/admin/user/youtube-recipe/",
  },

  //user payment
  userPayment: {
    singleProductPaymentDetail: "/payments/single-product/",
    subscriptionPaymentDetail: "/payments/order-subscription/",
    subscriptionCancelDetail: "/payments/cancel-subscription/",
    upgradePaymentDetail: "/payments/remaining-amount/",
    postUpgradePaymentDetail: "/payments/upgrade-subscription/",
    purchasedProductLastMonthDetail: "/users/purchased-lastmonth/"
  },
  //notification
  notification: {
    getNotifications: "/notifier/notifications/?page=:page"
  },
  // post
  post: {
    postRoute: "/user/posts/",
    like: "/user/like-unlike/",
    comment: "/user/comment/",
    bookmark: "/user/bookmark/",
    savedPosts: "/user/saved-posts/",
    savedContents: "/user/saved-content/",
    itemReport: "/user/report-item/"
  },

  homePage: {
    meetTheExpert: "/adminpanel/meet-the-expert/",
    featuresSection: "/adminpanel/features-section/",
    homePageTopLeft: "/adminpanel/featured-top-left/",
    homePageTopRight: "/adminpanel/featured-top-right/",
    socialMediaSection: "/adminpanel/social-media-section/",
    galleryHeading: "/adminpanel/image-gallery-section/",
    appSection: "/adminpanel/app-section/",
    postCategory: "adminpanel/category-section/",
    getRecipesCategoryTitles: "adminpanel/category-section/",
    postRecipeSectionTitle:"/adminpanel/category-section-title/admin/",
  },

  //livestream
  livestream: {
    playbackId: "/livestream/current/",
    addComment: "/livestream/create-comment/",
    getCommentList: "/livestream/list-comment/:id/",
    // getLiveStreamViewersCount: "/livestream/viewers-count/:id/"
    getLiveStreamViewersCount: "/livestream/mux-livestream-viewers-count/:id/"

  },

  //Shopify
  shopifyStore: {
    storefrontAccessToken: process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN,
    domain: process.env.REACT_APP_STOREFRONT_DOMAIN
  },

  // home page new
  homepageNew: {
    topLeftfeatured: "homepage/featured-top-left/",
    topRightFeaturedRecipes: "homepage/featured-top-right/",
    // newestRecipes: "homepage/newest/",
    // popularRecipes: "homepage/popular/",
    // seasonalRecipes: "homepage/section/seasonal/",
    // veganReceipes: "homepage/section/vegan/",

    newestRecipes: "homepage/category-section/1/",
    popularRecipes: "homepage/category-section/2/",
    seasonalRecipes: "homepage/category-section/3/",
    veganReceipes: "homepage/category-section/4/",
    getRecipesCategoryTitles: "homepage/category-section/",

    meetExpert: "homepage/meet-the-expert/",
    imagesGallery: "homepage/image-gallery-section/",
    features: "homepage/features-section/",
    socialMediaLinks: "homepage/social-media-section/",
    appInfo: "homepage/app-section/",
    freeYoutubeRecipe: "homepage/free-youtube-recipe-section/",
    recipeSectionTitle:"/adminpanel/category-section-title/",

  },

  // mux url
  mux: {
    muxVideoUrl: "/adminpanel/mux-signed-url/",
    getPlaybackAssetId: "/adminpanel/mux-asset-playback-ids/:uploadId/"
  },
  categorySearch:'/admin/ingredients/categories/',
  categorySearchUser:'/grocery/category/typeahead/',

  pricingDetails:"payments/membership-pricing/",
};

const prod = {
  // all prod url's will be here
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
