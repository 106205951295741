import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "./TopSearch.scss";
import { connect } from "react-redux";
import {
  header_search_action,
  video_search_action,
  recipe_search_action,
  show_search_action,
} from "../../../../../_actions/User/headerSearch.action";
// import swal from "sweetalert";
class TopSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      searchType: this.props.searchType,
      indicator: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.state.searchType === "videos") {
      if (nextProps.videoList !== this.props.videoList) {
        this.setState({
          resultData:
            nextProps.videoList &&
            nextProps.videoList.data &&
            nextProps.videoList.data.data &&
            nextProps.videoList.data.data.results,
        });
      }
    }
  };

  searchHandler = async (e) => {
    const { searchType } = this.state;
    let searchText = e.target.value;

    let searchKeyword = { search: searchText };

    if (searchType === "videos") {
      let searchResult = await this.props.videoSearchResultData(searchKeyword);
    }
    if (searchType === "recipes" && searchText === "") {
      let searchResult = await this.props.recipeSearchResultData(searchKeyword);
    }
    if (searchType === "shows" && searchText === "") {
      let searchResult = await this.props.showsSearchResultData(searchKeyword);
    }
  };
  render() {
    let data = this.state && this.state.resultData;

    return (
      <div className="top-search-wrapper">
        <Form.Group className="mb-0 h-100">
          <Form.Control
            type="text"
            placeholder="Search"
            className="search-bar bg-grey-4 border-6 pl-4"
            onChange={(e) => this.searchHandler(e)}
          />
        </Form.Group>
    
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoList: state.video_search_result_reducer,
    //   editUserImg: state.edit_user_image_reducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // searchResultData: (data) => dispatch(header_search_action(data)),
  videoSearchResultData: (data) => dispatch(video_search_action(data)),
  recipeSearchResultData: (data) => dispatch(recipe_search_action(data)),
  showsSearchResultData: (data) => dispatch(show_search_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopSearch);