import {postConstants} from "../../_constants/User/post.constants";

const initialState = {}
export const add_post_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.ADD_POST_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.ADD_POST_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.ADD_POST_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_all_user_posts_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.GET_ALL_POSTS_ACTIONS_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.GET_ALL_POSTS_ACTIONS_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.GET_ALL_POSTS_ACTIONS_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_status_by_id_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.GET_STATUS_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.GET_STATUS_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.GET_STATUS_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const delete_post_by_id_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.DELETE_POST_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.DELETE_POST_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.DELETE_POST_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const update_post_by_id_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.UPDATE_POST_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.UPDATE_POST_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.UPDATE_POST_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const hit_like_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.HIT_LIKE_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.HIT_LIKE_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.HIT_LIKE_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const add_comment_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.ADD_COMMENT_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.ADD_COMMENT_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.ADD_COMMENT_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_comments_by_id_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.GET_COMMENTS_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.GET_COMMENTS_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.GET_COMMENTS_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const delete_comment_by_id_action = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.DELETE_COMMENTS_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.DELETE_COMMENTS_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.DELETE_COMMENTS_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const add_bookmark_to_post_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_saved_contents_reducer = (state = initialState, action) => {
    switch (action.type) {
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case postConstants.BOOKMARK_POST_BY_ID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}