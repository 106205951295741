import React, { Component } from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import * as UpChunk from "@mux/upchunk";

import "./UploadVideoContent.scss";
import {
  create_mux_url_action,
  delete_connected_recipe_action,
  get_playback_asset_id_action,
  publish_video_action,
  save_video_details_action,
} from "../../../../../../_actions/Admin/uploadVideo.action";
import { add_post_tags_action } from "../../../../../../_actions/Admin";
import ContentLoader from "../../../../../Common/Loader/ContentLoader";
import { isEmptyObject } from "../../../../../../_helpers/validators";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
class UploadVideoContent extends Component {
  state = {
    loading: false,
    deleteVideoModal: false,
    deleteRecipeModal: false,
    videoName:
      this.props.videoDetails !== null ? this.props.videoDetails.title : "",
    videoDesc:
      this.props.videoDetails !== null
        ? this.props.videoDetails.description
        : "",
    imageSrc: null,
    imageUrl:
      this.props.videoDetails !== null
        ? this.props.videoDetails["title_image"]
        : null,
    imageName: "",
    videoSrc: null,
    videoFileName:
      this.props.videoDetails !== null &&
      this.props.videoDetails["video"] !== null
        ? this.props.videoDetails["video"].toString().substr(8)
        : "",
    isPremium:
      this.props.videoDetails !== null
        ? this.props.videoDetails["is_premium"]
        : false,
    //isPremium:false,
    recipeToDelete: "",
    deleteConnectedRecipe: false,
    connectedRecipes:
      this.props.videoDetails !== null &&
      this.props.videoDetails["connected_recipe"] !== null
        ? this.props.videoDetails["connected_recipe"]
        : null,
    videoId:
      this.props.videoDetails !== null ? this.props.videoDetails.id : null,
    submitTriggered: false,
    isChangesSaved: this.props.videoDetails !== null,
    isSaveVideoClicked: false,
    videoPublished: false,
    publishVideoTriggered: false,
    deleteLoading: false,
    addRecipeClicked: false,
    authenticatedId: "",
    authenticatedUploadUrl: "",
    muxAssetId: "",
    muxAssetPlayebackId: "",
    uploadedStatus: null,
    tags: this.props.videoDetails ? this.props.videoDetails.tags_names : [],
    inputVisible: false,
    inputValue: "",
    isVideoSaved:
      this.props &&
      this.props.videoDetails &&
      this.props.videoDetails.is_publish,
    progressModal: false,
  };

  componentDidMount() {
    this.callGetUploadUrlApi();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadUrl !== this.props.uploadUrl) {
      if (
        !isEmptyObject(this.props.uploadUrl) &&
        this.props.uploadUrl.url &&
        this.props.uploadUrl.url.length
      )
        this.setState({
          authenticatedUploadUrl: this.props.uploadUrl.url,
          authenticatedId: this.props.uploadUrl.id,
        });
    }
    if (prevProps.muxAssetsData !== this.props.muxAssetsData) {
      if (
        !isEmptyObject(this.props.muxAssetsData) &&
        this.props.muxAssetsData.asset_id &&
        this.props.muxAssetsData.playback_ids &&
        this.props.muxAssetsData.playback_ids.length
      ) {
        this.setState(
          {
            muxAssetId: this.props.muxAssetsData.asset_id,
            muxAssetPlayebackId: this.props.muxAssetsData.playback_ids[0].id,
          },
          () => {
            this.uploadDataToApi();
          }
        );
      }
    }
  }

  callGetUploadUrlApi = async () => {
    await this.props.getUploadUrl();
  };

  deleteVideoModalHandler = () => {
    const { videoSrc, isPremium } = this.state;
    if (videoSrc && isPremium) {
      this.setState({
        deleteVideoModal: !this.state.deleteVideoModal,
      });
    }
  };

  getMuxAssetData = async () => {
    await this.props.onGetMuxAssetData(this.state.authenticatedId);
  };

  uploadVideoToMux = () => {
    this.setState({ progressModal: true });
    const data = {
      endpoint: this.state.authenticatedUploadUrl,
      file: this.state.videoSrc,
      chunkSize: 5120,
    };

    if (data && data.file) {
      const upload = UpChunk.createUpload({
        ...data,
      });

      // subscribe to events
      upload.on("error", (err) => {
        console.error("💥 🙀", err.detail);
      });

      upload.on("progress", (progress) => {
        this.setState({
          uploadedStatus: progress.detail,
        });
      });

      // subscribe to events
      upload.on("success", (data) => {
        this.getMuxAssetData();
        swal({
          title: "Video Uploaded Successfully!",
          // text: "You clicked the button!",
          icon: "success",
        }).then(() => {
          this.setState({
            progressModal: false,
          });
        });
      });
    }
  };

  uploadDataToApi = async () => {
    const { videoName, videoDesc, videoSrc, imageSrc, isPremium } = this.state;
    if (!videoName || !videoDesc || !videoSrc || !imageSrc) return;

    /// limit file size to kbs
    const newVideoSrc = new File([videoSrc.name], videoSrc.name, {
      type: videoSrc.type,
    });

    const detailsForm = new FormData();
    detailsForm.append("title", videoName);
    detailsForm.append("title_image", this.state.imageSrc);
    detailsForm.append("video_asset_id", this.state.muxAssetId);
    detailsForm.append("video_playback_id", this.state.muxAssetPlayebackId);
    detailsForm.append("is_premium", isPremium.toString());
    detailsForm.append("description", videoDesc);

    /// limit video size to 2 kb
    detailsForm.append("video", newVideoSrc);

    const saveRes = await this.props.onSaveVideoDetails(detailsForm);
    if (saveRes && saveRes.data && !isEmptyObject(saveRes.data)) {
      this.setState({
        publishOptionEnabled: true,
        connectedRecipes: saveRes["connected_recipe"],
        videoId: saveRes.id,
        isChangesSaved: true,
      });

      this.props.history.push(`/update-content/video/${saveRes.data.id}`);
      window.location.reload();
    }
    this.setState({ loading: false, submitTriggered: false });
  };

  handleContentSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ isSaveVideoClicked: true, submitTriggered: true });
    this.uploadVideoToMux();
  };

  handleUploadFile = (e, type) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.length === 0) return;

    const uploadedFileType = file.type.toLowerCase();
    if (type === "image" && uploadedFileType.includes("image")) {
      this.setState({
        imageSrc: file,
        imageName: file.name,
      });
    } else if (type === "video" && uploadedFileType.includes("video")) {
      this.setState({
        videoSrc: file,
        videoFileName: file.name,
      });
    } else {
      swal("Wrong File type encountered! Please try again");
      return;
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onAddRecipeClick = () => {
    const { isChangesSaved } = this.state;
    const { videoDetails } = this.props;
    this.setState({ addRecipeClicked: true });

    if (!isChangesSaved) {
      this.setState({
        errorText: "Save changes before adding recipes!",
      });
      return;
    }

    if (isChangesSaved && videoDetails !== null) {
      this.props.history.push(
        `/upload-recipe-instructions/video/${videoDetails.id}/new-recipe`
      );
    }
  };

  onPublishVideoClick = async () => {
    const { videoName, videoDesc, isVideoSaved, videoSrc } = this.state;
    if (!videoName || !videoDesc) return;
    this.setState({ publishVideoTriggered: true });

    /// limit file size to kbs
    let newVideoSrc = null;
    if (videoSrc)
      newVideoSrc = new File([videoSrc.name], videoSrc.name, {
        type: videoSrc.type,
      });
    if (
      this.state.isChangesSaved &&
      this.props.videoDetails &&
      this.props.videoDetails["connected_recipe"] &&
      this.props.videoDetails["connected_recipe"].length !== 0
    ) {
      const formData = new FormData();
      formData.append("title", this.state.videoName);
      if (this.state.imageSrc)
        formData.append("title_image", this.state.imageSrc);
      if (this.state.videoSrc) formData.append("video", newVideoSrc);
      formData.append("description", this.state.videoDesc);
      formData.append("is_publish", "true");
      formData.append("is_premium", this.state.isPremium);
      this.setState({ loading: true, videoSrc: this.state.videoSrc }, () => {
        if (isVideoSaved) {
          this.uploadVideoToMux();
        }
      });
      const publishVideoRes = await this.props.onPublishVideo(
        this.props.videoDetails.id,
        formData
      );

      this.setState({ loading: false });
      if (publishVideoRes) {
        this.setState({ videoPublished: true });
        swal("Video Published Successfully!");
        this.props.history.push("/upload-content-listing");
        // window.location.reload();
      }
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.publishedContent &&
      nextProps.publishedContent.error &&
      nextProps.publishedContent.error.message !== this.props &&
      this.props.publishedContent &&
      this.props.publishedContent.error &&
      this.props.publishedContent.error.message
    ) {
      swal(
        nextProps &&
          nextProps.publishedContent &&
          nextProps.publishedContent.error &&
          nextProps.publishedContent.error.message
      ).then((value) => {
        window.location.reload();
      });
    }
  }

  onToggleDeleteConnectedRecipe = (e, recipeId) => {
    this.setState((prevState) => ({
      deleteConnectedRecipe: !prevState.deleteConnectedRecipe,
      recipeToDelete: recipeId,
    }));
  };

  onDeleteConnectedRecipeClick = async () => {
    const { isChangesSaved, recipeToDelete } = this.state;
    if (isChangesSaved && recipeToDelete) {
      this.setState({ deleteLoading: true });
      const data = {
        delete_connected_recipe: [recipeToDelete],
      };
      await this.props.onDeleteConnectedVideoRecipe(
        this.props.videoDetails.id,
        data
      );
      this.setState((prevState) => ({
        deleteConnectedRecipe: !prevState.deleteConnectedRecipe,
        deleteLoading: !prevState.deleteLoading,
      }));
      window.location.reload();
    }
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags }, async () => {
      let tags = this.state.tags;
      const tagsData = {
        tags: tags,
        video:
          this.props && this.props.videoDetails && this.props.videoDetails.id,
      };
      const tagsRes = await this.props.addTags(tagsData);
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState(
      {
        tags,
        inputVisible: false,
        inputValue: "",
      },
      async () => {
        let tags = this.state.tags;
        const tagsData = {
          tags: tags,
          video:
            this.props && this.props.videoDetails && this.props.videoDetails.id,
        };
        const tagsRes = await this.props.addTags(tagsData);
      }
    );
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        color="#87449a"
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const {
      videoName,
      videoDesc,
      videoFileName,
      videoSrc,
      imageName,
      imageSrc,
      loading,
      submitTriggered,
      isPremium,
      isChangesSaved,
      videoPublished,
      publishVideoTriggered,
      addRecipeClicked,
      uploadedStatus,
      tags,
      inputVisible,
      inputValue,
    } = this.state;
    const tagChild = tags.map(this.forMap);

    return (
      <div className="form-outer bg-grey-6 fs-16 font-weight-semi">
        <Form.Group className="bg-grey-4 p-2 text-black">
          {/* video name input */}
          <Form.Control
            className="font-weight-semi fs-16 text-black"
            type="text"
            name="videoName"
            value={videoName}
            onChange={this.handleChange}
            placeholder="Video Name"
            required
          />
          {!videoName && submitTriggered ? (
            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
              Field required!
            </p>
          ) : null}
        </Form.Group>
        {/* video desc */}
        <div>
          <Form.Group className="bg-grey-4 p-3 ">
            <Form.Label className="mb-2">Description</Form.Label>
            <Form.Control
              className="bg-white"
              as="textarea"
              rows={8}
              name="videoDesc"
              value={videoDesc}
              onChange={this.handleChange}
              required
            />
            {videoDesc && !videoDesc.length && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
          </Form.Group>
          {/* upload image section */}
          <Form.Group className="bg-grey-4 p-3">
            <Form.Label className="mb-2">Images</Form.Label>
            <label
              htmlFor="titleImage"
              className="btn btn-block text-grey-5 bg-white rounded"
            >
              <input
                type="file"
                className="d-none invisible"
                id="titleImage"
                name="videoImage"
                onChange={(e) => this.handleUploadFile(e, "image")}
                accept="image/png, image/jpg, image/jpeg"
                required
              />
              {imageName ? (
                imageName
              ) : (
                <>
                  <span className="fa fa-upload mr-3"></span>Add Title Image
                </>
              )}
            </label>
            {this.state.imageUrl ? (
              <img
                src={this.state.imageUrl}
                className="mt-3"
                alt="upload image"
              />
            ) : null}
            {!imageSrc && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
          </Form.Group>
          <div className="form-outer p-3  bg-grey-4 fs-16 font-weight-semi">
            {/* handle video delete action */}
            <div className="d-flex align-items-center justify-content-between">
              <span>Video</span>
            </div>

            <Form.Group>
              <div className="tile-video-checkbox mt-4 fs-14 text-dark-3 d-flex align-items-center justify-content-between">
                <span className="font-weight-bold text-grey-5 ">
                  Title and video
                </span>
                <div className="custom-control custom-checkbox  info">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="premium-content"
                    name="PremiumContent"
                    defaultChecked={this.state.isPremium}
                    onChange={() =>
                      this.setState({ isPremium: !this.state.isPremium })
                    }
                  />
                  <label
                    className="custom-control-label text-grey-5 font-weight-semi ml-2"
                    htmlFor="premium-content"
                  >
                    Check if this is premium content
                  </label>
                </div>
              </div>
            </Form.Group>

            {/* upload video input */}
            <div className="upload-video d-flex align-items-center">
              <label
                htmlFor="videoUpload"
                className="btn btn-outline-dark font-smallest font-weight-semi rounded py-2 px-4"
              >
                <input
                  id="videoUpload"
                  className="d-none"
                  type="file"
                  onChange={(e) => this.handleUploadFile(e, "video")}
                  accept="video/*"
                  required
                />
                {videoFileName ? videoFileName : "Upload Video"}
              </label>
              <img
                src={"/assets/images/upload-video.svg"}
                alt="upload-video"
                className="ml-2"
              />
            </div>
            {!videoSrc && submitTriggered ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Field required!
              </p>
            ) : null}
            <hr className="bg-info my-4" />

            <Form.Group>
              <Form.Label className="mb-4 fs-14 font-weight-bold text-grey-5">
                Recipe
              </Form.Label>
              <ul>
                {this.props.videoDetails &&
                this.props.videoDetails["connected_recipe"] &&
                this.props.videoDetails["connected_recipe"].length
                  ? this.props.videoDetails["connected_recipe"].map(
                      (recipe) => (
                        <li className="d-flex mb-3" key={recipe.id}>
                          <button
                            onClick={() =>
                              this.props.history.push(
                                `/edit-recipe-instructions/video/${this.props.videoDetails.id}/edit-recipe/${recipe.id}`
                              )
                            }
                            className="btn btn-block bg-white font-weight-semi py-2 mr-2 border-info rounded text-grey-5 text-left"
                          >
                            {recipe.name}
                          </button>
                          <a
                            href="#"
                            className="ml-2 d-flex"
                            onClick={(e) =>
                              this.onToggleDeleteConnectedRecipe(e, recipe.id)
                            }
                          >
                            <img
                              src={"/assets/images/delete-content.svg"}
                              alt="delete"
                            />
                          </a>
                        </li>
                      )
                    )
                  : null}
              </ul>
            </Form.Group>

            {/* add more button */}
            <a onClick={this.onAddRecipeClick} className="text-info d-block ">
              +{" "}
              {this.props.videoDetails &&
              this.props.videoDetails["connected_recipe"] &&
              this.props.videoDetails["connected_recipe"].length
                ? "Add more"
                : "Add Recipe"}
            </a>
            {!isChangesSaved && addRecipeClicked ? (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                Save changes before adding recipes!
              </p>
            ) : videoPublished ? (
              <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                Video published successfully!
              </p>
            ) : addRecipeClicked && isChangesSaved ? (
              <p className="fs-14 text-success font-source-sans text-left font-weight-semi">
                Video saved successfully!
              </p>
            ) : null}
          </div>

          {isChangesSaved ? (
            <Form.Group>
              <div className="bg-grey-4 p-3 fs-16 font-weight-semi mt-3">
                <p className="fs-14 font-weight-bold text-grey-5 mb-3">
                  Add Tags
                </p>
                <>
                  <div style={{ marginBottom: 16 }}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: "from",
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === "appear" || e.type === "enter") {
                          e.target.style = "display: inline-block";
                        }
                      }}
                      leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                      }}
                      appear={false}
                    >
                      {tagChild}
                    </TweenOneGroup>
                  </div>
                  {inputVisible && (
                    <Input
                      ref={this.saveInputRef}
                      type="text"
                      size="small"
                      style={{ width: 78 }}
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag onClick={this.showInput} className="site-tag-plus">
                      <PlusOutlined /> New Tag
                    </Tag>
                  )}
                </>
              </div>
            </Form.Group>
          ) : null}

          {publishVideoTriggered &&
          !this.props.videoDetails["connected_recipe"].length ? (
            <p className="fs-14 text-danger font-source-sans text-left font-weight-semi">
              You need add recipe first to publish!
            </p>
          ) : null}

          <Modal show={this.state.progressModal} centered>
            <Modal.Header>
              <Modal.Title>Upload Progress</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {uploadedStatus ? (
                <div className=" text-center">
                  <ProgressBar
                    animated
                    now={uploadedStatus}
                    variant="success"
                    label={`${uploadedStatus.toFixed(2)}%`}
                  />
                </div>
              ) : null}
            </Modal.Body>
          </Modal>

          <div className="action fs-14 d-flex justify-content-end mt-3 flex-wrap">
            {isChangesSaved ? (
              <button
                onClick={() => {
                  localStorage.removeItem("contentRecipe");
                  this.props.history.push("/upload-content-listing");
                }}
                className="btn btn-outline-dark py-2 px-4 font-weight-400 my-2 rounded"
              >
                Exit
              </button>
            ) : null}
            {isChangesSaved ? (
              <button
                onClick={this.onPublishVideoClick}
                className="btn btn-success py-2 px-4 font-weight-semi ml-3 my-2 rounded"
                disabled={
                  !videoName ||
                  !videoDesc ||
                  (isChangesSaved &&
                    this.props &&
                    this.props.videoDetails &&
                    this.props.videoDetails.connected_recipe &&
                    this.props.videoDetails.connected_recipe.length === 0) ||
                  !this.props.videoDetails
                }
              >
                {loading ? (
                  <div className="text-center">
                    <ContentLoader color="text-white text-center" />
                  </div>
                ) : (
                  "Publish Video"
                )}
              </button>
            ) : null}
            {!isChangesSaved ? (
              <button
                onClick={() =>
                  this.props.history.push("/upload-content-listing")
                }
                className="btn btn-outline-dark py-2 px-3 ml-3 font-weight-400 my-2 rounded"
              >
                Cancel
              </button>
            ) : null}
            {/* save and exit is not in use */}
            {/*<button className="btn btn-warning py-2 px-3 font-weight-semi ml-3 my-2 rounded">Save & Exit</button>*/}
            {!isChangesSaved ? (
              <button
                className="btn btn-primary py-2 px-3 font-weight-semi ml-3 my-2 rounded"
                disabled={!videoFileName || (!imageSrc && !this.state.imageUrl)}
                onClick={this.handleContentSubmit}
              >
                {loading ? (
                  <ContentLoader color="text-white" />
                ) : (
                  "Save Changes"
                )}
              </button>
            ) : null}
          </div>
        </div>
        {/* delete action modal for premium content*/}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isPremium && this.state.deleteVideoModal}
          onHide={this.deleteVideoModalHandler}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              You cannot delete this video since it has been marked as premium
              content.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.deleteVideoModalHandler}
              variant="outline-dark"
              className="rounded"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete action modal for premium content Ends */}

        {/* delete connected recipe modal*/}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteConnectedRecipe}
          onHide={this.onToggleDeleteConnectedRecipe}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Are you sure you want to delete the recipe instructions?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.onToggleDeleteConnectedRecipe}
              variant="outline-dark"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={this.onDeleteConnectedRecipeClick}
              variant="danger"
              className="rounded"
            >
              {this.state.deleteLoading ? <ContentLoader /> : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete recipe instructions popup ends */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    publishedContent: state.publish_video_content_reducer,
    uploadUrl: state.save_video_details_reducer.authenticatedUrl,
    muxAssetsData: state.save_video_details_reducer.muxAssetsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUploadUrl: () => dispatch(create_mux_url_action()),
  onGetMuxAssetData: (id) => dispatch(get_playback_asset_id_action(id)),
  onPublishVideo: (id, data) => dispatch(publish_video_action(id, data)),
  onSaveVideoDetails: (data) => dispatch(save_video_details_action(data)),
  onDeleteConnectedVideoRecipe: (id, data) =>
    dispatch(delete_connected_recipe_action(id, data)),
  addTags: (data) => dispatch(add_post_tags_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoContent);
