import React, { Component } from "react";
import TopSearch from "./TopSearch/TopSearch";
import "./Header.scss";
import { get_profile_content_action } from "../../../../_actions/User";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";

import { logout_user_action } from "../../../../_actions/Common";
import OneSignalReact from "react-onesignal";
import { isEmptyObject } from "../../../../_helpers/validators";
import ContentLoader from "../../../Common/Loader/ContentLoader";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      backToTop: false,
      menuOpen: false,
      loading: false,
    };
  }

  componentWillMount() {
    this.props.getProfileContent();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getProfileContentData !== this.props.getProfileContentData) {
      this.setState({
        content: nextProps.getProfileContentData,
      });
    }
  };

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 250) {
      this.setState({
        backToTop: true,
      });
    } else {
      this.setState({
        backToTop: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  menuOpenHandler = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleLogout = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      const token = {
        refresh: user["refresh_token"],
      };
      if (token && !isEmptyObject(user.user) && user.user.role === "user") {
        // hit the fcm token and redierct to home page
        let fcmToken;
    await    OneSignalReact.getUserId((id) => {
          fcmToken = id;
        });
        if (fcmToken) {
          const data = {
            registration_id: fcmToken,
            type: "web",
            device_id: fcmToken.substr(0, 150),
            active: false,
          };
          await this.props.setClientForNotification(data);
        }
      }
      await this.props.userLogout(token);
    }
    this.props.history.push("/home-page-new");
    this.setState({ loading: false });
  };

  render() {
    let profileData = this.state && this.state.content;
    // first letter for first name
    let firstLetterFirstName =
      profileData && profileData.first_name && profileData.first_name.charAt(0);

    // first letter for last name
    let firstLetterLastName =
      profileData && profileData.last_name && profileData.last_name.charAt(0);

    const { backToTop, loading } = this.state;

    return (
      // New Header ==========================

      <div className="user-header-wrapper d-flex flex-column position-fixed shadow-sm">
        <div className="container py-1 bg-white">
          <div className="top-strip d-flex justify-content-between align-items-center ">
            {/* mobile back button  */}
            <button className={`d-md-none btn-back bg-transparent border-0 text-grey-3 fs-30  ${
                      this.props.activeMenuItem === "home" ? "d-none" : "d-block"
                    }`}>
              <span
                className="fa fa-angle-left"
                onClick={() => this.props.history.goBack()}
              ></span>
            </button>
           <a
              href="/user-home-page"
              className="left-part fs-30 font-poppins font-weight-bold"
            >
              <img src={"/assets/images/darious-tv.png"} alt={"DARIUS COOKS"} />
            </a> 
            <div
              className={`primary-menu ${this.state.menuOpen ? "active" : ""}`}
              onClick={this.menuOpenHandler}
            >
              <button
                className="menu-close bg-primary text-white bg-transparent border-0 position-absolute d-md-none"
                onClick={this.menuOpenHandler}
              >
                X
              </button>
              <ul
                className="d-flex align-items-md-center flex-column flex-md-row"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <li>
                  <a
                    href={
                      localStorage.getItem("user") !== null &&
                      JSON.parse(localStorage.getItem("user"))['user'] &&
                      JSON.parse(localStorage.getItem("user"))['user']["role"] === "user"
                        ? "/user-home-page"
                        : JSON.parse(localStorage.getItem("user"))['user']["role"] === "admin"
                        ? "/admin-home-page"
                        : "/home-page-new"
                    }
                    className={`px-md-1 px-lg-2 font-weight-semi ${
                      this.props.activeMenuItem === "home" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-home mr-2" aria-hidden="true"></i>Home
                  </a>
                </li>

                <li>
                  <a
                    href={"/all-recipes"}
                    className={`px-md-1 px-lg-2 font-weight-semi ${
                      this.props.activeMenuItem === "recipes" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-cutlery mr-2" aria-hidden="true"></i>
                    Recipes
                  </a>
                </li>

                {/* <li>
                  <a
                    href={"/all-videos"}
                    className={`px-md-1 px-lg-2 font-weight-semi menu-items ${
                      this.props.activeMenuItem === "videos" ? "active" : ""
                    }`}
                  >
                    <i
                      className="fa fa-video-camera mr-2"
                      aria-hidden="true"
                    ></i>
                    Videos
                  </a>
                </li> */}
                <li>
                  <a
                    href={"/all-shows"}
                    className={`px-md-1 px-lg-2 font-weight-semi menu-items ${
                      this.props.activeMenuItem === "shows" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-television mr-2" aria-hidden="true"></i>
                    Shows
                  </a>
                </li>
                <li>
                  <a
                    href={"/shopping-store"}
                    className={`px-md-1 px-lg-2 font-weight-semi ${
                      this.props.activeMenuItem === "store" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-cart-arrow-down mr-2" aria-hidden="true"></i>
                    Store
                  </a>
                </li>
                <li>
                  <a
                    href={"/community-timeline"}
                    className={`px-md-1 px-lg-2 font-weight-semi ${
                      this.props.activeMenuItem === "community" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-users mr-2" aria-hidden="true"></i>
                    Community
                  </a>
                </li>
                <li>
                  <a
                    href={"/add-post/add-new"}
                    className={`px-md-1 px-lg-2 font-weight-semi ${
                      this.props.activeMenuItem === "add-post" ? "active" : ""
                    }`}
                  >
                    <i
                      className="fa fa-plus-circle mr-2"
                      aria-hidden="true"
                    ></i>
                    Create Post
                  </a>
                </li>
              </ul>
            </div>

            <div className="right-part d-flex align-items-center">
              <a href="/notifications" className="mr-3 notification-link">
                <span className="fa fa-bell fs-24"></span>
              </a>
              <Dropdown as={ButtonGroup}>
                <button className="profile-btn border-0 bg-white">
                  <a href="/user-profile">
                    {profileData && profileData.profile_image ? (
                      <img
                        src={profileData && profileData.profile_image}
                        alt="Profile Image"
                        className="rounded-circle user-img"
                      />
                    ) : (
                      <span className="user-initials bg-primary text-white text-uppercase d-flex align-items-center justify-content-center font-weight-bold rounded-circle">
                        {firstLetterFirstName} {firstLetterLastName}
                      </span>
                    )}
                  </a>
                </button>

                <Dropdown.Toggle
                  variant=""
                  split
                  id="dropdown-split-basic"
                  className="p-0"
                />

                <Dropdown.Menu>
                  <Dropdown.Item href="/user-profile" eventKey="option-1">
                    {" "}
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="/user-posts" eventKey="option-1">
                    {" "}
                    My Posts
                  </Dropdown.Item>
                  <a
                    href="#"
                    onClick={this.handleLogout}
                    className="d-flex justify-content-center py-2  my-2 bg-warning-1 mx-2 border-radius-10"
                  >
                    {loading ? (
                      <ContentLoader color="text-white" />
                    ) : (
                      <img
                        src={"/assets/images/power-white.png"}
                        alt="logout"
                      />
                    )}
                  </a>
                </Dropdown.Menu>
              </Dropdown>

              <span
                className="menu-open d-inline-block d-md-none fa fa-reorder bg-primary text-white ml-3"
                onClick={this.menuOpenHandler}
              ></span>
            </div>
          </div>

          {/* <div className="hometop-wrapper flex-wrap flex-sm-nowrap d-flex justify-content-between pt-4 pb-1">
     <TopSearch
       searchType={this.props.searchType}
       history={this.props.history}
     />
   </div> */}
        </div>

        {backToTop && (
          <span
            className="back-top-btn bg-primary d-flex cursor-pointer align-items-center justify-content-center rounded-circle"
            onClick={() => this.scrollToTop()}
          >
            <span className="text-white fa fa-angle-up"></span>
          </span>
        )}
      </div>

      // <div className="user-header-wrapper d-flex flex-column position-fixed">
      //   <div className="container px-md-0 py-2 pt-4 bg-white">
      //     <div className="top-strip d-flex justify-content-between align-items-center ">
      //       {/* mobile back button  */}
      //       <button className="d-md-none btn-back bg-transparent border-0 text-grey-3 fs-30 ">
      //         <span
      //           className="fa fa-angle-left"
      //           onClick={() => this.props.history.goBack()}
      //         ></span>
      //       </button>
      //       <a
      //         href="/user-home-page"
      //         className="left-part fs-30 font-poppins font-weight-bold"
      //       >
      //         DariusCooks
      //       </a>

      //       <div className="right-part d-flex align-items-center">
      //         <a href="#">
      //           <img
      //             src="/assets/images/cart.svg"
      //             alt="Shopping-cart"
      //             className="mr-3"
      //           />
      //         </a>
      //         <a href="/user-profile">
      //           {profileData && profileData.profile_image ? (
      //             <img
      //               src={profileData && profileData.profile_image}
      //               alt="Profile Image"
      //               className="rounded-circle user-img"
      //             />
      //           ) : (
      //             <span className="user-initials bg-primary text-white text-uppercase d-flex align-items-center justify-content-center font-weight-bold rounded-circle">
      //               {firstLetterFirstName} {firstLetterLastName}
      //             </span>
      //           )}
      //         </a>
      //       </div>
      //     </div>

      //     {/* <div className="hometop-wrapper flex-wrap flex-sm-nowrap d-flex justify-content-between pt-4 pb-1">
      //       <TopSearch
      //         searchType={this.props.searchType}
      //         history={this.props.history}
      //       />
      //     </div> */}
      //   </div>

      //   {backToTop && (
      //     <span
      //       className="back-top-btn bg-primary d-flex cursor-pointer align-items-center justify-content-center rounded-circle"
      //       onClick={() => this.scrollToTop()}
      //     >
      //       <span className="text-white fa fa-angle-up"></span>
      //     </span>
      //   )}
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfileContent: () => dispatch(get_profile_content_action()),
  userLogout: (data) => dispatch(logout_user_action(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
