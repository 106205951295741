import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button } from "react-bootstrap";

import "./ReportedStatus.scss";
import {
  get_reported_post_by_id_action,
  take_on_reported_post_action,
} from "../../../_actions/Admin";
import CommentCard from "../common/CommentCard/CommentCard";
import { isEmptyObject } from "../../../_helpers/validators";
import { AntDLoader, LoaderBasic } from "../common/Loader";

class ReportedStatus extends Component {
  state = {
    postId: "",
    reportId: "",
    successText: "",
    reportedPost: {},
    isApiLoading: false,
    isActionApiLoading: { loading: false, type: "" },
    reportedBy: "",
    reportType: "",
  };

  componentDidMount() {
    if (!isEmptyObject(this.props.match.params) && this.props.match.params.id) {
      this.setState(
        {
          postId: this.props.match.params.id,
          reportId: this.props.location.state.reportId,
          reportedBy: this.props.location.state.reporterName,
          reportType: this.props.location.state.reportType,
        },
        () => {
          this.getReportedPostApi();
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportedPost !== this.props.reportedPost) {
      if (this.props.reportedPost.id) {
        this.setState({ reportedPost: { ...this.props.reportedPost } });
      }
    }
    if (prevProps.reportedAction !== this.props.reportedAction) {
      if (this.props.reportedAction.msg) {
        this.setState({ successText: `You've resolved this report request.` });
      }
    }
  }

  getReportedPostApi = async () => {
    const { postId } = this.state;

    this.setState({ isApiLoading: true });
    await this.props.getReportedPost(postId);
    this.setState({ isApiLoading: false });
  };

  handleBackClickHandler = () => {
    return this.props.history.goBack();
  };

  handleActionHandler = async (type) => {
    const { reportId } = this.state;
    const data = {
      action: type,
    };
    this.setState({ isActionApiLoading: { loading: true, type } });
    await this.props.takeOnReport(reportId, data);
    this.setState({ isActionApiLoading: { loading: false, type: "" } });
  };

  render() {
    const {
      successText,
      reportedPost,
      isApiLoading,
      isActionApiLoading,
      reportedBy,
      reportType,
    } = this.state;
    
    return (
      <div className="reported-photo reported-content-wrapper">
        <div className="reported-header fixed-top ">
          <Container className="bg-white py-4">
            <h3 className="text-primary fs-30">DariusCooks</h3>
            <div className="action-wrapper flex-wrap d-flex align-items-center">
              <span
                onClick={this.handleBackClickHandler}
                className="action d-flex align-items-center font-weight-bold cursor-pointer"
              >
                <img src="/assets/images/back-arrow.svg" alt="" />
                Back
              </span>
              <button
                className="btn btn-primary rounded fs-14 font-weight-semi ml-auto p-2 py-md-3 px-md-5"
                onClick={() => this.handleActionHandler("dismiss")}
              >
                {isActionApiLoading.loading &&
                isActionApiLoading.type === "dismiss" ? (
                  <AntDLoader />
                ) : (
                  "Dismiss the Report"
                )}
              </button>
              <button
                className="btn btn-outline-dark rounded fs-14 font-weight-semi ml-3 p-2 ml-3 p-md-3"
                onClick={() => this.handleActionHandler("delete")}
              >
                {isActionApiLoading.loading &&
                isActionApiLoading.type === "delete" ? (
                  <AntDLoader />
                ) : (
                  "Delete Status"
                )}
              </button>
            </div>
            {successText && successText.length ? (
              <div className="text-success">{successText}</div>
            ) : null}
          </Container>
        </div>

        {isApiLoading ? (
          <LoaderBasic />
        ) : (
          <div className="reported-content-inner">
            <Container className="bg-white">
              <Row className="justify-content-center">
                <Col lg={11}>
                <div className="d-flex flex-wrap justify-content-between font-smallest">
                      <div>
                        <p className="mb-0">
                          <span>Reported By: </span>
                          <span className="font-weight-semi text-primary">
                            {reportedBy}
                          </span>
                        </p>
                        <p>
                          Reason: {" "}
                          <span className="font-weight-semi text-danger">
                            {reportType}
                          </span>
                        </p>
                      </div>
                      <div className="ml-2">
                        <span>Posted By: </span>
                        <span className="font-weight-semi text-info">{`${
                          reportedPost &&
                          reportedPost.user_details &&
                          reportedPost.user_details.first_name
                        } ${
                          reportedPost &&
                          reportedPost.user_details &&
                          reportedPost.user_details.last_name
                        }`}</span>
                      </div>
                    </div>
                  <div className="reported-status bg-grey rounded p-4 mb-5">
                   
                    <div className="user-meta d-flex align-items-center">
                      <div className="user-profile-img mr-2">
                        <img
                          src={"/assets/images/user.svg"}
                          alt="user-img"
                          className="image rounded-circle img-fit"
                        />
                      </div>
                      <p className="font-weight-bold fs-small ">
                        <a href="#" className="text-dark">
                          {reportedPost.user_details &&
                            reportedPost.user_details.first_name}{" "}
                          {reportedPost.user_details &&
                            reportedPost.user_details.last_name}
                        </a>
                      </p>
                    </div>
                    <div className="status-content font-smallest font-weight-bold mt-3">
                      <p>
                        {reportedPost.status_text && reportedPost.status_text}
                      </p>
                    </div>
                    <div className="comments-count text-right font-smallest font-weight-bold  text-right mt-4">
                      <a href="#" className="text-dark">
                        <span className="mr-2">
                          {reportedPost.comments_count &&
                            reportedPost.comments_count}
                        </span>
                        Comments
                      </a>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={10}>
                <h3 className="mb-4">Comments</h3>
                <CommentCard />
                <CommentCard />
                <CommentCard />
                <CommentCard />
              </Col> */}
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.add_to_dashboard_items_reducer.errorMessage,
    reportedPost: state.reported_content_reducer.reportedPost,
    reportedAction: state.reported_content_reducer.reportedAction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReportedPost: (id) => dispatch(get_reported_post_by_id_action(id)),
  takeOnReport: (id, data) => dispatch(take_on_reported_post_action(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportedStatus);
