import config from "../../Config/config";
import axiosInstance from "../../_helpers/axiosInstance";
import { homePageConstants } from "../../_constants/Admin";

export const get_top_left_homepage_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_TOP_LEFT_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.homePageTopLeft}`
    );
    if (response.status) {
      dispatch({
        type: homePageConstants.GET_TOP_LEFT_SECTION_ACTION_SUCCESS,
        payload: response.data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_TOP_LEFT_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const update_top_left_homepage_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_TOP_LEFT_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      `${config.homePage.homePageTopLeft}`,
      data
    );
    if (response.status) {
      dispatch({
        type: homePageConstants.UPDATE_TOP_LEFT_SECTION_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_TOP_LEFT_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_top_right_homepage_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_TOP_RIGHT_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.homePageTopRight}`
    );
    if (response.status) {
      dispatch({
        type: homePageConstants.GET_TOP_RIGHT_SECTION_ACTION_SUCCESS,
        payload: response.data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_TOP_RIGHT_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const update_top_right_homepage_action =
  (data, type, rank) => async (dispatch) => {
    dispatch({
      type: homePageConstants.UPDATE_TOP_RIGHT_SECTION_ACTION_REQUEST,
    });
    try {
      const response =
        type === "post"
          ? await axiosInstance.post(
            `${config.homePage.homePageTopRight}`,
            data
          )
          : await axiosInstance.put(
            `${config.homePage.homePageTopRight}${rank}/`,
            data
          );
      if (response.status) {
        dispatch({
          type: homePageConstants.UPDATE_TOP_RIGHT_SECTION_ACTION_SUCCESS,
          payload: response.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (e) {
      dispatch({
        type: homePageConstants.UPDATE_TOP_RIGHT_SECTION_ACTION_FAILURE,
        payload: e.msg,
      });
    }
  };

export const add_heading_top_right_homepage_action =
  (data) => async (dispatch) => {
    dispatch({
      type: homePageConstants.ADD_HEADING_TOP_RIGHT_SECTION_ACTION_REQUEST,
    });
    try {
      const response = await axiosInstance.patch(
        `${config.homePage.homePageTopRight}`,
        data
      );
      if (response.status) {
        dispatch({
          type: homePageConstants.ADD_HEADING_TOP_RIGHT_SECTION_ACTION_SUCCESS,
          payload: response.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (e) {
      dispatch({
        type: homePageConstants.ADD_HEADING_TOP_RIGHT_SECTION_ACTION_FAILURE,
        payload: e.msg,
      });
    }
  };

export const get_expert_section_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_EXPERT_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.meetTheExpert}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_EXPERT_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_EXPERT_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const update_expert_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_EXPERT_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      `${config.homePage.meetTheExpert}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_EXPERT_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_EXPERT_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_features_section_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_FEATURES_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.featuresSection}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_FEATURES_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_FEATURES_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const add_features_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.ADD_FEATURES_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      `${config.homePage.featuresSection}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.ADD_FEATURES_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.ADD_FEATURES_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const post_features_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.POST_FEATURES_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      config.homePage.featuresSection,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.POST_FEATURES_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.POST_FEATURES_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const update_features_section_action = (rank, data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_FEATURES_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.patch(
      `${config.homePage.featuresSection}${rank}/`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_FEATURES_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_SOCIAL_MEDIA_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const social_media_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_SOCIAL_MEDIA_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      `${config.homePage.socialMediaSection}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_SOCIAL_MEDIA_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_social_media_section_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_SOCIAL_MEDIA_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.socialMediaSection}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_SOCIAL_MEDIA_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};



export const update_gallery_section_heading_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_GALLERY_HEADING_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      `${config.homePage.galleryHeading}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_GALLERY_HEADING_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_GALLERY_HEADING_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_gallery_section_heading_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_GALLERY_SECTION_HEADING_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.galleryHeading}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_GALLERY_SECTION_HEADING_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_GALLERY_SECTION_HEADING_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const post_gallery_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.POST_GALLERY_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.put(
      `${config.homePage.galleryHeading}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.POST_GALLERY_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.POST_GALLERY_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const update_gallery_section_action = (rank, data) => async (dispatch) => {

  dispatch({
    type: homePageConstants.UPDATE_GALLERY_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.patch(
      `${config.homePage.galleryHeading}${rank}/`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_GALLERY_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_GALLERY_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_gallery_section_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_GALLERY_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.galleryHeading}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_GALLERY_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_GALLERY_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};




export const update_app_section_action = (data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.UPDATE_APP_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(
      `${config.homePage.appSection}`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.UPDATE_APP_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.UPDATE_APP_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};

export const get_app_section_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_APP_SECTION_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.appSection}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_APP_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_APP_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const post_category_section_action = (rank, data) => async (dispatch) => {
  dispatch({
    type: homePageConstants.POST_CATEGORY_SECTION_ACTION_REQUEST,
  });
  try {

    const response = await axiosInstance.post(
      `${config.homePage.postCategory}${rank}/`,
      data
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.POST_CATEGORY_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.POST_CATEGORY_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const get_category_section_action = (rank) => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_CATEGORY_SECTION_ACTION_REQUEST,
  });
  try {

    const response = await axiosInstance.get(
      `${config.homePage.postCategory}${rank}/`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_CATEGORY_SECTION_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_CATEGORY_SECTION_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};


export const get_recipe_category_titles_action = () => async (dispatch) => {
  dispatch({
    type: homePageConstants.GET_RECIPE_CATEGORY_TITLES_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      `${config.homePage.getRecipesCategoryTitles}`
    );
    if (response) {
      if (response.data) {
        dispatch({
          type: homePageConstants.GET_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS,
          payload: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    }
  } catch (e) {
    dispatch({
      type: homePageConstants.GET_RECIPE_CATEGORY_TITLES_ACTION_FAILURE,
      payload: e.msg,
    });
  }
};



export const update_recipe_category_title_action =
  (data, type, rank) => async (dispatch) => {
    dispatch({
      type: homePageConstants.POST_RECIPE_CATEGORY_TITLES_ACTION_REQUEST,
    });
    try {

      const response = await axiosInstance.put(
        `${config.homePage.postRecipeSectionTitle}`,
        data
      );
      if (response.status) {
        dispatch({
          type: homePageConstants.POST_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS,
          payload: response.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (e) {
      dispatch({
        type: homePageConstants.POST_RECIPE_CATEGORY_TITLES_ACTION_FAILURE,
        payload: e.msg,
      });
    }
  };