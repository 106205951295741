import React, { Component } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./UserLiveFeed.scss";
class UserLiveFeed extends Component {
  render() {
    return (
      <div className="live-stream user-live-feed bg-white">
        <Container>
          <a href="#" className="d-none d-md-flex align-items-center ">
            <img src="/assets/images/back-arrow.svg" alt="back" className="ml-n2" />
            <span className="text-dark fs-16 font-weight-bold ml-1">Back</span>
          </a>
          <Row className="justify-content-center  mt-md-4">
            <Col lg={10}>
              <div className="live-stream-content">
                {/* live stream header  */}
                <div className="live-stream-action d-flex align-items-center">
                  <div className="user-info d-flex align-items-center mr-2">
                    <div className="user-img mr-2">
                      <img
                        src="/assets/images/live-viewer-comment.svg"
                        alt="user img"
                        className="img-fit rounded-circle"
                      />
                    </div>
                    <p className="font-weight-bold mb-0">DariusCooks</p>
                  </div>
                  <span className="btn btn-primary rounded px-2 py-0 fs-14 font-weight-bold">
                    Live
                  </span>

                  <div className="right ml-auto d-flex align-items-center">
                    <span className="view-count d-flex align-items-center">
                      <img src="/assets/images/view-icon.svg" alt="" />
                      <span className="fs-14 font-weight-bold ml-2">3000</span>
                    </span>
                    <button className="p-0 border-0 bg-transparent ml-3">
                      <img src="/assets/images/close-icon.svg" alt="" />
                    </button>
                  </div>
                </div>
                {/* live stream header  */}

                {/* video part  */}
                <div className="live-stream-video mt-3">
                  <img
                    src="/assets/images/live-stream.svg"
                    alt="live-stream-video"
                    className="img-fit img-fluid w-100"
                  />
                </div>

                {/* video part  */}

                <Row>
                  <Col lg={9}>
                    {/* comment section */}
                    <div className="viewers-comments mt-4">
                      <div className="viewers-comment-content d-flex align-items-center mb-3">
                        <div className="viewers-comment-image">
                          <img
                            src="/assets/images/live-viewer-comment.svg"
                            alt=""
                            className="user-image rounded-circle img-fit"
                          />
                        </div>
                        <div className="viewers-comment-content">
                          <h6>User Name</h6>
                          <p className="font-smallest font-weight-bold text-dark m-0">
                            Amet minim mollit non
                          </p>
                        </div>
                      </div>
                      <div className="viewers-comment-content d-flex align-items-center mb-3">
                        <div className="viewers-comment-image">
                          <img
                            src="/assets/images/live-viewer-comment.svg"
                            alt=""
                            className="user-image img-fit"
                          />
                        </div>
                        <div className="viewers-comment-content">
                          <h6>User Name</h6>
                          <p className="font-smallest font-weight-bold text-dark m-0">
                            Amet minim mollit non
                          </p>
                        </div>
                      </div>
                      <div className="viewers-comment-content d-flex align-items-center mb-3">
                        <div className="viewers-comment-image">
                          <img
                            src="/assets/images/live-viewer-comment.svg"
                            alt=""
                            className="user-image img-fit"
                          />
                        </div>
                        <div className="viewers-comment-content">
                          <h6>User Name</h6>
                          <p className="font-smallest font-weight-bold text-dark m-0">
                            Amet minim mollit non
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* comment section */}
                    <div className="d-flex align-items-center mt-5">
                      <div className="form-group mb-0 flex-grow-1 ">
                        <input
                          type="text"
                          className="form-control rounded-pill  border-dark bg-transparent"
                          placeholder="Comments"
                          style={{ height: "45px" }}
                        />
                      </div>
                      <button className="btn">
                        <span className="fa fa-heart-o fs-25 ml-2"></span>
                        <span className="fa fa-heart fs-25 ml-2 text-primary"></span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default UserLiveFeed;
