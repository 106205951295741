import { recipeConstants } from "../../_constants/User";

const initialState = {};
export const get_recipe_content_reducer = (state = initialState, action) => {
  switch (action.type) {
    case recipeConstants.GET_RECIPE_CONTENT_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case recipeConstants.GET_RECIPE_BY_ID_ACTION_SUCCESS:
    case recipeConstants.GET_RECIPE_CONTENT_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case recipeConstants.GET_RECIPE_CONTENT_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};

export const get_all_user_recipes_reducer = (state = initialState, action) => {
  switch (action.type) {
    case recipeConstants.GET_ALL_RECIPE_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case recipeConstants.GET_ALL_RECIPE_ACTION_SUCCESS:
      return {
        type: "alert-success",
        data: action.payload,
      };
    case recipeConstants.GET_ALL_RECIPE_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};
