import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";
import { showConstants } from "../../_constants/User/show.constants";

export const get_all_user_shows_action = (page) => async(dispatch) => {
  
    dispatch({
        type: showConstants.GET_ALL_SHOW_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userHeaderSearch.showSearchResult.replace(":page", page))
        if (response.status) {
            dispatch({
                type: showConstants.GET_ALL_SHOW_ACTION_SUCCESS,
                payload: response && response.data
            })
            return response && response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: showConstants.GET_ALL_SHOW_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_show_details_actions = (id) => async(dispatch) => {
    dispatch({
        type: showConstants.GET_SHOW_DETAILS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.userShow.singleShowDetails}${id}/`)
        if (response.status) {
            dispatch({
                type: showConstants.GET_SHOW_DETAILS_ACTION_SUCCESS,
                payload: response && response.data
            })
            return response && response.data && response.data.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: showConstants.GET_SHOW_DETAILS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}