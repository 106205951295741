import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Modal, ProgressBar } from "react-bootstrap";

import "./HomepageContent.scss";
import ContentModal from "./ContentModal";
import TopTab from "../../common/TopTab/TopTab";
import SearchBar from "../../common/SearchBar/SearchBar";
import AdminSidebarMenu from "../../common/SidebarMenu/AdminSidebarMenu";
import {
  add_features_section_action,
  add_heading_top_right_homepage_action,
  get_expert_section_action,
  get_features_section_action,
  get_top_left_homepage_action,
  get_top_right_homepage_action,
  update_expert_section_action,
  update_features_section_action,
  update_top_left_homepage_action,
  update_top_right_homepage_action,
  social_media_section_action,
  get_social_media_section_action,
  get_gallery_section_heading_action,
  update_gallery_section_heading_action,
  update_gallery_section_action,
  get_gallery_section_action,
  update_app_section_action,
  get_app_section_action,
  post_gallery_section_action,
  post_features_section_action,
  update_recipe_category_title_action,
} from "../../../../_actions/Admin";

import {
  create_mux_url_action,
  get_playback_asset_id_action,
} from "../../../../_actions/Admin/uploadVideo.action";
import { isEmptyObject, validateUrl } from "../../../../_helpers/validators";
import {
  get_all_content_action,
  get_all_content_with_filter_action,
} from "../../../../_actions/Admin/carousel.action";
import swal from "sweetalert";
import { LoaderBasic } from "../../common/Loader";
import { TranslationOutlined } from "@ant-design/icons";
import RecipesSection from "./RecipesSection/RecipesSection";
import * as UpChunk from "@mux/upchunk";
class HomepageContent extends Component {
  state = {
    rank: 0,
    content: [],
    isApiLoading: true,
    isContentModal: false,
    pagination: {
      next: "",
      total: 0,
      current: 1,
      pageSize: 25,
      previous: "",
    },
    heading: "",
    errorText: "",
    labelText: "",
    modalType: "",
    coverImage: "",
    inputError: {},
    labelColor: "",
    expertDesc: "",
    expertVideo: {},
    expertVideoPreview: "",
    coverImageName: "",
    topLeftContent: {},
    topRightContent: [],
    coverImagePreview: "",
    isAddApiLoading: false,
    selectedItem: { isSelected: false },

    featureRank: 0,
    featuresDesc: "",
    liveFeatureDesc: "",
    liveFeatureTitle: "",
    showFeatureDesc: "",
    showFeatureTitle: "",
    videoFeatureDesc: "",
    videoFeatureTitle: "",
    shopFeatureDesc: "",
    shopFeatureTitle: "",

    preLiveFeatureDesc: "",
    preLiveFeatureTitle: "",
    preShowFeatureDesc: "",
    preShowFeatureTitle: "",
    preVideoFeatureDesc: "",
    preVideoFeatureTitle: "",
    preShopFeatureDesc: "",
    preShopFeatureTitle: "",

    featureRankOne: null,
    featureRankTwo: null,
    featureRankThree: null,
    featureRankFour: null,

    socialMediaDesc: null,
    facebookLink: null,
    instagramLink: null,
    TwitterLink: null,
    YoutubeLink: null,
    galleryDesc: "",
    gallery: null,

    rankOneImg: null,
    rankTwoImg: null,
    rankThreeImg: null,
    rankFourImg: null,
    rankFiveImg: null,

    appDesc: null,
    playstoreLink: null,
    appstoreLink: null,
    appPhoto: null,

    isLoadingGallery: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },

    isLoadingLeftRecipes: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
    },
    isAppUpdateLoading: false,
    isExpertVideoLoading: false,

    isLeftRecipeLoading: false,
    leftRecipeActive: false,
    rightRecipeActive: false,
    searchKey: "",
    authenticatedId: "",
    authenticatedUploadUrl: "",
    muxAssetId: "",
    muxAssetPlayebackId: "",
    uploadedStatus: null,
    progressModal: false,
    expertVideoName: "",
  };

  componentDidMount() {
    this.callTopLeftApi();
    this.callTopRightApi();
    this.callExpertApi();
    this.callFeaturesApi();
    this.callSocialMediaApi();
    this.callGalleryHeadingApi();
    this.getGallerySectionHandler();
    this.getAppSectionHandler();
    this.callGetUploadUrlApi();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.featuresSection !== this.props.featuresSection) {
      if (
        this.props.featuresSection.features &&
        this.props.featuresSection.features.length
      ) {
        let liveFeatureDesc = "";
        let liveFeatureTitle = "";
        let showFeatureDesc = "";
        let showFeatureTitle = "";
        let videoFeatureDesc = "";
        let videoFeatureTitle = "";
        let shopFeatureDesc = "";
        let shopFeatureTitle = "";
        let featureRankOne = null;
        let featureRankTwo = null;
        let featureRankThree = null;
        let featureRankFour = null;
        this.props.featuresSection.features.forEach((feature) => {
          if (feature.rank === 1) {
            liveFeatureTitle = feature.title;
            liveFeatureDesc = feature.description;
            featureRankOne = feature.rank;
          } else if (feature.rank === 2) {
            showFeatureTitle = feature.title;
            showFeatureDesc = feature.description;
            featureRankTwo = feature.rank;
          } else if (feature.rank === 3) {
            videoFeatureTitle = feature.title;
            videoFeatureDesc = feature.description;
            featureRankThree = feature.rank;
          } else if (feature.rank === 4) {
            shopFeatureTitle = feature.title;
            shopFeatureDesc = feature.description;
            featureRankFour = feature.rank;
          }
        });
        this.setState({
          liveFeatureDesc: liveFeatureDesc,
          shopFeatureDesc: shopFeatureDesc,
          showFeatureDesc: showFeatureDesc,
          videoFeatureDesc: videoFeatureDesc,

          liveFeatureTitle: liveFeatureTitle,
          shopFeatureTitle: shopFeatureTitle,
          showFeatureTitle: showFeatureTitle,
          videoFeatureTitle: videoFeatureTitle,
          featureRankOne: featureRankOne,
          featureRankTwo: featureRankTwo,
          featureRankThree: featureRankThree,
          featureRankFour: featureRankFour,
        });
      }
    }

    if (prevProps.expertSection !== this.props.expertSection) {
      this.setState((prevState) => ({
        expertDesc: this.props.expertSection.content,
        expertVideoPreview:
          this.props &&
          this.props.expertSection &&
          this.props.expertSection.video_playback_id,
        expertVideoName: this.props.expertSection.video_file_name,
      }));
    }

    if (prevProps.socialMediaSection !== this.props.socialMediaSection) {
      this.setState((prevState) => ({
        socialMediaDesc: this.props.socialMediaSection.description,
        facebookLink: this.props.socialMediaSection.facebook_link,
        YoutubeLink: this.props.socialMediaSection.youtube_link,
        instagramLink: this.props.socialMediaSection.instagram_link,
        TwitterLink: this.props.socialMediaSection.twitter_link,
      }));
    }

    if (prevProps.gallerySection !== this.props.gallerySection) {
      let rank = this.props.gallerySection.rank;
      let imageUrl = this.props.gallerySection.image;

      if (rank === 1) {
        this.setState((prevState) => ({
          rankOneImg: imageUrl,
        }));
      }
      if (rank === 2) {
        this.setState((prevState) => ({
          rankTwoImg: imageUrl,
        }));
      }
      if (rank === 3) {
        this.setState((prevState) => ({
          rankThreeImg: imageUrl,
        }));
      }
      if (rank === 4) {
        this.setState((prevState) => ({
          rankFourImg: imageUrl,
        }));
      }
      if (rank === 5) {
        this.setState((prevState) => ({
          rankFiveImg: imageUrl,
        }));
      }
    }

    if (prevProps.appSection !== this.props.appSection) {
      this.setState((prevState) => ({
        appstoreLink: this.props.appSection.apple_store_link,
        appDesc: this.props.appSection.content,
        playstoreLink: this.props.appSection.google_store_link,
        appPhoto: this.props.appSection.image,
      }));
    }

    if (prevProps.allContentItems !== this.props.allContentItems) {
      const { leftRecipeActive } = this.state;
      if (leftRecipeActive) {

        this.setState((prevState) => ({
          content: this.props.allContentItems.results,
          pagination: {
            ...prevState.pagination,
            total: this.props.allContentItems.count,
            next: this.props.allContentItems.next,
            previous: this.props.allContentItems.previous,
          },
          leftRecipeActive: false,
        }));
      } else {

        this.setState((prevState) => ({
          content: this.props.allContentItems.results.filter(
            (item) => item.content.content_type === "youtube-recipe"
          ),
          pagination: {
            ...prevState.pagination,
            total: this.props.allContentItems.count,
            next: this.props.allContentItems.next,
            previous: this.props.allContentItems.previous,
          },
        }));
      }
    }
    if (prevProps.updatedTopLeftSection !== this.props.updatedTopLeftSection) {
      if (
        this.props.updatedTopLeftSection.errorMessage &&
        this.props.updatedTopLeftSection.errorMessage.length
      ) {
        this.setState({
          errorText: this.props.updatedTopLeftSection.errorMessage,
        });
        return;
      }

      if (
        this.props.updatedTopLeftSection &&
        !isEmptyObject(this.props.updatedTopLeftSection.updatedTopLeftContent)
      ) {
        this.onCancelClickHandler();
        this.handleCloseModal();
      }
    }
    if (
      prevProps.homePageTopLeftContent !== this.props.homePageTopLeftContent
    ) {
      this.setState({ topLeftContent: this.props.homePageTopLeftContent });
    }
    if (
      prevProps.homePageTopRightContent !== this.props.homePageTopRightContent
    ) {
      this.setState({
        heading: this.props.homePageTopRightContent.heading.heading,
        topRightContent: this.props.homePageTopRightContent.recipes,
      });
    }
    if (
      prevProps.updatedTopRightSection !== this.props.updatedTopRightSection
    ) {
      if (
        this.props.updatedTopRightSection.errorMessage &&
        this.props.updatedTopRightSection.errorMessage.length
      ) {
        this.setState({
          errorText: this.props.updatedTopRightSection.errorMessage,
        });
        return;
      }

      if (
        this.props.updatedTopRightSection &&
        !isEmptyObject(this.props.updatedTopRightSection)
      ) {
        this.onCancelClickHandler();
        this.handleCloseModal();
      }
    }

    if (prevProps.uploadUrl !== this.props.uploadUrl) {
      if (
        !isEmptyObject(this.props.uploadUrl) &&
        this.props.uploadUrl.url &&
        this.props.uploadUrl.url.length
      )
        this.setState({
          authenticatedUploadUrl: this.props.uploadUrl.url,
          authenticatedId: this.props.uploadUrl.id,
        });
    }
    if (prevProps.muxAssetsData !== this.props.muxAssetsData) {
      if (
        !isEmptyObject(this.props.muxAssetsData) &&
        this.props.muxAssetsData.asset_id &&
        this.props.muxAssetsData.playback_ids &&
        this.props.muxAssetsData.playback_ids.length
      ) {
        this.setState(
          {
            muxAssetId: this.props.muxAssetsData.asset_id,
            muxAssetPlayebackId: this.props.muxAssetsData.playback_ids[0].id,
          },
          () => {
            this.uploadExpertVideo();
          }
        );
      }
    }
  }
  callGetUploadUrlApi = async () => {
    await this.props.getUploadUrl();
  };
  getMuxAssetData = async () => {
    await this.props.onGetMuxAssetData(this.state.authenticatedId);
  };

  uploadVideoToMux = () => {
    this.setState({ progressModal: true });
    const data = {
      endpoint: this.state.authenticatedUploadUrl,
      file: this.state.expertVideo,
      chunkSize: 5120,
    };

    const upload = UpChunk.createUpload({
      ...data,
    });

    // subscribe to events
    upload.on("error", (err) => {
      console.error("💥 🙀", err.detail);
    });

    upload.on("progress", (progress) => {
      this.setState({
        uploadedStatus: progress.detail,
      });
    });

    // subscribe to events
    upload.on("success", (data) => {
      this.getMuxAssetData();
      swal({
        title: "Video Uploaded Successfully!",
        // text: "You clicked the button!",
        icon: "success",
      }).then(() => {
        this.setState({
          progressModal: false,
        });
      });
    });
  };

  handleSelectItemHandler = (e, item) => {

    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      selectedItem: {
        isSelected:
          item.id === prevState.selectedItem.id &&
            prevState.selectedItem.isSelected
            ? false
            : true,
        ...item,
      },

    }));
  };

  handleCloseModal = () => {
    this.setState({
      isContentModal: false,
      selectedItem: { isSelected: false },
      searchKey: "",
    });
  };

  handleAddContentClickHandler = (rank, modalType) => {
    this.setState({ content: [] })
    if (modalType === "left") {
      this.setState({ leftRecipeActive: true });
    }

    this.setState({ isContentModal: true, rank, modalType }, () => {
      this.callContentApi(rank);
    });
  };

  callContentApi = async (rank) => {
    await this.props.getAllContentItems();
    this.setState({ isApiLoading: false });
  };

  //Handle change for image input
  handleCoverImageChange = async (e) => {
    const file = e.target.files[0];
    await this.setState((prevState) => ({
      coverImage: file ? file : prevState.coverImage,
      coverImageName: file && file.name ? file.name : prevState.coverImageName,
      coverImagePreview: file
        ? URL.createObjectURL(file)
        : prevState.coverImagePreview,
    }));
  };

  //Handle label color and text
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onFileChange = (e) => {
    // Update the state
    this.setState(
      {
        expertVideo: e.target.files[0],
        expertVideoName: e.target.files[0].name,
      },
      () => {
        this.uploadVideoToMux();
      }
    );
  };
  uploadExpertVideo = async () => {
    const { expertDesc, expertVideo, expertVideoName, expertVideoPreview } =
      this.state;
    const formData = new FormData();
    formData.append("video_file_name", expertVideoName);
    formData.append("video_asset_id", this.state.muxAssetId);
    formData.append("video_playback_id", this.state.muxAssetPlayebackId);
    this.setState({ isExpertVideoLoading: true });
    await this.props.updateExpertSection(formData);
    this.setState({ isExpertVideoLoading: false });
  };

  onExpertVideoBlur = async () => {
    const { expertDesc, expertVideo, expertVideoName, expertVideoPreview } =
      this.state;

    const formData = new FormData();
    formData.append("content", expertDesc);
    this.setState({ isExpertVideoLoading: true });
    await this.props.updateExpertSection(formData);
    this.setState({ isExpertVideoLoading: false });
  };
  onFeatureHandleChange = (name, value, rank) => {
    this.setState({ [name]: value, featureRank: rank });
  };

  onAddClickHandler = async (type) => {
    const { rank, selectedItem, topRightContent, coverImage } = this.state;

    if (type === "left") {
      this.setState({ isAddApiLoading: true });
      let handleSubmit = async (Item, coverImg) => {
        let ItemType = Item.content_type;
        let ItemId = Item.id;
        const detailsForm = new FormData();
        detailsForm.append("content_type", ItemType === "youtube-recipe" ? "youtube_recipe" : ItemType);
        detailsForm.append("video", ItemType === "video" ? ItemId : "");
        detailsForm.append("youtube_recipe", ItemType === "youtube-recipe" ? ItemId : "");
        detailsForm.append("show", ItemType === "show" ? ItemId : "");
        detailsForm.append("episode", ItemType === "episode" ? ItemId : "");

        if (typeof coverImg !== "string") {
          detailsForm.append("cover_image", coverImg);
        }
        else {
          if (typeof coverImg === "string") {
            detailsForm.append("cover_image", "");
          }
        }
        // const data = {
        //   content_type:
        //     ItemType === "youtube-recipe" ? "youtube_recipe" : ItemType,
        //   video: ItemType === "video" ? ItemId : null,
        //   youtube_recipe: ItemType === "youtube-recipe" ? ItemId : null,
        //   show: ItemType === "show" ? ItemId : null,
        //   episode: ItemType === "episode" ? ItemId : null,

        // };

        this.setState({ isLeftRecipeLoading: true });

        await this.props.updateTopLeftHomePage(detailsForm);


        this.setState({ isAddApiLoading: false }, () => {
          this.callTopLeftApi();
          this.setState({ isContentModal: false });
        });
        setTimeout(() => {
          this.setState({ isLeftRecipeLoading: false, coverImage: "" });
        }, 2000);
      };
      handleSubmit(selectedItem, coverImage);

      return;
    }
    if (type === "right") {
      // let data = {
      //   youtube_recipe: selectedItem.id,
      // };

      const detailsForm = new FormData();
      detailsForm.append("youtube_recipe", selectedItem.id);

      if (typeof coverImage !== "string") {
        detailsForm.append("cover_image", coverImage);
      }

      if (typeof coverImage === "string") {
        detailsForm.append("cover_image", "");
      }


      if (topRightContent.length && topRightContent[rank - 1]) {
        this.setState({
          isLoadingLeftRecipes: {
            [rank]: true,
          },
        });
        await this.props.updateTopRightHomePage(detailsForm, "put", rank);
        setTimeout(() => {
          this.setState({
            isLoadingLeftRecipes: {
              [rank]: false,
              coverImage: ""
            },
          });
        }, 2000);
      } else {

        const detailsForm = new FormData();
        detailsForm.append("rank", rank);

        await this.props.updateTopRightHomePage(detailsForm, "post");
        setTimeout(() => {
          this.setState({
            isLoadingLeftRecipes: {
              [rank]: false,
              coverImage: ""
            },
          });
        }, 2000);
      }
      this.setState({ isAddApiLoading: false, coverImage: "" }, () => {
        this.callTopRightApi();
      });
      return;
    }
  };

  onCancelClickHandler = () => {
    this.setState({
      labelText: "",
      coverImage: {},
      inputError: {},
      labelColor: "",
      coverImageName: "",
      coverImagePreview: "",
      selectedItem: { isSelected: false },
    });
  };

  callTopLeftApi = async () => {
    await this.props.getHomepageTopLeftContent();
  };

  callTopRightApi = async () => {
    await this.props.getHomepageTopRightContent();
  };

  callExpertApi = async () => {
    await this.props.getExpertSection();
  };

  callFeaturesApi = async () => {
    let data = await this.props.getFeaturesSection();
    let feature = data && data.data && data.data.features;
    let rankOneItem =
      feature &&
      feature.filter((item) => item.rank === 1) &&
      feature.filter((item) => item.rank === 1)[0];
    let rankTwoItem =
      feature &&
      feature.filter((item) => item.rank === 2) &&
      feature.filter((item) => item.rank === 2)[0];
    let rankThreeItem =
      feature &&
      feature.filter((item) => item.rank === 3) &&
      feature.filter((item) => item.rank === 3)[0];
    let rankFourItem =
      feature &&
      feature.filter((item) => item.rank === 4) &&
      feature.filter((item) => item.rank === 4)[0];

    this.setState({
      featureRankOne: rankOneItem && rankOneItem.rank,
      liveFeatureTitle: rankOneItem && rankOneItem.title,
      liveFeatureDesc: rankOneItem && rankOneItem.description,
      featureRankTwo: rankTwoItem && rankTwoItem.rank,
      showFeatureTitle: rankTwoItem && rankTwoItem.title,
      showFeatureDesc: rankTwoItem && rankTwoItem.description,
      featureRankThree: rankThreeItem && rankThreeItem.rank,
      videoFeatureTitle: rankThreeItem && rankThreeItem.title,
      videoFeatureDesc: rankThreeItem && rankThreeItem.description,
      featureRankFour: rankFourItem && rankFourItem.rank,
      shopFeatureTitle: rankFourItem && rankFourItem.title,
      shopFeatureDesc: rankFourItem && rankFourItem.description,
      featuresDesc: data && data.data && data.data.description,
    });
  };
  callSocialMediaApi = async () => {
    await this.props.getSocialMediaSection();
  };
  callGalleryHeadingApi = async () => {
    let data = await this.props.getGallerySectionHeading();

    this.setState({
      galleryDesc: data && data.data && data.data.text,
    });
  };
  addHeadingToTopRight = async () => {
    const { heading } = this.state;
    await this.props.addHeadingTopRight({ heading });
  };

  onExpertDescriptionBlur = async () => {
    const { expertDesc } = this.state;
    const data = {
      content: expertDesc,
    };
    await this.props.updateExpertSection(data);
  };

  onFeaturesDescBlur = async () => {
    const { featuresDesc } = this.state;
    const data = {
      description: featuresDesc,
    };
    await this.props.addFeaturesSection(data);
  };

  onLiveFeatureBlur = async () => {
    const { featureRankOne, featureRank, liveFeatureDesc, liveFeatureTitle } =
      this.state;

    if (liveFeatureDesc !== "" || liveFeatureTitle !== "") {
      if (!featureRankOne) {
        const data = {
          rank: featureRank,
          title: liveFeatureTitle,
          description: liveFeatureDesc,
        };
        await this.props.postFeaturesSection(data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      } else {
        const data = {
          title: liveFeatureTitle,
          description: liveFeatureDesc,
        };
        await this.props.updateFeaturesSection(featureRank, data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      }
    } else {
      swal({
        title: "Oops!",
        text: "please fill all fields",
        icon: "error",
      });
    }
  };

  onShowFeatureBlur = async () => {
    const { featureRankTwo, featureRank, showFeatureDesc, showFeatureTitle } =
      this.state;
    if (showFeatureDesc !== "" || showFeatureTitle !== "") {
      if (!featureRankTwo) {
        const data = {
          rank: featureRank,
          title: showFeatureTitle,
          description: showFeatureDesc,
        };
        await this.props.postFeaturesSection(data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      } else {
        const data = {
          title: showFeatureTitle,
          description: showFeatureDesc,
        };
        await this.props.updateFeaturesSection(featureRank, data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      }
    } else {
      swal({
        title: "Oops!",
        text: "please fill all fields",
        icon: "error",
      });
    }
  };

  onVideoFeatureBlur = async () => {
    const {
      featureRankThree,
      featureRank,
      videoFeatureDesc,
      videoFeatureTitle,
    } = this.state;

    if (videoFeatureDesc !== "" || videoFeatureTitle !== "") {
      if (!featureRankThree) {
        const data = {
          rank: featureRank,
          title: videoFeatureTitle,
          description: videoFeatureDesc,
        };
        await this.props.postFeaturesSection(data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      } else {
        const data = {
          title: videoFeatureTitle,
          description: videoFeatureDesc,
        };
        await this.props.updateFeaturesSection(featureRank, data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      }
    } else {
      swal({
        title: "Oops!",
        text: "please fill all fields",
        icon: "error",
      });
    }
  };

  onShopFeatureBlur = async () => {
    const { featureRankFour, featureRank, shopFeatureDesc, shopFeatureTitle } =
      this.state;

    if (shopFeatureDesc !== "" || shopFeatureTitle !== "") {
      if (!featureRankFour) {
        const data = {
          rank: featureRank,
          title: shopFeatureTitle,
          description: shopFeatureDesc,
        };
        await this.props.postFeaturesSection(data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      } else {
        const data = {
          title: shopFeatureTitle,
          description: shopFeatureDesc,
        };
        await this.props.updateFeaturesSection(featureRank, data);
        swal({
          title: "Hurray!",
          text: "Feature Updated",
          icon: "success",
        });
      }
    } else {
      swal({
        title: "Oops!",
        text: "please fill all fields",
        icon: "error",
      });
    }
  };

  // social media handler
  socialMediaUpateHandler = async (updateType) => {
    let {
      socialMediaDesc,
      facebookLink,
      YoutubeLink,
      TwitterLink,
      instagramLink,
    } = this.state;

    let isfacebookUrlTrue = validateUrl(facebookLink);
    let isYoutubeUrlTrue = validateUrl(YoutubeLink);
    let isTwitterUrlTrue = validateUrl(TwitterLink);
    let isInstagramUrlTrue = validateUrl(instagramLink);

    if (updateType === "description") {
      if (socialMediaDesc !== null) {
        let data = {
          description: socialMediaDesc,
        };

        await this.props.updateSocialMediaSection(data);
      }
    }

    if (updateType === "facebook") {
      if (isfacebookUrlTrue && facebookLink !== null) {
        let data = {
          facebook_link: facebookLink,
        };
        await this.props.updateSocialMediaSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid facebook url",
          icon: "error",
        });

        return;
      }
    }
    if (updateType === "youtube") {
      if (isYoutubeUrlTrue && YoutubeLink !== null) {
        let data = {
          youtube_link: YoutubeLink,
        };
        await this.props.updateSocialMediaSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid youtube url",
          icon: "error",
        });

        return;
      }
    }

    if (updateType === "instagram") {
      if (isInstagramUrlTrue && instagramLink !== null) {
        let data = {
          instagram_link: instagramLink,
        };
        await this.props.updateSocialMediaSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid instagram url",
          icon: "error",
        });

        return;
      }
    }

    if (updateType === "twitter") {
      if (isTwitterUrlTrue && TwitterLink !== null) {
        let data = {
          twitter_link: TwitterLink,
        };
        await this.props.updateSocialMediaSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid twitter url",
          icon: "error",
        });

        return;
      }
    }
  };
  galleryDescriptionUpateHandler = async () => {
    let { galleryDesc } = this.state;
    let data = {
      text: galleryDesc,
    };
    await this.props.updateGallerySectionHeading(data);
  };
  getGallerySectionHandler = async () => {
    let data = await this.props.getGallerySection();
    let gallery = data && data.data && data.data.images;
    let rankOneImg =
      gallery &&
      gallery.filter((images) => images.rank === 1) &&
      gallery.filter((images) => images.rank === 1)[0] &&
      gallery.filter((images) => images.rank === 1)[0].image;
    let rankTwoImg =
      gallery &&
      gallery.filter((images) => images.rank === 2) &&
      gallery.filter((images) => images.rank === 2)[0] &&
      gallery.filter((images) => images.rank === 2)[0].image;
    let rankThreeImg =
      gallery &&
      gallery.filter((images) => images.rank === 3) &&
      gallery.filter((images) => images.rank === 3)[0] &&
      gallery.filter((images) => images.rank === 3)[0].image;
    let rankFourImg =
      gallery &&
      gallery.filter((images) => images.rank === 4) &&
      gallery.filter((images) => images.rank === 4)[0] &&
      gallery.filter((images) => images.rank === 4)[0].image;
    let rankFiveImg =
      gallery &&
      gallery.filter((images) => images.rank === 5) &&
      gallery.filter((images) => images.rank === 5)[0] &&
      gallery.filter((images) => images.rank === 5)[0].image;

    this.setState({
      rankOneImg: rankOneImg,
      rankTwoImg: rankTwoImg,
      rankThreeImg: rankThreeImg,
      rankFourImg: rankFourImg,
      rankFiveImg: rankFiveImg,
    });
  };
  galleryPostHandler = async (rank, e) => {
    let files = e.target.files;
    let formData = new FormData();
    formData.append("image", files[0]);
    formData.append("rank", rank);
    this.setState({
      isLoadingGallery: {
        ...this.state.isLoadingGallery,
        [rank]: true,
      },
    });
    await this.props.postGallerySection(formData);
    this.setState({
      isLoadingGallery: {
        ...this.state.isLoadingGallery,
        [rank]: false,
      },
    });
  };

  galleryUpdateHandler = async (rank, e) => {
    let files = e.target.files;
    let formData = new FormData();
    formData.append("image", files[0]);
    this.setState({
      isLoadingGallery: {
        ...this.state.isLoadingGallery,
        [rank]: true,
      },
    });
    this.setState({
      isLoadingGallery: {
        ...this.state.isLoadingGallery,
        [rank]: true,
      },
    });
    await this.props.updateGallerySection(rank, formData);
    this.setState({
      isLoadingGallery: {
        ...this.state.isLoadingGallery,
        [rank]: false,
      },
    });
  };

  getAppSectionHandler = async () => {
    let data = await this.props.getAppSection();
  };
  appUpdateHandler = async (updateType, e) => {
    let { appDesc, playstoreLink, appstoreLink } = this.state;

    let isPlaystoreUrlTrue = validateUrl(playstoreLink);
    let isAppstoreUrlTrue = validateUrl(appstoreLink);

    if (updateType === "photo") {
      let files = e.target.files;
      let formData = new FormData();
      formData.append("image", files[0]);
      this.setState({ isAppUpdateLoading: true });
      await this.props.updateAppSection(formData);
      this.setState({ isAppUpdateLoading: false });
    }

    if (updateType === "description") {
      if (appDesc !== null) {
        let data = {
          content: appDesc,
        };

        await this.props.updateAppSection(data);
      }
    }

    if (updateType === "playstore") {
      if (isPlaystoreUrlTrue && playstoreLink !== null) {
        let data = {
          google_store_link: playstoreLink,
        };
        await this.props.updateAppSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid playstore url",
          icon: "error",
        });

        return;
      }
    }
    if (updateType === "appstore") {
      if (isAppstoreUrlTrue && appstoreLink !== null) {
        let data = {
          apple_store_link: appstoreLink,
        };
        await this.props.updateAppSection(data);
      } else {
        swal({
          title: "Oops!",
          text: "please enter valid appstore url",
          icon: "error",
        });
        return;
      }
    }
  };

  handlePagination = async (page) => {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, current: page },
      }),
      async () => {
        const { pagination } = this.state;
        this.setState({ isApiLoading: true });
        await this.props.getAllContentItems(pagination.current);
        this.setState({ isApiLoading: false });
      }
    );
  };

  // search handler ================

  searchContentHandler = async (e) => {
    const { modalType } = this.state;
    this.setState({ searchKey: e.target.value });
    let searchKey = e.target.value;
    this.setState({ isApiLoading: true });
    await this.props.searchContent({ search: searchKey });
    this.setState({ isApiLoading: false });
    if (modalType === "left") {
      this.setState({ leftRecipeActive: true });
    }
  };

  render() {
    const {
      content,
      heading,
      labelText,
      coverImage,
      labelColor,
      modalType,
      expertDesc,
      selectedItem,
      isApiLoading,
      isContentModal,
      coverImageName,
      topLeftContent,
      topRightContent,
      coverImagePreview,
      expertVideoPreview,

      featuresDesc,
      liveFeatureDesc,
      shopFeatureDesc,
      showFeatureDesc,
      liveFeatureTitle,
      shopFeatureTitle,
      showFeatureTitle,
      videoFeatureDesc,
      videoFeatureTitle,

      galleryDesc,
      rankOneImg,
      rankTwoImg,
      rankThreeImg,
      rankFourImg,
      rankFiveImg,

      playstoreLink,
      appstoreLink,
      appDesc,
      appPhoto,
      isLoadingGallery,
      isAppUpdateLoading,
      isExpertVideoLoading,
      isLoadingLeftRecipes,
      isLeftRecipeLoading,
      pagination,
      expertVideoName,
      socialMediaDesc,
      facebookLink,
      instagramLink,
      TwitterLink,
      YoutubeLink,
    } = this.state;
    let rankOneItem =
      topRightContent && topRightContent.filter((item) => item.rank === 1);
    let rankTwoItem =
      topRightContent && topRightContent.filter((item) => item.rank === 2);
    let rankThreeItem =
      topRightContent && topRightContent.filter((item) => item.rank === 3);
    let rankFourItem =
      topRightContent && topRightContent.filter((item) => item.rank === 4);
    let rankFiveItem =
      topRightContent && topRightContent.filter((item) => item.rank === 5);
    let rankSixItem =
      topRightContent && topRightContent.filter((item) => item.rank === 6);
    let rankSevenItem =
      topRightContent && topRightContent.filter((item) => item.rank === 7);
    let rankEightItem =
      topRightContent && topRightContent.filter((item) => item.rank === 8);

    return (
      <div className="content-management admin-home-dashboard common-dashboard">
        <Container className="p-0">
          <AdminSidebarMenu activeTab="Home" />
          <TopTab activeTab="Homepage" tabCategoryName="contentManagment" />
          <div className="main-content  bg-white pt-5">
            <div className="homepage-content-wrapper">
              {/* display recipes  */}
              <div className="display-recipes">
                <div className="row mx-n2">
                  <div className="col-lg-6 px-2 mb-4 mb-lg-0">
                    <div className="display-recipe bg-grey-2 border-radius-20 position-relative">
                      {/* item image ================= */}

                      {topLeftContent &&
                        topLeftContent.cover_image ? (
                        <img
                          src={
                            topLeftContent &&
                            topLeftContent.cover_image
                          }
                          alt=""
                        />
                      ) : (<>

                        {/* for recipe  */}
                        {topLeftContent &&
                          topLeftContent.youtube_recipe_details &&
                          topLeftContent.youtube_recipe_details.title_image ? (
                          <img
                            src={
                              topLeftContent.youtube_recipe_details.title_image
                            }
                            alt=""
                          />
                        ) : null}



                        {/* for video = */}
                        {topLeftContent &&
                          topLeftContent.video_details &&
                          topLeftContent.video_details.title_image ? (
                          <img
                            src={topLeftContent.video_details.title_image}
                            alt=""
                          />
                        ) : null}

                        {/* for show  */}
                        {topLeftContent &&
                          topLeftContent.show_details &&
                          topLeftContent.show_details.title_image ? (
                          <img
                            src={topLeftContent.show_details.title_image}
                            alt=""
                          />
                        ) : null}

                        {/* for episode  */}

                        {topLeftContent &&
                          topLeftContent.episode_details &&
                          topLeftContent.episode_details.title_image ? (
                          <img
                            src={topLeftContent.episode_details.title_image}
                            alt=""
                          />
                        ) : null}
                        {/* item image end ======= */}

                      </>)}

                      {/* Item Title   */}
                      {topLeftContent &&
                        topLeftContent.youtube_recipe_details ? (
                        <>
                          <input
                            disabled
                            type="text"
                            className={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.youtube_recipe_details
                                ? "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36 active"
                                : "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36"
                            }
                            value={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.youtube_recipe_details
                                ? topLeftContent.youtube_recipe_details.title
                                : "Recipe Name"
                            }
                          />
                        </>
                      ) : null}

                      {topLeftContent && topLeftContent.video_details ? (
                        <>
                          <input
                            disabled
                            type="text"
                            className={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.video_details
                                ? "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36 active"
                                : "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36"
                            }
                            value={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.video_details
                                ? topLeftContent.video_details.title
                                : "Recipe Name"
                            }
                          />
                        </>
                      ) : null}

                      {topLeftContent && topLeftContent.show_details ? (
                        <>
                          <input
                            disabled
                            type="text"
                            className={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.show_details
                                ? "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36 active"
                                : "form-control w-100  font-weight-semi title-edit position-absolute bg-transparent border-0 fs-36"
                            }
                            value={
                              !isEmptyObject(topLeftContent) &&
                                topLeftContent.show_details
                                ? topLeftContent.show_details.title
                                : "Recipe Name"
                            }
                          />
                        </>
                      ) : null}

                      {isLeftRecipeLoading ? (
                        <LoaderBasic
                          styles={{
                            paddingBottom: "30px",
                            paddingRight: "30px",
                          }}
                        />
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            this.handleAddContentClickHandler(0, "left")
                          }
                          className="bg-white rounded position-absolute btn btn-outline-info px-2"
                        >
                          Add Content
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6  px-2">
                    <div className="display-recipes-lists">
                      <input
                        type="text"
                        value={heading}
                        placeholder="Recipe Title"
                        onBlur={this.addHeadingToTopRight}
                        onChange={(e) =>
                          this.handleChange("heading", e.target.value)
                        }
                        className="form-control w-100 text-center  font-weight-semi border-0 fs-36"
                      />
                      <div className="row mx-n2">
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">

                            {rankOneItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankOneItem[0] &&
                              rankOneItem[0].cover_image ? (
                              <img
                                src={

                                  rankOneItem[0] &&
                                  rankOneItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankOneItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankOneItem[0].recipe_details &&
                                  rankOneItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankOneItem[0] &&
                                      rankOneItem[0].recipe_details &&
                                      rankOneItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}


                            <input
                              disabled
                              type="text"
                              className={
                                rankOneItem.length &&
                                  !isEmptyObject(rankOneItem[0]) &&
                                  rankOneItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankOneItem.length &&
                                  !isEmptyObject(rankOneItem[0]) &&
                                  rankOneItem[0].recipe_details
                                  ? rankOneItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />

                            {isLoadingLeftRecipes[1] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(1, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">


                            {rankTwoItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankTwoItem[0] &&
                              rankTwoItem[0].cover_image ? (
                              <img
                                src={
                                  rankTwoItem[0] &&
                                  rankTwoItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankTwoItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankTwoItem[0].recipe_details &&
                                  rankTwoItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankTwoItem[0] &&
                                      rankTwoItem[0].recipe_details &&
                                      rankTwoItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}

                            {/* {rankTwoItem.length &&
                              !isEmptyObject(rankTwoItem[0]) &&
                              rankTwoItem[0].recipe_details &&
                              rankTwoItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankTwoItem[0] &&
                                  rankTwoItem[0].recipe_details &&
                                  rankTwoItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}

                            <input
                              disabled
                              type="text"
                              className={
                                rankTwoItem.length &&
                                  !isEmptyObject(rankTwoItem[0]) &&
                                  rankTwoItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankTwoItem.length &&
                                  !isEmptyObject(rankTwoItem[0]) &&
                                  rankTwoItem[0].recipe_details
                                  ? rankTwoItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[2] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(2, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">

                            {rankThreeItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankThreeItem[0] &&
                              rankThreeItem[0].cover_image ? (
                              <img
                                src={

                                  rankThreeItem[0] &&
                                  rankThreeItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankThreeItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankThreeItem[0].recipe_details &&
                                  rankThreeItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankThreeItem[0] &&
                                      rankThreeItem[0].recipe_details &&
                                      rankThreeItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}



                            {/* {rankThreeItem.length &&
                              !isEmptyObject(rankThreeItem[0]) &&
                              rankThreeItem[0].recipe_details &&
                              rankThreeItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankThreeItem[0] &&
                                  rankThreeItem[0].recipe_details &&
                                  rankThreeItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankThreeItem.length &&
                                  !isEmptyObject(rankThreeItem[0]) &&
                                  rankThreeItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankThreeItem.length &&
                                  !isEmptyObject(rankThreeItem[0]) &&
                                  rankThreeItem[0].recipe_details
                                  ? rankThreeItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[3] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(3, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">

                            {rankFourItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankFourItem[0] &&
                              rankFourItem[0].cover_image ? (
                              <img
                                src={

                                  rankFourItem[0] &&
                                  rankFourItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankFourItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankFourItem[0].recipe_details &&
                                  rankFourItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankFourItem[0] &&
                                      rankFourItem[0].recipe_details &&
                                      rankFourItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}


                            {/* {rankFourItem.length &&
                              !isEmptyObject(rankFourItem[0]) &&
                              rankFourItem[0].recipe_details &&
                              rankFourItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankFourItem[0] &&
                                  rankFourItem[0].recipe_details &&
                                  rankFourItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankFourItem.length &&
                                  !isEmptyObject(rankFourItem[0]) &&
                                  rankFourItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankFourItem.length &&
                                  !isEmptyObject(rankFourItem[0]) &&
                                  rankFourItem[0].recipe_details
                                  ? rankFourItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[4] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(4, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">

                            {rankFiveItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankFiveItem[0] &&
                              rankFiveItem[0].cover_image ? (
                              <img
                                src={

                                  rankFiveItem[0] &&
                                  rankFiveItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankFiveItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankFiveItem[0].recipe_details &&
                                  rankFiveItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankFiveItem[0] &&
                                      rankFiveItem[0].recipe_details &&
                                      rankFiveItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}

                            {/* {rankFiveItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankFiveItem[0].recipe_details &&
                              rankFiveItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankFiveItem[0] &&
                                  rankFiveItem[0].recipe_details &&
                                  rankFiveItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankFiveItem.length &&
                                  !isEmptyObject(rankFiveItem[0]) &&
                                  rankFiveItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankFiveItem.length &&
                                  !isEmptyObject(rankFiveItem[0]) &&
                                  rankFiveItem[0].recipe_details
                                  ? rankFiveItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[5] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(5, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">

                            {rankSixItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankSixItem[0] &&
                              rankSixItem[0].cover_image ? (
                              <img
                                src={
                                  rankSixItem[0] &&
                                  rankSixItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankSixItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankSixItem[0].recipe_details &&
                                  rankSixItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankSixItem[0] &&
                                      rankSixItem[0].recipe_details &&
                                      rankSixItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}

                            {/* {rankSixItem.length &&
                              !isEmptyObject(rankSixItem[0]) &&
                              rankSixItem[0].recipe_details &&
                              rankSixItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankSixItem[0] &&
                                  rankSixItem[0].recipe_details &&
                                  rankSixItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankSixItem.length &&
                                  !isEmptyObject(rankSixItem[0]) &&
                                  rankSixItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankSixItem.length &&
                                  !isEmptyObject(rankSixItem[0]) &&
                                  rankSixItem[0].recipe_details
                                  ? rankSixItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[6] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(6, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">
                            {rankSevenItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankSevenItem[0] &&
                              rankSevenItem[0].cover_image ? (
                              <img
                                src={
                                  rankSevenItem[0] &&
                                  rankSevenItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankSevenItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankSevenItem[0].recipe_details &&
                                  rankSevenItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankSevenItem[0] &&
                                      rankSevenItem[0].recipe_details &&
                                      rankSevenItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}


                            {/* {rankSevenItem.length &&
                              !isEmptyObject(rankSevenItem[0]) &&
                              rankSevenItem[0].recipe_details &&
                              rankSevenItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankSevenItem[0] &&
                                  rankSevenItem[0].recipe_details &&
                                  rankSevenItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankSevenItem.length &&
                                  !isEmptyObject(rankSevenItem[0]) &&
                                  rankSevenItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankSevenItem.length &&
                                  !isEmptyObject(rankSevenItem[0]) &&
                                  rankSevenItem[0].recipe_details
                                  ? rankSevenItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[7] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(7, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="display-recipe bg-grey-2 border-radius-20 position-relative">
                            {rankEightItem.length &&
                              !isEmptyObject(topRightContent[0]) &&
                              rankEightItem[0] &&
                              rankEightItem[0].cover_image ? (
                              <img
                                src={
                                  rankEightItem[0] &&
                                  rankEightItem[0].cover_image
                                }
                                alt=""
                              />
                            ) :
                              (<>
                                {rankEightItem.length &&
                                  !isEmptyObject(topRightContent[0]) &&
                                  rankEightItem[0].recipe_details &&
                                  rankEightItem[0].recipe_details.title_image ? (
                                  <img
                                    src={
                                      rankEightItem[0] &&
                                      rankEightItem[0].recipe_details &&
                                      rankEightItem[0].recipe_details.title_image
                                    }
                                    alt=""
                                  />
                                ) : null}
                              </>)}


                            {/* {rankEightItem.length &&
                              !isEmptyObject(rankEightItem[0]) &&
                              rankEightItem[0].recipe_details &&
                              rankEightItem[0].recipe_details.title_image ? (
                              <img
                                src={
                                  rankEightItem[0] &&
                                  rankEightItem[0].recipe_details &&
                                  rankEightItem[0].recipe_details.title_image
                                }
                                alt=""
                              />
                            ) : null} */}
                            <input
                              disabled
                              type="text"
                              className={
                                rankEightItem.length &&
                                  !isEmptyObject(rankEightItem[0]) &&
                                  rankEightItem[0].recipe_details
                                  ? "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20 active"
                                  : "form-control title-edit w-100 position-absolute  bg-transparent font-weight-semi border-0 fs-20"
                              }
                              value={
                                rankEightItem.length &&
                                  !isEmptyObject(rankEightItem[0]) &&
                                  rankEightItem[0].recipe_details
                                  ? rankEightItem[0].recipe_details.title
                                  : "Recipe Name"
                              }
                            />
                            {isLoadingLeftRecipes[8] ? (
                              <LoaderBasic
                                styles={{
                                  paddingBottom: "30px",
                                  paddingRight: "30px",
                                }}
                              />
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleAddContentClickHandler(8, "right")
                                }
                                className="bg-white rounded position-absolute btn btn-outline-info px-2"
                              >
                                Add Content
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* display recipes  Ends*/}

              <RecipesSection />
              {/* expert section starts  */}
              <div className="expert-section my-5">
                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <h2 className="mb-3">Expert</h2>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      value={expertDesc}
                      onBlur={this.onExpertDescriptionBlur}
                      placeholder="Add description"
                      className="form-control rounded bg-transparent"
                      onChange={(e) =>
                        this.handleChange("expertDesc", e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div className="col-lg-6">
                    <div className="expert-video border-radius-20 bg-grey-2 position-relative">
                      {expertVideoPreview && expertVideoPreview.length ? (
                        <img
                          src={`https://image.mux.com/${expertVideoPreview}/thumbnail.png?width=400&height=200&fit_mode=smartcrop&time=35`}
                          alt="preview"
                          className="h-100 w-100 img-fit"
                        />
                      ) : null}
                      <label
                        htmlFor="ExpertVideo"
                        className="bg-white btn-centered rounded position-absolute btn btn-outline-info px-2"
                      >
                        {isExpertVideoLoading ? (
                          <LoaderBasic
                            styles={{
                              paddingBottom: "30px",
                              paddingRight: "30px",
                            }}
                          />
                        ) : (
                          <input
                            type="file"
                            id="ExpertVideo"
                            className="d-none"
                            accept="video/*"
                            onChange={this.onFileChange}
                          />
                        )}
                        {expertVideoName ? expertVideoName : "Add Video"}
                      </label>

                      <Modal show={this.state.progressModal} centered>
                        <Modal.Header>
                          <Modal.Title>Upload Progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {this.state.uploadedStatus ? (
                            <div className=" text-center">
                              <ProgressBar
                                animated
                                now={this.state.uploadedStatus}
                                variant="success"
                                label={`${this.state.uploadedStatus.toFixed(
                                  2
                                )}%`}
                              />
                            </div>
                          ) : null}
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              {/* expert section ends  */}

              {/* app section starts  */}
              <div className="app-section my-5">
                <div className="row">
                  <div className="col-lg-7 order-2 order-lg-1">
                    <div className="app-photo border-radius-20 bg-grey-2 position-relative overflow-hidden">
                      {appPhoto ? (
                        <>
                          <img
                            src={appPhoto}
                            className="h-100 w-100 img-fit"
                            alt="App Image"
                          />
                        </>
                      ) : null}
                      {isAppUpdateLoading ? (
                        <LoaderBasic
                          styles={{
                            paddingBottom: "30px",
                            paddingRight: "30px",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="AppPhoto"
                          className="bg-white rounded position-absolute btn-centered btn btn-outline-info px-2"
                        >
                          <input
                            type="file"
                            id="AppPhoto"
                            onChange={(e) => {
                              this.appUpdateHandler("photo", e);
                            }}
                            className="d-none"
                          />
                          Add Photo
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5 order-1 order-lg-2 mb-4 mb-lg-0">
                    <h2 className="mb-3">App</h2>
                    <div className="form-group">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        value={appDesc}
                        onBlur={(e) => this.appUpdateHandler("description", e)}
                        onChange={(e) =>
                          this.handleChange("appDesc", e.target.value)
                        }
                        className="form-control bg-transparent rounded"
                        placeholder="Add description"
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <img
                          src={"../assets/images/play-store-btn.png"}
                          alt="apple store"
                        />
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Playstore link"
                            value={appstoreLink}
                            onBlur={(e) => this.appUpdateHandler("appstore")}
                            onChange={(e) =>
                              this.handleChange("appstoreLink", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <img
                          src={"/assets/images/google-play.png"}
                          alt="playstore"
                        />
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Google Play link"
                            value={playstoreLink}
                            onBlur={(e) => this.appUpdateHandler("playstore")}
                            onChange={(e) =>
                              this.handleChange("playstoreLink", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* expert section ends  */}

              {/* features content starts */}
              <div className="features-content">
                <h2 className="mb-4">Features</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <textarea
                        name=""
                        id=""
                        rows="6"
                        value={featuresDesc}
                        placeholder="Add description"
                        onBlur={this.onFeaturesDescBlur}
                        className="form-control bg-transparent"
                        onChange={(e) =>
                          this.handleChange("featuresDesc", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                    <div className="feature-box border-radius-20 bg-grey-10 p-3">
                      <div className="icon bg-primary d-inline-block p-2 mb-3 rounded">
                        <img
                          src={"../assets/images/go-live.png"}
                          alt="live icon"
                        />
                      </div>
                      <input
                        type="text"
                        value={liveFeatureTitle}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "liveFeatureTitle",
                            e.target.value,
                            1
                          )
                        }
                        className="form-control font-weight-bold bg-transparent border-0 mb-2"
                        placeholder="Add Title"
                      />
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        value={liveFeatureDesc}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "liveFeatureDesc",
                            e.target.value,
                            1
                          )
                        }
                        className="form-control bg-transparent"
                        placeholder="Add Description..."
                      ></textarea>
                      <div className="text-center mt-2">
                        <button
                          className="btn btn-primary py-2 px-3"
                          onClick={this.onLiveFeatureBlur}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-4 mb-lg-0">
                    <div className="feature-box border-radius-20 bg-grey-10 p-3">
                      <div className="icon bg-primary d-inline-block p-2 mb-3 rounded">
                        <img
                          src={"../assets/images/shows-icon.png"}
                          alt="live icon"
                        />
                      </div>
                      <input
                        type="text"
                        value={showFeatureTitle}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "showFeatureTitle",
                            e.target.value,
                            2
                          )
                        }
                        className="form-control font-weight-bold bg-transparent border-0 mb-2"
                        placeholder="Add Title"
                      />
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        value={showFeatureDesc}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "showFeatureDesc",
                            e.target.value,
                            2
                          )
                        }
                        className="form-control bg-transparent"
                        placeholder="Add Description..."
                      ></textarea>
                      <div className="text-center mt-2">
                        <button
                          className="btn btn-primary py-2 px-3"
                          onClick={this.onShowFeatureBlur}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-4 mb-lg-0">
                    <div className="feature-box border-radius-20 bg-grey-10 p-3">
                      <div className="icon bg-primary d-inline-block p-2 mb-3 rounded">
                        <img
                          src={"../assets/images/video-icon.png"}
                          alt="live icon"
                        />
                      </div>
                      <input
                        type="text"
                        value={videoFeatureTitle}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "videoFeatureTitle",
                            e.target.value,
                            3
                          )
                        }
                        className="form-control font-weight-bold bg-transparent border-0 mb-2"
                        placeholder="Add Title"
                      />
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        value={videoFeatureDesc}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "videoFeatureDesc",
                            e.target.value,
                            3
                          )
                        }
                        className="form-control bg-transparent"
                        placeholder="Add Description..."
                      ></textarea>
                      <div className="text-center mt-2">
                        <button
                          className="btn btn-primary py-2 px-3"
                          onClick={this.onVideoFeatureBlur}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                    <div className="feature-box border-radius-20 bg-grey-10 p-3">
                      <div className="icon bg-primary d-inline-block p-2 mb-3 rounded">
                        <img
                          src={"../assets/images/store-icon.png"}
                          alt="live icon"
                        />
                      </div>
                      <input
                        type="text"
                        value={shopFeatureTitle}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "shopFeatureTitle",
                            e.target.value,
                            4
                          )
                        }
                        className="form-control font-weight-bold bg-transparent border-0 mb-2"
                        placeholder="Add Title"
                      />
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        value={shopFeatureDesc}
                        onChange={(e) =>
                          this.onFeatureHandleChange(
                            "shopFeatureDesc",
                            e.target.value,
                            4
                          )
                        }
                        className="form-control bg-transparent"
                        placeholder="Add Description..."
                      ></textarea>
                      <div className="text-center mt-2">
                        <button
                          className="btn btn-primary py-2 px-3"
                          onClick={this.onShopFeatureBlur}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* features content ends */}
              <div className="social-media-content my-5">
                <h2 className="mb-4">Social Media</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="mb-3">
                      <textarea
                        name=""
                        id=""
                        rows="6"
                        className="form-control desc"
                        placeholder="Add description"
                        value={socialMediaDesc}
                        onBlur={() => {
                          this.socialMediaUpateHandler("description");
                        }}
                        onChange={(e) =>
                          this.handleChange("socialMediaDesc", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <ul className="social-media-links">
                      <li className="d-flex align-items-center mb-2">
                        {/* <div className="custom-control mr-2 text-center custom-checkbox dark">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="facebookCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="facebookCheck"
                          ></label>
                        </div> */}
                        <div className="icon bg-grey-10 p-2 rounded mr-3">
                          <img
                            src={"../assets/images/fb-icon.png"}
                            alt="facebook"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            value={facebookLink}
                            className="form-control bg-grey-10 border-0"
                            placeholder="Facebook link"
                            onBlur={() => {
                              this.socialMediaUpateHandler("facebook");
                            }}
                            onChange={(e) =>
                              this.handleChange("facebookLink", e.target.value)
                            }
                          />
                        </div>
                      </li>
                      <li className="d-flex align-items-center mb-2">
                        {/* <div className="custom-control mr-2 text-center custom-checkbox dark">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="instagramCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="instagramCheck"
                          ></label>
                        </div> */}
                        <div className="icon bg-grey-10 p-2 rounded mr-3">
                          <img
                            src={"../assets/images/insta-icon.png"}
                            alt="instagram"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            value={instagramLink}
                            className="form-control bg-grey-10 border-0"
                            placeholder="Instagram link"
                            onBlur={() => {
                              this.socialMediaUpateHandler("instagram");
                            }}
                            onChange={(e) =>
                              this.handleChange("instagramLink", e.target.value)
                            }
                          />
                        </div>
                      </li>
                      <li className="d-flex align-items-center mb-2">
                        {/* <div className="custom-control mr-2 text-center custom-checkbox dark">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="row-checkbox1"
                            id="twitterCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="twitterCheck"
                          ></label>
                        </div> */}
                        <div className="icon bg-grey-10 p-2 rounded mr-3">
                          <img
                            src={"../assets/images/twitter-icon.png"}
                            alt="twitter"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            value={TwitterLink}
                            className="form-control bg-grey-10 border-0"
                            placeholder="Twitter link"
                            onBlur={() => {
                              this.socialMediaUpateHandler("twitter");
                            }}
                            onChange={(e) =>
                              this.handleChange("TwitterLink", e.target.value)
                            }
                          />
                        </div>
                      </li>
                      <li className="d-flex align-items-center mb-2">
                        {/* <div className="custom-control mr-2 text-center custom-checkbox dark">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="youtubeCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="youtubeCheck"
                          ></label>
                        </div> */}
                        <div className="icon bg-grey-10 p-2 rounded mr-3">
                          <img
                            src={"../assets/images/youtube-icon.png"}
                            alt="youtube"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            value={YoutubeLink}
                            className="form-control bg-grey-10 border-0"
                            placeholder="Youtube link"
                            onBlur={() => {
                              this.socialMediaUpateHandler("youtube");
                            }}
                            onChange={(e) =>
                              this.handleChange("YoutubeLink", e.target.value)
                            }
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* gallery content starts */}
              <div className="gallery-content my-5">
                <h2 className="mb-4">Gallery of Our Food and Cook</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="mb-3">
                      <textarea
                        name=""
                        id=""
                        rows="6"
                        className="form-control"
                        placeholder="Add description"
                        value={galleryDesc}
                        onBlur={() => {
                          this.galleryDescriptionUpateHandler();
                        }}
                        onChange={(e) =>
                          this.handleChange("galleryDesc", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mx-n1">
                  <div className="col-md-4 col-6 px-1">
                    <div className="gallery-box border-radius-20 bg-grey-2 position-relative">
                      {rankOneImg ? (
                        <>
                          <img
                            src={rankOneImg}
                            alt=""
                            className="img-fluid border-radius-20"
                          />
                        </>
                      ) : null}
                      {isLoadingGallery && isLoadingGallery[1] && rankOneImg ? (
                        <LoaderBasic
                          styles={{
                            paddingBottom: "30px",
                            paddingRight: "30px",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="GalleryImage1"
                          className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                        >
                          <input
                            type="file"
                            id="GalleryImage1"
                            className="d-none"
                            onChange={(e) => {
                              {
                                rankOneImg
                                  ? this.galleryUpdateHandler(1, e)
                                  : this.galleryPostHandler(1, e);
                              }
                            }}
                          />
                          Add Photo
                        </label>
                      )}
                    </div>
                  </div>

                  <div className="col-md col-6 px-1">
                    <div className="row mx-n1">
                      <div className="col-md-12 px-1 pb-1">
                        <div className="gallery-box border-radius-20 small bg-grey-2 position-relative">
                          {rankTwoImg ? (
                            <>
                              <img
                                src={rankTwoImg}
                                alt=""
                                className="img-fluid border-radius-20"
                              />
                            </>
                          ) : null}
                          {isLoadingGallery &&
                            isLoadingGallery[2] &&
                            rankTwoImg ? (
                            <LoaderBasic
                              styles={{
                                paddingBottom: "30px",
                                paddingRight: "30px",
                              }}
                            />
                          ) : (
                            <label
                              htmlFor="GalleryImage2"
                              className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                            >
                              <input
                                type="file"
                                id="GalleryImage2"
                                className="d-none"
                                onChange={(e) => {
                                  {
                                    rankTwoImg
                                      ? this.galleryUpdateHandler(2, e)
                                      : this.galleryPostHandler(2, e);
                                  }
                                }}
                              />
                              Add photo
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 px-1 pt-1">
                        <div className="gallery-box border-radius-20 small bg-grey-2 position-relative">
                          {rankThreeImg ? (
                            <>
                              <img
                                src={rankThreeImg}
                                alt=""
                                className="img-fluid border-radius-20"
                              />
                            </>
                          ) : null}
                          {isLoadingGallery &&
                            isLoadingGallery[3] &&
                            rankThreeImg ? (
                            <LoaderBasic
                              styles={{
                                paddingBottom: "30px",
                                paddingRight: "30px",
                              }}
                            />
                          ) : (
                            <label
                              htmlFor="GalleryImage3"
                              className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                            >
                              <input
                                type="file"
                                id="GalleryImage3"
                                className="d-none"
                                onChange={(e) => {
                                  {
                                    rankThreeImg
                                      ? this.galleryUpdateHandler(3, e)
                                      : this.galleryPostHandler(3, e);
                                  }
                                }}
                              />
                              Add photo
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md col-6 py-1 py-md-0 px-1">
                    <div className="gallery-box border-radius-20 bg-grey-2 position-relative">
                      {rankFourImg ? (
                        <>
                          <img
                            src={rankFourImg}
                            alt=""
                            className="img-fluid border-radius-20"
                          />
                        </>
                      ) : null}
                      {isLoadingGallery &&
                        isLoadingGallery[4] &&
                        rankFourImg ? (
                        <LoaderBasic
                          styles={{
                            paddingBottom: "30px",
                            paddingRight: "30px",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="GalleryImage4"
                          className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                        >
                          <input
                            type="file"
                            id="GalleryImage4"
                            className="d-none"
                            onChange={(e) => {
                              {
                                rankFourImg
                                  ? this.galleryUpdateHandler(4, e)
                                  : this.galleryPostHandler(4, e);
                              }
                            }}
                          />
                          Add photo
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-md col-6 py-1 py-md-0 px-1">
                    <div className="gallery-box border-radius-20 bg-grey-2 position-relative">
                      {rankFiveImg ? (
                        <>
                          <img
                            src={rankFiveImg}
                            alt=""
                            className="img-fluid border-radius-20"
                          />
                        </>
                      ) : null}
                      {isLoadingGallery &&
                        isLoadingGallery[5] &&
                        rankFiveImg ? (
                        <LoaderBasic
                          styles={{
                            paddingBottom: "30px",
                            paddingRight: "30px",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="GalleryImage5"
                          className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                        >
                          <input
                            type="file"
                            id="GalleryImage5"
                            className="d-none"
                            onChange={(e) => {
                              {
                                rankFiveImg
                                  ? this.galleryUpdateHandler(5, e)
                                  : this.galleryPostHandler(5, e);
                              }
                            }}
                          />
                          Add Photo
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* gallery content ends*/}

              <div className="subscribe-content bg-grey-10 border-radius-20 ">
                <div className="row">
                  <div className="col-md-6">
                    <div className="inner py-4 pl-4 pr-4 pr-md-0">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="bg-transparent border-0 form-control font-weight-semi fs-36"
                          placeholder="Add Title"
                        />
                      </div>
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control bg-transparent border-0"
                          placeholder="Add Description"
                        />
                      </div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control h-auto border-0"
                          placeholder="Email Address"
                        />
                        <button className="btn btn-primary ml-3 rounded">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row justify-content-end align-items-center">
                      <div className="col-5 col-sm-4 col-md-6 text-right">
                        <div className="inner-image-1 bg-grey-2 ml-auto">
                          <div className="">
                            <label
                              htmlFor="subscribeImg1"
                              className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                            >
                              <input
                                type="file"
                                id="subscribeImg1"
                                className="d-none"
                              />
                              Add Photo
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-5 col-sm-4 col-md-6 text-right">
                        <div className="inner-image-2 bg-grey-2 ml-auto">
                          <div className="">
                            <label
                              htmlFor="subscribeImg2"
                              className="bg-white rounded btn-centered position-absolute btn btn-outline-info px-2"
                            >
                              <input
                                type="file"
                                id="subscribeImg2"
                                className="d-none"
                              />
                              Add Photo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContentModal
            content={content}
            labelText={labelText}
            modalType={modalType}
            coverImage={coverImage}
            isOpen={isContentModal}
            labelColor={labelColor}
            selectedItem={selectedItem}
            isApiLoading={isApiLoading}
            coverImageName={coverImageName}
            coverImagePreview={coverImagePreview}
            handleChange={this.handleChange}
            onHandleClose={this.handleCloseModal}
            onAddClickHandler={this.onAddClickHandler}
            onCancelClickHandler={this.onCancelClickHandler}
            handleCoverImageChange={this.handleCoverImageChange}
            handleSelectItemHandler={this.handleSelectItemHandler}
            pagination={pagination}
            handlePagination={this.handlePagination}
            searchContentHandler={(e) => this.searchContentHandler(e)}
            searchKey={this.state.searchKey}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatedTopLeftSection: state.update_top_left_home_reducer,
    expertSection: state.update_top_left_home_reducer.expertSection,
    featuresSection: state.update_top_left_home_reducer.featuresSection,
    topRightHeading: state.update_top_left_home_reducer.topRightHeading,
    updatedTopRightSection:
      state.update_top_left_home_reducer.updatedTopRightContent,
    allContentItems: state.carousel_items_reducer.allContentItems,
    homePageTopLeftContent:
      state.update_top_left_home_reducer.homePageTopLeftContent,
    homePageTopRightContent:
      state.update_top_left_home_reducer.homePageTopRightContent,
    socialMediaSection: state.update_top_left_home_reducer.socialMediaSection,
    galleryHeadingSection:
      state.update_top_left_home_reducer.galleryHeadingSection,
    gallerySection: state.update_top_left_home_reducer.gallerySection,
    appSection: state.update_top_left_home_reducer.appSection,
    uploadUrl: state.save_video_details_reducer.authenticatedUrl,
    muxAssetsData: state.save_video_details_reducer.muxAssetsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllContentItems: (page) => dispatch(get_all_content_action(page)),
  getExpertSection: () => dispatch(get_expert_section_action()),
  getFeaturesSection: () => dispatch(get_features_section_action()),
  getHomepageTopLeftContent: () => dispatch(get_top_left_homepage_action()),
  getHomepageTopRightContent: () => dispatch(get_top_right_homepage_action()),
  addHeadingTopRight: (data) =>
    dispatch(add_heading_top_right_homepage_action(data)),
  updateTopLeftHomePage: (data) =>
    dispatch(update_top_left_homepage_action(data)),
  updateTopRightHomePage: (data, type, rank) =>
    dispatch(update_top_right_homepage_action(data, type, rank)),
  addFeaturesSection: (data) => dispatch(add_features_section_action(data)),
  updateExpertSection: (data) => dispatch(update_expert_section_action(data)),
  updateFeaturesSection: (rank, data) =>
    dispatch(update_features_section_action(rank, data)),

  postFeaturesSection: (data) => dispatch(post_features_section_action(data)),

  updateSocialMediaSection: (data) =>
    dispatch(social_media_section_action(data)),
  getSocialMediaSection: () => dispatch(get_social_media_section_action()),
  updateGallerySectionHeading: (data) =>
    dispatch(update_gallery_section_heading_action(data)),
  getGallerySectionHeading: () =>
    dispatch(get_gallery_section_heading_action()),

  postGallerySection: (data) => dispatch(post_gallery_section_action(data)),
  updateGallerySection: (rank, data) =>
    dispatch(update_gallery_section_action(rank, data)),
  getGallerySection: () => dispatch(get_gallery_section_action()),
  updateAppSection: (data) => dispatch(update_app_section_action(data)),
  getAppSection: () => dispatch(get_app_section_action()),
  searchContent: (data) => dispatch(get_all_content_with_filter_action(data)),
  getUploadUrl: () => dispatch(create_mux_url_action()),
  onGetMuxAssetData: (id) => dispatch(get_playback_asset_id_action(id)),


});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageContent);
