import swal from 'sweetalert';
import { PaymentConstants } from '../../_constants/User/payment.constants';
import axiosInstance from '../../_helpers/axiosInstance';
import history from "../../_helpers/history";
import config from "../../Config/config";


//for single product
export const single_product_payment_action = (payload) => async (dispatch) => {
  dispatch({
    type: PaymentConstants.SINGLE_PRODUCT_PAYMENT_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.userPayment.singleProductPaymentDetail, payload);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.SINGLE_PRODUCT_PAYMENT_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: PaymentConstants.SINGLE_PRODUCT_PAYMENT_FAILURE,
    });
    swal(e.msg)
  }
};

//subscription_payment_action

export const subscription_payment_action = (payload) => async (dispatch) => {
  dispatch({
    type: PaymentConstants.PAYMENT_SUBSCRIPTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.userPayment.subscriptionPaymentDetail, payload);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.PAYMENT_SUBSCRIPTION_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    
    dispatch({
      type: PaymentConstants.PAYMENT_SUBSCRIPTION_FAILURE,
      payload: e,
    });
    swal(e.msg)
  }
};

//getupgrade_amount_payment_action

export const getupgrade_amount_payment_action = () => async (dispatch) => {
  dispatch({
    type: PaymentConstants.PAYMENT_GET_UPGRADE_AMOUNT_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.userPayment.upgradePaymentDetail);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.PAYMENT_GET_UPGRADE_AMOUNT_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    
    dispatch({
      type: PaymentConstants.PAYMENT_GET_UPGRADE_AMOUNT_FAILURE,
      payload: e,
    });
    swal(e.msg)
  }
};

//upgrade_payment_action
export const upgrade_payment_action = () => async (dispatch) => {
  dispatch({
    type: PaymentConstants.PAYMENT_UPGRADE_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.userPayment.postUpgradePaymentDetail);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.PAYMENT_UPGRADE_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    
    dispatch({
      type: PaymentConstants.PAYMENT_UPGRADE_FAILURE,
      payload: e,
    });
    swal(e.msg)
  }
};

//cancel_subscription_action
export const cancel_subscription_action = () => async (dispatch) => {
  dispatch({
    type: PaymentConstants.CANCEL_SUBSCRIPTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.userPayment.subscriptionCancelDetail);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.CANCEL_SUBSCRIPTION_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    
    dispatch({
      type: PaymentConstants.CANCEL_SUBSCRIPTION_FAILURE,
      payload: e,
    });
    swal(e.msg)
  }
};

//purchased_product_last_month_action
export const purchased_product_last_month_action = () => async (dispatch) => {
  dispatch({
    type: PaymentConstants.PURCHASED_PRODUCT_LAST_MONTH_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.userPayment.purchasedProductLastMonthDetail);
    
    if (response.status) {
      dispatch({
        type: PaymentConstants.PURCHASED_PRODUCT_LAST_MONTH_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      return false;
    }
  } catch (e) {
    
    dispatch({
      type: PaymentConstants.PURCHASED_PRODUCT_LAST_MONTH_FAILURE,
      payload: e,
    });
    swal(e.msg)
  }
};
