import React, { Component } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import "./ContentBox.scss";

import {
  add_bookmark_to_post_action,
  get_status_by_id_action,
  hit_like_action,
} from "../../../../_actions/User/post.action";

import VideoPlayer from "../../common/VideoPlayer/VideoPlayer";

class ContentBox extends Component {
  state = {
    updatedPost: [],
    deleteActionModal: false,
    reportActionModal: false,
    reportListingActionModal: false,
    updateTriggered: false,
    likesCount: this.props.likesCount,
    copySuccess: "copy",
  };

  mountPostData = async (id) => {
    if (!id) return;
    const getPostRes = await this.props.getPostData(id);
    if (getPostRes && getPostRes.data) {
      await this.setState({
        updatedPost: getPostRes.data,
        updateTriggered: true,
        likesCount: getPostRes.data.likes_count,
      });
    } else this.props.history.push("/community-timeline");
  };

  deleteActionModalHandler = () => {
    this.setState({
      deleteActionModal: !this.state.deleteActionModal,
    });
  };
  reportActionModalHandler = () => {
    this.setState({
      reportActionModal: !this.state.reportActionModal,
    });
  };

  reportListingActionModalHandler = () => {
    this.setState({
      reportListingActionModal: !this.state.reportListingActionModal,
    });
  };

  likePost = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id,
    };
    await this.props.hitLike(data);
    await this.mountPostData(id);
  };

  bookmarkPost = async (id) => {
    if (!id) return;
    const data = {
      content_type: "user_post",
      id,
    };
    await this.props.addBookmarkAction(data);
    await this.mountPostData(id);
  };
  copyToClipboard = (id, type) => {
    if (type === "status") {
      var a = window.location.origin + "/post-status/" + id;
    } else {
      var a = window.location.origin + "/view-post/" + id;
    }

    const el = document.createElement("textarea");
    el.value = a;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copySuccess: "Copied!" });
  };
  render() {
    const { updatedPost, updateTriggered, likesCount } = this.state;
    const {
      bookmarkArray,
      likesArray,
      statusText,
      thumbnail,
      firstname,
      lastname,
      userimg,
      videoId,
      edit,
      post,
    } = this.props;
    const userObject = JSON.parse(localStorage.getItem("user"));
    return (
      <>
        <div className="content-box px-md-2">
          <div className="header d-flex align-items-center justify-content-between mb-3">
            <div className="user-info d-flex align-items-center flex-grow-1">
              {userimg ? (
                <img
                  src={userimg}
                  alt="user-img"
                  className="mr-2 rounded-circle"
                />
              ) : (
                <span className="user-initials bg-primary text-white text-uppercase d-flex align-items-center justify-content-center font-weight-bold rounded-circle mr-2">
                  {post["user_details"]["first_name"].charAt(0)}
                  {post["user_details"]["last_name"].charAt(0)}
                </span>
              )}
              {firstname ? (
                <p className="font-smallest font-weight-bold m-0">{firstname}{" "}{lastname}</p>
              ) : (
                <p className="font-smallest font-weight-bold m-0">User</p>
              )}
            </div>
            {/*  report action */}
            <div className="report  position-relative">
              {edit || this.props.delete || this.props.report ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant=" "
                    id="dropdown-basic"
                    className="p-0"
                  >
                    <img
                      src={"/assets/images/menu.png"}
                      alt="report-action"
                      className="cursor-pointer"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="bg-grey-2">
                    {/* delete post  */}
                    <Dropdown.Item
                      href="#"
                      className="bg-grey-2  pr-0 pt-0"
                      onClick={this.deleteActionModalHandler}
                    >
                      <p className="font-weight-bold mb-0 text-capitalize">
                        {this.props.delete}
                      </p>
                    </Dropdown.Item>

                    {/* report post  */}
                    <Dropdown.Item
                      href="#"
                      className="bg-grey-2 pr-0 pt-0"
                      onClick={this.reportActionModalHandler}
                    >
                      <p className="font-weight-bold mb-0 text-capitalize">
                        {this.props.report}
                      </p>
                    </Dropdown.Item>

                    {/* edit post */}
                    <Dropdown.Item
                      href="#"
                      className="bg-grey-2 pr-0 pt-0"
                      onClick={() => this.props.editPost()}
                    >
                      <p className="font-weight-bold mb-0 text-capitalize">
                        {this.props.edit}
                      </p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
          </div>

          {thumbnail ? (
            <img
              src={thumbnail}
              alt=""
              className="thumbnail border-radius-10 img-fit w-100 cursor-pointer"
              onClick={() => this.props.redirect()}
            />
          ) : !videoId && post["post_type"] !== "status" ? (
            <div
              className="thumbnail border-radius-10 bg-grey-2 img-fit w-100 cursor-pointer"
              onClick={() => this.props.redirect()}
            />
          ) : null}
          {videoId ? <VideoPlayer playbackId={videoId} /> : null}
          {statusText ? (
            <div className="status-text " onClick={() => this.props.redirect()}>
              <p className="font-smallest font-weight-bold mb-3">
                {statusText}
              </p>
            </div>
          ) : null}

          {/* icons list */}
          <div className="bottom d-flex align-items-center mt-1 justify-content-between">
            <div className="user-action-btn d-flex align-items-center">
              {
                <>
                  {/* like icon */}
                  <a>
                    {updateTriggered ? (
                      updatedPost &&
                      updatedPost["likes"] &&
                      updatedPost["likes"].includes(userObject.user.id) ? (
                        <span onClick={() => this.likePost(post && post.id)}>
                          <i
                            className="fa fa-heart text-danger mr-3 mr-lg-4 fs-18"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span onClick={() => this.likePost(post && post.id)}>
                          <i
                            className="fa fa-heart-o mr-3 mr-lg-4 fs-18"
                            aria-hidden="true"
                          />
                        </span>
                      )
                    ) : likesArray &&
                      likesArray.includes(userObject.user.id) ? (
                      <span onClick={() => this.likePost(post && post.id)}>
                        <i
                          className="fa fa-heart text-danger mr-3 mr-lg-4 fs-18"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <span onClick={() => this.likePost(post && post.id)}>
                        <i
                          className="fa fa-heart-o mr-3 mr-lg-4 fs-18"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </a>
                  {/* share icon */}

                  <Dropdown className="d-inline-block">
                    <Dropdown.Toggle
                      variant=""
                      className="px-0 btn-share mr-2"
                      id="dropdown-basic"
                    >
                      <img
                        src={"/assets/images/share.png"}
                        alt="share"
                        className="mr-3 mr-lg-4"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div
                        className="py-1 px-2 cursor-pointer"
                        onClick={() =>
                          this.copyToClipboard(
                            post && post.id,
                            post && post.post_type
                          )
                        }
                      >
                        <span className="fa fa-clone mr-2"></span>
                        {this.state.copySuccess}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* save icon */}
                  <a>
                    {updateTriggered ? (
                      updatedPost &&
                      updatedPost["bookmarks"] &&
                      updatedPost["bookmarks"].includes(userObject.user.id) ? (
                        <span
                          onClick={() => this.bookmarkPost(post && post.id)}
                        >
                          <i
                            className="fa fa-bookmark text-warning mr-3 mr-lg-4 fs-18"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          onClick={() => this.bookmarkPost(post && post.id)}
                        >
                          <i
                            className="fa fa-bookmark-o text-black mr-3 mr-lg-4 fs-18"
                            aria-hidden="true"
                          />
                        </span>
                      )
                    ) : bookmarkArray.includes(userObject.user.id) ? (
                      <span onClick={() => this.bookmarkPost(post && post.id)}>
                        <i
                          className="fa fa-bookmark text-warning mr-3 mr-lg-4 fs-18"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <span onClick={() => this.bookmarkPost(post && post.id)}>
                        <i
                          className="fa fa-bookmark-o text-black mr-3 mr-lg-4 fs-18"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </a>
                </>
              }
            </div>
            <a
              onClick={() => this.props.redirect()}
              className="font-smallest font-weight-bold text-dark redirect-link"
            >
              {this.props.commentsCount}{" "}
            </a>
          </div>
          {likesCount ? (
            <p className="font-smallest mb-0 text-grey-4">
              {likesCount} like{likesCount > 1 ? "s" : ""}
            </p>
          ) : null}
        </div>

        {/*--------------Modals--------------*/}

        {/* Report Post Popup ======== */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.reportActionModal}
          onHide={this.reportActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3">
            <h6 className="text-center py-3">
              Are you sure you want to Report this post?
            </h6>
          </Modal.Body>
          <Modal.Footer className="justify-content-md-between justify-content-center border-top-0 fs-14 font-weight-semi px-5 pb-3">
            <Button
              onClick={() => {
                // this.props.reportPost();
                this.reportListingActionModalHandler();
                this.reportActionModalHandler();
              }}
              variant="btn btn-primary"
              className="rounded px-5 py-2"
            >
              Yes
            </Button>
            <Button
              variant="btn btn-light"
              className="rounded px-5 py-2"
              onClick={this.reportActionModalHandler}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Report Post Popup ======== */}

        {/* Report listing Post Popup ======== */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.reportListingActionModal}
          onHide={this.reportListingActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3 px-0">
            <h6 className="text-center py-3">
              Why are you reporting this post?
            </h6>

            <ul className="reasons-list">
              <li className="cursor-pointer" onClick={async () => {
                await this.props.reportReason("It's spam");
                await this.props.reportPost();
                await this.reportListingActionModalHandler();
              }}>
                It's spam
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.props.reportReason(
                    "Intellectual property violation"
                  );
                  await this.props.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Intellectual property violation
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.props.reportReason("Bullying or harassment");
                  await this.props.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Bullying or harassment
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.props.reportReason("Nudity or sexual activity");
                  await this.props.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Nudity or sexual activity
              </li>
              <li
                className="cursor-pointer"
                onClick={async () => {
                  await this.props.reportReason("Something else");
                  await this.props.reportPost();
                  await this.reportListingActionModalHandler();
                }}
              >
                Something else
              </li>
            </ul>
            <br />
          </Modal.Body>
        </Modal>
        {/* Report listing Post Popup ======== */}
        {/* Delete Post Popup ======== */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteActionModal}
          onHide={this.deleteActionModalHandler}
          className="text-center delete-action-popup px-4"
        >
          <Modal.Body className="delete-action-popup-content pt-3">
            <h6 className="text-center py-3">
              Are you sure you want to delete this post?
            </h6>
          </Modal.Body>
          <Modal.Footer className="justify-content-md-between justify-content-center border-top-0 fs-14 font-weight-semi px-5 pb-3">
            <Button
              onClick={() => {
                this.props.deletePost();
                this.deleteActionModalHandler();
              }}
              variant="btn btn-dark"
              className="rounded px-5 py-2"
            >
              Yes
            </Button>
            <Button
              className="rounded px-5 py-2"
              variant="btn btn-light"
              onClick={this.deleteActionModalHandler}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Delete Post Popup ======== */}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPostData: (id) => dispatch(get_status_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data)),
});
export default connect(null, mapDispatchToProps)(ContentBox);
