import { carouselConstants } from "../../_constants";

const initialState = {};
export const carousel_items_reducer = (state = initialState, action) => {
  switch (action.type) {
    case carouselConstants.GET_ALL_DASHBOARD_ITEMS_ACTION_SUCCESS:
      return {
        ...state,
        carouselItems: action.payload
      };
    case carouselConstants.GET_ALL_DASHBOARD_ITEMS_ACTION_FAILURE:
      return {
        ...state,
        error: {
          message: action.payload
        }
      };
    case carouselConstants.GET_ALL_CONTENT_ACTION_SUCCESS:
      return {
        ...state,
        allContentItems: action.payload
      };
    default:
      return state;
  }
};

export const add_to_dashboard_items_reducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_SUCCESS:
      return {
        ...state,
        itemAdded: action.payload
      };
    case carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
