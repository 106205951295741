import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Modal,
  Form
} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./RecipesSection.scss";
import NewestRecipe from "./NewestRecipe/NewestRecipe";
import PopularRecipe from "./PopularRecipe/PopularRecipe";
import SeasonalRecipe from "./SeasonalRecipe/SeasonalRecipe";
import VeganRecipe from "./VeganRecipe/VeganRecipe";
import {
  get_featured_top_left_video_content_action,
  get_featured_top_right_recipes_content_action,
  get_recipes_category_titles_action,
  get_categories_section_title_action,
} from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
export class RecipesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeListModal: false,
      featuredTopLeftContent: [],
      featuredTopRightRecipesContent: [],

      recipeCategoryTitles: [],
      sectionTitle: "",

    };
  }
  componentDidMount() {
    this.getFeaturedTopLeftVideoContent();
    this.getFeaturedTopRightRecipesContent();
    this.getRecipesCategoriesTitles();
    this.getSectionTitle();

  }
  getSectionTitle = async () => {
    const data = await this.props.getRecipeCategoryTitle();
    let title = data && data.data && data.data.title;
    this.setState({
      sectionTitle: title,
    });
  }

  getRecipesCategoriesTitles = async () => {
    let data = await this.props.getRecipesCategoryTitles();
    this.setState({ recipeCategoryTitles: data && data.data });
  };
  getFeaturedTopLeftVideoContent = async () => {
    let data = await this.props.getFeaturedTopLeftVideo();
    this.setState({ featuredTopLeftContent: data && data.data });
  };

  getFeaturedTopRightRecipesContent = async () => {
    let data = await this.props.getFeaturedTopRightRecipes();
    this.setState({ featuredTopRightRecipesContent: data && data.data });
  };

  redirectHandler = (data) => {
    let routeData = data;
    if (routeData.content_type === "youtube_recipe") {
      this.props.history.push(`/recipe/${routeData.youtube_recipe_details.id}`);
    } else if (routeData.content_type === "video") {
      this.props.history.push(`/video/${routeData.video_details.id}`);
    } else if (routeData.content_type === "show") {
      this.props.history.push(`/view-show/${routeData.show_details.id}`);
    } else {
      this.props.history.push(`/watch-show/${routeData.episode_details.id}`);
    }
  };

  redirectRecipeHandler = (id) => {
    this.props.history.push(`/recipe/${id}`);
  };
  render() {
    let {
      featuredTopLeftContent,
      featuredTopRightRecipesContent,
      recipeCategoryTitles,
      sectionTitle
    } = this.state;

    let featuredTopRightRecipes =
      featuredTopRightRecipesContent && featuredTopRightRecipesContent.recipes;
    let rankOneTitle =
      recipeCategoryTitles &&
      recipeCategoryTitles.filter((title) => title.rank === 1) &&
      recipeCategoryTitles.filter((title) => title.rank === 1)[0] &&
      recipeCategoryTitles.filter((title) => title.rank === 1)[0];
    let rankTwoTitle =
      recipeCategoryTitles &&
      recipeCategoryTitles.filter((title) => title.rank === 2) &&
      recipeCategoryTitles.filter((title) => title.rank === 2)[0] &&
      recipeCategoryTitles.filter((title) => title.rank === 2)[0];
    let rankThreeTitle =
      recipeCategoryTitles &&
      recipeCategoryTitles.filter((title) => title.rank === 3) &&
      recipeCategoryTitles.filter((title) => title.rank === 3)[0] &&
      recipeCategoryTitles.filter((title) => title.rank === 3)[0];
    let rankFourTitle =
      recipeCategoryTitles &&
      recipeCategoryTitles.filter((title) => title.rank === 4) &&
      recipeCategoryTitles.filter((title) => title.rank === 4)[0] &&
      recipeCategoryTitles.filter((title) => title.rank === 4)[0];

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="recipe-section">
        <Container>
          <div className="recipe-top-section">
            <Row>
              <Col lg={6} className="pl-lg-0">
                <div className="left-section position-relative cursor-pointer">
                  {featuredTopLeftContent &&
                    featuredTopLeftContent.video_details &&
                    featuredTopLeftContent.video_details.is_premium ? (
                    <span className="premium position-absolute d-inline-block bg-warning text-white fs-16 font-weight-bold py-2 px-4">
                      Premium
                    </span>
                  ) : null}

                  <div className="overlay d-flex align-items-center justify-content-center p-2 position-absolute">
                    <span
                      onClick={() =>
                        this.redirectHandler(featuredTopLeftContent)
                      }
                      className="btn btn-outline-white rounded"
                    >
                      {featuredTopLeftContent.content_type === "youtube_recipe"
                        ? "View Recipe"
                        : featuredTopLeftContent.content_type === "video"
                          ? "View Video"
                          : featuredTopLeftContent.content_type === "show"
                            ? "View Show"
                            : featuredTopLeftContent.content_type === "episode"
                              ? "View Episode"
                              : "view"}
                    </span>
                  </div>
                  <div className="img-box">
                    {/* images preview ============== */}

                    {/* recipe img preview  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.youtube_recipe_details &&
                      featuredTopLeftContent.youtube_recipe_details
                        .title_image ? (
                      <img
                        src={
                          featuredTopLeftContent &&
                            featuredTopLeftContent.youtube_recipe_details &&
                            featuredTopLeftContent.youtube_recipe_details
                              .title_image ? featuredTopLeftContent &&
                              featuredTopLeftContent.youtube_recipe_details &&
                          featuredTopLeftContent.youtube_recipe_details
                            .title_image : "/assets/images/placeholder-recipe.jpg"
                        }
                        alt={
                          featuredTopLeftContent &&
                          featuredTopLeftContent.youtube_recipe_details &&
                          featuredTopLeftContent.youtube_recipe_details.title
                        }
                        className="img-fit img-fluid"
                      />
                    ) : null}

                    {/* video img preview  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.video_details &&
                      featuredTopLeftContent.video_details.title_image ? (
                      <img
                        src={
                          featuredTopLeftContent &&
                            featuredTopLeftContent.video_details &&
                            featuredTopLeftContent.video_details.title_image ?
                            featuredTopLeftContent &&
                            featuredTopLeftContent.video_details &&
                            featuredTopLeftContent.video_details.title_image
                            : "/assets/images/placeholder-recipe.jpg"
                        }
                        alt={
                          featuredTopLeftContent &&
                          featuredTopLeftContent.video_details &&
                          featuredTopLeftContent.video_details.title
                        }
                        className="img-fit img-fluid"
                      />
                    ) : null}

                    {/* show img preview  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.show_details &&
                      featuredTopLeftContent.show_details.title_image ? (
                      <img
                        src={
                          featuredTopLeftContent &&
                            featuredTopLeftContent.show_details &&
                            featuredTopLeftContent.show_details.title_image ?
                            featuredTopLeftContent &&
                            featuredTopLeftContent.show_details &&
                            featuredTopLeftContent.show_details.title_image : "/assets/images/placeholder-recipe.jpg"
                        }
                        alt={
                          featuredTopLeftContent &&
                          featuredTopLeftContent.show_details &&
                          featuredTopLeftContent.show_details.title
                        }
                        className="img-fit img-fluid"
                      />
                    ) : null}

                    {/* episode_details img preview  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.episode_details &&
                      featuredTopLeftContent.episode_details.title_image ? (
                      <img
                        src={
                          featuredTopLeftContent &&
                            featuredTopLeftContent.episode_details &&
                            featuredTopLeftContent.episode_details.title_image ?
                            featuredTopLeftContent &&
                            featuredTopLeftContent.episode_details &&
                            featuredTopLeftContent.episode_details.title_image : "/assets/images/placeholder-recipe.jpg"

                        }
                        alt={
                          featuredTopLeftContent &&
                          featuredTopLeftContent.episode_details &&
                          featuredTopLeftContent.episode_details.title
                        }
                        className="img-fit img-fluid"
                      />
                    ) : null}
                  </div>
                  <h2 className="recipe-info text-white font-poppins font-weight-semi fs-36  position-absolute">
                    {/* recipe title  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.youtube_recipe_details &&
                      featuredTopLeftContent.youtube_recipe_details.title}

                    {/* video_details title  */}
                    {featuredTopLeftContent &&
                      featuredTopLeftContent.video_details &&
                      featuredTopLeftContent.video_details.title}
                    {/* show_details title  */}

                    {featuredTopLeftContent &&
                      featuredTopLeftContent.show_details &&
                      featuredTopLeftContent.show_details.title}
                    {/* recipe title  */}

                    {featuredTopLeftContent &&
                      featuredTopLeftContent.episode_details &&
                      featuredTopLeftContent.episode_details.title}
                  </h2>
                </div>
              </Col>
              <Col lg={6} className="pr-lg-0">
                <div className="right-section">
                  <h2 className="text-center mb-md-4 mt-4 p-2 font-family-rochester fs-48">
                    {featuredTopRightRecipesContent &&
                      featuredTopRightRecipesContent.heading &&
                      featuredTopRightRecipesContent.heading.heading}
                  </h2>
                  <Row className="mr-lg-2">
                    {featuredTopRightRecipes &&
                      featuredTopRightRecipes.map((recipes) => (
                        <Col md={6} key={recipes && recipes.id}>
                          <div className="recipe-content-wrapper position-relative pt-2 mb-2 cursor-pointer ">
                            {/* premium content icon will come after adding active class */}
                            {/* <span className="premium-icon active position-absolute">
                              <img
                                src="/assets/images/premium-icon.svg"
                                alt="premium-icon"
                                className="img-fit img-fluid"
                              />
                            </span> */}
                            <div className="recipe-image">
                              <img
                                src={recipes.recipe_details.title_image}
                                alt={recipes.recipe_details.title}
                                className="img-fit img-fluid"
                              />
                            </div>

                            <div className="overlay d-flex align-items-center justify-content-center p-2 position-absolute">
                              <span
                                onClick={() =>
                                  this.redirectRecipeHandler(
                                    recipes.recipe_details.id
                                  )
                                }
                                className="position-absolute read-more-btn p-2 rounded"
                              >
                                Read More
                              </span>
                            </div>
                            <h3 className="recipe-title position-absolute text-white px-2">
                              {recipes.recipe_details.title}
                            </h3>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

          <div className="recipe-bottom-section ">
            <h2 className="fs-48 my-5">{sectionTitle}</h2>
            <div className="recipe-type  font-poppins  my-5">
              <Tabs
                defaultActiveKey="Category1"
                className="tab-menu justify-content-around"
              >
                {rankOneTitle && rankOneTitle.name ? (
                  <Tab
                    eventKey="Category1"
                    title={rankOneTitle && rankOneTitle.name}
                  >
                    <NewestRecipe history={this.props.history} />
                  </Tab>
                ) : null}

                {rankTwoTitle && rankTwoTitle.name ? (
                  <Tab
                    eventKey="Category2"
                    title={rankTwoTitle && rankTwoTitle.name}
                  >
                    <PopularRecipe history={this.props.history} />
                  </Tab>
                ) : null}

                {rankThreeTitle && rankThreeTitle.name ? (
                  <Tab
                    eventKey="Category3"
                    title={rankThreeTitle && rankThreeTitle.name}
                  >
                    <SeasonalRecipe history={this.props.history} />
                  </Tab>
                ) : null}

                {rankFourTitle && rankFourTitle.name ? (
                  <Tab
                    eventKey="Category4"
                    title={rankFourTitle && rankFourTitle.name}
                  >
                    <VeganRecipe history={this.props.history} />
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFeaturedTopLeftVideo: () =>
    dispatch(get_featured_top_left_video_content_action()),
  getFeaturedTopRightRecipes: () =>
    dispatch(get_featured_top_right_recipes_content_action()),
  getRecipesCategoryTitles: () => dispatch(get_recipes_category_titles_action()),
  getRecipeCategoryTitle: () => dispatch(get_categories_section_title_action()),

});

export default connect(null, mapDispatchToProps)(RecipesSection);
