import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { isEmptyObject } from '../../../../_helpers/validators';
import './CommentCard.scss';
class CommentCard extends Component {

    render() {
        return (
            <div className="reported-comment-card">
                <div className="reported-comment">
                    <div className="user-profile d-flex">
                        <div className="user-profile-img cursor-pointer"><img src={!isEmptyObject(this.props.user) && this.props.user.profile_image ? this.props.user.profile_image : "/assets/images/user.svg"} alt="user-img" className="image" /></div>
                        <div className="user-profile-content">
                            <p className="font-weight-bold fs-small">{!isEmptyObject(this.props.user) ? `${this.props.user.first_name} ${this.props.user.last_name}` : ''}</p>
                            <p className="font-smallest font-weight-bold">{this.props.commentText ? this.props.commentText : `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`}</p>
                            <div className="img-report cursor-pointer">
                                {this.props.isHideImage ? null : <img src="/assets/images/comment-report-img.svg" alt="" className="image" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CommentCard;