import React, { Component } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import "./SocialMedia.scss";
import { get_social_media_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";

export class SocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getMediaContent: []
    };
  }
  componentDidMount() {
    this.getMediaContentData();
   
  }
  getMediaContentData = async () => {
    let data = await this.props.getMediaContent();
    this.setState({ getMediaContent: data.data });
  };


  render() {
    let { getMediaContent } = this.state;

    return (
      <div className="social-media-follow-options mt-md-5 mt-5  pt-0 ">
        <Container>
          <h2 className="fs-48 mb-3">Social Media</h2>
          <p className="font-poppin fs-18 text-dark-2 mb-md-5 mb-0 pt-3 pb-md-5 pb-0">
            {getMediaContent.description}
          </p>
          <Row>
            {getMediaContent.facebook_link ? (
              <Col sm={6} lg={3} className="mt-2 mt-lg-0 pt-5 pt-md-0 ">
                <div className="socia-media-content position-relative h-100 px-3 px-xl-4 p-t-50 text-center p-b-50 bg-grey-10 border-radius-20">
                  <span className="d-inline-block bg-white social-media-icon border-radius-50 position-absolute">
                    <img src="/assets/images/fb-icon.png" alt="" />
                  </span>
                  <h3>Darius Cooks</h3>
                  {/* <p className="py-5 fs-36 font-weight-bold font-poppins"> 
                    799,967
                  </p> */}
                  <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                    <a
                      target="_blank"
                      href={getMediaContent.facebook_link}
                      className="btn mt-2 btn-outline-purple rounded px-4 py-2 mr-2"
                    >
                      View
                    </a>
                    <a
                      target="_blank"
                      href={getMediaContent.facebook_link}
                      className="btn mt-2 btn-primary rounded px-4 py-2"
                    >
                      Follow
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}

            {getMediaContent.instagram_link ? (
              <Col sm={6} lg={3} className="mt-2 mt-lg-0 pt-5 pt-md-0">
                <div className="socia-media-content position-relative h-100 px-3 px-xl-4 p-t-50 text-center p-b-50 bg-grey-10 border-radius-20">
                  <span className="d-inline-block bg-white social-media-icon border-radius-50 position-absolute">
                    <img src="/assets/images/insta-icon.png" alt="" />
                  </span>
                  <h3>Darius Cooks</h3>
                  {/* <p className="py-5 fs-36 font-weight-bold font-poppins">
                    337,000
                  </p> */}
                  <div className="d-flex flex-wrap align-items-center justify-content-center  mt-3">
                    <a
                      target="_blank"
                      href={getMediaContent.instagram_link}
                      className="btn mt-2 btn-outline-purple rounded px-4 py-2 mr-2"
                    >
                      View
                    </a>
                    <a
                      target="_blank"
                      href={getMediaContent.instagram_link}
                      className="btn mt-2 btn-primary rounded px-4 py-2"
                    >
                      Follow
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}
            {getMediaContent.twitter_link ? (
              <Col sm={6} lg={3} className="mt-2 mt-lg-0 pt-5 pt-md-0">
                <div className="socia-media-content position-relative h-100 px-3 px-xl-4 p-t-50 text-center p-b-50 bg-grey-10 border-radius-20">
                  <span className="d-inline-block bg-white  social-media-icon border-radius-50 position-absolute">
                    <img src="/assets/images/twitter-icon.png" alt="" />
                  </span>
                  <h3>Darius Cooks</h3>
                  {/* <p className="py-5 fs-36 font-weight-bold font-poppins">
                    337,000
                  </p> */}
                  <div className="d-flex flex-wrap align-items-center justify-content-center  mt-3">
                    <a
                      target="_blank"
                      href={getMediaContent.twitter_link}
                      className="btn mt-2 btn-outline-purple rounded px-4 py-2 mr-2"
                    >
                      View
                    </a>
                    <a
                      target="_blank"
                      href={getMediaContent.twitter_link}
                      className="btn mt-2 btn-primary rounded px-4 py-2"
                    >
                      Follow
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}
            {getMediaContent.youtube_link ? (
              <Col sm={6} lg={3} className="mt-2 mt-lg-0 pt-5 pt-md-0">
                <div className="socia-media-content position-relative h-100 px-3 px-xl-4 p-t-50 text-center p-b-50 bg-grey-10 border-radius-20">
                  <span className="d-inline-block bg-white social-media-icon border-radius-50 position-absolute">
                    <img src="/assets/images/youtube-icon.png" alt="" />
                  </span>
                  <h3>Darius Cooks</h3> 
                  {/* <p className="py-5 fs-36 font-weight-bold font-poppins">
                    337,000
                  </p> */}
                  <div className="d-flex align-items-center flex-wrap justify-content-center  mt-3">
                    <a
                      target="_blank"
                      href={getMediaContent.youtube_link}
                      className="btn mt-2 btn-outline-purple rounded px-4 py-2 mr-2"
                    >
                      View
                    </a>
                    <a
                      target="_blank"
                      href={getMediaContent.youtube_link}
                      className="btn mt-2 btn-primary rounded px-2 py-2"
                    >
                      Subscribe
                    </a>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMediaContent: () => dispatch(get_social_media_content_action())
});

export default connect(null, mapDispatchToProps)(SocialMedia);
