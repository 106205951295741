export const authConstants = {
    SIGNUP_USER_ACTION_REQUEST: 'SIGNUP_USER_ACTION_REQUEST',
    SIGNUP_USER_ACTION_SUCCESS: 'SIGNUP_USER_ACTION_SUCCESS',
    SIGNUP_USER_ACTION_FAILURE: 'SIGNUP_USER_ACTION_FAILURE',

    LOGIN_USER_ACTION_REQUEST: 'LOGIN_USER_ACTION_REQUEST',
    LOGIN_USER_ACTION_SUCCESS: 'LOGIN_USER_ACTION_SUCCESS',
    LOGIN_USER_ACTION_FAILURE: 'LOGIN_USER_ACTION_FAILURE',
    
    FORGOT_PASSWORD_ACTION_REQUEST: 'FORGOT_PASSWORD_ACTION_REQUEST',
    FORGOT_PASSWORD_ACTION_SUCCESS: 'FORGOT_PASSWORD_ACTION_SUCCESS',
    FORGOT_PASSWORD_ACTION_FAILURE: 'FORGOT_PASSWORD_ACTION_FAILURE',
    
    GOOGLE_AUTH_USER_ACTION_REQUEST: 'GOOGLE_AUTH_USER_ACTION_REQUEST',
    GOOGLE_AUTH_USER_ACTION_SUCCESS: 'GOOGLE_AUTH_USER_ACTION_SUCCESS',
    GOOGLE_AUTH_USER_ACTION_FAILURE: 'GOOGLE_AUTH_USER_ACTION_FAILURE',

    FACEBOOK_AUTH_USER_ACTION_REQUEST: 'FACEBOOK_AUTH_USER_ACTION_REQUEST',
    FACEBOOK_AUTH_USER_ACTION_SUCCESS: 'FACEBOOK_AUTH_USER_ACTION_SUCCESS',
    FACEBOOK_AUTH_USER_ACTION_FAILURE: 'FACEBOOK_AUTH_USER_ACTION_FAILURE',

    LOGOUT_USER_ACTION_REQUEST: 'LOGOUT_USER_ACTION_REQUEST',
    LOGOUT_USER_ACTION_SUCCESS: 'LOGOUT_USER_ACTION_SUCCESS',
    LOGOUT_USER_ACTION_FAILURE: 'LOGOUT_USER_ACTION_FAILURE',
    
    RESET_USER_PASSWORD_ACTION_REQUEST: 'RESET_USER_PASSWORD_ACTION_REQUEST',
    RESET_USER_PASSWORD_ACTION_SUCCESS: 'RESET_USER_PASSWORD_ACTION_SUCCESS',
    RESET_USER_PASSWORD_ACTION_FAILURE: 'RESET_USER_PASSWORD_ACTION_FAILURE',

    SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_REQUEST: 'SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_REQUEST',
    SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_SUCCESS: 'SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_SUCCESS',
    SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_FAILURE: 'SET_CLIENT_TOKEN_FOR_NOTIFICATION_ACTION_FAILURE',

}