import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { Container, Row, Col, Form } from "react-bootstrap";
import "./AllShows.scss";
import Header from "../../User/common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import NewEpisodes from "../common/NewEpisodes/NewEpisodes";
import {
  get_carousel_content_action,
  show_search_action,
} from "../../../_actions/User";
import { get_all_user_shows_action } from "../../../_actions/User/show.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import TopNotification from "../common/TopNotification/TopNotification";
import { get_announcement_table_list_action } from "../../../_actions/Admin";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";
import { debounce } from "../../../_helpers/debounce";

class AllShows extends Component {
  constructor(props) {
    super(props);

    this.search = debounce(this.searchHandler);
  }

  state = {
    showLoading: false,
    showTableData: [],
    loading: false,
    newAddition: null,
    searchResultData: [],
    searchStatus: false,
    searchEnabled: false,
    updatingAnnouncements: false,
    topNotifications: [],
    pagination: {
      pageSize: 25,
      current: sessionStorage.getItem("currentShowPagination")
        ? Number(sessionStorage.getItem("currentShowPagination"))
        : 1,
      total: 0,
    },
    userObject: null,
    searchText: null, // stack of chars
    promiseStack: [],
  };

  componentDidMount() {
    this.mountShowTableData();
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState(
      {
        userObject: userObject,
      },
      () => {
        if (userObject) {
          this.setContents();
          this.mountOrUpdateAnnouncements();
        }
      }
    );
  }

  mountShowTableData = async () => {
    const { pagination } = this.state;
    this.setState({ showLoading: true });

    const getAllShowsRes = await this.props.getAllUserSideShows(
      pagination.current
    );
    if (getAllShowsRes && getAllShowsRes.data && getAllShowsRes.data.results)
      this.setState((prevState) => ({
        showTableData: getAllShowsRes.data.results,
        pagination: {
          ...prevState.pagination,
          total: getAllShowsRes.data.count,
        },
      }));
    this.setState({ showLoading: false });
    sessionStorage.removeItem("currentShowPagination");
    window.scrollTo(0, 0);
  };

  setContents = async () => {
    this.setState({ loading: true });
    const carouselContentRes = await this.props.getCarouselContent();

    let LatestUpdated = [];
    let PopularShows = [];
    if (
      carouselContentRes &&
      carouselContentRes.data &&
      carouselContentRes.data.length
    ) {
      carouselContentRes.data.forEach((contentItem) => {
        if (contentItem["row_type"] === "show") {
          PopularShows = [...PopularShows, { ...contentItem }];
        }
        if (
          contentItem["row_type"] === "update" &&
          contentItem["content_type"] &&
          contentItem["content_type"] === "episode"
        ) {
          LatestUpdated = [...LatestUpdated, { ...contentItem }];
        }
      });
    }
    this.setState({
      newAddition: LatestUpdated.length
        ? LatestUpdated.sort((a, b) => a.priority - b.priority)
        : [],
    });

    this.setState({ loading: false });
  };

  routeHandler = (showId) => {
    this.props.history.push(`/view-show/${showId}`);
    sessionStorage.setItem(
      "currentShowPagination",
      this.state && this.state.pagination && this.state.pagination.current
    );
  };

  handleSearch = (e) => {
    e.preventDefault();
    let searchVal = e.target.value;
    let searchKeyword = { search: searchVal };
    // eslint-disable-next-line no-lone-blocks
    if (searchVal.length === "" || searchVal.length >= 2) {
      this.setState({ searchEnabled: true, searchResultData: [] });
      this.setState({ searchStatus: true, searchText: searchVal }, () => {
        this.search(searchKeyword);
      });
    } else {
      this.setState({ searchEnabled: false });
    }
  };

  checkSearch = () => {
    let val = this.state.promiseStack.filter(
      (item) => item.searchKey === this.state.searchText
    );
    if (val && val.length) {
      this.setState({ searchResultData: val[0].response });
    }
  };

  // search handler ===========
  searchHandler = async (searchKeyword) => {
    let searchResult = await this.props.showsSearchResultData(searchKeyword);

    let searchResultResponse =
      searchResult && searchResult.data && searchResult.data.results;

    if (searchResultResponse.length) {
      let newStack = this.state.promiseStack;

      newStack.push({
        searchKey: searchKeyword.search,
        response: searchResultResponse,
      });

      this.setState({ promiseStack: newStack }, () => {
        this.checkSearch();
      });
    }
    this.setState({ searchStatus: false });
  };

  mountOrUpdateAnnouncements = async () => {
    await this.setState({ updatingAnnouncements: true, topNotifications: [] });
    // re-fetch announcement details and show it again
    const getAnnouncementDetailsRes = await this.props.getAnnouncementData(
      "user"
    );
    if (
      getAnnouncementDetailsRes &&
      getAnnouncementDetailsRes.type &&
      getAnnouncementDetailsRes.type === "user"
    ) {
      if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.data
      ) {
        this.setState({
          topNotifications: getAnnouncementDetailsRes.response.data,
        });
      }
    }

    this.setState({ updatingAnnouncements: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title),
    });
    this.mountOrUpdateAnnouncements();
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountShowTableData();
      }
    );
  };

  render() {
    const {
      showLoading,
      pagination,
      showTableData,
      searchResultData,
      userObject,
      searchStatus,
    } = this.state;
    return (
      <div className="common-dashboard">
        <Container className="p-0">
          {/* <SidebarMenu
            alertNewNotification={(payload) =>
              this.openNewNotification(payload)
            }
            history={this.props.history}
          /> */}
          <div className="main-content bg-white position-relative">
            {userObject ? (
              <Header
                searchType="shows"
                history={this.props.history}
                activeMenuItem="shows"
              />
            ) : (
              <HeaderNew activeMenuItem="shows" history={this.props.history} />
            )}

            {/* search handler */}
            <div className="top-search-wrapper flex-grow-1 mt-4 mb-4 mr-0 mr-sm-2 mr-xl-5">
              <Form.Group className="mb-0 h-100 position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search Shows..."
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.handleSearch(e)}
                />
                <i className="fa fa-search fa-1x" aria-hidden="true"></i>
              </Form.Group>
            </div>

            {/* Announcement notifications */}
            {userObject ? (
              <>
                {this.state.topNotifications.length > 0 ? (
                  <TopNotification
                    updatingAnnouncements={this.state.updatingAnnouncements}
                    notifications={
                      this.state.topNotifications.length > 0
                        ? this.state.topNotifications
                        : []
                    }
                  />
                ) : this.state.updatingAnnouncements ? (
                  <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                    <ContentLoader color="text-warning" />
                  </div>
                ) : (
                  <p className="text-primary font-weight-medium">
                    No notifications to show!
                  </p>
                )}
              </>
            ) : null}

            {/* all video wrapper */}
            <div className="all-show-wrapper mt-3 mt-md-5">
              <NewEpisodes
                loading={this.state.loading}
                newVideos={this.state.newAddition}
                history={this.props.history}
              />
              {this.state.searchEnabled ? (
                <>
                  <h3 className="font-weight-semi pt-4 mb-3">Search Results</h3>

                  {searchStatus ? (
                    <ContentLoader color="text-warning mx-auto mt-5" />
                  ) : (
                    <Row className="search enabled">
                      {Array.isArray(searchResultData) &&
                        searchResultData.length === 0 ? (
                        <div className="text-center p-4 w-100">
                          <img
                            src={"/assets/images/error.png"}
                            alt="no videos"
                            className="p-3"
                          />
                          <p className="text-danger font-weight-bold">
                            Oops! Result Not Found
                          </p>
                        </div>
                      ) : (
                        Array.isArray(searchResultData) &&
                        searchResultData.map((show) => (
                          <Col key={show.content.id} md={4} className=" mb-5">
                            <div
                              className="show-box cursor-pointer"
                              onClick={() => this.routeHandler(show.content.id)}
                            >
                              <img
                                src={show.content["title_image"]}
                                alt="slider-img-1"
                                className="border-6 img-fit w-100"
                              />
                              <h4 className="title font-weight-semi my-2">
                                {show.content.title}
                              </h4>
                            </div>
                          </Col>
                        ))
                      )}
                    </Row>
                  )}
                </>
              ) : (
                <>
                  <h3 className="font-weight-semi pt-4 mb-3">All Shows</h3>

                  <Row>
                    {showLoading ? (
                      <ContentLoader color="text-warning mx-auto mt-5" />
                    ) : showTableData && showTableData.length ? (
                      showTableData &&
                      showTableData.map((show) => (
                        <Col key={show.id} md={4} className=" mb-5">
                          <div
                            className="show-box cursor-pointer"
                            onClick={() => this.routeHandler(show.id)}
                          >
                            <img
                              src={show["title_image"]}
                              alt="slider-img-1"
                              className="border-6 img-fit w-100"
                            />
                            <h4 className="title font-weight-semi mb-0 fs-24">
                              {show["title"]}
                            </h4>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div className="col-12 mt-4 py-3 py-md-4 text-center ">
                        <img
                          src={"/assets/images/noshows-home.png"}
                          alt="no videos"
                          className="border border-danger rounded"
                        />
                        <h4 className="text-danger">
                          No Shows Popular to Show
                        </h4>
                      </div>
                    )}
                  </Row>
                  {showTableData && showTableData.length ? (
                    <Pagination
                      current={pagination.current}
                      showSizeChanger={false}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      onChange={this.onHandlePaginationChange}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserSideShows: (page) => dispatch(get_all_user_shows_action(page)),
  getCarouselContent: () => dispatch(get_carousel_content_action()),
  showsSearchResultData: (data) => dispatch(show_search_action(data)),
  getAnnouncementData: (userType) =>
    dispatch(get_announcement_table_list_action(userType)),
});

export default connect(null, mapDispatchToProps)(AllShows);
