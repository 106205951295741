import React, { Component } from 'react';
import { connect } from 'react-redux'
import "./SavedVideo.scss";
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../common/Header/Header";
import SidebarMenu from '../common/SidebarMenu/SidebarMenu';
import { add_bookmark_to_post_action, get_saved_posts_action, get_saved_contents_action } from "../../../_actions/User/post.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import VideoPlayer from "../common/VideoPlayer/VideoPlayer";

class SavedVideo extends Component {

    state = {
        contentLoading: false,
        contents: [],
        getContentsError: false
    }

    componentDidMount() {
        this.mountSavedContents()
    }

    mountSavedContents = async () => {
        this.setState({ contentLoading: true })
        if (this.props && this.props.match && this.props.match.path == '/saved-post') {
            const getContent = await this.props.getSavedPosts();
            if (getContent && getContent.data) {
                this.setState({
                    contents: getContent.data,
                    getContentsError: false
                })
            } else
                this.setState({ getContentsError: true })

            this.setState({ contentLoading: false })
        } else {

            const getContent = await this.props.getSavedContents();
            if (getContent && getContent.data) {
                this.setState({
                    contents: getContent.data,
                    getContentsError: false
                })
            } else
                this.setState({ getContentsError: true })

            this.setState({ contentLoading: false })
        }
    }

    openPost = async (content) => {

        const { path } = this.props && this.props.match;
        if (path == '/saved-content') {
            let id = content.id;
            if (content.content_type == "video") {
                this.props.history.push(`/video/${id}`)
            } else if (content.content_type == "youtube-recipe") {
                this.props.history.push(`/recipe/${id}`)
            } else if (content.content_type == "episode") {
                this.props.history.push(`/watch-show/${id}`)
            } else if (content.content_type == "show") {
                this.props.history.push(`/view-show/${id}`)
            } else {
                return
            }

        } else if (path == '/saved-post') {
            let id = content.id;
            if (content.post_type !== "status") {
                // this.props.history.push(`/view-post/${id}`)
                this.props.history.push({
                    pathname: `/view-post/${id}`,
                    state: { detail: "saved-posts" }
                })

            } else {
                //   this.props.history.push(`/post-status/${id}`)
                this.props.history.push({
                    pathname: `/post-status/${id}`,
                    state: { detail: "saved-posts" }
                })
            }
        } else {
            return
        }


    }

    checkForLikeAndBookmark = async () => {
        if (this.props && this.props.match && this.props.match.path == '/saved-post') {
            const getContent = await this.props.getSavedPosts()
            this.setState({
                contents: getContent.data
            })
        } else if (this.props && this.props.match && this.props.match.path == '/saved-content') {
            const getContent = await this.props.getSavedContents()
            this.setState({
                contents: getContent.data
            })
        } else {
            return
        }
    }

    bookmarkContent = async (id, type) => {

        if (!id) return

        if (type == "youtube-recipe") {
            const data = {
                content_type: "youtube_recipe",
                id
            }
            await this.props.addBookmarkAction(data)
            await this.checkForLikeAndBookmark(id)
        } else {
            const data = {
                content_type: type,
                id
            }
            await this.props.addBookmarkAction(data)
            await this.checkForLikeAndBookmark(id)
        }


    }

    render() {
        const { contentLoading, contents, getContentsError } = this.state
        const { path } = this.props && this.props.match;

        return (
            <div className="common-dashboard saved-video">
                <Container className="p-0">
                    <SidebarMenu />
                    <div className="main-content bg-white ">
                        <Header />

                        <div className="saved-video-wrapper">
                            {/* back button */}
                            <a
                                href={"/user-profile"}
                                className="align-items-center d-none d-md-flex"
                            >
                                <img
                                    src={"/assets/images/back-arrow.svg"}
                                    alt="back"
                                    className="ml-md-n2"
                                />
                                <span className="text-dark fs-16 font-weight-bold ml-1">Back</span>
                            </a>

                            <Row>
                                <Col lg={8} className=" mb-5">
                                    {/**in case of saved dc contents */}
                                    {
                                        path == '/saved-content' ?
                                            (<>

                                                <div className="saved-video-box mt-2">
                                                    {
                                                        contentLoading ?
                                                            <div style={{
                                                                margin: "50px auto 0",
                                                                textAlign: 'center'
                                                            }}>
                                                                <ContentLoader color='text-warning' />
                                                            </div>
                                                            :

                                                            contents &&
                                                                contents.length ?
                                                                contents.map(content =>
                                                                    <div
                                                                        key={content.content.id}
                                                                        className="saved-video-content d-flex bg-grey px-3 px-md-5 py-3 py-md-4 border-6 mb-4"
                                                                    >
                                                                        <div className="video cursor-pointer" onClick={() => this.openPost(content.content)} >
                                                                            {
                                                                                content && content.content['video_playback_id'] ?
                                                                                    <VideoPlayer playbackId={content.content['video_playback_id']} />
                                                                                    :
                                                                                    content &&
                                                                                        (content.content['title_image'] ||
                                                                                            content.content['image'])
                                                                                        ? <>
                                                                                            <img
                                                                                                src={content.content['title_image'] || content.content['image']}
                                                                                                alt="slider-img-1"
                                                                                                className="saved-vdo-img border-6 img-fit w-100"
                                                                                            /></>
                                                                                        : null
                                                                            }
                                                                        </div>
                                                                        {/* ------options-for-video------- */}
                                                                        <div
                                                                            onClick={() => this.openPost(content.content)}
                                                                            className="video-option ml-md-4 ml-2 d-flex flex-column cursor-pointer"
                                                                        >
                                                                            <h5 className="font-montserrat">{content.content.title}</h5>
                                                                            <p className="font-smallest font-weight-bold">{content.content.show_name}{" "}

                                                                                {content && content.content && content.content.description && content.content.description.length > 150 ? (
                                                                                    <>{`${content.content.description.substring(0, 150)}...`}</>
                                                                                ) : (
                                                                                    <>{content.content.description}</>
                                                                                )}
                                                                                {/* {content.content.description} */}

                                                                            </p>
                                                                            {/*<p className="font-smallest  text-grey-3 mb-0 mt-auto">Time</p>*/}
                                                                        </div>
                                                                        {/* ------back-option-------- */}
                                                                        <div className="remove-video ml-auto cursor-pointer">
                                                                            {
                                                                                <a>
                                                                                    <img
                                                                                        onClick={() => this.bookmarkContent(content.content && content.content.id, content.content['content_type'] || content.content['post_type'])}
                                                                                        src={"/assets/images/remove-circle.svg"}
                                                                                        alt="remove video"
                                                                                    />
                                                                                </a>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                )
                                                                : getContentsError ?
                                                                    <p className="text-danger">Unable to get contents</p>
                                                                    : <p className="text-danger-2 font-weight-semi">No post found!</p>
                                                    }
                                                </div>
                                            </>)

                                            :
                                            <div className="saved-video-box mt-2">
                                                {
                                                    contentLoading ?
                                                        <div style={{
                                                            margin: "50px auto 0",
                                                            textAlign: 'center'
                                                        }}>
                                                            <ContentLoader color='text-warning' />
                                                        </div>
                                                        :

                                                        contents &&
                                                            contents.length ?
                                                            contents.map(content =>
                                                                <div
                                                                    key={content.id}
                                                                    className="saved-video-content d-flex bg-grey px-3 px-md-5 py-3 py-md-4 border-6 mb-4"
                                                                >
                                                                    <div className="video cursor-pointer" onClick={() => this.openPost(content)} >
                                                                        {
                                                                            content && content['video_playback_id'] ?
                                                                                <VideoPlayer playbackId={content['video_playback_id']} />
                                                                                :
                                                                                content &&
                                                                                    (content['title_image'] ||
                                                                                        content['image'])
                                                                                    ? <>
                                                                                        <img
                                                                                            src={content['title_image'] || content['image']}
                                                                                            alt="slider-img-1"
                                                                                            className="saved-vdo-img border-6 img-fit w-100"
                                                                                        /></>
                                                                                    : null
                                                                        }
                                                                    </div>
                                                                    {/* ------options-for-video------- */}
                                                                    <div
                                                                        onClick={() => this.openPost(content)}
                                                                        className="video-option ml-md-4 ml-2 d-flex flex-column cursor-pointer"
                                                                    >
                                                                        {/* <h5 className="font-montserrat">{content.title}</h5> */}

                                                                        {content.post_type == "status" ?
                                                                            <>
                                                                                <p className="font-smallest font-weight-bold">
                                                                                    
                                                                                    {/* {content.status_text} */}
                                                                                    
                                                                                    {content && content.status_text && content.status_text.length > 200 ? (
                                                                                        <>{`${content && content.status_text && content.status_text.substring(0, 200)}...`}</>
                                                                                    ) : (
                                                                                        <>{content && content.status_text}</>
                                                                                    )}
                                                                                    
                                                                                    </p>
                                                                                <p className="font-smallest font-weight-bold">


                                                                                    {content && content.description && content.description.length > 200 ? (
                                                                                        <>{`${content && content.description && content.description.substring(0, 200)}...`}</>
                                                                                    ) : (
                                                                                        <>{content && content.description}</>
                                                                                    )}
                                                                                    {/* {content.description} */}

                                                                                </p>
                                                                            </> :
                                                                            content.post_type == "recipe" ?
                                                                                <p className="font-smallest font-weight-bold">{content.recipe_name}</p> :

                                                                                content.post_type == "photo" || "video" ?
                                                                                    <p className="font-smallest font-weight-bold">

                                                                                        {content.description.length > 200 ? (
                                                                                            <>{`${content.description.substring(0, 200)}...`}</>
                                                                                        ) : (
                                                                                            <>{content.description}</>
                                                                                        )}


                                                                                        {/* {content.description} */}
                                                                                    </p> :

                                                                                    null
                                                                        }

                                                                        {/*<p className="font-smallest  text-grey-3 mb-0 mt-auto">Time</p>*/}
                                                                    </div>
                                                                    {/* ------back-option-------- */}
                                                                    <div className="remove-video ml-auto cursor-pointer">
                                                                        {
                                                                            <a>
                                                                                <img
                                                                                    onClick={() => this.bookmarkContent(content && content.id, content['content_type'] || content['post_type'])}
                                                                                    src={"/assets/images/remove-circle.svg"}
                                                                                    alt="remove video"
                                                                                />
                                                                            </a>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                            : getContentsError ?
                                                                <p className="text-danger">Unable to get contents</p>
                                                                : <p className="text-danger-2 font-weight-semi">No post found!</p>
                                                }
                                            </div>

                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    getSavedPosts: () => dispatch(get_saved_posts_action()),
    addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data)),
    getSavedContents: () => dispatch(get_saved_contents_action()),
})

export default connect(null, mapDispatchToProps)(SavedVideo);