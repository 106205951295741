import { groceryConstants } from "../../_constants/User/grocery.constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// get grocery list from backend
export const get_grocery_list_action = () => async (dispatch) => {
    dispatch({
        type: groceryConstants.GET_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userGrocery.getAllgrocery)
        if (response.status) {
            dispatch({
                type: groceryConstants.GET_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.GET_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get grocery list from backend
export const get_grocery_list_byid_action = (id) => async (dispatch) => {
    dispatch({
        type: groceryConstants.GET_GROCERY_LIST_BYID_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.userGrocery.getGroceryListById.replace(":id", id))
        if (response.status) {
            dispatch({
                type: groceryConstants.GET_GROCERY_LIST_BYID_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.GET_GROCERY_LIST_BYID_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//add grocery list action
export const add_grocery_list_action = (data) => async (dispatch) => {
    dispatch({
        type: groceryConstants.ADD_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.userGrocery.addGroceryList, data)
        if (response.status) {
            dispatch({
                type: groceryConstants.ADD_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.ADD_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//add item to grocery list action

export const add_item_to_grocery_list_action = (data) => async (dispatch) => {
    dispatch({
        type: groceryConstants.ADD_ITEM_TO_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.userGrocery.addItemToGroceryList, data)
        if (response.status) {
            dispatch({
                type: groceryConstants.ADD_ITEM_TO_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.ADD_ITEM_TO_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//delete_item_from_grocery_list_action
export const delete_item_from_grocery_list_action = (id) => async (dispatch) => {
    dispatch({
        type: groceryConstants.DELETE_ITEM_FROM_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.userGrocery.deleteItemFromGroceryList.replace(":id", id))
        if (response.status) {
            dispatch({
                type: groceryConstants.DELETE_ITEM_FROM_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.DELETE_ITEM_FROM_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//toggle_checkbox_action

export const toggle_checkbox_action = (id, data) => async (dispatch) => {
    dispatch({
        type: groceryConstants.TOGGELE_CHECKBOX_REQUEST
    })
    try {
        const response = await axiosInstance.patch(config.userGrocery.toggleCheckbox.replace(":id", id), data)
        if (response.status) {
            dispatch({
                type: groceryConstants.TOGGELE_CHECKBOX_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.TOGGELE_CHECKBOX_FAILURE,
            payload: e.msg
        })
    }
}

//remove_grocery_list_action
export const remove_grocery_list_action = (id) => async (dispatch) => {
    dispatch({
        type: groceryConstants.REMOVE_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.userGrocery.removeGroceryList.replace(":id", id))
        if (response.status) {
            dispatch({
                type: groceryConstants.REMOVE_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.REMOVE_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

//add_grocery_item_to_list_action
export const add_grocery_item_to_list_action = (id, data) => async (dispatch) => {
    dispatch({
        type: groceryConstants.ADD_GROCERY_ITEM_TO_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.patch(config.userGrocery.addGroceryItemToList.replace(":id", id), data)
        if (response.status) {
            dispatch({
                type: groceryConstants.ADD_GROCERY_ITEM_TO_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.ADD_GROCERY_ITEM_TO_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}



//delete_item_from_grocery_list_action
export const delete_items_by_recipe_from_grocery_list_action = (groceryListId, recipeId) => async (dispatch) => {
    dispatch({
        type: groceryConstants.DELETE_ITEMS_BY_RECIPE_FROM_GROCERY_LIST_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.delete(config.userGrocery.deleteItemsByRecipeFromGroceryList.replace(":grocery_list_id", groceryListId).replace(":recipe_id", recipeId))
        if (response.status) {
            dispatch({
                type: groceryConstants.DELETE_ITEMS_BY_RECIPE_FROM_GROCERY_LIST_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: groceryConstants.DELETE_ITEMS_BY_RECIPE_FROM_GROCERY_LIST_ACTION_FAILURE,
            payload: e.msg
        })
    }
}