export const uploadEpisodeConstants = {
    UPLOAD_EPISODE_DETAILS_ACTION_REQUEST: 'UPLOAD_EPISODE_DETAILS_ACTION_REQUEST',
    UPLOAD_EPISODE_DETAILS_ACTION_SUCCESS: 'UPLOAD_EPISODE_DETAILS_ACTION_SUCCESS',
    UPLOAD_EPISODE_DETAILS_ACTION_FAILURE: 'UPLOAD_EPISODE_DETAILS_ACTION_FAILURE',

    GET_EPISODE_DETAILS_ACTION_REQUEST:'GET_EPISODE_DETAILS_ACTION_REQUEST',
    GET_EPISODE_DETAILS_ACTION_SUCCESS:'GET_EPISODE_DETAILS_ACTION_SUCCESS',
    GET_EPISODE_DETAILS_ACTION_FAILURE:'GET_EPISODE_DETAILS_ACTION_FAILURE',

    GET_USER_SIDE_EPISODE_DETAILS_ACTION_REQUEST:'GET_USER_SIDE_EPISODE_DETAILS_ACTION_REQUEST',
    GET_USER_SIDE_EPISODE_DETAILS_ACTION_SUCCESS:'GET_USER_SIDE_EPISODE_DETAILS_ACTION_SUCCESS',
    GET_USER_SIDE_EPISODE_DETAILS_ACTION_FAILURE:'GET_USER_SIDE_EPISODE_DETAILS_ACTION_FAILURE',

    UPDATE_EPISODE_DETAILS_ACTION_REQUEST:'UPDATE_EPISODE_DETAILS_ACTION_REQUEST',
    UPDATE_EPISODE_DETAILS_ACTION_SUCCESS:'UPDATE_EPISODE_DETAILS_ACTION_SUCCESS',
    UPDATE_EPISODE_DETAILS_ACTION_FAILURE:'UPDATE_EPISODE_DETAILS_ACTION_FAILURE',

    DELETE_EPISODE_DETAILS_ACTION_REQUEST:'DELETE_EPISODE_DETAILS_ACTION_REQUEST',
    DELETE_EPISODE_DETAILS_ACTION_SUCCESS:'DELETE_EPISODE_DETAILS_ACTION_SUCCESS',
    DELETE_EPISODE_DETAILS_ACTION_FAILURE:'DELETE_EPISODE_DETAILS_ACTION_FAILURE',
}