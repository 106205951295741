import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FlashSale.scss";

class FlashSale extends Component {
  routeHandler = () => {
    this.props.history.push("/collections");
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
      ],
    };
    return (
      <div>
        {/* flash sale slider */}
        <div className="flash-sale-slider mx-n1">
          <Slider {...settings}>
            <div className="sale-preview cursor-pointer px-2 position-relative">
              <div className="img-box border-6" onClick={this.routeHandler}>
                <img
                  src="/assets/images/sale-item-1.svg"
                  alt="sale-img-1"
                  className="img-fit w-100"
                />
              </div>
              <h2 className="flash-sale-heading fs-36 text-white position-absolute">
                FLash Sale
              </h2>
              {/* <span className="tag bg-warning text-white position-absolute px-2">New Arrivals</span> */}
            </div>
            <div className="sale-preview cursor-pointer px-2 position-relative">
              <div className="img-box border-6" onClick={this.routeHandler}>
                <img
                  src="/assets/images/sale-item-2.svg"
                  alt="sale-img-2"
                  className="img-fit w-100"
                />
              </div>{" "}
              <span className="tag bg-warning text-white position-absolute px-2">
                New Arrivals
              </span>
            </div>
            <div className="sale-preview cursor-pointer px-2 position-relative">
              <div className="img-box border-6" onClick={this.routeHandler}>
                <img
                  src="/assets/images/sale-item-3.svg"
                  alt="sale-img-3"
                  className="img-fit w-100"
                />
              </div>{" "}
              {/* <span className="tag bg-warning text-white position-absolute px-2">New Arrivals</span> */}
            </div>
            <div className="sale-preview cursor-pointer px-2 position-relative">
              <div className="img-box border-6" onClick={this.routeHandler}>
                <img
                  src="/assets/images/sale-item-1.svg"
                  alt="sale-img-4"
                  className="img-fit w-100"
                />
              </div>{" "}
              {/* <span className="tag bg-warning text-white position-absolute px-2">New Arrivals</span> */}
            </div>

            <div className="sale-preview cursor-pointer px-2 position-relative">
              <div className="img-box border-6" onClick={this.routeHandler}>
                <img
                  src="/assets/images/sale-item-2.svg"
                  alt="sale-img-1"
                  className="img-fit w-100"
                />
              </div>{" "}
              {/* <span className="tag bg-warning text-white position-absolute px-2">New Arrivals</span> */}
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default FlashSale;
