import { homePageConstants } from "../../_constants/Admin";

const initialState = {};
export const update_top_left_home_reducer = (state = initialState, action) => {
  switch (action.type) {
    case homePageConstants.UPDATE_TOP_LEFT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        updatedTopLeftContent: action.payload
      };
    case homePageConstants.UPDATE_TOP_RIGHT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        updatedTopRightContent: action.payload
      };
    case homePageConstants.GET_TOP_LEFT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        homePageTopLeftContent: action.payload
      };
    case homePageConstants.GET_TOP_RIGHT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        homePageTopRightContent: action.payload
      };
    case homePageConstants.UPDATE_TOP_LEFT_SECTION_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case homePageConstants.UPDATE_TOP_RIGHT_SECTION_ACTION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case homePageConstants.ADD_HEADING_TOP_RIGHT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        topRightHeading: action.payload
      };
    case homePageConstants.GET_EXPERT_SECTION_ACTION_SUCCESS:
    case homePageConstants.UPDATE_EXPERT_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        expertSection: action.payload
      };
    case homePageConstants.ADD_FEATURES_SECTION_ACTION_SUCCESS:
    case homePageConstants.UPDATE_FEATURES_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        featuresSection: action.payload
      };
    case homePageConstants.GET_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS:
    case homePageConstants.UPDATE_SOCIAL_MEDIA_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        socialMediaSection: action.payload
      };
    case homePageConstants.UPDATE_GALLERY_HEADING_SECTION_ACTION_SUCCESS:
    case homePageConstants.GET_GALLERY_SECTION_HEADING_ACTION_SUCCESS:
      return {
        ...state,
        galleryHeadingSection: action.payload
      };
    case homePageConstants.POST_GALLERY_SECTION_ACTION_SUCCESS:
    case homePageConstants.UPDATE_GALLERY_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        gallerySection: action.payload
      };

    case homePageConstants.GET_APP_SECTION_ACTION_SUCCESS:
    case homePageConstants.UPDATE_APP_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        appSection: action.payload
      };

    case homePageConstants.GET_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS:
      // case homePageConstants.UPDATE_APP_SECTION_ACTION_SUCCESS:
      return {
        ...state,
        recipeCategoryTitleData: action.payload
      };

    case homePageConstants.GET_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS:
    case homePageConstants.POST_RECIPE_CATEGORY_TITLES_ACTION_SUCCESS:
      return {
        ...state,
        categorySectionTitle: action.payload
      };
    default:
      return state;
  }
};




