import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewestRecipe.scss";
import {
  get_category_section_action,
  post_category_section_action,
  get_recipe_category_titles_action,
} from "../../../../../../_actions/Admin/homePage.action";
import { connect } from "react-redux";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import swal from "sweetalert";

export class NewestRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RecipesContent: [],
      tags: [],
      inputVisible: false,
      inputValue: "",
      categoryName: "",
      sortedBy: "created_date",
    };
  }
  componentDidMount() {
    this.getCategoryRecipesData();
    this.getCategoryTitles();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.recipeCategoryTitleData !== this.props.recipeCategoryTitleData
    ) {
      let titles = this.props && this.props.recipeCategoryTitleData;
      let rankOneData =
        titles &&
        titles.filter((title) => title.rank === 1) &&
        titles.filter((title) => title.rank === 1)[0];

      if (rankOneData && rankOneData.length) {
        this.setState({
          tags: rankOneData && rankOneData.tags_names,
          categoryName: rankOneData && rankOneData.name,
          sortedBy: rankOneData && rankOneData.sort_by,
        });
      }
    }
  }
  getCategoryRecipesData = async () => {
    let data = await this.props.getCategoryRecipesRankOne(1);

    if (data && data.data) {
      this.setState({ RecipesContent: data.data });
    }
  };

  getCategoryTitles = () => {
    this.props.getCategoryTitles();
  };
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };
  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };
  saveInputRef = (input) => {
    this.input = input;
  };

  redirectRecipeHandler = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          this.props.history.push(
            `/video/${recipe?.video_details && recipe.video_details?.id}`
          );
          return;
        case "show":
          this.props.history.push(
            `/view-show/${recipe?.show_details && recipe.show_details?.id}`
          );
          return;
        case "episode":
          this.props.history.push(
            `/watch-show/${
              recipe?.episode_details && recipe.episode_details?.id
            }`
          );
          return;
        case "youtube-recipe":
          this.props.history.push(
            `/recipe/${
              recipe?.youtube_recipe_details &&
              recipe.youtube_recipe_details?.id
            }`
          );
          return;
        default:
          return null;
      }
    }
  };

  getRecipeTitle = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.title;
        case "show":
          return recipe?.show_details && recipe.show_details?.title;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.title;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.title
          );
        default:
          return null;
      }
    }
  };

  getRecipeImage = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.title_image;
        case "show":
          return recipe?.show_details && recipe.show_details?.title_image;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.title_image;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.title_image
          );
        default:
          return null;
      }
    }
  };

  getRecipeDescription = (recipe) => {
    if (recipe?.content_type) {
      switch (recipe?.content_type) {
        case "video":
          return recipe?.video_details && recipe.video_details?.description;
        case "show":
          return recipe?.show_details && recipe.show_details?.description;
        case "episode":
          return recipe?.episode_details && recipe.episode_details?.description;
        case "youtube-recipe":
          return (
            recipe?.youtube_recipe_details &&
            recipe.youtube_recipe_details?.description
          );
        default:
          return null;
      }
    }
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        color="#883e7f"
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  postCategoryRecipeHandler = () => {
    let { categoryName, tags, sortedBy } = this.state;
    if (categoryName !== "") {
      let data = {
        name: categoryName,
        tags: tags,
        sort_by: sortedBy,
      };
      this.props.postCategoryRecipes(1, data);
      swal({
        title: "Success!",
        text: "Category recipes updated successfully!",
        icon: "success",
      }).then(() => {
        this.getCategoryRecipesData();
        this.getCategoryTitles();
      });
    } else {
      swal({
        title: "Oops!",
        text: "Please Enter Category Name!",
        icon: "error",
      });
      this.getCategoryTitles();
    }
  };
  render() {
    const {
      categoryName,
      sortedBy,
      tags,
      inputVisible,
      inputValue,
      RecipesContent,
    } = this.state;
    const tagChild = tags.map(this.forMap);
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="recipe-section">
        <div className="row mx-n1">
          <div className="col-lg-3 px-1">
            <div className="form-group">
              <input
                className="form-control h-auto py-2"
                type="text"
                placeholder="Enter Category Name"
                value={categoryName}
                onChange={(e) =>
                  this.setState({ categoryName: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-lg-3 px-1">
            <div className="form-group tags-select">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="btn-block bg-grey after-content-none rounded py-2 pl-3 pr-2 text-left d-flex justify-content-between align-items-center"
                  id="dropdown-autoclose-true"
                >
                  Select a tag
                  <span className="fa fa-angle-down font-weight-bold fs-16 text-dark"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="tag-selector-dropdown p-2 w-100 mw-100">
                  <div style={{ marginBottom: 5 }}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: "from",
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === "appear" || e.type === "enter") {
                          e.target.style = "display: inline-block";
                        }
                      }}
                      leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                      appear={false}
                    >
                      {tagChild}
                    </TweenOneGroup>
                  </div>
                  {inputVisible && (
                    <Input
                      ref={this.saveInputRef}
                      type="text"
                      size="small"
                      style={{ width: 78 }}
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag onClick={this.showInput} className="site-tag-plus">
                      <PlusOutlined /> New Tag
                    </Tag>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="col-lg-3 px-1">
            <div className="form-group">
              <select
                className="form-control h-auto py-2"
                onChange={(e) => {
                  this.setState({ sortedBy: e.target.value });
                }}
              >
                <option
                  selected={sortedBy === "created_date" ? true : false}
                  value="created_date"
                >
                  Date Added
                </option>
                <option
                  selected={sortedBy === "likes_count" ? true : false}
                  value="likes_count"
                >
                  Most Liked
                </option>
                {/* <option selected={sortedBy === "view_count" ? true: false} value="view_count">Most Viewed</option> */}
                <option
                  selected={sortedBy === "comment_count" ? true : false}
                  value="comment_count"
                >
                  Most Commented
                </option>
              </select>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <button
                className="btn btn-primary py-2 px-4"
                onClick={this.postCategoryRecipeHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {RecipesContent && RecipesContent.length ? (
          <>
            <Slider {...settings}>
              {RecipesContent &&
                RecipesContent.map((recipe) => (
                  <div className="recipe-wrapper border-radius-20 position-relative">
                    {recipe && this.getRecipeImage(recipe) ? (
                      <>
                        <span className="recipe-img-wrapper bg-grey-2">
                          <img
                            src={this.getRecipeImage(recipe)}
                            alt={this.getRecipeTitle(recipe)}
                            className="tutotials-img img-fit img-fluid"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="recipe-img-wrapper bg-grey-2">
                          <img
                            src={"/assets/images/placeholder-recipe.jpg"}
                            alt={"Placeholder"}
                            className="tutotials-img img-fit img-fluid"
                          />
                        </span>
                      </>
                    )}

                    <div className="recipe-content-wrapper h-auto p-4 bg-grey-10">
                      <div className="top-section d-flex align-items-center justify-content-between">
                        <h4 className="recipe-title mr-1">
                          {this.getRecipeTitle(recipe)}
                        </h4>
                      </div>
                      <p className="fs-16 text-dark-2 mt-3">
                        {this.getRecipeDescription(recipe)}
                      </p>

                      <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                        <p className="fs-14 text-grey-4 text-nowrap mb-0">
                          {recipe && recipe.updated.substring(0, 10)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        ) : (
          <div className="text-center mt-2">
            <img
              src="/assets/images/no-recepies.png"
              alt="premium-icon"
              className="img-fit img-fluid"
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recipeCategoryTitleData:
      state.update_top_left_home_reducer.recipeCategoryTitleData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategoryRecipesRankOne: (rank) =>
    dispatch(get_category_section_action(rank)),
  postCategoryRecipes: (rank, data) =>
    dispatch(post_category_section_action(rank, data)),
  getCategoryTitles: () => dispatch(get_recipe_category_titles_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewestRecipe);
