import { reportsConstants } from "../../_constants";


const initialState = {}
export const reported_content_reducer = (state = initialState, action) => {
    switch (action.type) {
        case reportsConstants.GET_ALL_REPORTED_CONTENT_ACTION_SUCCESS:
            return {
                ...state,
                reportedItems: action.payload
            }
        case reportsConstants.GET_REPORTED_POST_ACTION_SUCCESS: 
            return {
                ...state,
                reportedPost: action.payload
            }
        case reportsConstants.TAKE_ON_REPORT_ACTION_SUCCESS: 
            return {
                ...state,
                reportedAction: action.payload
            }
        case reportsConstants.GET_REPORTED_COMMENT_ACTION_SUCCESS:
            return {
                ...state,
                reportedComment: action.payload
            }
        default:
            return state;
    }
}