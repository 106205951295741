import React, { Component } from "react";
import { Container, Form, Modal, Button } from "react-bootstrap";
import "./UploadShowContent.scss";
import { connect } from "react-redux";
import { save_show_details_action } from "../../../../../../_actions/Admin/uploadShow.action";
import { get_saved_show_details_action } from "../../../../../../_actions/Admin/uploadShow.action";
import { withRouter } from "react-router-dom";
import { update_show_details_action } from "../../../../../../_actions/Admin/uploadShow.action";
import {
  delete_episode_details_action,
  update_episode_details_action,
  get_episode_details_action,
} from "../../../../../../_actions/Admin/uploadEpisode.action";
import { add_post_tags_action } from "../../../../../../_actions/Admin";
import ContentLoader from "../../../../../Common/Loader/ContentLoader";
import swal from "sweetalert";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
class UploadShowContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteActionModal: false,
      publishActionModal: false,
      imageName: "",
      coverImageName: "",
      loading: false,
      successMessage: "",
      errorText: "",
      inputError: { type: "", text: "" },
      isApiLoading: false,

      isChangesSaved: false,
      savedShow: "",
      imageSrc: "",
      coverImageSrc: "",
      showDesc: "",
      showName: "",
      deleteEpisodeId: "",
      isPublish: false,
      publishEpisodeId: "",
      episodeDetail: "",
      instructionActionModal: false,
      publishEpisodeName: "",
      currentShowId:
        this.props && this.props.match
          ? this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.contentId
          : "",
      tags: [],
      inputVisible: false,
      inputValue: "",
    };
  }

  componentWillMount = async () => {
    const { params } = this.props && this.props.match;
    if (params && params.contentType == "show") {
      this.setState({ currentShowId: Number(params.contentId) }, async () => {
        const savedShowDetails = await this.props.getSavedShow(
          this.state.currentShowId
        );
        this.setState({
          savedShow: savedShowDetails.data,
          imageSrc: savedShowDetails.data.title_image,
          coverImageSrc: savedShowDetails.data.cover_image,
          showDesc: savedShowDetails.data.description,
          showName: savedShowDetails.data.title,
          tags: savedShowDetails.data.tags_names,
        });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => ({
      updatedShow: nextProps && nextProps.updatedShow,
      savedShow: nextProps && nextProps.savedShow,
      imageSrc:
        nextProps && nextProps.savedShow && nextProps.savedShow.title_image,
      coverImageSrc:
        nextProps && nextProps.savedShow && nextProps.savedShow.cover_image,
      showDesc:
        nextProps && nextProps.savedShow && nextProps.savedShow.description,
      showName: nextProps && nextProps.savedShow && nextProps.savedShow.title,
      isPublish:
        nextProps &&
        nextProps.episodeDetail &&
        nextProps.episodeDetail.is_publish,
    }));
  }

  deleteActionModalHandler = (episode) => {
    if (episode && episode.is_premium == false) {
      this.setState({
        deleteActionModal: !this.state.deleteActionModal,
        deleteEpisodeId: episode.id,
      });
    } else {
      this.setState({
        instructionActionModal: !this.state.instructionActionModal,
      });
    }
  };
  deleteActionModalHandlerClose = () => {
    this.setState({
      deleteActionModal: !this.state.deleteActionModal,
    });
  };
  instructionActionModalHandler = () => {
    this.setState({
      instructionActionModal: !this.state.instructionActionModal,
    });
  };
  deleteEpisode = async () => {
    const { deleteEpisodeId } = this.state;
    const deletedEpisode = await this.props.onDeleteEpisode(deleteEpisodeId);
    this.setState(
      {
        deleteActionModal: !this.state.deleteActionModal,
        deleteEpisodeId: "",
      },
      async () => {
        const { savedShow } = this.state;
        const savedShow1 = await this.props.getSavedShow(savedShow.id);
        this.setState({ savedShow: savedShow1.data });
      }
    );
  };
  publishActionModalHandler = (episode) => {
    episode &&
      this.setState({
        publishActionModal: !this.state.publishActionModal,
        publishEpisodeId: episode.id,
        publishEpisodeName: episode.title,
      });
  };

  handlePublishEpisode = async () => {
    const { publishEpisodeId } = this.state;
    this.setState({
      isApiLoading: true,
    });
    await this.props.onPublishEpisode(publishEpisodeId, { is_publish: true });

    const { savedShow } = this.state;
    if (savedShow.is_publish == false) {
      await this.props.updateShow(savedShow.id, { is_publish: true });
    }
    const updatedShow = await this.props.getSavedShow(savedShow.id);

    this.setState({
      publishActionModal: !this.state.publishActionModal,
      publishEpisodeId: "",
      savedShow: updatedShow.data,
      successMessage: "Episode is published successfully!",
      isApiLoading: false,
    });
  };
  handleImageChange = (e, type) => {
    const file = e.target.files[0];

    if (type == "title") {
      this.setState((prevState) => ({
        imageSrc: file ? file : prevState.imageSrc,
        imageName: file && file.name ? file.name : prevState.imageName,
      }));
    } else if (type == "cover") {
      this.setState((prevState) => ({
        coverImageSrc: file ? file : prevState.imageSrc,
        coverImageName: file && file.name ? file.name : prevState.imageName,
      }));
    } else {
      return;
    }
  };
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "showName" && !this.state.showName.length) {
        this.setState({
          inputError: { type: "showName", text: `Title is required!` },
        });
      } else if (name === "showDesc" && !this.state.showDesc.length) {
        this.setState({
          inputError: { type: "showDesc", text: `Description is required!` },
        });
      } else {
        this.setState({ inputError: { type: "", text: `` } });
      }
    });
  };

  handleContentSubmit = async (e) => {
    e.preventDefault();

    const { imageSrc, coverImageSrc, showDesc, showName, isChangesSaved } =
      this.state;
    const params = this.props && this.props.match && this.props.match.params;
    if (params && params.contentType == "show") {
      const { imageSrc, coverImageSrc, showDesc, showName, savedShow } =
        this.state;
      //HIT put api if,changes are already done for one time

      const savedShowId = savedShow && savedShow.id;
      this.setState({ loading: true });
      this.setState({ isApiLoading: true });
      const detailsForm = new FormData();
      detailsForm.append("title", showName);
      detailsForm.append("description", showDesc);

      if (typeof imageSrc !== "string") {
        detailsForm.append("title_image", imageSrc);
      }
      if (typeof coverImageSrc !== "string") {
        detailsForm.append("cover_image", coverImageSrc);
      }

      const updateShow = await this.props.updateShow(savedShowId, detailsForm);
      const savedUpdatedShow = await this.props.getSavedShow(savedShowId);

      this.setState({
        savedShow: savedUpdatedShow.data,
        loading: false,
        isApiLoading: false,
        successMessage: `Your show details updated successfully!`,
      });
    } else if (!imageSrc || !coverImageSrc || !showDesc || !showName) {
      return;
    } else {
      const { imageSrc, coverImageSrc, showDesc, showName } = this.state;

      this.setState({ loading: true });
      this.setState({ isApiLoading: true });
      const detailsForm = new FormData();
      detailsForm.append("title", showName);
      detailsForm.append("description", showDesc);
      detailsForm.append("title_image", imageSrc);
      detailsForm.append("cover_image", coverImageSrc);
      const saveShowRes = await this.props.onSaveShowDetails(detailsForm);
      await this.props.getSavedShow(saveShowRes.data.id);
      this.setState(
        {
          savedShow: saveShowRes.data,
          loading: false,
          isChangesSaved: true,
          successMessage: `Your show details have been saved successfully!`,
          isApiLoading: false,
          currentShowId: saveShowRes.data.id,
        },
        () => {
          this.props.history.push(
            `/update-show-content/show/${saveShowRes.data.id}`
          );
          // window.location.reload()
        }
      );
    }
  };
  handleAddEpisode = () => {
    const { isChangesSaved } = this.state;
    if (!isChangesSaved) {
      this.setState({
        errorText: "You need to save changes before adding episodes.",
      });
    }
    this.props.history.push(
      `/upload-episode-content/show/${this.state.currentShowId}`
    );
  };
  editEpisode = (episode) => {
    this.props.history.push(`/update-episode-content/episode/${episode.id}`);
    // this.props.history.push({ pathname:'/upload-new-episode', search:`?query=${episode.id}` , state:episode,})
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags }, async () => {
      let { tags, savedShow } = this.state;
      const tagsData = {
        tags: tags,

        show: savedShow && savedShow.id,
      };
      await this.props.addTags(tagsData);
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue, savedShow } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState(
      {
        tags,
        inputVisible: false,
        inputValue: "",
      },
      async () => {
        let tags = this.state.tags;
        const tagsData = {
          tags: tags,
          show: savedShow && savedShow.id,
        };
        await this.props.addTags(tagsData);
      }
    );
  };

  saveInputRef = (input) => {
    this.input = input;
  };
  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        color="#87449a"
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };
  render() {
    const { params } = this.props && this.props.match;
    const {
      imageSrc,
      imageName,
      coverImageName,
      coverImageSrc,
      showDesc,
      showName,
      isChangesSaved,
      inputError,
      savedShow,
      isPublish,
      successMessage,
      errorText,
      isApiLoading,
      currentShowId,
      tags,
      inputVisible,
      inputValue,
    } = this.state;
    const tagChild = tags.map(this.forMap);

    return (
      <div className="form-outer bg-grey-6 fs-16 font-weight-semi">

        <Form.Group className="bg-grey-4 p-2 text-black">
          {/* Form name input */}
          <Form.Control
            type="text"
            name="showName"
            value={showName}
            onChange={this.handleChange}
            placeholder="Show Name"
            className={`font-weight-semi fs-16 text-black ${inputError.type === "showName" ? "input-invalid" : ""
              }`}
            required
          />
          {inputError.type === "showName" && inputError.text.length && (
            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
              {inputError.text}
            </p>
          )}
          {/*   {!showName && submitTriggered ? (
                        <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                        Field required!
                        </p>
                    ) : null} */}
        </Form.Group>

        <Form className="">
          <Form.Group className="bg-grey-4 p-3 ">
            <Form.Label className="mb-2">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              // className="bg-white"
              name="showDesc"
              value={showDesc}
              onChange={this.handleChange}
              className={`font-weight-semi fs-16 text-black bg-white ${inputError.type === "showDesc" ? "input-invalid" : ""
                }`}
            />
            {inputError.type === "showDesc" && inputError.text.length && (
              <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
                {inputError.text}
              </p>
            )}
          </Form.Group>

          <Form.Group className="bg-grey-4 p-3">
            <Form.Label className="mb-2">Images</Form.Label>
            <label
              htmlFor="titleimg"
              className="btn btn-block bg-white rounded"
            >
              <input
                type="file"
                className="d-none"
                id="titleimg"
                name="titleImage"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => this.handleImageChange(e, "title")}
              />

              {imageName ? imageName : <>
                <span className="fa fa-upload mr-3"></span>Add Title Image
              </>}

              {params.contentType == "show" && imageSrc ? (
                <img src={imageSrc} className="mt-3" alt="" />
              ) : null}
            </label>

            <label
              htmlFor="coverimg"
              className="btn btn-block bg-white rounded"
            >
              <input
                type="file"
                className="d-none"
                id="coverimg"
                name="coverImage"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => this.handleImageChange(e, "cover")}
              />
              {coverImageName ? coverImageName : "Add cover Image"}
              {params.contentType == "show" && coverImageSrc ? (
                <img src={coverImageSrc} alt="" />
              ) : null}
            </label>
          </Form.Group>

          <Form.Group controlId="formBasicfile" className="bg-grey-4 p-3">
            <Form.Label className="mb-2">Episodes</Form.Label>

            {/* remove 'd-none'  class to hide Episodes list or vice versa   */}
            {savedShow && savedShow.episodes.length
              ? savedShow.episodes.map((episode) => (
                <ul className="Episodes-list py-4 px-4 font-weight-bold">
                  <li className="d-flex align-items-center">
                    <span className="flex-grow-1 mr-2">{episode.title}</span>
                    <div className="d-flex align-items-center pl-4 justify-content-end ml-auto">
                      {
                        !episode.is_publish ? (
                          <a
                            className="mr-4"
                            onClick={() =>
                              this.publishActionModalHandler(episode)
                            }
                          >
                            <span className="btn  btn-success  py-2 fs-14 px-2  rounded">
                              Publish Episode
                            </span>
                          </a>
                        ) : null
                        /* (
                               <a className="ml-auto" onClick={()=>this.publishActionModalHandler(episode)}>
                                  <span className="btn  btn-success  py-2 fs-14 px-2  rounded">
                                  Publish Changes
                                  </span>
                              </a>
                              ) */
                      }

                      <a
                        href="#"
                        className="ml-auto"
                        style={{ flex: " 0 0 23px" }}
                        onClick={() => this.editEpisode(episode)}
                      >
                        <img
                          src="/assets/images/add-content.svg"
                          alt="edit"
                        />
                      </a>
                      <a
                        href="#" style={{ flex: " 0 0 23px" }}
                        onClick={() => this.deleteActionModalHandler(episode)}
                        className="ml-3"
                      >
                        <img
                          src="/assets/images/delete-content.svg"
                          alt="delete"
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              ))
              : null}

            {/* Episodes list ends  */}

            <a className="bg-white">
              <button
                className="btn btn-block bg-white font-weight-semi fs-16 rounded"
                onClick={this.handleAddEpisode}
                disabled={!currentShowId ? true : false}
              >
                Add Episode
              </button>
            </a>

            {/* <Form.File as="file"  className="bg-white px-2 py-3"/> */}
          </Form.Group>
          {savedShow ? (
            <Form.Group>
              <div className="bg-grey-4 p-3 fs-16 font-weight-semi">
                <p className="fs-14 font-weight-bold text-grey-5 mb-3">
                  Add Tags
                </p>
                <>
                  <div style={{ marginBottom: 16 }}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: "from",
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === "appear" || e.type === "enter") {
                          e.target.style = "display: inline-block";
                        }
                      }}
                      leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                      }}
                      appear={false}
                    >
                      {tagChild}
                    </TweenOneGroup>
                  </div>
                  {inputVisible && (
                    <Input
                      ref={this.saveInputRef}
                      type="text"
                      size="small"
                      style={{ width: 78 }}
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag onClick={this.showInput} className="site-tag-plus">
                      <PlusOutlined /> New Tag
                    </Tag>
                  )}
                </>
              </div>
            </Form.Group>
          ) : null}
          <div className="action fs-14 d-flex justify-content-end">
            <button
              className="btn btn-outline-dark py-2 px-3 font-weight-400 rounded"
              onClick={() => this.props.history.push(`/upload-content-listing`)}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary py-2 px-3 font-weight-400 ml-3 rounded"
              disabled={
                !imageSrc || !coverImageSrc || !showDesc || !showName
                  ? true
                  : false
              }
              onClick={this.handleContentSubmit}
            >
              {isApiLoading ? (
                <ContentLoader color="text-white" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
          {errorText.length ? (
            <p className="fs-14 text-danger-2 font-source-sans text-left font-weight-semi">
              {errorText}
            </p>
          ) : (
            ""
          )}
          {successMessage.length ? (
            <p className="fs-14 text-success-2 font-source-sans text-left font-weight-semi">
              {successMessage}
            </p>
          ) : (
            ""
          )}
        </Form>

        {/*=================== popups ======================== */}

        {/* delete action modal */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.deleteActionModal}
          onHide={this.deleteActionModalHandlerClose}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Are you sure you want to delete this Episode? All the recipes
              within Episode will also be deleted. All data will be lost.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.deleteActionModalHandlerClose}
              variant="outline-dark"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="rounded"
              onClick={this.deleteEpisode}
            >
              Delete Show
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete action modal Ends */}

        {/* publish action popup starts */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.publishActionModal}
          onHide={this.publishActionModalHandler}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              Confirm : Publish episode {this.state.publishEpisodeName} on
              DariusCooks.tv
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.publishActionModalHandler}
              variant="outline-dark"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className="rounded"
              onClick={this.handlePublishEpisode}
            >
              Publish Episode
            </Button>
          </Modal.Footer>
        </Modal>
        {/* publish action popup ends */}

        {/* NOT delete Episode -INSTRUCTION modal starts */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.instructionActionModal}
          onHide={this.instructionActionModalHandler}
          className="text-center delete-action-popup"
        >
          <Modal.Body className="delete-action-popup-content">
            <p className="font-poppins fs-18 text-center font-weight-medium">
              You cannot delete this episode since it has been marked as premium
              content.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 fs-14 font-weight-semi">
            <Button
              onClick={this.instructionActionModalHandler}
              variant="outline-dark"
              className="rounded"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* NOT delete Episode -INSTRUCTION modal Ends */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // publishedContent: state.publish_video_content_reducer,
    // updatedShow:state.update_show_reducer,
    savedShow:
      state.saved_show_reducer &&
      state.saved_show_reducer.updatedShow &&
      state.saved_show_reducer.updatedShow.data,

    episodeDetail:
      state.get_episode_reducer &&
      state.get_episode_reducer.updatedShow &&
      state.get_episode_reducer.updatedShow.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // onSaveVideoDetails: (data) => dispatch(save_video_details_action(data)),
  onSaveShowDetails: (data) => dispatch(save_show_details_action(data)),
  getSavedShow: (showId) => dispatch(get_saved_show_details_action(showId)),
  updateShow: (showId, data) =>
    dispatch(update_show_details_action(showId, data)),
  onDeleteEpisode: (showId) => dispatch(delete_episode_details_action(showId)),
  onPublishEpisode: (id, data) =>
    dispatch(update_episode_details_action(id, data)),
  getEpisodeDetail: (id) => dispatch(get_episode_details_action(id)),
  addTags: (data) => dispatch(add_post_tags_action(data)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadShowContent)
);
