import { combineReducers } from "redux";
import {
    signup_user_reducer,
    login_user_reducer,
    logout_user_reducer,
    forgot_password_user_reducer,
    google_auth_user_reducer,
    reset_password_user_reducer,
    facebook_auth_user_reducer
} from "./common/authentication.reducer";

import {
    get_user_details_reducer,
    get_prev_user_details_reducer,
    get_next_user_details_reducer,
    get_csv_data_reducer,
} from "./Admin/userTable.reducer";

import {
    carousel_items_reducer,
    add_to_dashboard_items_reducer,
} from "./Admin/carousel.reducer";

import {
    content_manager_reducer,
    add_connected_recipe_reducer,
    get_connected_recipe_reducer,
    add_recipe_video_content_reducer,
    publish_recipe_video_content_reducer,
    get_recipe_listing_reducer,
    edit_recipe_listing_reducer,
    delete_recipe_listing_reducer,
    upload_faq_question_reducer,
    load_faq_table_list_reducer,
    delete_faq_topic_reducer,
    fetch_faq_topic_details_reducer,
    new_faq_subtopic_addition_for_topic_reducer,
    delete_subTopic_reducer,
    get_saved_video_changes_reducer,
    get_announcement_table_list_reducer,
    delete_announcement_reducer,
    get_single_announcement_details_reducer,
} from "./Admin/content.reducer";

import {
    get_carousel_content_reducer,
    get_user_episode_details_reducer
} from "./User/content.reducer";

import {
    save_video_details_reducer,
    publish_video_content_reducer,
} from "./Admin/uploadVideo.reducer";

import {
    update_show_reducer,
    saved_show_reducer,
} from "./Admin/uploadShow.reducer";
import { get_episode_reducer } from "./Admin/uploadEpisode.reducer";
import { get_faq_content_reducer } from "./User/faq.reducer";
import {
    get_recipe_content_reducer,
    get_all_user_recipes_reducer,
} from "./User/recipe.reducer";
import {
    get_grocery_list_reducer
} from './User/grocery.reducer';
import {
    get_video_content_reducer,
    get_all_user_videos_reducer,
} from "./User/video.reducer";

import {
    get_all_user_shows_reducer,
    get_show_details_reducer
} from "./User/show.reducer";

import {
    get_profile_content_reducer,
    edit_user_name_reducer,
    edit_user_image_reducer,
    change_password_reducer,
} from "./User/profile.reducer";

import {
    get_notification_reducer
} from "./User/notification.reducer";

import {
    header_search_result_reducer,
    header_search_query_result_reducer,
    video_search_result_reducer,
    recipe_search_result_reducer,
    shows_search_result_reducer,
} from "./User/headerSearch.reducer";
import {
    add_bookmark_to_post_reducer,
    add_comment_reducer,
    add_post_reducer, delete_comment_by_id_action,
    delete_post_by_id_reducer,
    get_all_user_posts_reducer, get_comments_by_id_reducer, get_saved_contents_reducer,
    get_status_by_id_reducer, hit_like_reducer, update_post_by_id_reducer
} from "./User/post.reducer";

import { reported_content_reducer } from "./Admin/reports.reducer";
//livestream
import {get_current_livestream_playbackid_reducer,get_livestream_viewers_count_reducer} from './User/livestream.reducer';

//Homepage
import { update_top_left_home_reducer } from './Admin/homePage.reducer';
import {get_expert_info_reducer} from "./User/video.reducer";

const rootReducer = combineReducers({
    signup_user_reducer,
    login_user_reducer,
    logout_user_reducer,
    forgot_password_user_reducer,
    google_auth_user_reducer,
    reset_password_user_reducer,
    get_user_details_reducer,
    get_prev_user_details_reducer,
    get_next_user_details_reducer,
    facebook_auth_user_reducer,
    get_csv_data_reducer,

    //Carousel Reducer
    carousel_items_reducer,
    add_to_dashboard_items_reducer,

    //upload video content
    save_video_details_reducer,
    publish_video_content_reducer,

    //contend management
    content_manager_reducer,
    add_connected_recipe_reducer,
    get_connected_recipe_reducer,
    add_recipe_video_content_reducer,
    publish_recipe_video_content_reducer,
    get_recipe_listing_reducer,
    edit_recipe_listing_reducer,
    delete_recipe_listing_reducer,
    upload_faq_question_reducer,
    load_faq_table_list_reducer,
    delete_faq_topic_reducer,
    fetch_faq_topic_details_reducer,
    new_faq_subtopic_addition_for_topic_reducer,
    delete_subTopic_reducer,
    get_saved_video_changes_reducer,
    get_announcement_table_list_reducer,
    delete_announcement_reducer,
    get_single_announcement_details_reducer,

    // user homepage
    get_carousel_content_reducer,
    get_user_episode_details_reducer,

    //get show
    update_show_reducer,
    saved_show_reducer,

    //getEpisode
    get_episode_reducer,

    // user faq
    get_faq_content_reducer,

    //user  recipe
    get_recipe_content_reducer,
    get_all_user_recipes_reducer,

    //user grocery
    get_grocery_list_reducer,

    // user video
    get_video_content_reducer,

    // all user
    get_all_user_videos_reducer,

    // all show
    get_all_user_shows_reducer,
    get_show_details_reducer,

    // user profile
    get_profile_content_reducer,
    edit_user_name_reducer,
    edit_user_image_reducer,
    change_password_reducer,

    // user header search
    header_search_result_reducer,
    header_search_query_result_reducer,
    video_search_result_reducer,
    recipe_search_result_reducer,
    shows_search_result_reducer,

    // notification
    get_notification_reducer,

    // user posts
    add_post_reducer,
    get_all_user_posts_reducer,
    get_status_by_id_reducer,
    delete_post_by_id_reducer,
    update_post_by_id_reducer,
    hit_like_reducer,
    add_comment_reducer,
    get_comments_by_id_reducer,
    delete_comment_by_id_action,
    add_bookmark_to_post_reducer,
    get_saved_contents_reducer,

    //Reports
    reported_content_reducer,

    //livestream
    get_current_livestream_playbackid_reducer,
    get_livestream_viewers_count_reducer,

    //HomePage
    update_top_left_home_reducer,

    get_expert_info_reducer
});

export default rootReducer;