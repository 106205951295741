import {userTableConstants} from "../../_constants";


const initialState = {}
export const get_user_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case userTableConstants.GET_USER_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case userTableConstants.GET_USER_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case userTableConstants.GET_USER_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_prev_user_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case userTableConstants.GET_PREV_USER_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case userTableConstants.GET_PREV_USER_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case userTableConstants.GET_PREV_USER_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

export const get_next_user_details_reducer = (state = initialState, action) => {
    switch (action.type) {
        case userTableConstants.GET_NEXT_USER_DETAILS_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case userTableConstants.GET_NEXT_USER_DETAILS_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case userTableConstants.GET_NEXT_USER_DETAILS_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}


export const get_csv_data_reducer = (state = initialState, action) => {
    switch (action.type) {
        case userTableConstants.GET_CSV_DATA_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case userTableConstants.GET_CSV_DATA_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case userTableConstants.GET_CSV_DATA_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}