import React from "react";
import { Container, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { notification } from "antd";
import ReactHtmlparser from "react-html-parser";
import "./HomePage.scss";

import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import AppAvilable from "./AppAvilable/AppAvilable";
import ContactUs from "./ContactUs/ContactUs";
import ExistingFeatures from "./ExistingFeatures/ExistingFeatures";
import HeaderNew from "./HeaderNew/HeaderNew";
import MeetExpert from "./MeetExpert/MeetExpert";
import SideBarNew from "./SideBarNew/SideBarNew";
import SocialMedia from "./SocialMedia/SocialMedia";
import SubscribeForUpdate from "./SubscribeForUpdate/SubscribeForUpdate";
import FoodGallery from "./FoodGallery/FoodGallery";
import WatchTutorials from "./WatchTutorials/WatchTutorials";
import RecipesSection from "./RecipesSection/RecipesSection";
import TopNotification from "../common/TopNotification/TopNotification";
import {
  get_carousel_content_action,
  header_search_action,
  header_search_query_action,
} from "../../../_actions/User";
import Loader from "../../Common/Loader/FullPageLoader";
import { get_announcement_table_list_action } from "../../../_actions/Admin";
import ContentLoader from "../../Common/Loader/ContentLoader";
import { Dropdown, Card, ListGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CopyRight from "../HomePageNew/CopyRight/CopyRight";
import swal from "sweetalert";
class HomePage extends React.Component {
  state = {
    loading: false,
    recipeList: null,
    videoList: null,
    showList: null,
    newAddition: null,
    updatingAnnouncements: false,
    topNotifications: [],
    searchResultsData: {},
    searchQueryResult: [],
    searchKeyword: "",
    pagination: {
      next: "",
      total: 1,
      current: 1,
      pageSize: 25,
      previous: "",
    },
  };

  componentDidMount() {
    this.setContents();
    this.mountOrUpdateAnnouncements();
  }

  setContents = async () => {
    this.setState({ loading: true });

    const carouselContentRes = await this.props.getCarouselContent();

    let PopularShows = [];
    let LatestUpdated = [];
    let PopularVideos = [];
    let PopularRecipes = [];
    if (
      carouselContentRes &&
      carouselContentRes.data &&
      carouselContentRes.data.length
    ) {
      carouselContentRes.data.forEach((contentItem) => {
        if (contentItem["row_type"] === "youtube_recipe") {
          PopularRecipes = [...PopularRecipes, { ...contentItem }];
        }
        if (contentItem["row_type"] === "video") {
          PopularVideos = [...PopularVideos, { ...contentItem }];
        }
        if (contentItem["row_type"] === "show") {
          PopularShows = [...PopularShows, { ...contentItem }];
        }
        if (contentItem["row_type"] === "update") {
          LatestUpdated = [...LatestUpdated, { ...contentItem }];
        }
      });
    }
    this.setState({
      recipeList: PopularRecipes.length
        ? PopularRecipes.sort((a, b) => a.priority - b.priority)
        : [],
      videoList: PopularVideos.length
        ? PopularVideos.sort((a, b) => a.priority - b.priority)
        : [],
      newAddition: LatestUpdated.length
        ? LatestUpdated.sort((a, b) => a.priority - b.priority)
        : [],
      showList: PopularShows.length
        ? PopularShows.sort((a, b) => a.priority - b.priority)
        : [],
    });

    this.setState({ loading: false });
  };

  // search handler ===========
  getSearchKeyword = async (e) => {
    this.setState({ searchKeyword: e.target.value });
    let searchText = e.target.value;
    if (searchText.length > 0) {
      const res = await this.props.searchQueryResult({ search: searchText });
      this.setState({ searchQueryResult: res });
      return;
    } else {
      this.setState({ searchQueryResult: [] });
    }

    //this.setState({ searchKeyword: searchText });
  };

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => ({
      searchQueryResult: nextProps && nextProps.searchQueryResultData,
    }));
  }

  searchHandler = async (e) => {
    e.preventDefault();
    let { searchKeyword} = this.state;
    if (searchKeyword) {
      this.props.history.push({
        pathname: "/search-results",
        state: {
          searchKeyword: searchKeyword
        },
      });
      return;
    }
  };

  mountOrUpdateAnnouncements = async () => {
    await this.setState({ updatingAnnouncements: true, topNotifications: [] });

    // re-fetch announcement details and show it again
    const getAnnouncementDetailsRes = await this.props.getAnnouncementData(
      "user"
    );

    if (
      getAnnouncementDetailsRes &&
      getAnnouncementDetailsRes.type &&
      getAnnouncementDetailsRes.type === "user"
    ) {
      if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.data &&
        getAnnouncementDetailsRes.response.data.results
      ) {
        this.setState((prevState) => ({
          topNotifications: getAnnouncementDetailsRes.response.data.results,
          pagination: {
            ...prevState.pagination,
            total:
              getAnnouncementDetailsRes.response.data.count === 0
                ? 1
                : getAnnouncementDetailsRes.response.data.count,
            next: getAnnouncementDetailsRes.response.data.next,
            previous: getAnnouncementDetailsRes.response.data.previous,
          },
        }));
      } else if (
        getAnnouncementDetailsRes &&
        getAnnouncementDetailsRes.response &&
        getAnnouncementDetailsRes.response.msg
      ) {
        swal(getAnnouncementDetailsRes.response.msg);
      }
    }

    this.setState({ updatingAnnouncements: false });
  };

  openNewNotification = (payload) => {
    notification["info"]({
      message: ReactHtmlparser(payload.body),
      description: ReactHtmlparser(payload.title),
    });
    this.mountOrUpdateAnnouncements();
  };

  handleSearchClick = (data) => {
    if (data && data.content_type === "video") {
      this.props.history.push(`/video/${data && data.id}`);
    } else if (data && data.content_type === "show") {
      this.props.history.push(`/view-show/${data && data.id}`);
    } else if (data && data.content_type === "youtube-recipe") {
      this.props.history.push(`/recipe/${data && data.id}`);
    } else if (data && data.content_type === "episode") {
      this.props.history.push(`/watch-show/${data && data.id}`);
    }
  };

  closeSearchHandler = () => {
    this.setState({ searchQueryResult: [], searchKeyword: "" });
  };
  render() {
    return (
      <>
        <div
          className="common-dashboard user-home-page-dashboard"
          onClick={this.closeSearchHandler}
        >
          <Container className="p-0">
            <SidebarMenu
              alertNewNotification={(payload) =>
                this.openNewNotification(payload)
              }
              history={this.props.history}
            />
            <div className="main-content  bg-white position-relative">
              <Header activeMenuItem="home" />

              {/* search handler */}
              <div className="top-search-wrapper flex-grow-1 mb-4">
                {/* <Form onSubmit={(e) => this.searchHandler(e)}>  */}
                <Form className="position-relative" onSubmit={(e) => this.searchHandler(e)}>
                  <Form.Group className="mb-0 h-100 position-relative">
                    <Form.Control
                      type="text"
                      placeholder="Search for Recipes, Shows, Videos.."
                      // value={this.state.searchKeyword}
                      className="search-bar bg-grey-4 border-6 pl-4"
                      onChange={(e) => this.getSearchKeyword(e)}
                    />
                  </Form.Group>
                  {this.state &&
                    this.state.searchQueryResult &&
                    this.state.searchQueryResult.data ? (
                    <Card className="position-absolute">
                      <ListGroup variant="flush">
                        {this.state.searchQueryResult &&
                          this.state.searchQueryResult.data &&
                          this.state.searchQueryResult.data.map((data) => (
                            <>
                              <ListGroup.Item
                                className="cursor-pointer d-flex align-items-center"
                                key={data.id}
                                onClick={() => this.handleSearchClick(data)}
                              >
                                {data.is_premium ? (
                                  <img
                                    src={"assets/images/premium-icon.svg"}
                                    alt="premium"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      marginRight: "10px",
                                    }}
                                  />
                                ) : null}
                                <>
                                  {data.title}:
                                  <em className="text-info ml-2">
                                    {data &&
                                      data.content_type === "youtube-recipe"
                                      ? "Recipe"
                                      : data.content_type}
                                  </em>
                                </>
                              </ListGroup.Item>
                            </>
                          ))}
                      </ListGroup>
                    </Card>
                  ) : null}
                  <i className="fa fa-search fa-1x" aria-hidden="true"></i>
                </Form>
              </div>

              {this.state.loading ? (
                <Loader color="text-warning" />
              ) : (
                <>
                  {this.state.topNotifications.length > 0 ? (
                    <TopNotification
                      updatingAnnouncements={this.state.updatingAnnouncements}
                      notifications={
                        this.state.topNotifications.length > 0
                          ? this.state.topNotifications
                          : []
                      }
                    />
                  ) : this.state.updatingAnnouncements ? (
                    <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                      <ContentLoader color="text-warning" />
                    </div>
                  ) : (
                    <p className="text-primary font-weight-medium">
                      No notifications to show!
                    </p>
                  )}
                  <div className="mt-4">
                    <RecipesSection history={this.props.history} />
                    <WatchTutorials history={this.props.history} />
                    <MeetExpert history={this.props.history} />
                    <AppAvilable history={this.props.history} />
                    {/* <ExistingFeatures history={this.props.history} /> */}
                    <SocialMedia history={this.props.history} />
                    <FoodGallery history={this.props.history} />
                    {/* <SubscribeForUpdate history={this.props.history} /> */}
                    <ContactUs history={this.props.history} />
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>
        <CopyRight />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchQueryResultData:
      state.header_search_query_result_reducer &&
      state.header_search_query_result_reducer.data &&
      state.header_search_query_result_reducer.data.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCarouselContent: () => dispatch(get_carousel_content_action()),
  getAnnouncementData: (userType) =>
    dispatch(get_announcement_table_list_action(userType)),
  searchResultData: (data) => dispatch(header_search_action(data)),
  searchQueryResult: (data) => dispatch(header_search_query_action(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
