export const userTableConstants = {
    GET_USER_DETAILS_ACTION_REQUEST: 'GET_USER_DETAILS_ACTION_REQUEST',
    GET_USER_DETAILS_ACTION_SUCCESS: 'GET_USER_DETAILS_ACTION_SUCCESS',
    GET_USER_DETAILS_ACTION_FAILURE: 'GET_USER_DETAILS_ACTION_FAILURE',

    SEARCH_USER_DETAILS_ACTION_REQUEST: 'SEARCH_USER_DETAILS_ACTION_REQUEST',
    SEARCH_USER_DETAILS_ACTION_SUCCESS: 'SEARCH_USER_DETAILS_ACTION_SUCCESS',
    SEARCH_USER_DETAILS_ACTION_FAILURE: 'SEARCH_USER_DETAILS_ACTION_FAILURE',

    GET_PREV_USER_DETAILS_ACTION_REQUEST: 'GET_PREV_USER_DETAILS_ACTION_REQUEST',
    GET_PREV_USER_DETAILS_ACTION_SUCCESS: 'GET_PREV_USER_DETAILS_ACTION_SUCCESS',
    GET_PREV_USER_DETAILS_ACTION_FAILURE: 'GET_PREV_USER_DETAILS_ACTION_FAILURE',

    GET_NEXT_USER_DETAILS_ACTION_REQUEST: 'GET_NEXT_USER_DETAILS_ACTION_REQUEST',
    GET_NEXT_USER_DETAILS_ACTION_SUCCESS: 'GET_NEXT_USER_DETAILS_ACTION_SUCCESS',
    GET_NEXT_USER_DETAILS_ACTION_FAILURE: 'GET_NEXT_USER_DETAILS_ACTION_FAILURE',

    GET_CSV_DATA_ACTION_REQUEST: 'GET_CSV_DATA_ACTION_REQUEST',
    GET_CSV_DATA_ACTION_SUCCESS: 'GET_CSV_DATA_ACTION_SUCCESS',
    GET_CSV_DATA_ACTION_FAILURE: 'GET_CSV_DATA_ACTION_FAILURE'
}