export const contentConstants = {

    LOAD_RECIPE_DETAILS_ACTION_REQUEST: 'LOAD_RECIPE_DETAILS_ACTION_REQUEST',
    LOAD_RECIPE_DETAILS_ACTION_SUCCESS: 'LOAD_RECIPE_DETAILS_ACTION_SUCCESS',
    LOAD_RECIPE_DETAILS_ACTION_FAILURE: 'LOAD_RECIPE_DETAILS_ACTION_FAILURE',

    GET_SAVED_CHANGES_ACTION_REQUEST: 'GET_SAVED_CHANGES_ACTION_REQUEST',
    GET_SAVED_CHANGES_ACTION_SUCCESS: 'GET_SAVED_CHANGES_ACTION_SUCCESS',
    GET_SAVED_CHANGES_ACTION_FAILURE: 'GET_SAVED_CHANGES_ACTION_FAILURE',

    GET_SAVED_VIDEO_CHANGES_ACTION_REQUEST: 'GET_SAVED_VIDEO_CHANGES_ACTION_REQUEST',
    GET_SAVED_VIDEO_CHANGES_ACTION_SUCCESS: 'GET_SAVED_VIDEO_CHANGES_ACTION_SUCCESS',
    GET_SAVED_VIDEO_CHANGES_ACTION_FAILURE: 'GET_SAVED_VIDEO_CHANGES_ACTION_FAILURE',

    SAVE_RECIPE_CHANGES_ACTION_REQUEST: 'SAVE_RECIPE_CHANGES_ACTION_REQUEST',
    SAVE_RECIPE_CHANGES_ACTION_SUCCESS: 'SAVE_RECIPE_CHANGES_ACTION_SUCCESS',
    SAVE_RECIPE_CHANGES_ACTION_FAILURE: 'SAVE_RECIPE_CHANGES_ACTION_FAILURE',

    DELETE_RECIPE_CHANGES_ACTION_REQUEST: 'DELETE_RECIPE_CHANGES_ACTION_REQUEST',
    DELETE_RECIPE_CHANGES_ACTION_SUCCESS: 'DELETE_RECIPE_CHANGES_ACTION_SUCCESS',
    DELETE_RECIPE_CHANGES_ACTION_FAILURE: 'DELETE_RECIPE_CHANGES_ACTION_FAILURE',

    PUBLISH_RECIPE_CHANGES_ACTION_REQUEST: 'PUBLISH_RECIPE_CHANGES_ACTION_REQUEST',
    PUBLISH_RECIPE_CHANGES_ACTION_SUCCESS: 'PUBLISH_RECIPE_CHANGES_ACTION_SUCCESS',
    PUBLISH_RECIPE_CHANGES_ACTION_FAILURE: 'PUBLISH_RECIPE_CHANGES_ACTION_FAILURE',

    SAVE_CONNECTED_RECIPE_ACTION_REQUEST: 'SAVE_CONNECTED_RECIPE_ACTION_REQUEST',
    SAVE_CONNECTED_RECIPE_ACTION_SUCCESS: 'SAVE_CONNECTED_RECIPE_ACTION_SUCCESS',
    SAVE_CONNECTED_RECIPE_ACTION_FAILURE: 'SAVE_CONNECTED_RECIPE_ACTION_FAILURE',

    DELETE_CONNECTED_RECIPE_ACTION_REQUEST: 'DELETE_CONNECTED_RECIPE_ACTION_REQUEST',
    DELETE_CONNECTED_RECIPE_ACTION_SUCCESS: 'DELETE_CONNECTED_RECIPE_ACTION_SUCCESS',
    DELETE_CONNECTED_RECIPE_ACTION_FAILURE: 'DELETE_CONNECTED_RECIPE_ACTION_FAILURE',

    GET_CONNECTED_RECIPE_BY_ID_ACTION_REQUEST: 'GET_CONNECTED_RECIPE_BY_ID_ACTION_REQUEST',
    GET_CONNECTED_RECIPE_BY_ID_ACTION_SUCCESS: 'GET_CONNECTED_RECIPE_BY_ID_ACTION_SUCCESS',
    GET_CONNECTED_RECIPE_BY_ID_ACTION_FAILURE: 'GET_CONNECTED_RECIPE_BY_ID_ACTION_FAILURE', 

    //CONTENT MANAGEMENT
    SAVED_CONTENT_ACTION_SUCCESS: 'SAVED_CONTENT_ACTION_SUCCESS',
    SAVED_CONNECTED_RECIPE_ACTION_SUCCESS: 'SAVED_CONNECTED_RECIPE_ACTION_SUCCESS',

    GET_RECIPE_LISTING_REQUEST: 'GET_RECIPE_LISTING_REQUEST',
    GET_RECIPE_LISTING_SUCCESS: 'GET_RECIPE_LISTING_SUCCESS',
    GET_RECIPE_LISTING_FAILURE: 'GET_RECIPE_LISTING_FAILURE', 

    SEARCH_RECIPE_LISTING_REQUEST: 'SEARCH_RECIPE_LISTING_REQUEST',
    SEARCH_RECIPE_LISTING_SUCCESS: 'SEARCH_RECIPE_LISTING_SUCCESS',
    SEARCH_RECIPE_LISTING_FAILURE: 'SEARCH_RECIPE_LISTING_FAILURE', 

    GET_PREV_RECIPE_LISTING_ACTION_REQUEST: 'GET_PREV_RECIPE_LISTING_ACTION_REQUEST',
    GET_PREV_RECIPE_LISTING_ACTION_SUCCESS: 'GET_PREV_RECIPE_LISTING_ACTION_SUCCESS',
    GET_PREV_RECIPE_LISTING_ACTION_FAILURE: 'GET_PREV_RECIPE_LISTING_ACTION_FAILURE',

    GET_NEXT_RECIPE_LISTING_ACTION_REQUEST: 'GET_NEXT_RECIPE_LISTING_ACTION_REQUEST',
    GET_NEXT_RECIPE_LISTING_ACTION_SUCCESS: 'GET_NEXT_RECIPE_LISTING_ACTION_SUCCESS',
    GET_NEXT_RECIPE_LISTING_ACTION_FAILURE: 'GET_NEXT_RECIPE_LISTING_ACTION_FAILURE',

    EDIT_RECIPE_LISTING_REQUEST: 'EDIT_RECIPE_LISTING_REQUEST',
    EDIT_RECIPE_LISTING_SUCCESS: 'EDIT_RECIPE_LISTING_SUCCESS',
    EDIT_RECIPE_LISTING_FAILURE: 'EDIT_RECIPE_LISTING_FAILURE', 

    DELETE_RECIPE_LISTING_REQUEST: 'DELETE_RECIPE_LISTING_REQUEST',
    DELETE_RECIPE_LISTING_SUCCESS: 'DELETE_RECIPE_LISTING_SUCCESS',
    DELETE_RECIPE_LISTING_FAILURE: 'DELETE_RECIPE_LISTING_FAILURE',

    UPDATE_RECIPE_DETAILS_ACTION_REQUEST: 'UPDATE_RECIPE_DETAILS_ACTION_REQUEST',
    UPDATE_RECIPE_DETAILS_ACTION_SUCCESS: 'UPDATE_RECIPE_DETAILS_ACTION_SUCCESS',
    UPDATE_RECIPE_DETAILS_ACTION_FAILURE: 'UPDATE_RECIPE_DETAILS_ACTION_FAILURE',

    // FAQ
    CREATE_FAQ_ACTION_REQUEST: 'CREATE_FAQ_ACTION_REQUEST',
    CREATE_FAQ_ACTION_SUCCESS: 'CREATE_FAQ_ACTION_SUCCESS',
    CREATE_FAQ_ACTION_FAILURE: 'CREATE_FAQ_ACTION_FAILURE',

    SEARCH_FAQ_ACTION_REQUEST: 'SEARCH_FAQ_ACTION_REQUEST',
    SEARCH_FAQ_ACTION_SUCCESS: 'SEARCH_FAQ_ACTION_SUCCESS',
    SEARCH_FAQ_ACTION_FAILURE: 'SEARCH_FAQ_ACTION_FAILURE',

    UPDATE_FAQ_TOPIC_ACTION_REQUEST: 'UPDATE_FAQ_TOPIC_ACTION_REQUEST',
    UPDATE_FAQ_TOPIC_ACTION_SUCCESS: 'UPDATE_FAQ_TOPIC_ACTION_SUCCESS',
    UPDATE_FAQ_TOPIC_ACTION_FAILURE: 'UPDATE_FAQ_TOPIC_ACTION_FAILURE',

    GET_FAQ_TABLE_DATA_LIST_ACTION_REQUEST: 'GET_FAQ_TABLE_DATA_LIST_ACTION_REQUEST',
    GET_FAQ_TABLE_DATA_LIST_ACTION_SUCCESS: 'GET_FAQ_TABLE_DATA_LIST_ACTION_SUCCESS',
    GET_FAQ_TABLE_DATA_LIST_ACTION_FAILURE: 'GET_FAQ_TABLE_DATA_LIST_ACTION_FAILURE',
    
    DELETE_FAQ_TOPIC_ACTION_REQUEST: 'DELETE_FAQ_TOPIC_ACTION_REQUEST',
    DELETE_FAQ_TOPIC_ACTION_SUCCESS: 'DELETE_FAQ_TOPIC_ACTION_SUCCESS',
    DELETE_FAQ_TOPIC_ACTION_FAILURE: 'DELETE_FAQ_TOPIC_ACTION_FAILURE',

    GET_FAQ_TOPIC_DETAILS_ACTION_REQUEST: 'GET_FAQ_TOPIC_DETAILS_ACTION_REQUEST',
    GET_FAQ_TOPIC_DETAILS_ACTION_SUCCESS: 'GET_FAQ_TOPIC_DETAILS_ACTION_SUCCESS',
    GET_FAQ_TOPIC_DETAILS_ACTION_FAILURE: 'GET_FAQ_TOPIC_DETAILS_ACTION_FAILURE',

    NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_REQUEST: 'NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_REQUEST',
    NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_SUCCESS: 'NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_SUCCESS',
    NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_FAILURE: 'NEW_SUBTOPIC_ADDITION_FOR_TOPIC_ACTION_FAILURE',

    DELETE_FAQ_SUBTOPIC_ACTION_REQUEST: 'DELETE_FAQ_SUBTOPIC_ACTION_REQUEST',
    DELETE_FAQ_SUBTOPIC_ACTION_SUCCESS: 'DELETE_FAQ_SUBTOPIC_ACTION_SUCCESS',
    DELETE_FAQ_SUBTOPIC_ACTION_FAILURE: 'DELETE_FAQ_SUBTOPIC_ACTION_FAILURE',

    // ANNOUNCEMENT
    GET_ANNOUNCEMENT_TABLE_DATA_ACTION_REQUEST: 'GET_ANNOUNCEMENT_TABLE_DATA_ACTION_REQUEST',
    GET_ANNOUNCEMENT_TABLE_DATA_ACTION_SUCCESS: 'GET_ANNOUNCEMENT_TABLE_DATA_ACTION_SUCCESS',
    GET_ANNOUNCEMENT_TABLE_DATA_ACTION_FAILURE: 'GET_ANNOUNCEMENT_TABLE_DATA_ACTION_FAILURE',

    GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_REQUEST: 'GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_REQUEST',
    GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_SUCCESS: 'GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_SUCCESS',
    GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_FAILURE: 'GET_ANNOUNCEMENT_SEARCH_TABLE_DATA_ACTION_FAILURE',

    DELETE_ANNOUNCEMENT_ACTION_REQUEST: 'DELETE_ANNOUNCEMENT_ACTION_REQUEST',
    DELETE_ANNOUNCEMENT_ACTION_SUCCESS: 'DELETE_ANNOUNCEMENT_ACTION_SUCCESS',
    DELETE_ANNOUNCEMENT_ACTION_FAILURE: 'DELETE_ANNOUNCEMENT_ACTION_FAILURE',

    ADD_NEW_ANNOUNCEMENT_ACTION_REQUEST: 'ADD_NEW_ANNOUNCEMENT_ACTION_REQUEST',
    ADD_NEW_ANNOUNCEMENT_ACTION_SUCCESS: 'ADD_NEW_ANNOUNCEMENT_ACTION_SUCCESS',
    ADD_NEW_ANNOUNCEMENT_ACTION_FAILURE: 'ADD_NEW_ANNOUNCEMENT_ACTION_FAILURE',

    GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST: 'GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST',
    GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS: 'GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS',
    GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE: 'GET_SINGLE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE',

    UPDATE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST: 'UPDATE_ANNOUNCEMENT_DETAILS_ACTION_REQUEST',
    UPDATE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS: 'UPDATE_ANNOUNCEMENT_DETAILS_ACTION_SUCCESS',
    UPDATE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE: 'UPDATE_ANNOUNCEMENT_DETAILS_ACTION_FAILURE',

    ADD_POST_TAGS_ACTION_REQUEST: 'ADD_POST_TAGS_ACTION_REQUEST',
    ADD_POST_TAGS_ACTION_SUCCESS: 'ADD_POST_TAGS_ACTION_SUCCESS',
    ADD_POST_TAGS_ACTION_FAILURE: 'ADD_POST_TAGS_ACTION_FAILURE',
}