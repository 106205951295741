import React, { Component } from "react";
import LineItem from "./LineItem";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.openCheckout = this.openCheckout.bind(this);
    this.state = {
      iframeUrl: "",
    };
  }

  openCheckout() {
    window.open(this.props.checkout.webUrl);
    this.setState({
      iframeUrl: this.props.checkout.webUrl,
    });
  }

  render() {
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });

    return (
      <>
        <div className={`Cart ${this.props.isCartOpen ? "Cart--open" : ""}`}>
          <header className="Cart__header">
            <h4 className="text-primary">Your cart</h4>
            <button
              onClick={this.props.handleCartClose}
              className="Cart__close text-danger-4"
            >
              ×
            </button>
          </header>
          <ul className="Cart__line-items">
            {line_items.length ? (
              line_items
            ) : (
              <>
                <li className="text-center text-danger font-weight-bold text-capitalize">
                  Your Cart is empty
                </li>
              </>
            )}
          </ul>
          <footer className="Cart__footer">
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Subtotal</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  {this.props.checkout?.subtotalPrice &&
                    this.props.checkout.subtotalPrice?.currencyCode}{" "}
                  {this.props.checkout?.subtotalPrice &&
                    this.props.checkout.subtotalPrice?.amount}
                </span>
              </div>
            </div>
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Taxes</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  {this.props.checkout?.totalTax &&
                    this.props.checkout.totalTax?.currencyCode}{" "}
                  {this.props.checkout?.totalTax &&
                    this.props.checkout.totalTax?.amount}
                </span>
              </div>
            </div>
            <div className="Cart-info clearfix font-weight-bold">
              <div className="Cart-info__total Cart-info__small">Total</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  {/* $ {this.props.checkout.totalPrice} */}
                  {this.props.checkout?.totalPrice &&
                    this.props.checkout.totalPrice?.currencyCode}{" "}
                  {this.props.checkout?.totalPrice &&
                    this.props.checkout.totalPrice?.amount}
                </span>
              </div>
            </div>
            <button
              className="Cart__checkout button btn btn-primary"
              onClick={this.openCheckout}
            >
              Checkout
            </button>
          </footer>
        </div>
        {/* <div>
          <iframe src={this.state.iframeUrl} height="500px" width="500px"></iframe>
        </div> */}
      </>
    );
  }
}

export default Cart;
