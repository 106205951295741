import React, { Component } from "react";
import { connect } from "react-redux";

import { logout_user_action } from "../../../../_actions/Common";
import ContentLoader from "../../../Common/Loader/ContentLoader";


class AdminSidebarMenu extends Component {
  state = {
    loading: false,
  };
  handleLogout = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      this.setState({ loading: true });

      const token = {
        refresh: user["refresh_token"],
      };
      await this.props.userLogout(token);
      this.setState({ loading: false }, () => window.location.reload());
      return;
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="sidebar-menu bg-grey-4 position-fixed  h-100">
        <ul className="d-flex flex-md-column align-items-center py-md-5 justify-content-between justify-content-md-start w-100">
          <li>
            <a href={"/admin-home-page"}
             className={
              this.props.activeTab === "User Data" || this.props.activeTab === "Report/Review"
              ? "font-weight-bold fs-16 d-inline-block text-primary"
              : "font-weight-bold fs-16 d-inline-block text-dark"}>
               <span className="fa fa-user fa-2x"/>
            </a>
          </li>
          <li>
            <a  href="/upload-content-listing"
             className={
              this.props.activeTab === "UploadContent"
              ? "font-weight-bold fs-16 d-inline-block text-primary"
              : "font-weight-bold fs-16 d-inline-block text-dark"}>
              {/* <img src={"/assets/images/document.svg"} alt="content list" /> */}
              <span className="fa fa-upload fa-2x"/>
            </a>
          </li>
          <li>
            <a href={"/homepage-content"}
             className={
              this.props.activeTab === "Home" || this.props.activeTab === "Announcement" || this.props.activeTab === "FAQs"
              ? "font-weight-bold fs-16 d-inline-block text-primary"
              : "font-weight-bold fs-16 d-inline-block text-dark"}>
              <span className="fa fa-cog fa-2x"/>
            </a>
          </li>
          {/* <li>
            <a href="#">
              <img src={"/assets/images/video-icon.svg"} alt="notification" />
            </a>
          </li> */}
          <li>
            <a href="#" onClick={this.handleLogout}>
              {loading ? (
                <ContentLoader color="text-black" />
              ) : (
                <img src={"/assets/images/power.png"} alt="logout" />
              )}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch(logout_user_action(data)),
});

export default connect(null, mapDispatchToProps)(AdminSidebarMenu);
