import React, { Component } from "react";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {
    return (
      <li className="Line-item">
        <div className="Line-item__img">
          {this.props.line_item.variant.image ? (
            <img
              src={this.props.line_item.variant.image.src}
              alt={`${this.props.line_item.title} product shot`}
            />
          ) : null}
        </div>
        <div className="Line-item__content">
          <div className="Line-item__content-row">
            {this.props.line_item.variant.title ? (
              <>
                <div className="Line-item__variant-title">
                  {this.props.line_item.variant.title}
                </div>
              </>
            ) : null}

            <span className="Line-item__title text-black font-weight-semi">
              {this.props.line_item.title}
            </span>
          </div>
          <div className="Line-item__content-row">
            <div className="Line-item__quantity-container">
              <button
                className="Line-item__quantity-update text-primary font-weight-bold"
                onClick={() => this.decrementQuantity(this.props.line_item.id)}
              >
                -
              </button>
              <span className="Line-item__quantity">
                {this.props.line_item.quantity}
              </span>
              <button
                className="Line-item__quantity-update text-primary font-weight-bold"
                onClick={() => this.incrementQuantity(this.props.line_item.id)}
              >
                +
              </button>
            </div>
            <span className="Line-item__price text-primary">
              {this.props.line_item.variant.price?.currencyCode}{" "}
              {(
                this.props.line_item.quantity *
                this.props.line_item.variant.price?.amount
              ).toFixed(2)}
            </span>
            <button
              className="Line-item__remove text-danger-4 font-weight-bold"
              onClick={() =>
                this.props.removeLineItemInCart(this.props.line_item.id)
              }
            >
              ×
            </button>
          </div>
        </div>
      </li>
    );
  }
}

export default LineItem;
