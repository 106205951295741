import React, { Component } from "react";
import { Container, Row, Tabs, Tab, Col } from "react-bootstrap";
import SideBarMenu from "../common/SidebarMenu/SidebarMenu";
import Header from "../common/Header/Header";
import "./CollectionCategories.scss";
import CollectionCard from "./CollectionCard/CollectionCard";
import Client from "shopify-buy";

import Products from "../../User/ShopifyStore/Products";
import Cart from "../../User/ShopifyStore/Cart";

import "../../User/ShopifyStore/shopifystore.scss";
import ContentLoader from "../../Common/Loader/ContentLoader";
import config from '../../../Config/config';
class CollectionCategories extends Component {
  constructor() {
    super();
    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      collections: [],
      currentCollection: [],
      currentCollectionId: localStorage.getItem("currentCollectionId"),
      productsForCollection: [],
      nextPageOfProducts: [],
      shop: {},
      client: Client.buildClient({
        storefrontAccessToken: config.shopifyStore.storefrontAccessToken,
        domain: config.shopifyStore.domain,
      }),
      key: localStorage.getItem("currentCollectionHandle"),
      isApiLoading: false,
      fetchingNextProducts: false,
      disableLoadMore: false,
      showMessage: "",
      showCategoriesBar: false,
    };

    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.handleShowCategoriesBar = this.handleShowCategoriesBar.bind(this);
  }

  componentWillMount() {
    this.state && this.state.client && this.state.client.checkout && this.state.client.checkout.create() && this.state.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });
    });

    this.setState({ isApiLoading: true }, () => {
      this.state && this.state.client && this.state.client.collection && this.state.client.collection.fetchAllWithProducts() && this.state.client.collection.fetchAllWithProducts().then((collections) => {
        // const renderCollections=collections.filter((collection)=>collection.handle=='all-t-shirts'|| 
        // collection.handle=='all-cookbooks' ||
        // collection.handle=='all-aprons' || 
        // collection.handle=='all-hoodies' || 
        // collection.handle=='all-cbd-candles');

        // Do something with the collections
        this.setState({
          // collections: collections,
          isApiLoading: false,
          //  collections:renderCollections,
          collections: collections,

        });
      });
    })
  }
  /***************************cart code starts as follows*********************** */
  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return this.state.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false,
    });
  }

  handleShowCategoriesBar() {
    this.setState({
      showCategoriesBar: !this.state.showCategoriesBar,
    })
  }

  /***************************cart code ends here*********************** */
  nextProducts = (prevCollection) => {
    this.setState({ fetchingNextProducts: true }, () => {
      this.setState({ productsForCollection: prevCollection }, () => {
        this.state.client
          .fetchNextPage(this.state.productsForCollection)
          .then((nextPageOfProducts) => {
            this.setState(
              { nextPageOfProducts: nextPageOfProducts.model, fetchingNextProducts: false },
              () => {
                const { productsForCollection, nextPageOfProducts } = this.state;
                if (nextPageOfProducts[nextPageOfProducts && nextPageOfProducts.length - 1].hasNextPage == true) {
                  this.setState({
                    productsForCollection: [
                      ...productsForCollection,
                      ...nextPageOfProducts,
                    ],
                  });
                } else {
                  this.setState({
                    productsForCollection: [
                      ...productsForCollection,
                      ...nextPageOfProducts,
                    ],
                    disableLoadMore: true,
                    showMessage: "No more products to show..."
                  });
                }
              }
            );
          });
      });
    })
  };

  handleOnSelect = (k) => {
    this.setState({ isApiLoading: true }, () => {
      this.state.client.collection.fetchByHandle(k).then((res) => {
        localStorage.setItem("currentCollectionId", res && res.id);
        localStorage.setItem("currentCollectionTitle", res && res.title);
        localStorage.setItem("currentCollectionHandle", res && res.handle);
        this.setState({
          productsForCollection: res.products,
          currentCollection: res,
          key: res.handle,
          currentCollectionId: res.id,
          isApiLoading: false,
          showCategoriesBar: false,
        });
      });
    })
  };
  render() {
    const {
      productsForCollection
    } = this.state;

    return (
      <div className="common-dashboard collections-category-wrapper">
        <Container className="p-0">
          <SideBarMenu />
          <div className="main-content bg-white position-relative">
            <Header />
            {/* Collection tabs */}
            <div className={this.state && this.state.showCategoriesBar ? "collection-wrapper pl-3 active-sidebar" : "collection-wrapper pl-3"}  >
              <span className="btn fa fa-times border border-primary text-primary ml-3 py-1 px-3 rounded d-md-none close-side-bar"
                onClick={this.handleShowCategoriesBar}
              ></span>
              {/**all collection code starts here****************** */}
              {this.state.isApiLoading ?
                <div
                  style={{
                    margin: "50px auto 0",
                    textAlign: "center",
                  }}
                >
                  <ContentLoader color="text-primary" />
                </div>
                :
                <Tabs
                  className="comments-recipe-tab"
                  //defaultActiveKey="All-T-Shirts"
                  activeKey={this.state.key}
                  onSelect={(k) => this.handleOnSelect(k)}
                  id="CollectionsTab"
                >

                  {this.state.collections.map((collection) => (
                    <Tab
                      eventKey={collection && collection.handle}
                      title={collection && collection.title}
                    >
                      {this.state.isApiLoading ?
                        <div
                          style={{
                            margin: "50px auto 0",
                            textAlign: "center",
                          }}
                        >
                          <ContentLoader color="text-primary" />
                        </div>
                        :
                        <>

                          <h2 className="text-primary mb-3 px-2">{collection && collection.title}
                            <span className="btn fa fa-pencil border border-primary text-primary ml-3 py-1 px-3 rounded d-md-none"
                              onClick={this.handleShowCategoriesBar}
                            ></span>
                          </h2>

                          <Products
                            products={
                              productsForCollection &&
                                productsForCollection.length > 0
                                ? productsForCollection
                                : collection.products
                            }
                            client={this.state.client}
                            addVariantToCart={this.addVariantToCart}
                            collectionName={collection && collection.title}
                          />
                        </>


                      }
                      {/*   {collection.products && collection.products.length >= 20 ? */}
                      {collection.products[collection.products && collection.products.length - 1]
                        && collection.products[collection.products && collection.products.length - 1].hasNextPage ?
                        (
                          <div className="text-center">
                            {this.state.disableLoadMore ? (<div>{this.state.showMessage}</div>) : (
                              <button
                                className="btn btn-warning py-2 px-3"
                                onClick={() => this.nextProducts(collection.products)}
                              >
                                {this.state.fetchingNextProducts ?
                                  <ContentLoader />
                                  : <div>Load more</div>
                                }
                              </button>
                            )}
                          </div>
                        ) : collection.products &&
                          collection.products.length == 0 ? (

                          <div className="text-center p-4 mt-4 d-flex justify-content-center">
                            <img
                              src={"/assets/images/no-products.png"}
                              alt="no recipes"

                            />
                          </div>

                        ) : null}
                      <Cart
                        checkout={this.state.checkout}
                        isCartOpen={this.state.isCartOpen}
                        handleCartClose={this.handleCartClose}
                        updateQuantityInCart={this.updateQuantityInCart}
                        removeLineItemInCart={this.removeLineItemInCart}
                      />
                    </Tab>
                  ))}

                </Tabs>}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default CollectionCategories;
