import React, { Component } from "react";
import { Container, Row, Tabs, Tab, Col } from "react-bootstrap";
import SideBarMenu from "../common/SidebarMenu/SidebarMenu";
import Header from "../common/Header/Header";
import "./AllShoppingProducts.scss";
import CollectionCard from "./CollectionCard/CollectionCard";
import Client from "shopify-buy";

import Products from "../ShopifyStore/Products";
import Cart from "../ShopifyStore/Cart";

import "../../User/ShopifyStore/shopifystore.scss";
import ContentLoader from "../../Common/Loader/ContentLoader";
import config from "../../../Config/config";
import Product from "../ShopifyStore/Product";
class AllShoppingProducts extends Component {
  constructor() {
    super();
    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      collections: [],
      currentCollection: [],
      currentCollectionId: sessionStorage.getItem("currentCollectionId"),
      productsForCollection: [],
      nextPageOfProducts: [],
      shop: {},
      client: Client.buildClient({
        storefrontAccessToken: config.shopifyStore.storefrontAccessToken,
        domain: config.shopifyStore.domain
      }),
      key: sessionStorage.getItem("currentCollectionHandle"),
      isApiLoading: false,
      fetchingNextProducts: false,
      disableLoadMore: false,
      showMessage: "",
      showCategoriesBar: false
    };

    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.handleShowCategoriesBar = this.handleShowCategoriesBar.bind(this);
  }

  componentDidMount() {
    this.state &&
      this.state.client &&
      this.state.client.checkout.create().then((res) => {
        this.setState({
          checkout: res
        });
      });

    this.setState({ isApiLoading: true }, () => {
      this.state.client.product.fetchAll().then((products) => {
        this.setState({
          isApiLoading: false,
          collections: products,
        });
      });
    })
    // this.mountCheckoutDetails();
    // this.mountAllProducts();
  }


  // mountCheckoutDetails = async () => {
  //   const res = await this.state && this.state.client && this.state.client.checkout && this.state.client.checkout.create();

  //   this.setState({
  //     checkout: res
  //   })
  // }
  // mountAllProducts = async () => {
  //   this.setState({ isApiLoading: true });
  //   const res = await this.state && this.state.client && this.state.client.product && this.state.client.product.fetchAll();


  //   this.setState({
  //     isApiLoading: false,
  //     collections: res,
  //   });
  // }
  /***************************cart code starts as follows*********************** */
  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res
        });
      });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) }
    ];

    return this.state.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.setState({
          checkout: res
        });
      });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id;

    return this.state.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res
        });
      });
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false
    });
  }

  handleShowCategoriesBar() {
    this.setState({
      showCategoriesBar: !this.state.showCategoriesBar
    });
  }

  /***************************cart code ends here*********************** */
  nextProducts = (prevCollection) => {
    this.setState({ fetchingNextProducts: true }, () => {
      this.setState({ productsForCollection: prevCollection }, () => {
        this.state.client
          .fetchNextPage(this.state.productsForCollection)
          .then((nextPageOfProducts) => {
            this.setState(
              {
                nextPageOfProducts: nextPageOfProducts.model,
                fetchingNextProducts: false
              },
              () => {
                const { productsForCollection, nextPageOfProducts } =
                  this.state;
                if (
                  nextPageOfProducts[
                    nextPageOfProducts && nextPageOfProducts.length - 1
                  ].hasNextPage == true
                ) {
                  this.setState({
                    productsForCollection: [
                      ...productsForCollection,
                      ...nextPageOfProducts
                    ]
                  });
                } else {
                  this.setState({
                    productsForCollection: [
                      ...productsForCollection,
                      ...nextPageOfProducts
                    ],
                    disableLoadMore: true,
                    showMessage: "No more products to show..."
                  });
                }
              }
            );
          });
      });
    });
  };

  handleOnSelect = (k) => {
    this.setState({ isApiLoading: true }, () => {
      this.state.client.collection.fetchByHandle(k).then((res) => {
        sessionStorage.setItem("currentCollectionId", res && res.id);
        sessionStorage.setItem("currentCollectionTitle", res && res.title);
        sessionStorage.setItem("currentCollectionHandle", res && res.handle);
        this.setState({
          productsForCollection: res.products,
          currentCollection: res,
          key: res.handle,
          currentCollectionId: res.id,
          isApiLoading: false,
          showCategoriesBar: false
        });
      });
    });
  };
  render() {
    const { productsForCollection, collections, isApiLoading } = this.state;

    return (
      <>
        <div className="common-dashboard collections-category-wrapper">
          <div className="main-content bg-white position-relative">
            <Header />

            {isApiLoading ? (
              <>
                <div
                  style={{
                    margin: "50px auto 0",
                    textAlign: "center"
                  }}
                >
                  <ContentLoader color="text-primary" />
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  {productsForCollection && productsForCollection.length > 0 ? (
                    <>
                      {productsForCollection &&
                        productsForCollection.map((collection) => (
                          <div
                            className="col-md-4 col-lg-3 mb-4"
                            key={collection.id.toString()}
                          >

                            <Product
                              addVariantToCart={this.addVariantToCart}
                              client={this.state.client}
                              key={collection.id.toString()}
                              product={collection}
                            />
                            <Cart
                              checkout={this.state.checkout}
                              isCartOpen={this.state.isCartOpen}
                              handleCartClose={this.handleCartClose}
                              updateQuantityInCart={this.updateQuantityInCart}
                              removeLineItemInCart={this.removeLineItemInCart}
                            />
                          </div>
                        ))}

                      <div className="col-12">
                        {productsForCollection[
                          productsForCollection &&
                          productsForCollection.length - 1
                        ] &&
                          productsForCollection[
                            productsForCollection &&
                            productsForCollection.length - 1
                          ].hasNextPage ? (
                          <div className="text-center">
                            {this.state.disableLoadMore ? (
                              <div>{this.state.showMessage}</div>
                            ) : (
                              <button
                                className="btn btn-warning py-2 px-3"
                                onClick={() =>
                                  this.nextProducts(productsForCollection)
                                }
                              >
                                {this.state.fetchingNextProducts ? (
                                  <ContentLoader />
                                ) : (
                                  <div>Load more</div>
                                )}
                              </button>
                            )}
                          </div>
                        ) : productsForCollection &&
                          productsForCollection.length == 0 ? (
                          <div className="text-center p-4 mt-4 d-flex justify-content-center">
                            <img
                              src={"/assets/images/no-products.png"}
                              alt="no recipes"
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      {this.state &&
                        this.state.collections &&
                        this.state.collections.map((collection) => (
                          <div
                            className="col-md-4 col-lg-3 mb-4"
                            key={collection.id.toString()}
                          >
                            <Product
                              addVariantToCart={this.addVariantToCart}
                              client={this.state.client}
                              key={collection.id.toString()}
                              product={collection}
                            />
                            <Cart
                              checkout={this.state.checkout}
                              isCartOpen={this.state.isCartOpen}
                              handleCartClose={this.handleCartClose}
                              updateQuantityInCart={this.updateQuantityInCart}
                              removeLineItemInCart={this.removeLineItemInCart}
                            />
                          </div>
                        ))}
                      <div className="col-12">
                        {collections[collections && collections.length - 1] &&
                          collections[collections && collections.length - 1]
                            .hasNextPage ? (
                          <div className="text-center">
                            {this.state.disableLoadMore ? (
                              <div>{this.state.showMessage}</div>
                            ) : (
                              <button
                                className="btn btn-warning py-2 px-3"
                                onClick={() => this.nextProducts(collections)}
                              >
                                {this.state.fetchingNextProducts ? (
                                  <ContentLoader />
                                ) : (
                                  <div>Load more</div>
                                )}
                              </button>
                            )}
                          </div>
                        ) : collections && collections.length == 0 ? (
                          <div className="text-center p-4 mt-4 d-flex justify-content-center">
                            <img
                              src={"/assets/images/no-products.png"}
                              alt="no recipes"
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default AllShoppingProducts;
