import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import './LiveStream.scss';
class LiveStream extends Component {
    render() {
        return (
            <div className="live-stream bg-white">
                <Container>
                    <Row className="justify-content-center  ">
                        <Col lg={10}>
                            <div className="live-stream-content">


                                {/* live stream header  */}
                                <div className="live-stream-action d-flex align-items-center">
                                    <span className="btn btn-primary rounded px-2 py-0 fs-14 font-weight-bold">Live</span>

                                    <div className="right ml-auto d-flex align-items-center">
                                        <span className="view-count d-flex align-items-center">
                                            <img src="/assets/images/view-icon.svg" alt=""  />
                                            <span className="fs-14 font-weight-bold ml-2">3000</span>
                                        </span>
                                        <button className="p-0 border-0 bg-transparent ml-3" > <img src="/assets/images/close-icon.svg" alt="" /></button>
                                    </div>
                                </div>
                                {/* live stream header  */}


                                {/* video part  */}
                                <div className="live-stream-video mt-3">
                                    <img src="/assets/images/live-stream.svg" alt="live-stream-video" className="img-fit img-fluid w-100" />
                                </div>

                                {/* video part  */}

                                {/* comment section */}
                                <div className="viewers-comments mt-4">
                                    <div className="viewers-comment-content d-flex align-items-center mb-3">
                                        <div className="viewers-comment-image">
                                            <img src="/assets/images/live-viewer-comment.svg" alt="" className="user-image rounded-circle img-fit" />
                                        </div>
                                        <div className="viewers-comment-content">
                                            <h6>User Name</h6>
                                            <p className="font-smallest font-weight-bold text-dark m-0">Amet minim mollit non</p>
                                        </div>
                                    </div>
                                    <div className="viewers-comment-content d-flex align-items-center mb-3">
                                        <div className="viewers-comment-image">
                                            <img src="/assets/images/live-viewer-comment.svg" alt="" className="user-image img-fit" />
                                        </div>
                                        <div className="viewers-comment-content">
                                            <h6>User Name</h6>
                                            <p className="font-smallest font-weight-bold text-dark m-0">Amet minim mollit non</p>
                                        </div>
                                    </div>
                                    <div className="viewers-comment-content d-flex align-items-center mb-3">
                                        <div className="viewers-comment-image">
                                            <img src="/assets/images/live-viewer-comment.svg" alt="" className="user-image img-fit" />
                                        </div>
                                        <div className="viewers-comment-content">
                                            <h6>User Name</h6>
                                            <p className="font-smallest font-weight-bold text-dark m-0">Amet minim mollit non</p>
                                        </div>
                                    </div>
                                </div>

                                {/* comment section */}

                                <div className="text-right mt-5">
                                    <button className=" fs-14 btn btn-primary rounded px-4 py-3">End Stream</button>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        )
    }
}
export default LiveStream;