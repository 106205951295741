export const debounce = (func) => {
    let delay = 500
  let debounceTimer;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};
