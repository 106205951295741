import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./UploadShows.scss";
import UploadCarouselSlide from '../../../../common/UploadCarouselSlide/UploadCarouselSlide';



const imgurls = [
    { imgurl: "/assets/images/slider-item.svg" },
    { imgurl: "/assets/images/slider-item-2.svg" },
    { imgurl: "/assets/images/slider-item-3.svg" },
    { imgurl: "/assets/images/slider-item.svg" }
  ];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                adaptiveHeight: true,

            }
        },
    ]
};
export default ({ shows, addUpdateClickHandler }) => {
    return(
<div className="popular-shows-slider pt-sm-4">
                <h3 className="font-weight-semi d-inline-block pt-4  pt-4 mb-3 pl-2">Upload Show</h3>
                <Slider {...settings} className="shows">
                {shows && shows.length ? shows.map(show => (
                    <div className="Show-item" key={show.priority} onClick={() => addUpdateClickHandler('show', show.priority, show.id ? show.id : 'new')}>
                    <UploadCarouselSlide buttonTitle="Add Show" imgPreview={show.cover_image ? show.cover_image : null}/>
                    <h4 className="title font-weight-semi my-2 ml-2">{show.title ? show.title : 'Show Title'}</h4>
                </div>
                )) : null}
                </Slider>
            </div>
    )
};