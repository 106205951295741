import swal from "sweetalert";
import config from "../../Config/config";
import { carouselConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";

// Get saved all carousel content
export const get_all_carousel_items_action = () => async (dispatch) => {
    dispatch({
        type: carouselConstants.GET_ALL_DASHBOARD_ITEMS_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.carousel.carouselItems)
        if (response.status) {
            dispatch({
                type: carouselConstants.GET_ALL_DASHBOARD_ITEMS_ACTION_SUCCESS,
                payload: response.data.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
        dispatch({
            type: carouselConstants.GET_ALL_DASHBOARD_ITEMS_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


export const get_all_content_action = (page) => async (dispatch) => {
    dispatch({
        type: carouselConstants.GET_ALL_CONTENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(`${config.carousel.publishedContent}${page ? `?page=${page}` : ''}`)
        if (response.status) {
            dispatch({
                type: carouselConstants.GET_ALL_CONTENT_ACTION_SUCCESS,
                payload: response.data.data
            })
            return response.data.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
        dispatch({
            type: carouselConstants.GET_ALL_CONTENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const get_all_content_with_filter_action = (data) => async (dispatch) => {
    dispatch({
        type: carouselConstants.GET_ALL_CONTENT_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.carousel.publishedContent, data)
        if (response.status) {
            dispatch({
                type: carouselConstants.GET_ALL_CONTENT_ACTION_SUCCESS,
                payload: response.data.data
            })
            return response.data.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg || 'Something went wrong!')
        dispatch({
            type: carouselConstants.GET_ALL_CONTENT_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


export const add_to_dashboard_item_action = (data) => async (dispatch) => {
    dispatch({
        type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(config.carousel.dashboardItems, data)
        if (response.status) {
            dispatch({
                type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_SUCCESS,
                payload: response.data.data
            })
            return response.data.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

export const edit_to_dashboard_item_action = (id, data) => async (dispatch) => {
    dispatch({
        type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.put(config.carousel.dashboardItemsById.replace(":id", id), data)
        if (response.status) {
            dispatch({
                type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_SUCCESS,
                payload: response.data.msg
            })
            return response.data.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: carouselConstants.ADD_TO_DASHBOARD_ITEM_ACTION_FAILURE,
            payload: e.msg
        })
    }
}