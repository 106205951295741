import swal from 'sweetalert'
import { categorySearchConstants } from "../../_constants";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// GET USER DETAILS
export const search_category_action = (data) => async (dispatch) => {
    dispatch({
        type: categorySearchConstants.SEARCH_CATEGORY_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.post(
            config.categorySearch,
            data
        );
        if (response.status) {
            dispatch({
                type: categorySearchConstants.SEARCH_CATEGORY_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        swal(e.msg)
        dispatch({
            type: categorySearchConstants.SEARCH_CATEGORY_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

