import React, { Component } from "react";
import { Container, Form, Card, ListGroup } from "react-bootstrap";
// import {add} from "../"
import AppAvilable from "./AppAvilable/AppAvilable";
import ContactUs from "./ContactUs/ContactUs";
import ExistingFeatures from "./ExistingFeatures/ExistingFeatures";
import HeaderNew from "./HeaderNew/HeaderNew";
import MeetExpert from "./MeetExpert/MeetExpert";
import SideBarNew from "./SideBarNew/SideBarNew";
import SocialMedia from "./SocialMedia/SocialMedia";
import SubscribeForUpdate from "./SubscribeForUpdate/SubscribeForUpdate";
import FoodGallery from "./FoodGallery/FoodGallery";
import WatchTutorials from "./WatchTutorials/WatchTutorials";
import RecipesSection from "./RecipesSection/RecipesSection";
import SearchBar from "./SearchBar/SearchBar";
import "./HomePageNew.scss";
import CopyRight from "./CopyRight/CopyRight";
import { connect } from "react-redux";
import { header_search_query_action } from "../../../_actions/User";
import Header from "../../User/common/Header/Header";

export class HomePageNew extends Component {

  state = {
    userObject: null,
  };

  componentDidMount() {

    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState(
      {
        userObject: userObject
      }
    );
  }


  render() {
    const {
      userObject
    } = this.state;
    return (
      <>
        <div className="common-dashboard user-home-page-dashboard bg-white">
          <Container className="p-0">
            {/* <SideBarNew /> */}
            <div className="main-content">
                <HeaderNew
                  history={this.props.history}
                  activeMenuItem="home"
                />
            
              {/* <HeaderNew history={this.props.history} activeMenuItem="home" /> */}
              <SearchBar history={this.props.history} />
              <RecipesSection history={this.props.history} />
              <WatchTutorials history={this.props.history} />
              <MeetExpert history={this.props.history} />
              <AppAvilable history={this.props.history} />
              {/* <ExistingFeatures history={this.props.history} /> */}
              <SocialMedia history={this.props.history} />
              <FoodGallery history={this.props.history} />
              {/* <SubscribeForUpdate history={this.props.history} /> */}
              <ContactUs history={this.props.history} />
            </div>
          </Container>
        </div>
        <CopyRight />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchQueryResultData:
      state.header_search_query_result_reducer &&
      state.header_search_query_result_reducer.data &&
      state.header_search_query_result_reducer.data.data
  };
};
const mapDispatchToProps = (dispatch) => ({
  searchQueryResult: (data) => dispatch(header_search_query_action(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageNew);
