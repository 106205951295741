import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./CreateAccount.scss";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import swal from "sweetalert";
import {
  facebook_login_action,
  google_login_action,
  set_client_token_for_notification_action,
} from "../../../_actions/Common";
import { connect, useDispatch } from "react-redux";
import ContentLoader from "../Loader/ContentLoader";
import OneSignalReact from "react-onesignal";

function CreateAccount(props) {
  const [googleLoading, setGoogleLoading] = useState(false);
  const dispatch = useDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => successResponseGoogle(codeResponse),
    onError: (response) => failureResponseGoogle(response),
  });

  const successResponseGoogle = async (response) => {
    const token = { access_token: response.access_token };

    if (token) {
      setGoogleLoading(true);
      const loginRes = await dispatch(google_login_action(token));
      setGoogleLoading(false);
      if (loginRes !== undefined && loginRes && loginRes.user) {
        let userRole = loginRes && loginRes.user && loginRes.user.role;
        if (userRole === "user") {
          let fcmToken;
          await OneSignalReact.getUserId((id) => {
            fcmToken = id;
          });
          let premiumLogin = !loginRes.user.membership_expired;
          let videoId = localStorage.getItem("videoId");
          let episodeId = localStorage.getItem("episodeId");

          if (fcmToken) {
            const data = {
              registration_id: fcmToken,
              type: "web",
              device_id: fcmToken.substr(0, 150),
              active: true,
            };
            await dispatch(set_client_token_for_notification_action(data));

            props.history.push("/user-home-page");
          } else if (premiumLogin && videoId) {
            if (props?.isLoginfromRecipeIngredient) {
              window.location.reload();
            } else {
              props.history.push(`/video/${videoId}`);
            }
          } else if (premiumLogin && episodeId) {
            props.history.push(`/watch-show/${episodeId}`);
          } else {
            props.history.push("/user-home-page");
          }

          return;
        } else if (userRole === "admin") props.history.push("admin-home-page");
      } else {
        swal(response.error).then(() => {
          setGoogleLoading(false);
        });
      }
    }
  };

  const failureResponseGoogle = (response) => {
    if (response.error !== "popup_closed_by_user")
      if (response.details.includes("Cookies are not enabled"))
        swal("Please enable cookies.");
      else swal(response.error);
  };

  return (
    <div className="common-login d-flex align-items-center jusfity-content-center">
      <Container className="p-0">
        <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
          <div className="form-wraper d-flex flex-column justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
            <h2 className="text-dark form-title text-center font-poppins mb-4">
              Create Account
            </h2>
            <p className="fs-16 text-dark   mb-5 text-center font-weight-bold">
              Welcome to Darius Cooks ! <br /> Create account using any one of
              the following options{" "}
            </p>
            <button
              className="btn btn-outline-primary d-flex mw-50 justify-content-center align-items-center mb-4"
              onClick={(e) => googleLogin()}
            >
              Sign In with Google
              {googleLoading ? (
                <ContentLoader color="text-white" />
              ) : (
                <img
                  src="/assets/images/google-plus-icon.svg"
                  className="ml-2"
                  alt="Google"
                />
              )}
            </button>
            <form action="#">
              {/* <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email,picture"
                callback={this.responseFacebook}
                render={(renderProps) => (
                  <a
                    onClick={renderProps.onClick}
                    className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black ml-2 rounded-pill"
                  >
                    {facebookLoading ? (
                      <ContentLoader color="text-white" />
                    ) : (
                      <img
                        src={"/assets/images/facebook-icon.svg"}
                        className="mr-2"
                        alt="Facebook"
                      />
                    )}
                    Facebook
                  </a>
                )}
              /> */}

              <div className="d-flex justify-content-between social-login-links mb-3">
                <a
                  href={"/sign-up"}
                  className="d-flex align-items-center justify-content-center fs-14 bg-grey-3 p-3 font-weight-bold text-black rounded-pill"
                >
                  Create Account Using Email
                </a>
              </div>

              <p className="fs-14 font-Modelica text-center mt-5 pt-md-5 font-weight-bold">
                Already have an Account?{" "}
                <a
                  href={"/login"}
                  className="fs-20 text-underline text-primary"
                >
                  Login
                </a>{" "}
              </p>
            </form>
          </div>
          <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
            <img src={"/assets/images/bg-signup.svg"} alt="login bg" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CreateAccount;

{
  /* // class CreateAccount extends Component {
//   state = {
//     googleLoading: false,
//     facebookLoading: false,
//   };

//   successResponseGoogle = async (response) => {
//     const token = { access_token: response.access_token };
//     if (token) {

//       this.setState({ googleLoading: true });
//       const loginRes = await this.props.onGoogleLogin(token);
//       if (loginRes !== undefined && loginRes.user) {
//         let userRole = loginRes.user.role;
//         if (userRole === "user") {
//           // hit the fcm token and redirect to home page
//           let fcmToken;
//           await OneSignalReact.init({
//             appId: "f1442b0c-9846-4bcc-bcbb-e3b4243efc8d",
//           }).then(async () => {
//             fcmToken = await OneSignalReact.getUserId((id) => {
//               return id;
//             });
//           });
//           if (fcmToken) {
//             const data = {
//               registration_id: fcmToken,
//               type: "web",
//               device_id: fcmToken.substr(0, 150),
//               active: true,
//             };
//             await this.props.setClientForNotification(data);
//           }
//           this.props.history.push("/user-home-page");
//           return;
//         } else if (userRole === "admin")
//           this.props.history.push("admin-home-page");
//       } else {
//         swal(loginRes);
//       }

//       this.setState({ googleLoading: false });
//     }
//   };
//   failureResponseGoogle = (response) => {
//     this.setState({ googleLoading: true });
//     if (response.error !== "popup_closed_by_user")
//       if (response.details.includes("Cookies are not enabled"))
//         swal("Please enable cookies.");
//       else swal(response.error);
//     this.setState({ googleLoading: false });
//   };
//   responseFacebook = async (response) => {
//     if (response.status !== "unknown") {
//       this.setState({ facebookLoading: true });
//       if (response.accessToken) {
//         const token = { access_token: response.accessToken };
//         const loginRes = await this.props.onFacebookLogin(token);
//         if (loginRes && loginRes.user) {
//           let userRole = loginRes.user.role;
//           if (userRole === "user") {
//             // hit the fcm token and redirect to home page
//             let fcmToken = await getToken();
//             if (fcmToken) {
//               const data = {
//                 registration_id: fcmToken,
//                 type: "web",
//                 device_id: fcmToken.substr(0, 150),
//                 active: true,
//               };
//               await this.props.setClientForNotification(data);
//             }
//             this.props.history.push("/user-home-page");
//             return;
//           } else if (userRole === "admin")
//             this.props.history.push("admin-home-page");
//         } else {
//           swal("Signup failed failed! Try again");
//         }
//       }
//       this.setState({ facebookLoading: false });
//     }
//   };
//   render() {
//     const { googleLoading, facebookLoading } = this.state;
//     return (
//       <div className="common-login d-flex align-items-center jusfity-content-center">
//         <Container className="p-0">
//           <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
//             <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
//               <Form action="#">
//                 <h2 className="text-dark form-title text-center font-poppins mb-4">
//                   Create Account
//                 </h2>
//                 <p className="fs-16 text-dark   mb-5 text-center font-weight-bold">
//                   Welcome to Darius Cooks ! <br /> Create Account using any one
//                   of the following options{" "}
//                 </p>
//                 <Form.Group className="d-flex justify-content-between social-login-links py-3">
//                   <GoogleLogin
//                     clientId={process.env.REACT_APP_GOOGLE_ID}
//                     render={(renderProps) => (
//                       <a
//                         onClick={renderProps.onClick}
//                         className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black rounded-pill"
//                       >
//                         {googleLoading ? (
//                           <ContentLoader color="text-white" />
//                         ) : (
//                           <img
//                             src={"/assets/images/google-plus-icon.svg"}
//                             className="mr-2"
//                             alt="Google"
//                           />
//                         )}
//                         Google
//                       </a>
//                     )}
//                     buttonText="Login"
//                     onSuccess={this.successResponseGoogle}
//                     onFailure={this.failureResponseGoogle}
//                   />
//                   {/* <FacebookLogin
//                     appId={process.env.REACT_APP_FACEBOOK_APP_ID}
//                     fields="name,email,picture"
//                     callback={this.responseFacebook}
//                     render={(renderProps) => (
//                       <a
//                         onClick={renderProps.onClick}
//                         className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black ml-2 rounded-pill"
//                       >
//                         {facebookLoading ? (
//                           <ContentLoader color="text-white" />
//                         ) : (
//                           <img
//                             src={"/assets/images/facebook-icon.svg"}
//                             className="mr-2"
//                             alt="Facebook"
//                           />
//                         )}
//                         Facebook
//                       </a>
//                     )}
//                   /> */
}
//                 </Form.Group>

//                 <Form.Group className="d-flex justify-content-between social-login-links mb-3">
//                   <a
//                     href={"/sign-up"}
//                     className="d-flex align-items-center justify-content-center fs-14 bg-grey-3 p-3 font-weight-bold text-black rounded-pill"
//                   >
//                     Create Account using Email
//                   </a>
//                 </Form.Group>

//                 <p className="fs-14 font-Modelica text-center mt-5 pt-md-5 font-weight-bold">
//                   Already have an Account?{" "}
//                   <a
//                     href={"/login"}
//                     className="fs-20 text-underline text-primary"
//                   >
//                     Login
//                   </a>{" "}
//                 </p>
//               </Form>
//             </div>
//             <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
//               <img src={"/assets/images/bg-signup.svg"} alt="login bg" />
//             </div>
//           </div>
//         </Container>
//       </div>
//     );
//   }
// }

// const mapDispatchToProps = (dispatch) => ({
//   onGoogleLogin: (data) => dispatch(google_login_action(data)),
//   onFacebookLogin: (data) => dispatch(facebook_login_action(data)),
//   setClientForNotification: (data) =>
//     dispatch(set_client_token_for_notification_action(data)),
// });

// export default connect(null, mapDispatchToProps)(CreateAccount); */}
