import React, { Component } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import "./HeaderNew.scss";
import Login from "../../../Common/Login/Login";
import SignUp from "../../../Common/SignUp/SignUp";
export class HeaderNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInModal: false,
      signUpModal: false,
      menuOpen: false,
      backToTop: false,
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  signInModalHandler = () => {
    this.setState({
      signInModal: !this.state.signInModal
    });
  };
  signUpModalHandler = () => {
    this.setState({
      signUpModal: !this.state.signUpModal
    });
  };
  menuOpenHandler = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  
  toggleVisibility() {
    if (window.pageYOffset > 250) {
      this.setState({
        backToTop: true,
      });
    } else {
      this.setState({
        backToTop: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  render() {
    const { backToTop } = this.state;
    return (
      <div className="user-header-wrapper d-flex flex-column position-fixed shadow-sm">
        <div className="container py-2 bg-white">
          <div className="top-strip d-flex justify-content-between align-items-center ">
            <div className="logo">
              <a href="/home-page-new">
                <img src={"/assets/images/darious-tv.png"} alt="darious-tv" />
              </a>
            </div>

            <div
              className={`primary-menu ${this.state.menuOpen ? "active" : ""}`}
              onClick={this.menuOpenHandler}
            >
              <button
                className="menu-close bg-primary text-white bg-transparent border-0 position-absolute d-md-none"
                onClick={this.menuOpenHandler}
              >
                X
              </button>
              <ul
                className="d-flex align-items-md-center flex-column flex-md-row"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <li>
                  <a href={"/home-page-new"}
                    className={`px-2 font-weight-semi ${this.props.activeMenuItem === "home" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-home mr-2" aria-hidden="true"></i>Home
                  </a>
                </li>

                <li>
                  <a
                    href={"/all-recipes"}
                    className={`px-2 font-weight-semi ${this.props.activeMenuItem === "recipes" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-cutlery mr-2" aria-hidden="true"></i>
                    Recipes
                  </a>
                </li>

                {/* <li>
                  <a
                    href={"/all-videos"}
                    className={`px-2 font-weight-semi menu-items ${this.props.activeMenuItem === "videos" ? "active" : ""
                      }`}
                  >
                    <i
                      className="fa fa-video-camera mr-2"
                      aria-hidden="true"
                    ></i>
                    Videos
                  </a>
                </li> */}
                <li>
                  <a
                    href={"/all-shows"}
                    className={`px-2 font-weight-semi menu-items ${this.props.activeMenuItem === "shows" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-television mr-2" aria-hidden="true"></i>
                    Shows
                  </a>
                </li>
              </ul>
            </div>

            {/* <nav>
              <ul className="d-flex align-items-center">
                 <li>
                  <a
                    href="/home-page-new"
                    className={`px-2 font-weight-semi menu-items ${
                      this.props.activeMenuItem === "home" ? "active" : ""
                    }`}
                  >
                    <i className="fa fa-home mr-2" aria-hidden="true"></i>Home
                  </a>
                </li> 
                <li>
                  <a
                    href={"/all-recipes"}
                    className={`px-2 font-weight-semi menu-items ${this.props.activeMenuItem === "recipes" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-cutlery mr-2" aria-hidden="true"></i>
                    Recipes
                  </a>
                </li> 
                <li>
                  <a
                    href={"/all-videos"}
                    className={`px-2 font-weight-semi menu-items ${this.props.activeMenuItem === "videos" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-video-camera mr-2" aria-hidden="true"></i>
                    Videos
                  </a>
                </li> 
                <li>
                  <a
                    href={"/all-shows"}
                    className={`px-2 font-weight-semi menu-items ${this.props.activeMenuItem === "shows" ? "active" : ""
                      }`}
                  >
                    <i className="fa fa-video-camera mr-2" aria-hidden="true"></i>
                    Shows
                  </a>
                </li> 
              </ul>
            </nav> */}
            <div className="d-flex align-items-center ">
              <Button
                onClick={this.signInModalHandler}
                className="btn btn-outline-purple rounded px-2 px-md-4 py-2 mr-3 px-md-5 py-md-3"
              >
                Log in
              </Button>
              <Button
                onClick={this.signUpModalHandler}
                className="btn btn-primary rounded px-2 px-md-4 py-2 px-md-5 py-md-3"
              >
                Sign up
              </Button>
            </div>
            <span
              className="menu-open d-inline-block d-md-none fa fa-reorder bg-primary text-white ml-3 cursor-pointer fs-20"
              onClick={this.menuOpenHandler}
            ></span>
          </div>

        </div>
        {/*  popup  */}
        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.signInModal}
          onHide={this.signInModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content position-relative p-0">
            <span
              onClick={this.signInModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <Login history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>

        {/* sign up modal  */}

        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.signUpModal}
          onHide={this.signUpModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content position-relative p-0">
            <span
              onClick={this.signUpModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <SignUp history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>
        {backToTop && (
          <span
            className="back-top-btn bg-primary d-flex cursor-pointer align-items-center justify-content-center rounded-circle"
            onClick={() => this.scrollToTop()}
          >
            <span className="text-white fa fa-angle-up"></span>
          </span>
        )}
      </div>
    );
  }
}

export default HeaderNew;
// --------------signup - email----------------

// < div className = "common-login d-flex align-items-center jusfity-content-center" >
//     <Container className="p-0">
//         <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
//             <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1  mb-4 mb-md-0 py-2">
//                 <Form>
//                     <h2 className="text-dark form-title text-center font-poppins mb-5">Create using Email</h2>

//                     <Form.Group className='with-placeholder'>
//                         <Form.Control
//                             type="text"
//                             placeholder=" "
//                             name='firstName'

//                             required
//                         />
//                         <span className="placeholder-text font-montserrat">First Name</span>
//                     </Form.Group>
//                     <Form.Group className='with-placeholder'>
//                         <Form.Control
//                             type="text"
//                             placeholder=" "
//                             name='lastName'

//                             required
//                         />
//                         <span className="placeholder-text font-montserrat">Last Name</span>
//                     </Form.Group>
//                     <Form.Group className='with-placeholder'>
//                         <Form.Control
//                             className=""
//                             type="email"
//                             placeholder=" "
//                             name='email'
//                             required
//                         />
//                         <span className="placeholder-text font-montserrat">Email address</span>
//                     </Form.Group>

//                     <Form.Group className='with-placeholder'>
//                         <Form.Control
//                             type="password"
//                             placeholder=" "
//                             name='password'
//                         />
//                         <span className="placeholder-text font-montserrat">Password</span>
//                     </Form.Group>

//                     <Form.Group className='with-placeholder'>
//                         <Form.Control
//                             className=""
//                             type="password"
//                             placeholder=" "
//                             name='confirm_password'
//                             required
//                         />
//                         <span className="placeholder-text font-montserrat">Confirm Password</span>
//                     </Form.Group>

//                     <p className="fs-14 font-source-sans text-center font-weight-semi my-3">Read the
//                         <a href="#" className="text-underline text-primary mx-1">Terms and Conditions</a>
//                         and the
//                         <a href="#" className="text-underline text-primary mx-1">privacy policy</a>
//                         here
//                     </p>

//                     <Form.Group className="mb-3">
//                         <Button
//                             type="submit"
//                             onClick={this.handleSubmit}
//                             variant="warning"
//                             className="fs-18"
//                             block
//                         >
//                             Signup
//                         </Button>
//                     </Form.Group>
//                     <p className="fs-16 font-source-sans text-center font-weight-semi">Already have an Account? <a href="#" className="fs-20 text-underline text-primary font-weight-bold">Login</a> </p>
//                 </Form>
//             </div>
//             <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
//                 <img src="/assets/images/bg-signup-email.svg" alt="login bg" />
//             </div>
//         </div>
//     </Container>
//                     </div >

// --------------signup email----end------------

//  ----------------login---------------------
{
  /* <div className="common-login d-flex align-items-center jusfity-content-center fs-18">
                            <Container className="p-0">
                                <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
                                    <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
                                        <Form action="#">
                                            <h2 className="text-dark form-title text-center font-poppins mb-5">Login</h2>
                                            <Form.Group className="d-flex justify-content-between social-login-links">
                                                <a
                                                    className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black rounded-pill"
                                                >
                                                    <img
                                                        src="/assets/images/google-plus-icon.svg"
                                                        className="mr-2"
                                                        alt="Google"
                                                    />
                                                    Google
                                                </a>
                                                <a
                                                    className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black ml-2 rounded-pill"
                                                >
                                                    <img
                                                        src="/assets/images/facebook-icon.svg"
                                                        className="mr-2"
                                                        alt="Facebook"
                                                    />
                                                    Facebook
                                                </a>
                                            </Form.Group>
                                            <Form.Group className='with-placeholder'>
                                                <Form.Control
                                                    type="email"
                                                    placeholder=" "
                                                    name='email'
                                                    required
                                                />
                                                <span className="placeholder-text">Email address</span>
                                            </Form.Group>
                                            <Form.Group className='with-placeholder'>
                                                <Form.Control

                                                    type="password"
                                                    placeholder=" "
                                                    Facebook name='password'
                                                    required
                                                />
                                                <span className="placeholder-text">Password</span>
                                            </Form.Group>
                                            <a href="/forgot-password" className="text-primary font-weight-bold d-block mb-3">Forgot Password?</a>
                                            <Form.Group className="mb-4">
                                                <Button
                                                    type="submit"
                                                    variant="warning"
                                                    className="fs-18"
                                                >
                                                    <div>Login</div>
                                                </Button>
                                            </Form.Group>
                                            <p className="fs-16 font-source-sans font-weight-semi">Dont have an Account yet? <a href="/create-account" className="fs-20 text-underline text-primary font-weight-bold">Create Account</a> </p>
                                        </Form>
                                    </div>
                                    <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
                                        <img src="/assets/images/bg-login.svg" alt="login bg" />
                                    </div>
                                </div>
                            </Container>
                        </div> */
}
//  ----------------login-----end-----------

//   ---------------------create account-----------start
{
  /* <div className="common-login d-flex align-items-center jusfity-content-center">
    <Container className="p-0">
        <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 pb-5 mb-md-0">
                <Form action="#">
                    <h2 className="text-dark form-title text-center font-poppins mb-4">Create Account</h2>
                    <p className="fs-16 text-dark   mb-5 text-center font-weight-bold">Welcome to Darius Cooks ! <br /> Create Account using any one of the following options </p>
                    <Form.Group className="d-flex justify-content-between social-login-links py-3">
                        <a
                            className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black rounded-pill"
                        >
                            <img
                                src="/assets/images/google-plus-icon.svg"
                                className="mr-2"
                                alt="Google"
                            />
                            Google
                        </a>

                        <a
                            className="d-flex align-items-center justify-content-center font-smallest bg-grey-3 p-2 font-weight-bold text-black ml-2 rounded-pill"
                        >

                            <img
                                src="/assets/images/facebook-icon.svg"
                                className="mr-2"
                                alt="Facebook"
                            />
                            Facebook
                        </a>


                    </Form.Group>

                    <Form.Group className="d-flex justify-content-between social-login-links mb-3">
                        <a href={"/sign-up"} className="d-flex align-items-center justify-content-center fs-14 bg-grey-3 p-3 font-weight-bold text-black rounded-pill">Create Account using Email</a>
                    </Form.Group>

                    <p className="fs-14 font-Modelica text-center mt-5 pt-md-5 font-weight-bold">Already have an Account? <a href={"/login"} className="fs-20 text-underline text-primary">Login</a> </p>
                </Form>
            </div>
            <div className="display-img  order-1 order-md-2 mb-5 mb-md-0">
                <img src={"/assets/images/bg-signup.svg"} alt="login bg" />
            </div>
        </div>
    </Container>
</div> */
}

//   ---------------------create account-----------start
