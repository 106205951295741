import React, { Component } from "react";
import VariantSelector from "./VariantSelector";

class Product extends Component {
  constructor(props) {
    super(props);

    let defaultOptionValues = {};
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = { selectedOptions: defaultOptionValues };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(
      this.props.product,
      selectedOptions
    );

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image,
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value,
    });
  }

  render() {
    let variantImage =
      this.state.selectedVariantImage || this.props.product.images[0];
    let variant = this.state.selectedVariant || this.props.product.variants[0];
    let variantQuantity = this.state.selectedVariantQuantity || 1;
    let variantSelectors = this.props.product.options.map((option) => {
      return (
        <div className="varient-options px-1" key={option.id.toString()}>
          <VariantSelector
            handleOptionChange={this.handleOptionChange}
            key={option.id.toString()}
            option={option}
          />
        </div>
      );
    });
    return (
      <div className="Product border shadow-sm p-3 rounded">
        {this.props.product.images.length ? (
          <div className="product-img d-flex align-items-center justify-content-center">
            <img
              src={variantImage.src}
              alt={`${this.props.product.title} product shot`}
            />
          </div>
        ) : null}
        <h5
          className="Product__title text-black"
          title={this.props.product.title}
        >
          {this.props.product.title.length > 100 ? (
            <>{`${this.props.product.title.substring(0, 100)}...`}</>
          ) : (
            <>{this.props.product.title}</>
          )}
        </h5>
        {/* <span className="Product__price">${variant.price}</span> */}
        <h5 className="text-warning mt-3 mb-3 ">
          {variant?.price && variant.price?.currencyCode}{" "}
          {variant?.price && variant.price?.amount}
        </h5>
        <div className="d-flex align-items-center justify-content-between mx-n1">
          {variantSelectors}
        </div>

        <div className="d-flex align-items-center mb-3 mx-n1 product-quantity justify-content-between">
          <label className="Product__option mb-0 px-1">Quantity</label>
          <div className="px-1">
            <input
              min="1"
              type="number"
              defaultValue={variantQuantity}
              onChange={this.handleQuantityChange}
              className="form-control"
            />
          </div>
        </div>
        <button
          className="Product__buy button btn btn-primary btn-block py-2"
          onClick={() =>
            this.props.addVariantToCart(variant.id, variantQuantity)
          }
        >
          Add to Cart
        </button>
      </div>
    );
  }
}

export default Product;
