import {groceryConstants} from "../../_constants/User/grocery.constants";

const initialState = {};

export const get_grocery_list_reducer = (state = initialState, action) => {
    switch (action.type) {
      case groceryConstants.GET_GROCERY_LIST_ACTION_REQUEST:
        return {
          type: "alert-request",
          message: action.payload,
        };
      case groceryConstants.GET_GROCERY_LIST_BYID_ACTION_SUCCESS:
      case groceryConstants.GET_GROCERY_LIST_ACTION_SUCCESS:
        return {
          type: "alert-success",
          data: action.payload,
        };
      case groceryConstants.GET_GROCERY_LIST_ACTION_FAILURE:
        return {
          type: "alert-failure",
          message: action.payload,
        };
      default:
        return state;
    }
  };