export const livestreamConstants = {
    GET_LIVESTREAM_PLAYBACKID_ACTION_REQUEST: 'GET_LIVESTREAM_PLAYBACKID_ACTION_REQUEST',
    GET_LIVESTREAM_PLAYBACKID_ACTION_SUCCESS: 'GET_LIVESTREAM_PLAYBACKID_ACTION_SUCCESS',
    GET_LIVESTREAM_PLAYBACKID_ACTION_FAILURE: 'GET_LIVESTREAM_PLAYBACKID_ACTION_FAILURE',

    ADD_COMMENT_LIVESTREAM_ACTION_REQUEST:"ADD_COMMENT_LIVESTREAM_ACTION_REQUEST",
    ADD_COMMENT_LIVESTREAM_ACTION_SUCCESS:"ADD_COMMENT_LIVESTREAM_ACTION_SUCCESS",
    ADD_COMMENT_LIVESTREAM_ACTION_FAILURE:"ADD_COMMENT_LIVESTREAM_ACTION_FAILURE",

    GET_COMMENTS_LIST_ACTION_REQUEST:"GET_COMMENTS_LIST_ACTION_REQUEST",
    GET_COMMENTS_LIST_ACTION_SUCCESS:"GET_COMMENTS_LIST_ACTION_SUCCESS",
    GET_COMMENTS_LIST_ACTION_FAILURE:"GET_COMMENTS_LIST_ACTION_FAILURE",

    GET_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST:"GET_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST",
    GET_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS:"GET_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS",
    GET_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE:"GET_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE",

    GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST:"GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST",
    GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS:"GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS",
    GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE:"GET_MUX_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE"
}