import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Comments from './Comments/Comments'
import RecipeDetails from './RecipeDetails/RecipeDetails';
import './CommentsRecipeTabs.scss';
import ContentLoader from "../../../Common/Loader/ContentLoader";

class CommentsRecipeTabs extends Component {
    state = {
        userObject: null,
    }

    componentDidMount() {
        let userObject = JSON.parse(localStorage.getItem("user"));

        this.setState({
            userObject: userObject
        });
    }
    render() {
        let data = this.props && this.props.details;
        let { userObject } = this.state;
        return (
            <>
                {/* Recipe & comments tab*/}
                {
                    !data ?
                        <div className="ml-auto text-center mt-5" >
                            <ContentLoader color='text-warning' />
                        </div>
                        : <Tabs className="comments-recipe-tab" defaultActiveKey="Recipe" id="uncontrolled-tab-example" >
                            <Tab eventKey="Recipe" title="Recipe">
                                <RecipeDetails
                                    receipeData={data}
                                    history={this.props.history}
                                />
                            </Tab>
                            {userObject ? 
                                <Tab eventKey="Comments" title="Comments">
                                    <Comments commentsDetails={data}
                                     history={this.props.history}
                                    />
                                </Tab> : null}

                        </Tabs>
                }
            </>
        )
    }
}

export default CommentsRecipeTabs;