export const uploadVideoConstants = {
    UPLOAD_VIDEO_DETAILS_ACTION_REQUEST: 'UPLOAD_VIDEO_DETAILS_ACTION_REQUEST',
    UPLOAD_VIDEO_DETAILS_ACTION_SUCCESS: 'UPLOAD_VIDEO_DETAILS_ACTION_SUCCESS',
    UPLOAD_VIDEO_DETAILS_ACTION_FAILURE: 'UPLOAD_VIDEO_DETAILS_ACTION_FAILURE',
    
    PUBLISH_VIDEO_ACTION_REQUEST: 'PUBLISH_VIDEO_ACTION_REQUEST',
    PUBLISH_VIDEO_ACTION_SUCCESS: 'PUBLISH_VIDEO_ACTION_SUCCESS',
    PUBLISH_VIDEO_ACTION_FAILURE: 'PUBLISH_VIDEO_ACTION_FAILURE',

    DELETE_CONNECTED_RECIPE_ACTION_REQUEST: 'DELETE_CONNECTED_RECIPE_ACTION_REQUEST',
    DELETE_CONNECTED_RECIPE_ACTION_SUCCESS: 'DELETE_CONNECTED_RECIPE_ACTION_SUCCESS',
    DELETE_CONNECTED_RECIPE_ACTION_FAILURE: 'DELETE_CONNECTED_RECIPE_ACTION_FAILURE',

    CREATE_MUX_URL_ACTION_REQUEST: 'CREATE_MUX_URL_ACTION_REQUEST',
    CREATE_MUX_URL_ACTION_SUCCESS: 'CREATE_MUX_URL_ACTION_SUCCESS',
    CREATE_MUX_URL_ACTION_FAILURE: 'CREATE_MUX_URL_ACTION_FAILURE',

    GET_PLAYBACK_ASSET_ID_ACTION_REQUEST: 'GET_PLAYBACK_ASSET_ID_ACTION_REQUEST',
    GET_PLAYBACK_ASSET_ID_ACTION_SUCCESS: 'GET_PLAYBACK_ASSET_ID_ACTION_SUCCESS',
    GET_PLAYBACK_ASSET_ID_ACTION_FAILURE: 'GET_PLAYBACK_ASSET_ID_ACTION_FAILURE',
}