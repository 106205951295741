import React, { Component } from "react";
import { Button, Modal, Form, Card, ListGroup } from "react-bootstrap";
import { header_search_query_action, header_search_action } from "../../../../_actions/User";
import { connect } from "react-redux";
import Login from "../../../Common/Login/Login";

export class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      topNotifications: [],
      searchResultsData: {},
      searchQueryResult: [],
      searchKeyword: "",
      LoginModal: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeSearchHandler();
    }
  }
  // search handler ===========
  getSearchKeyword = async (e) => {
    this.setState({ searchKeyword: e.target.value });
    
    let searchText = e.target.value;
    if (searchText.length === 0) {
      this.setState({ searchQueryResult: [] });
      
      return
    } else {
      const res = await this.props.searchQueryResult({ search: searchText });
      this.setState({ searchQueryResult: res });
      return;
    }
    
    //this.setState({ searchKeyword: searchText });
  };
  searchHandler = async (e) => {
    e.preventDefault();
    let { searchKeyword} = this.state;

    // let searchKeywordText = { search: searchKeyword };

    
    // let searchResult = await this.props.searchResultsData(searchKeywordText);

    // let searchResultData = searchKeyword.length > 0 ? searchResult && searchResult.data : [];
    if (searchKeyword) {
      this.props.history.push({
        pathname: "/search-results",
        state: {
          searchKeyword: searchKeyword
        },
      });
      return;
    }
  };

  handleSearchClick = (data) => {
    if (data && data.content_type === "video") {
      if (data && data.is_premium) {
        localStorage.setItem("videoId", data && data.id);
        this.setState({ LoginModal: !this.state.LoginModal });
      } else {
        this.props.history.push(`/video/${data.id}`);
      }
    } else if (data && data.content_type === "show") {
      this.props.history.push(`/view-show/${data.id}`);
    } else if (data && data.content_type === "youtube-recipe") {
      this.props.history.push(`/recipe/${data.id}`);
    } else if (data && data.content_type === "episode") {
      if (data && data.is_premium) {
        localStorage.setItem("episodeId", data && data.id);
        this.setState({ LoginModal: !this.state.LoginModal });
      } else {
        this.props.history.push(`/view-show/${data.show}`);
      }
    }
  };
  closeSearchHandler = () => {
    this.setState({ searchQueryResult: [], searchKeyword: "" });
  };
  LoginModalHandler = () => {
    this.setState({
      LoginModal: !this.state.LoginModal
    });
  };
  render() {
    return (
      <div className="mt-4">
        {/* search handler */}
        <div className="container px-lg-0">
          <div className="top-search-wrapper flex-grow-1 mb-5">
            {/* <Form onSubmit={(e) => this.searchHandler(e)}>  */}
            <Form className="position-relative"  onSubmit={(e) => this.searchHandler(e)}>
              <Form.Group className="mb-0 h-100 position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search Text"
                  // value={this.state.searchKeyword}
                  className="search-bar bg-grey-4 border-6 pl-4"
                  onChange={(e) => this.getSearchKeyword(e)}
                  
                />
              </Form.Group>
              {this.state &&
              this.state.searchQueryResult &&
              this.state.searchQueryResult.data ? (
                <Card className="position-absolute">
                  <ListGroup variant="flush">
                    {this.state.searchQueryResult &&
                      this.state.searchQueryResult.data &&
                      this.state.searchQueryResult.data.map((data) => (
                        <>
                          <ListGroup.Item
                            className="cursor-pointer d-flex align-items-center"
                            key={data.id}
                            onClick={() => this.handleSearchClick(data)}
                          >
                            {data.is_premium ? (
                              <img
                                src={"assets/images/premium-icon.svg"}
                                alt="premium"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  marginRight: "10px"
                                }}
                              />
                            ) : null}
                            <>
                              {data.title}:
                              <em className="text-info ml-2">
                                {data && data.content_type === "youtube-recipe"
                                  ? "Recipe"
                                  : data.content_type}
                              </em>
                            </>
                          </ListGroup.Item>
                        </>
                      ))}
                  </ListGroup>
                </Card>
              ) : null}
               <i className="fa fa-search fa-1x" aria-hidden="true"></i>
            </Form>
          </div>
        </div>

        {/*  popup  */}
        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.LoginModal}
          onHide={this.LoginModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content p-0 position-relative">
            <span
              onClick={this.LoginModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <Login history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchQueryResultData:
      state.header_search_query_result_reducer &&
      state.header_search_query_result_reducer.data &&
      state.header_search_query_result_reducer.data.data
  };
};
const mapDispatchToProps = (dispatch) => ({
  searchQueryResult: (data) => dispatch(header_search_query_action(data)),
  searchResultData: (data) => dispatch(header_search_action(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
