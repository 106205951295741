import React, { Component } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import EpisodesList from "./EpisodesList/EpisodesList";
import "./ViewShow.scss";
import { get_show_details_actions } from "../../../_actions/User/show.action";
import ContentLoader from "../../Common/Loader/ContentLoader";
import {
  add_bookmark_to_post_action,
  hit_like_action
} from "../../../_actions/User/post.action";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";

class ViewShow extends Component {
  state = {
    loadingShowDetails: false,
    showDetails: null,
    userObject: null,
    copySuccess: "copy",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.mountShowDetails();

    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState({
      userObject: userObject
    });
  }

  mountShowDetails = async () => {
    const { showId } = this.props.match.params;

    this.setState({ loadingShowDetails: true });

    const getShowDetailsRes = await this.props.getShowDetails(showId);
    if (getShowDetailsRes) this.setState({ showDetails: getShowDetailsRes });

    this.setState({ loadingShowDetails: false });
  };

  checkForLikeAndBookmark = async (id) => {
    const getShowDetailsRes = await this.props.getShowDetails(id);

    if (getShowDetailsRes) this.setState({ showDetails: getShowDetailsRes });
  };

  likeShow = async (id) => {
    if (!id) return;
    const data = {
      content_type: "show",
      id
    };
    await this.props.hitLike(data);
    await this.checkForLikeAndBookmark(id);
  };

  bookmarkShow = async (id) => {
    if (!id) return;
    const data = {
      content_type: "show",
      id
    };
    await this.props.addBookmarkAction(data);
    await this.checkForLikeAndBookmark(id);
  };
  copyToClipboard = (e) => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    this.setState({ copySuccess: 'Copied!' });
  };
  render() {
    const { loadingShowDetails, showDetails, userObject } = this.state;
    return (
      <div className="common-dashboard view-show-wrapper show-list-wrapper">
        <Container className="p-0">
          {/* <SidebarMenu /> */}

          <div className="main-content bg-white">
            {userObject ? <Header className="pt-2" /> : <HeaderNew history={this.props.history} />}
            {loadingShowDetails ? (
              <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                <ContentLoader color="text-warning" />
              </div>
            ) : showDetails && Object.keys(showDetails).length ? (
              <>
                <div>
                  <a
                    // href="/user-home-page"
                    onClick={() => {
                      this.props && this.props.history && this.props.history.goBack();
                    }}
                    className="align-items-center d-none d-md-flex"
                  >
                    <img
                      src="/assets/images/back-arrow.svg"
                      alt="back"
                      className="ml-md-n2"
                    />
                    <span className="text-dark fs-16 font-weight-bold ml-1">
                      Back
                    </span>
                  </a>
                </div>
                <Row className="align-items-center mt-3">
                  <Col md={8} className="mb-3 mb-md-0">
                    <div className="show-thumbnail">
                      <img
                        src={showDetails["title_image"]}
                        alt=""
                        className="w-100 border-radius-10"
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="show-details">
                      <h2 className="mb-3">{showDetails["title"]}</h2>
                      {/* icon list */}
                      {userObject ? (
                        <>
                          <ul className="share-links d-flex align-items-center mb-2">
                            {/* like icon */}
                            <li>
                              <a>
                                {showDetails &&
                                  showDetails["likes"] &&
                                  showDetails["likes"].includes(
                                    userObject.user.id
                                  ) ? (
                                  <span
                                    onClick={() =>
                                      this.likeShow(
                                        showDetails && showDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart text-danger fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.likeShow(
                                        showDetails && showDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart-o text-black fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                            </li>
                            {/* share icon */}
                            <li className="ml-4">
                              <Dropdown className="d-inline-block">
                                <Dropdown.Toggle variant="" className="px-0 btn-share" id="dropdown-basic">
                                  <img
                                    src={"/assets/images/share.png"}
                                    alt="share"
                                    className="mr-2"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className="py-1 px-2 cursor-pointer" onClick={(e) => this.copyToClipboard(e)} >
                                    <span className="fa fa-clone mr-2"></span>
                                    {this.state.copySuccess}
                                  </div>

                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                            {/* bookmark icon */}
                            <li className="ml-4">
                              <a>
                                {showDetails &&
                                  showDetails["bookmarks"] &&
                                  showDetails["bookmarks"].includes(
                                    userObject.user.id
                                  ) ? (
                                  <span
                                    onClick={() =>
                                      this.bookmarkShow(
                                        showDetails && showDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark text-warning fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.bookmarkShow(
                                        showDetails && showDetails.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark-o text-black fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                            </li>
                          </ul>
                        </>
                      ) : null}

                      <div className="mb-4">
                        <p>{showDetails["description"]}</p>
                      </div>
                      {showDetails &&
                        showDetails["episodes"] &&
                        showDetails["episodes"][0] &&
                        showDetails["episodes"][0].id ? (
                        <a
                          href={`/watch-show/${showDetails["episodes"][0].id}`}
                          className="btn btn-primary rounded"
                        >
                          Watch Show
                        </a>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="episodes-list mt-5">
                  <h2 className="mb-4">Episodes List</h2>
                  {showDetails &&
                    showDetails["episodes_count"] > 0 &&
                    showDetails["episodes"] &&
                    showDetails["episodes"].length ? (
                    <EpisodesList episodes={showDetails["episodes"]} history={this.props.history} />
                  ) : (
                    <p className="text-danger">oops! No Episodes to show!</p>
                  )}
                </div>
              </>
            ) : (
              <div className="text-center">
                <p className="text-danger-2 font-weight-semi">
                  oops! No show details found!
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getShowDetails: (showId) => dispatch(get_show_details_actions(showId)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data))
});
export default connect(null, mapDispatchToProps)(ViewShow);
