import React, { Component } from 'react';
import { Button, Col, Tabs, Tab, Row, Container } from 'react-bootstrap';
import "./TopNotification.scss";
import ContentLoader from "../../../Common/Loader/ContentLoader";
import moment from "moment";
import { withRouter } from 'react-router';

class TopNotification extends Component {
    state = {
        notifications: this.props &&
            this.props.notifications &&
            this.props.notifications.length ?
            this.props.notifications
            : [],
        today: [],
        yesterday: [],
        dayBeforeYesterday: []
    }

    componentDidMount() { this.arrangeNotifications() }

    arrangeNotifications = () => {
        const { notifications } = this.state
        if (notifications &&
            notifications.length
        ) {
            let todayArray = [], yesterdayArray = [], dayBeforeYesterdayArray = []
            notifications.forEach(notification => {
                const todayDate = new Date()
                let yesterday = new Date()
                let dayBeforeYesterdayDate = new Date()

                yesterday.setDate(todayDate.getDate() - 1)
                dayBeforeYesterdayDate.setDate(todayDate.getDate() - 2)
                if (new Date(notification.start_date).toLocaleDateString() === todayDate.toLocaleDateString())
                    todayArray.push(notification)
                else if (new Date(notification.start_date).toLocaleDateString() === yesterday.toLocaleDateString())
                    yesterdayArray.push(notification)
                else if (new Date(notification.start_date).toLocaleDateString() === dayBeforeYesterdayDate.toLocaleDateString())
                    dayBeforeYesterdayArray.push(notification)
            })

            this.setState({
                today: todayArray,
                yesterday: yesterdayArray,
                dayBeforeYesterday: dayBeforeYesterdayArray
            })
        }
    }

    handleClick = (notification) => {

        let postType = notification && notification.published_item_type;
        let postId = notification && notification.published_item_id;

        if (postType === "video") {
            return this.props.history.push(`/video/${postId}`);
        } else if (postType === "episode") {
            return this.props.history.push(`/watch-show/${postId}`);
        } else if (postType === "youtube-recipe") {
            return this.props.history.push(`/recipe/${postId}`);
        } else if (postType === "show") {
            return this.props.history.push(`/view-show/${postId}`);
        } else if (notification && notification.content === "Darius is going live") {
            return this.props.history.push(`/join-livestream`);
        }
    }
    render() {

        const { today, yesterday, dayBeforeYesterday } = this.state
        const todayDate = new Date()
        const dayBeforeYesterdayDate = new Date()
        dayBeforeYesterdayDate.setDate(todayDate.getDate() - 2)
        return (
            <Container>
                <div className="announcements-wrapper position-relative">
                    <h4 className="d-inline-block pt-3 mb-3">Announcements</h4>
                    <a
                        href={"/notifications"}
                        className="fs-18 font-weight-semi font-poppins text-primary pl-5"
                    >
                        view all{" "}
                        <img src={"/assets/images/next-btn.svg"} className="ml-3" alt="" />{" "}
                    </a>
                    {
                        this.props.updatingAnnouncements ?
                            <div style={{ margin: "50px auto 0", textAlign: 'center' }}
                            >
                                <ContentLoader color='text-warning' />
                            </div>

                            : <>
                                {/*================= Notifications for desktop =========== */}

                                <div className="all-notifiactions d-none d-md-block">
                                    <Row className="notification-wrapper border-radius-10 mx-1">
                                        <Col md={4} className="notifiaction-box pb-3">
                                            <h6 className="font-montserrat mt-3 mb-2">Today</h6>
                                            {/*<Button variant="primary"*/}

                                            {/*    className="notification-btn border-6 d-flex align-items-center px-3 w-100"*/}
                                            {/*>*/}
                                            {/*    <img*/}
                                            {/*        src={"assets/images/live-img.svg"}*/}
                                            {/*        className="chef-img mr-3"*/}
                                            {/*        alt={'live'}*/}
                                            {/*    />*/}
                                            {/*    <p className="font-weight-medium text-white mb-0">DariusCooks is <span className="text-warning">live now!</span></p>*/}
                                            {/*</Button>*/}
                                            {
                                                today.length ?
                                                    today.map(notification =>
                                                        <div className='bg-grey d-flex align-items-center border-6 py-2 px-3 mt-3 cursor-pointer'>
                                                            <p
                                                                key={notification.id}
                                                                className="mb-0"
                                                                dangerouslySetInnerHTML={{ __html: notification.content }}
                                                                onClick={() => this.handleClick(notification)}
                                                            />
                                                            {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                                <p className='mb-0' onClick={() => this.handleClick(notification)} >- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                            ) : null}                                                    </div>
                                                    )
                                                    : <p className='text-primary'>No notifications for this day</p>
                                            }
                                        </Col>
                                        <Col md={4} className="notifiaction-box pb-3">
                                            <h6 className="mt-3 mb-2">Yesterday</h6>
                                            
                                            {
                                                yesterday.length ?
                                                    yesterday.map(notification =>

                                                        <div className='bg-grey d-flex align-items-center border-6 py-2 px-3 mt-3 cursor-pointer'>
                                                            <p
                                                                key={notification.id}
                                                                className="mb-0"
                                                                dangerouslySetInnerHTML={{ __html: notification.content }}
                                                                onClick={() => this.handleClick(notification)}
                                                            />
                                                            {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                                <p className='mb-0' onClick={() => this.handleClick(notification)}>- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                            ) : null}
                                                        </div>
                                                    )
                                                    : <p className='text-primary'>No notifications for this day</p>
                                            }

                                        </Col>
                                        <Col md={4} className="notifiaction-box pb-3">
                                            <h6 className="font-montserrat mt-3 mb-2">
                                                {

                                                    `${moment(dayBeforeYesterdayDate).format('MM-DD-YYYY')}`
                                                }
                                            </h6>
                                            {
                                                dayBeforeYesterday.length ?
                                                    dayBeforeYesterday.map(notification =>

                                                        <div className='bg-grey d-flex align-items-center border-6 py-2 px-3 mt-3 cursor-pointer'>
                                                            <p
                                                                key={notification.id}
                                                                className="mb-0"
                                                                dangerouslySetInnerHTML={{ __html: notification.content }}
                                                                onClick={() => this.handleClick(notification)}
                                                            />
                                                            {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                                <p className='mb-0' onClick={() => this.handleClick(notification)}>- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                            ) : null}
                                                        </div>
                                                    )
                                                    : <p className='text-primary'>No notifications for this day</p>
                                            }
                                        </Col>
                                    </Row>

                                </div>

                                {/* =========notifiactions for mobile============= */}
                                <div className="notifiactions-tab d-block d-md-none">
                                    <Tabs className="comments-recipe-tab" defaultActiveKey="Today" id="uncontrolled-tab-example">
                                        <Tab eventKey="Today" title="Today" className="pt-3">
                                            {/*  <Button variant="primary"

                                                className="notification-btn border-6 d-flex align-items-center px-3 w-100"
                                        >
                                            <img
                                                src={"assets/images/live-img.svg"}
                                                className="chef-img mr-3"
                                                alt={'live'}
                                            />
                                            <p className="font-weight-medium text-white mb-0">DariusCooks is <span className="text-warning">live now!</span></p>
                                        </Button> */}
                                            {
                                                today.length ?
                                                    today.map(notification =>
                                                    (<div className='bg-grey d-flex align-items-center border-6 py-4 px-3 mt-3 cursor-pointer'>
                                                        <p
                                                            key={notification.id}
                                                            className="mb-0"
                                                            dangerouslySetInnerHTML={{ __html: notification.content }}
                                                            onClick={() => this.handleClick(notification)}
                                                        />
                                                        {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                            <p className='mb-0' onClick={() => this.handleClick(notification)}>- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                        ) : null}
                                                    </div>

                                                    )) :
                                                    (
                                                        <p className='text-primary'>No notifications for this day</p>
                                                    )
                                            }

                                            {/*  <p className="font-montserrat bg-grey border-6 px-3 py-4 mt-3">I will be live at 7:30 to give you a glimpse of my preparation for dinner party .</p> */}
                                        </Tab>
                                        <Tab eventKey="Yesterday" title="Yesterday" className="pt-3">
                                            {
                                                yesterday.length ?
                                                    yesterday.map(notification =>
                                                        <div className='bg-grey d-flex align-items-center border-6 py-4 px-3 mt-3 cursor-pointer'>
                                                            <p
                                                                key={notification.id}
                                                                className="mb-0"
                                                                dangerouslySetInnerHTML={{ __html: notification.content }}
                                                                onClick={() => this.handleClick(notification)}
                                                            />
                                                            {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                                <p className='mb-0' onClick={() => this.handleClick(notification)}>- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                            ) : null}
                                                        </div>
                                                    )
                                                    : <p className='text-primary'>No notifications for this day</p>
                                            }
                                           </Tab>
                                        <Tab eventKey="Older" title="Older" className="pt-3">
                                            {
                                                dayBeforeYesterday.length ?
                                                    dayBeforeYesterday.map(notification =>
                                                        <div className='bg-grey d-flex align-items-center border-6 py-4 px-3 mt-3 cursor-pointer'>
                                                            <p
                                                                key={notification.id}
                                                                className="mb-0"
                                                                dangerouslySetInnerHTML={{ __html: notification.content }}
                                                                onClick={() => this.handleClick(notification)}
                                                            />
                                                            {notification && notification.announcement_item_details && notification.announcement_item_details.title ? (
                                                                <p className='mb-0' onClick={() => this.handleClick(notification)}>- "<em>{notification && notification.announcement_item_details && notification.announcement_item_details.title}</em>"</p>

                                                            ) : null}
                                                        </div>

                                                    )
                                                    : <p className='text-primary'>No notifications for this day</p>
                                            }
                                            {/* <div className="older-announcements bg-grey border-6 px-3 py-4">
                                            <p className="font-montserrat">
                                                Phew ! what a year it has been ! now it’s time
                                                to treat yourself with our goodies at our own online store. A Grand Fashion sale Preview. Get<a href="#"> 20% flat </a> discount on all the hoodies and tees .
                                            </p>
                                            <p>Shop :- <a href="#" className="text-primary">Link</a>
                                            </p>
                                        </div>
                                        <div className="older-announcements bg-grey border-6 px-3 py-4 mt-3">
                                            <p className="font-montserrat">
                                                Phew ! what a year it has been ! now it’s time
                                                to treat yourself with our goodies at our own online store. A Grand Fashion sale Preview. Get<a href="#"> 20% flat </a> discount on all the hoodies and tees .
                                            </p>
                                            <p>Shop :- <a href="#" className="text-primary">Link</a>
                                            </p>
                                        </div> */}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </>
                    }
                </div>
            </Container>

        )

    }
}
export default withRouter(TopNotification);