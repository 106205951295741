import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./UploadEpisodes.scss";
import UploadCarouselSlide from "../../../../common/UploadCarouselSlide/UploadCarouselSlide";
import { isEmptyObject } from "../../../../../../_helpers/validators";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      },
    },
  ],
};

export default ({ updates, addUpdateClickHandler }) => {
  return (
    <div className="slider-wrapper pt-0">
      <Slider {...settings} className="episodes">
        {updates && updates.length
          ? updates.map((update) => (
              <div
                onClick={() =>
                  addUpdateClickHandler(
                    "update",
                    update.priority,
                    update.id ? update.id : "new"
                  )
                }
                key={update.priority}
              >
                <UploadCarouselSlide
                  buttonTitle="Add update"
                  imgPreview={update.cover_image ? update.cover_image : null}
                />
              </div>
            ))
          : null}
      </Slider>
    </div>
  );
};
