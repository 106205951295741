import React, { Component } from "react";
import { Col, Container, Row, Modal, Dropdown } from "react-bootstrap";
import CommentsRecipeTabs from "../common/CommentsRecipeTabs/CommentsRecipeTabs";
import Header from "../common/Header/Header";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import SuggestedRecipes from "../common/SuggestedRecipes/SuggestedRecipes";
import "./ViewVideo.scss";
import { connect } from "react-redux";
import {
  get_video_by_id_action,
  get_all_suggested_videos_action,
  get_pricing_action,
} from "../../../_actions/User";
import VideoPlayer from "../common/VideoPlayer/VideoPlayer";
import ContentLoader from "../../Common/Loader/ContentLoader";
//import SubscriptionPopup from '../../common/SubscriptionPopup/SubscriptionPopup';
import SubscriptionPopup from "../../User/common/SubscriptionPopup/SubscriptionPopup";
import {
  add_bookmark_to_post_action,
  hit_like_action,
} from "../../../_actions/User/post.action";
import { HeaderNew } from "../HomePageNew/HeaderNew/HeaderNew";
import Login from "../../Common/Login/Login";
class ViewVideo extends Component {
  state = {
    loading: false,
    content: [],
    videoId: "",
    userObject: null,
    suggestedVideos: [],
    LoginModal: false,
    copySuccess: "copy",
    pricing: [],
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.getVideoContentData !== this.props.getVideoContentData) {
      this.setState({
        content: nextProps.getVideoContentData,
      });
    }
  };

  componentDidMount = () => {
    this.loadVideoDetails();
    this.getSuggestedVideos();
    this.getPricingDetailsData();
    let userObject = JSON.parse(localStorage.getItem("user"));
    this.setState({
      userObject: userObject,
    });
  };

  getSuggestedVideos = async () => {
    let data = await this.props.getSuggestedVideoContent();
    this.setState({
      suggestedVideos: data && data.data,
    });
  };

  getPricingDetailsData = async () => {
    let data = await this.props.getPricingDetails();
    if (data && data.data && data.data.data) {
      this.setState({
        pricing: data && data.data && data.data.data,
      });
    }
  };

  loadVideoDetails = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      await this.setState({ videoId: this.props.match.params.id }, async () => {
        let data = await this.props.getVideoContent(this.state.videoId);
      });
    }
    this.setState({ loading: false });
  };

  likeVideo = async (id) => {
    if (!id) return;
    const data = {
      content_type: "video",
      id,
    };
    await this.props.hitLike(data);
    await this.props.getVideoContent(id);
  };

  bookmarkVideo = async (id) => {
    if (!id) return;
    const data = {
      content_type: "video",
      id,
    };
    await this.props.addBookmarkAction(data);
    await this.props.getVideoContent(id);
  };

  handlePremiumRedirect = (ItemId) => {
    localStorage.setItem("videoId", ItemId);
    this.setState({ LoginModal: !this.state.LoginModal });
  };
  routeHandler = (id) => {
    this.props.history.push(`/video/${id}`);
    window.location.reload();
  };

  LoginModalHandler = () => {
    this.setState({
      LoginModal: !this.state.LoginModal,
    });
  };

  copyToClipboard = (e) => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    this.setState({ copySuccess: "Copied!" });
  };
  render() {
    const { loading, userObject, suggestedVideos } = this.state;
    let getVideoData =
      this.state && this.state.content && this.state.content.data;
    let { data } = this.state && this.state.content;

    let pricing = this.state && this.state.pricing;

    let suggestedVideosFiltered =
      suggestedVideos &&
      suggestedVideos.filter(
        (item) => (item && item.id) !== (getVideoData && getVideoData.id)
      );

    return (
      <div className="common-dashboard view-video-wapper">
        <Container className="p-0">
          {/* <SidebarMenu /> */}
          {userObject && data && pricing ? (
            <>
              {!loading ? (
                <SubscriptionPopup details={data} pricing={pricing} />
              ) : null}
            </>
          ) : null}

          <div className="main-content bg-white">
            {userObject ? (
              <Header className="pt-2" />
            ) : (
              <HeaderNew history={this.props.history} />
            )}
            {/*  */}

            {/* video box*/}
            {loading ? (
              <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                <ContentLoader color="text-warning" />
              </div>
            ) : getVideoData && Object.keys(getVideoData).length ? (
              <>
                <Row className="video-box mt-4">
                  <Col md={8} lg={9}>
                    {getVideoData && getVideoData.video_playback_id ? (
                      <VideoPlayer
                        playbackId={
                          getVideoData && getVideoData.video_playback_id
                        }
                      ></VideoPlayer>
                    ) : null}
                    <div className="episode-wrapper d-flex justify-content-between align-items-center mt-3 mb-3">
                      <h3 className="font-weight-semi">
                        {getVideoData && getVideoData.title}
                      </h3>
                      {userObject ? (
                        <>
                          {/* episode name and user action */}
                          <div className="bottom d-flex align-items-center">
                            {/* icon list */}
                            <div className="user-action-btn d-flex align-items-center">
                              {/* like icon */}
                              <a>
                                {" "}
                                {getVideoData &&
                                getVideoData["likes"] &&
                                getVideoData["likes"].includes(
                                  userObject.user.id
                                ) ? (
                                  <span
                                    onClick={() =>
                                      this.likeVideo(
                                        getVideoData && getVideoData.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart text-danger-2 mr-2 mr-md-4 fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.likeVideo(
                                        getVideoData && getVideoData.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-heart-o text-black mr-2 mr-md-4 fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                              {/* share icon */}
                              <Dropdown className="d-inline-block">
                                <Dropdown.Toggle
                                  variant=""
                                  className="px-0 btn-share"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={"/assets/images/share.png"}
                                    alt="share"
                                    className="mr-2 mr-md-4"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div
                                    className="py-1 px-2 cursor-pointer"
                                    onClick={(e) => this.copyToClipboard(e)}
                                  >
                                    <span className="fa fa-clone mr-2"></span>
                                    {this.state.copySuccess}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* bookmark icon */}
                              <a>
                                {" "}
                                {getVideoData &&
                                getVideoData["bookmarks"] &&
                                getVideoData["bookmarks"].includes(
                                  userObject.user.id
                                ) ? (
                                  <span
                                    onClick={() =>
                                      this.bookmarkVideo(
                                        getVideoData && getVideoData.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark text-warning fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      this.bookmarkVideo(
                                        getVideoData && getVideoData.id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-bookmark-o text-black fs-18"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </a>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="video-desc mb-4 pr-md-5">
                      <p className="text-grey-2 fs-small font-weight-semi">
                        {getVideoData && getVideoData.description}
                      </p>
                    </div>
                  </Col>
                  <Col md={4} lg={3} className="d-none d-md-block">
                    {/* suggestions list */}
                    <h3 className="mb-4">Suggested Videos</h3>

                    <div className="suggestions-wapper">
                      {suggestedVideosFiltered &&
                      suggestedVideosFiltered.length ? (
                        <>
                          <div className="suggestion-list">
                            {suggestedVideosFiltered &&
                              suggestedVideosFiltered.slice(0, 4) &&
                              suggestedVideosFiltered
                                .slice(0, 4)
                                .map((item) => (
                                  <div key={item.id}>
                                    {userObject ? (
                                      <li
                                        className="suggestion-detail d-flex mb-4 cursor-pointer"
                                        onClick={() => {
                                          this.routeHandler(item && item.id);
                                        }}
                                      >
                                        <div className="img">
                                          <img
                                            src={
                                              item && item.title_image
                                                ? item && item.title_image
                                                : "/assets/images/placeholder-recipe.png"
                                            }
                                            alt="suggestions-item"
                                            className="img-fit border-6 mr-4"
                                          />
                                        </div>
                                        <div className="right d-flex flex-column align-items-start">
                                          <h6>{item && item.title}</h6>
                                          <p className="info font-weight-semi font-smallest pt-1">
                                            {item.description.length > 75 ? (
                                              <>
                                                {item.description.substring(
                                                  0,
                                                  75
                                                )}
                                                ...
                                              </>
                                            ) : (
                                              item.description
                                            )}
                                          </p>
                                          {item && item.is_premium ? (
                                            <>
                                              <span className="d-inline-flex align-items-center font-smallest px-2 rounded bg-primary text-white">
                                                <img
                                                  src={
                                                    "/assets/images/premium-icon.svg"
                                                  }
                                                  alt="premium"
                                                  style={{
                                                    width: "15px",
                                                    height: "15px",
                                                  }}
                                                  className="mr-1"
                                                />
                                                Premium
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      </li>
                                    ) : (
                                      <li
                                        className="suggestion-detail d-flex mb-4 cursor-pointer"
                                        onClick={() => {
                                          item && item.is_premium
                                            ? this.handlePremiumRedirect(
                                                item && item.id
                                              )
                                            : this.routeHandler(
                                                item && item.id
                                              );
                                        }}
                                      >
                                        <div className="img">
                                          <img
                                            src={
                                              item && item.title_image
                                                ? item && item.title_image
                                                : "/assets/images/placeholder-recipe.png"
                                            }
                                            alt="suggestions-item"
                                            className="img-fit border-6 mr-4"
                                          />
                                        </div>
                                        <div className="right d-flex flex-column align-items-start">
                                          <h6>{item && item.title}</h6>
                                          <p className="info font-weight-semi font-smallest pt-1">
                                            {item.description.length > 75 ? (
                                              <>
                                                {item.description.substring(
                                                  0,
                                                  75
                                                )}
                                                ...
                                              </>
                                            ) : (
                                              item.description
                                            )}
                                          </p>
                                          {item && item.is_premium ? (
                                            <>
                                              <span className="d-inline-flex align-items-center font-smallest px-2 rounded bg-primary text-white">
                                                <img
                                                  src={
                                                    "/assets/images/premium-icon.svg"
                                                  }
                                                  alt="premium"
                                                  style={{
                                                    width: "15px",
                                                    height: "15px",
                                                  }}
                                                  className="mr-1"
                                                />
                                                Premium
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      </li>
                                    )}
                                  </div>
                                ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center flex-column justify-content-center">
                            <img
                              src={"/assets/images/novideos-home.png"}
                              alt=""
                              style={{ maxWidth: "200px" }}
                            />
                            <h4 className="text-danger mt-4">
                              No Videos to Show
                            </h4>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <>
                  {loading && !getVideoData ? (
                    <div style={{ margin: "50px auto 0", textAlign: "center" }}>
                      <ContentLoader color="text-warning" />
                    </div>
                  ) : (
                    <div className="recipe-desc-wrapper">
                      <CommentsRecipeTabs
                        details={getVideoData}
                        history={this.props.history}
                      />
                    </div>
                  )}
                </>
              </>
            ) : (
              <div className="text-center">
                <p className="text-danger-2 font-weight-semi">
                  Oops! No Video details found!
                </p>
              </div>
            )}
          </div>
        </Container>

        {/*  popup  */}
        <Modal
          size=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.LoginModal}
          onHide={this.LoginModalHandler}
          className="text-center signup-email-popup"
        >
          <Modal.Body className="delete-action-popup-content p-0 position-relative">
            <span
              onClick={this.LoginModalHandler}
              className="position-absolute close-modal-option"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
            {/* ------------------login screns--------------- */}
            <Login history={this.props.history} />

            {/* ------------login screens----------------- */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getVideoContentData: state.get_video_content_reducer.data,
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVideoContent: (id) => dispatch(get_video_by_id_action(id)),
  hitLike: (data) => dispatch(hit_like_action(data)),
  addBookmarkAction: (data) => dispatch(add_bookmark_to_post_action(data)),
  getSuggestedVideoContent: () => dispatch(get_all_suggested_videos_action()),
  getPricingDetails: () => dispatch(get_pricing_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVideo);
