import {uploadEpisodeConstants} from '../../_constants/Admin/uploadEpisode.constants'
const initialState = {}

export const get_episode_reducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadEpisodeConstants.GET_EPISODE_DETAILS_ACTION_SUCCESS:
            return {
                updatedShow: action.payload
            }
        case uploadEpisodeConstants.GET_EPISODE_DETAILS_ACTION_FAILURE:
            return {
                error: {
                    message: action.payload
                }
            }
        default:
            return state;
    }
}
