import React from 'react'

export default ({size, color}) => {
    return (
        <div className={color ? `spinner-border ${color}` : `spinner-border text-white`}
             role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    );
}