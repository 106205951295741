import React, { Component } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import ContactUs from "../HomePageNew/ContactUs/ContactUs";
import HeaderNew from "../HomePageNew/HeaderNew/HeaderNew";
import "./RecipeList.scss";
import {
  get_popular_recipes_content_action,
  get_newest_recipes_content_action,
  get_vegan_recipes_content_action,
  get_seasonal_recipes_content_action,
  get_recipes_category_titles_action
} from "../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
export class RecipeList extends Component {
  state = {
    recipeLoading: false,
    recipeData: [],
    loading: false,
    recipeCategoryTitles: [],
    topNotifications: [],
    pagination: {
      pageSize: 25,
      current: 1,
      total: 0
    }
  };

  componentDidMount() {
    this.getRecipesCategoriesTitles();
  }
  redirectRecipeHandler = (id) => {
    this.props.history.push({
      pathname: "/recipe-read-more",
      state: {
        recipeId: id
      }
    });
  };
  getRecipesCategoriesTitles = async () => {
    let data = await this.props.getRecipesCategoryTitles();
    this.setState({ recipeCategoryTitles: data && data.data });
    this.mountRecipeData();
  };

  mountRecipeData = async () => {
    const { pagination, recipeCategoryTitles } = this.state;
    this.setState({ recipeLoading: true });
    const pathName = this.props.location.pathname.split("/").pop();

    const getRecipeRank =
      recipeCategoryTitles &&
      recipeCategoryTitles.filter((item) => item.name === pathName)[0].rank;

    let getAllRecipeRes = {};

    switch (getRecipeRank) {
      case 1:
        getAllRecipeRes = await this.props.getNewestRecipes();
        break;
      case 2:
        getAllRecipeRes = await this.props.getPopularRecipes();
        break;
      case 3:
        getAllRecipeRes = await this.props.getSeasonalRecipes();
        break;
      case 4:
        getAllRecipeRes = await this.props.getVeganRecipes();
        break;
      default:
        return;
    }

    getAllRecipeRes && this.setState({ recipeData: getAllRecipeRes.data });
    this.setState({ recipeLoading: false });
  };

  onHandlePaginationChange = (current) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, current } }),
      () => {
        this.mountRecipeData();
      }
    );
  };

  render() {
    let { recipeData , pagination} = this.state;

    return (
      <div className="common-dashboard recipe-list-page-dashboard bg-white">
        <Container className="p-0">
          {/* <SideBarNew /> */}
          <div className="main-content">
            <HeaderNew history={this.props.history} activeMenuItem="recipes" />
            <div className="recipe-list">
              <h2 className="fs-48">Recipes</h2>
              <p className="fs-18 font-poppins text-dark-2 mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu,
                morbi nunc vivamus scelerisque semper nibh fringilla facilisis.
                In gravida malesuada suscipit eu.{" "}
              </p>

              {/* <PopularRecipe /> */}

              <Row className="my-5">
                {recipeData && recipeData.length ? (
                  <>
                    {recipeData &&
                      recipeData.map((recipe) => (
                        <Col md={4} className="mt-4">
                          <div className="recipe-wrapper border-radius-20  bg-grey-10">
                            <span className="recipe-img-wrapper bg-grey-2 d-flex justify-content-center align-items-center">
                              {recipe.title_image ? (
                                <img
                                  src={recipe.title_image}
                                  alt={recipe.title}
                                  className="tutotials-img w-100 img-fit img-fluid"
                                />
                              ) : (
                                <img
                                  src={"/assets/images/placeholder-recipe.jpg"}
                                  alt=""
                                  className="tutotials-img w-100 img-fit img-fluid"
                                />
                              )}
                            </span>
                            <div className="recipe-content-wrapper p-4 bg-grey-10">
                              <div className="top-section d-flex align-items-center justify-content-between">
                                <h4 className="recipe-title">{recipe.title}</h4>
                                <p className="fs-14 text-grey-4 font-weight-medium mb-0 text-nowrap ml-2">
                                  {recipe.updated.substring(0, 10)}
                                </p>
                              </div>
                              <p className="fs-16 text-dark-2 mt-3">
                                {recipe.description}
                              </p>
                              <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                                <span
                                  href="#"
                                  onClick={() =>
                                    this.redirectRecipeHandler(recipe.id)
                                  }
                                  className="btn btn-outline-purple rounded py-2 px-3"
                                >
                                  Read More
                                </span>
                                <div className="comment">
                                  <span>
                                    <i
                                      className="fa fa-commenting text-primary"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="fs-14 font-poppins font-weight-medium text-grey-4 pl-2">
                                    {recipe.comments_count}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </>
                ) : (
                  <>
                    <h2 className="text-danger text-center pl-3">No Recipes</h2>
                  </>
                )}
              </Row>

              <div className="pagination my-5 pb-5 d-flex justify-content-center align-items-center">
                {recipeData && recipeData.length ?
                  <Pagination
                    current={pagination.current}
                    showSizeChanger={false}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={this.onHandlePaginationChange}
                  /> : null
                }
              </div>
            </div>
            <ContactUs />
          </div>
        </Container>

        <div className="copyright">
          <div className="container">
            <p className="bg-primary text-white py-4 fs-20 font-weight-semi text-center font-poppins">
              © COPYRIGHT 2022. DARIUS COOKS TV
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUserSideRecipes: () => dispatch(get_popular_recipes_content_action()),
  getNewestRecipes: () => dispatch(get_newest_recipes_content_action()),
  getVeganRecipes: () => dispatch(get_vegan_recipes_content_action()),
  getSeasonalRecipes: () => dispatch(get_seasonal_recipes_content_action()),
  getPopularRecipes: () => dispatch(get_popular_recipes_content_action()),
  getRecipesCategoryTitles: () => dispatch(get_recipes_category_titles_action())
});

export default connect(null, mapDispatchToProps)(RecipeList);
