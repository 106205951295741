import React, { Component } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import Header from "../common/Header/Header";
import "./PremiumPlans.scss";
import { get_profile_content_action } from "../../../_actions/User";
import { get_pricing_action } from "../../../_actions/User";
import { connect } from "react-redux";
import swal from "sweetalert";
import * as PaymentActions from "../../../_actions/User/payment.action";

class PremiumPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      per_product: null,
      monthly: null,
      annual: null,
      purchasedProductLastMonth: 0,
    };
  }
  componentDidMount = async () => {
    let res = await this.props.purchasedProductLastMonth();
    let data = res && res.data && res.data.purchased_in_last_30_days;
    this.setState({ purchasedProductLastMonth: data });
    await this.getPricingDetailsData();
  };
  getPricingDetailsData = async () => {
    let data = await this.props.getPricingDetails();
    const perProductPrice =
      data &&
      data.data &&
      data.data.data &&
      data.data.data.filter(
        (price) => price && price.membership_type === "per_product"
      );
    const monthlyPrice =
      data &&
      data.data &&
      data.data.data &&
      data.data.data.filter(
        (price) => price && price.membership_type === "monthly"
      );
    const annualPrice =
      data &&
      data.data &&
      data.data.data &&
      data.data.data.filter(
        (price) => price && price.membership_type === "annual"
      );

    if (data && data.data && data.data.data) {
      this.setState({
        per_product:
          perProductPrice && perProductPrice[0] && perProductPrice[0].price,
        monthly: monthlyPrice && monthlyPrice[0] && monthlyPrice[0].price,
        annual: annualPrice && annualPrice[0] && annualPrice[0].price,
      });
    }
  };

  handleBuy = (plan) => {
    const { getProfileContentData } = this.props;
    let membership_type =
      getProfileContentData && getProfileContentData.membership_type;
    //check if user has already purchsed monthly plan() or not
    //if not
    if (!membership_type) {
      const { per_product, monthly, annual } = this.state;
      var plan = {
        plan: plan,
        amount:
          plan == "per_product"
            ? per_product
            : plan == "monthly"
            ? monthly
            : plan == "annual"
            ? annual
            : null,
      };
      localStorage.setItem("plan", JSON.stringify(plan));
      this.props.history.push("/payment");
    } else if (
      membership_type &&
      membership_type.membership &&
      membership_type.membership.membership_type == "monthly"
    ) {
      this.props.history.push("/user-plan-details");
    } else if (
      membership_type &&
      membership_type.membership &&
      membership_type.membership.membership_type == "annual"
    ) {
      this.props.history.push("/user-plan-details");
    } else {
      return;
    }
  };

  render() {
    const { per_product, monthly, annual, purchasedProductLastMonth } =
      this.state;
    const { getProfileContentData } = this.props;
    let membership_type =
      getProfileContentData && getProfileContentData.membership_type;
    let membership_expired =
      getProfileContentData && getProfileContentData.membership_expired;
   
    return (
      <div className="premium-plans-wrapper common-dashboard">
        <Container className="p-0">
          <SidebarMenu />
          <Header />

          <div className="premium-plans-content bg-white main-content">
            <a
              href="/user-profile"
              className="d-none d-md-flex align-items-center mt-4"
            >
              <img
                src="/assets/images/back-arrow.svg"
                alt="back"
                className="ml-n2"
              />
              <span className="text-dark fs-16 font-weight-bold ml-1">
                Back
              </span>
            </a>
            <h1 className="my-4">
              Premium plans for <br /> Premium Features
            </h1>

            {/* plan expired label indicator  */}
            {membership_expired == true ? (
              <span className="plan-expiry-info fs-18 bg-warning text-white d-inline-block rounded px-4 py-2">
                Your current plan has expired. Choose a plan to continue
              </span>
            ) : null}
            {/* plan expired info label indicator ends */}

            <Row className="mt-5">
              <Col lg={3} className="mb-2">
                <div
                  className="plan-box bg-grey rounded px-3 py-4  h-100 d-flex flex-column justify-content-between  cursor-pointer"
                  onClick={() => this.handleBuy("monthly")}
                >
                  <h1 className="mb-2">Monthly</h1>

                  <h1 className="text-primary">${monthly}</h1>
                </div>
              </Col>
              <Col lg={3} className="mb-2">
                <div
                  className="plan-box bg-grey rounded px-3 py-4 h-100 d-flex flex-column justify-content-between cursor-pointer"
                  onClick={() => this.handleBuy("annual")}
                >
                  <h1 className="mb-2">Annual</h1>
                  <h1 className="text-primary">${annual }</h1>
                </div>
              </Col>
              <Col lg={3} className="mb-2">
                <div className="plan-box bg-grey rounded px-3 py-4 h-100 d-flex flex-column justify-content-between">
                  <h1 className="mb-2">Per Episode</h1>
                  <h1 className="text-primary">${per_product}</h1>
                  {purchasedProductLastMonth >= 0 ? (
                    <h5>
                      *Bought {purchasedProductLastMonth} times in last 30 days
                    </h5>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getProfileContentData: state.get_profile_content_reducer.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  //getProfileContent: () => dispatch(get_profile_content_action()),
  purchasedProductLastMonth: () =>
    dispatch(PaymentActions.purchased_product_last_month_action()),
  getPricingDetails: () => dispatch(get_pricing_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPlans);
