import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./FoodGallery.scss";
import { get_gallery_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
export class FoodGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryContent: []
    };
  }

  componentDidMount() {
    this.getGalleryContentData();
  }
  getGalleryContentData = async () => {
    let data = await this.props.getGalleryContent();
    this.setState({ galleryContent: data?.data });
  };
  render() {
    let { galleryContent } = this.state;
    let imagesList = galleryContent && galleryContent.images;

    let rankOneImg =
      imagesList &&
      imagesList.filter((images) => images.rank === 1) &&
      imagesList.filter((images) => images.rank === 1)[0] &&
      imagesList.filter((images) => images.rank === 1)[0].image;
    let rankTwoImg =
      imagesList &&
      imagesList.filter((images) => images.rank === 2) &&
      imagesList.filter((images) => images.rank === 2)[0] &&
      imagesList.filter((images) => images.rank === 2)[0].image;
    let rankThreeImg =
      imagesList &&
      imagesList.filter((images) => images.rank === 3) &&
      imagesList.filter((images) => images.rank === 3)[0] &&
      imagesList.filter((images) => images.rank === 3)[0].image;
    let rankFourImg =
      imagesList &&
      imagesList.filter((images) => images.rank === 4) &&
      imagesList.filter((images) => images.rank === 4)[0] &&
      imagesList.filter((images) => images.rank === 4)[0].image;
    let rankFiveImg =
      imagesList &&
      imagesList.filter((images) => images.rank === 5) &&
      imagesList.filter((images) => images.rank === 5)[0] &&
      imagesList.filter((images) => images.rank === 5)[0].image;

    return (
      <div className="food-gallery my-5 py-4">
        <Container>
          <div className="food-gallery-content pr-5">
            <h1 className="">You Hungry? Let's make a snack!</h1>
            <p className="font-poppins fs-18 text-dark-2 my-4 pr-md-5 mr-md-5">
            {galleryContent && galleryContent.text}
            </p>
          </div>
          <div className="images-wrapper-outer d-flex mt-5">
            <div className="d-flex first-wrapper">
              <div className="img-1">
                <img
                  src={rankOneImg}
                  alt=""
                  className="img-fluid border-radius-20 w-100"
                />
              </div>
            </div>
            <div className="d-flex second-wrapper mx-0 mt-1 mt-md-0 mx-md-1">
              <div className="images-wrapper-inner ml-0 ml-lg-2">
                <div className="img-2 mt-2 mt-xsm-1 mt-md-0 ml-md-1 mr-2 mr-md-0 mr-lg-2">
                  <img
                    src={rankTwoImg}
                    alt=""
                    className="img-fluid border-radius-20 w-100"
                  />
                </div>
                <div className="img-3 mt-2 ml-0 ml-md-1 mr-lg-2">
                  <img
                    src={rankThreeImg}
                    alt=""
                    className="img-fluid border-radius-20 w-100"
                  />
                </div>
              </div>

              <div className="img-4 my-2 mx-lg-2 mr-md-1 mt-md-0 ml-1 ml-md-2">
                <img
                  src={rankFourImg}
                  alt=""
                  className="img-fluid border-radius-20 w-100"
                />
              </div>
              <div className="img-5 my-2 ml-lg-2 ml-1 mt-md-0 flex-grow-1">
                <img
                  src={rankFiveImg}
                  alt=""
                  className="img-fluid border-radius-20 w-100"
                />
              </div>
            </div>  
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGalleryContent: () => dispatch(get_gallery_content_action())
});

export default connect(null, mapDispatchToProps)(FoodGallery);
