import {livestreamConstants} from "../../_constants/User/livestream.constants";

const initialState = {}
export const get_current_livestream_playbackid_reducer = (state = initialState, action) => {
    switch (action.type) {
        case livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case livestreamConstants.GET_LIVESTREAM_PLAYBACKID_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}

/**viers count reducer */
export const get_livestream_viewers_count_reducer = (state = initialState, action) => {
    switch (action.type) {
        case livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload
            }
        case livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_SUCCESS:
            return {
                type: "alert-success",
                data: action.payload
            }
        case livestreamConstants.GET_LIVESTREAM_VIEWERS_COUNT_ACTION_FAILURE:
            return {
                type: "alert-failure",
                message: action.payload
            }
        default:
            return state;
    }
}