import React, { Component } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import "./ForgotPassword.scss";
import {forgot_password_user_action} from "../../../_actions/Common";
import {connect} from "react-redux";
import swal from "sweetalert";
class ForgotPassword extends Component {
    state = {
        email: ''
    }

    handleClick = async (e) => {
        e.preventDefault()
        const { email } = this.state
        const data = { email }
        const apiRes = await this.props.hitResetPasswordApi(data)
        if (apiRes) {
            await swal(apiRes.detail)
            this.props.history.push('/login')
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    render() {
        const { email } = this.state
        return (
            <div className="common-login forget-password-wrapper d-flex align-items-center jusfity-content-center">
                <Container className="p-0">

                    <div className="login-wrapper bg-white d-flex justify-content-between flex-md-nowrap flex-wrap">

                        <div className="form-wraper d-flex justify-content-center align-items-center order-2 order-md-1 py-5 mb-md-0  position-relative">

                            {/* desktop back button  */}
                            <button onClick={() => this.props.history.push('/login')} className="border-0 bg-transparent btn-back p-2 d-none d-md-inline-block">  <img src="/assets/images/back-btn.svg" alt="back-arrow" /></button>

                            <Form className="px-2 px-sm-0">

                                {/* mobile back button  */}
                                <button className="border-0 bg-transparent btn-back p-2 d-md-none d-inline-block">  <img src="/assets/images/back-btn.svg" alt="back-arrow" /></button>

                                <h2 className="form-title fs-24 font-poppins mb-3 mt-4 mt-md-0">Reset Password</h2>
                                <h5 className="mb-5">Enter the email address used to register your account</h5>
                                <Form.Group className='with-placeholder'>
                                    <Form.Control
                                        type="email"
                                        placeholder=" "
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <span className="placeholder-text fs-14">Email address</span>
                                </Form.Group>
                                <Form.Group className="mb-4 pt-2">
                                    <Button
                                        type="submit"
                                        onClick={this.handleClick}
                                        variant="warning"
                                        className="fs-14"
                                        block
                                    >
                                        Send Password Reset Link
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="display-img  order-1 order-md-2 d-none d-md-block">
                            <img src="/assets/images/set-password.svg" alt="login bg" />
                        </div>
                    </div>
                </Container>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    hitResetPasswordApi: (data) => dispatch(forgot_password_user_action(data))
})
export default connect(null, mapDispatchToProps)(ForgotPassword);