export const videoConstants = {
    GET_VIDEO_CONTENT_ACTION_REQUEST: 'GET_VIDEO_CONTENT_ACTION_REQUEST',
    GET_VIDEO_CONTENT_ACTION_SUCCESS: 'GET_VIDEO_CONTENT_ACTION_SUCCESS',
    GET_VIDEO_CONTENT_ACTION_FAILURE: 'GET_VIDEO_CONTENT_ACTION_FAILURE',
    
    GET_VIDEO_BY_ID_ACTION_REQUEST: 'GET_VIDEO_BY_ID_ACTION_REQUEST',
    GET_VIDEO_BY_ID_ACTION_SUCCESS: 'GET_VIDEO_BY_ID_ACTION_SUCCESS',
    GET_VIDEO_BY_ID_ACTION_FAILURE: 'GET_VIDEO_BY_ID_ACTION_FAILURE',

    GET_ALL_VIDEO_ACTION_REQUEST: 'GET_ALL_VIDEO_ACTION_REQUEST',
    GET_ALL_VIDEO_ACTION_SUCCESS: 'GET_ALL_VIDEO_ACTION_SUCCESS',
    GET_ALL_VIDEO_ACTION_FAILURE: 'GET_ALL_VIDEO_ACTION_FAILURE',


    GET_SUGGESTED_VIDEO_ACTION_REQUEST: 'GET_SUGGESTED_VIDEO_ACTION_REQUEST',
    GET_SUGGESTED_VIDEO_ACTION_SUCCESS: 'GET_SUGGESTED_VIDEO_ACTION_SUCCESS',
    GET_SUGGESTED_VIDEO_ACTION_FAILURE: 'GET_SUGGESTED_VIDEO_ACTION_FAILURE'
}
