export const homePageConstants = {
    GET_FEATURED_TOP_LEFT_VIDEO_ACTION_REQUEST: 'GET_FEATURED_TOP_LEFT_RECIPES_ACTION_REQUEST',
    GET_FEATURED_TOP_LEFT_VIDEO_ACTION_SUCCESS: 'GET_FEATURED_TOP_LEFT_RECIPES_ACTION_SUCCESS',
    GET_FEATURED_TOP_LEFT_VIDEO_ACTION_FAILURE: 'GET_FEATURED_TOP_LEFT_RECIPES_ACTION_FAILURE',
    
    GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_REQUEST: 'GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_REQUEST',
    GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_SUCCESS: 'GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_SUCCESS',
    GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_FAILURE: 'GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_FAILURE',

    GET_NEWEST_RECIPES_ACTION_REQUEST: 'GET_NEWEST_RECIPES_ACTION_REQUEST',
    GET_NEWEST_RECIPES_ACTION_SUCCESS: 'GET_NEWEST_RECIPES_ACTION_SUCCESS',
    GET_NEWEST_RECIPES_ACTION_FAILURE: 'GET_NEWEST_RECIPES_ACTION_FAILURE',
    
    GET_POPULAR_RECIPES_ACTION_REQUEST: 'GET_POPULAR_RECIPES_ACTION_REQUEST',
    GET_POPULAR_RECIPES_ACTION_SUCCESS: 'GET_POPULAR_RECIPES_ACTION_SUCCESS',
    GET_POPULAR_RECIPES_ACTION_FAILURE: 'GET_POPULAR_RECIPES_ACTION_FAILURE',

    GET_SEASONAL_RECIPES_ACTION_REQUEST: 'GET_SEASONAL_RECIPES_ACTION_REQUEST',
    GET_SEASONAL_RECIPES_ACTION_SUCCESS: 'GET_SEASONAL_RECIPES_ACTION_SUCCESS',
    GET_SEASONAL_RECIPES_ACTION_FAILURE: 'GET_SEASONAL_RECIPES_ACTION_FAILURE',

    GET_VEGAN_RECIPES_ACTION_REQUEST: 'GET_VEGAN_RECIPES_ACTION_REQUEST',
    GET_VEGAN_RECIPES_ACTION_SUCCESS: 'GET_VEGAN_RECIPES_ACTION_SUCCESS',
    GET_VEGAN_RECIPES_ACTION_FAILURE: 'GET_VEGAN_RECIPES_ACTION_FAILURE',

    GET_EXPERT_INFO_ACTION_REQUEST: 'GET_EXPERT_INFO_ACTION_REQUEST',
    GET_EXPERT_INFO_ACTION_SUCCESS: 'GET_EXPERT_INFO_ACTION_SUCCESS',
    GET_EXPERT_INFO_ACTION_FAILURE: 'GET_EXPERT_INFO_ACTION_FAILURE',

    GET_APP_INFO_ACTION_REQUEST: 'GET_APP_INFO_ACTION_REQUEST',
    GET_APP_INFO_ACTION_SUCCESS: 'GET_APP_INFO_ACTION_SUCCESS',
    GET_APP_INFO_ACTION_FAILURE: 'GET_APP_INFO_ACTION_FAILURE',
    
    GET_FEATURES_INFO_ACTION_REQUEST: 'GET_FEATURES_INFO_ACTION_REQUEST',
    GET_FEATURES_INFO_ACTION_SUCCESS: 'GET_FEATURES_INFO_ACTION_SUCCESS',
    GET_FEATURES_INFO_ACTION_FAILURE: 'GET_FEATURES_INFO_ACTION_FAILURE',

    GET_SOCIAL_MEDIA_INFO_ACTION_REQUEST: 'GET_SOCIAL_MEDIA_INFO_ACTION_REQUEST',
    GET_SOCIAL_MEDIA_INFO_ACTION_SUCCESS: 'GET_SOCIAL_MEDIA_INFO_ACTION_SUCCESS',
    GET_SOCIAL_MEDIA_INFO_ACTION_FAILURE: 'GET_SOCIAL_MEDIA_INFO_ACTION_FAILURE',

    GET_GALLERY_INFO_ACTION_REQUEST: 'GET_GALLERY_INFO_ACTION_REQUEST',
    GET_GALLERY_INFO_ACTION_SUCCESS: 'GET_GALLERY_INFO_ACTION_SUCCESS',
    GET_GALLERY_INFO_ACTION_FAILURE: 'GET_GALLERY_INFO_ACTION_FAILURE',

    GET_NEWSLETTER_INFO_ACTION_REQUEST: 'GET_NEWSLETTER_INFO_ACTION_REQUEST',
    GET_NEWSLETTER_INFO_ACTION_SUCCESS: 'GET_NEWSLETTER_INFO_ACTION_SUCCESS',
    GET_NEWSLETTER_INFO_ACTION_FAILURE: 'GET_NEWSLETTER_INFO_ACTION_FAILURE',


    GET_FREE_YOUTUBE_RECIPES_ACTION_REQUEST: 'GET_FREE_YOUTUBE_RECIPES_ACTION_REQUEST',
    GET_FREE_YOUTUBE_RECIPES_ACTION_SUCCESS: 'GET_FREE_YOUTUBE_RECIPES_ACTION_SUCCESS',
    GET_FREE_YOUTUBE_RECIPES_ACTION_FAILURE: 'GET_FREE_YOUTUBE_RECIPES_ACTION_FAILURE',

    
    GET_RECIPE_CATEGORIES_TITLES_ACTION_REQUEST: 'GET_RECIPE_CATEGORIES_TITLES_ACTION_REQUEST',
    GET_RECIPE_CATEGORIES_TITLES_ACTION_SUCCESS: 'GET_RECIPE_CATEGORIES_TITLES_ACTION_SUCCESS',
    GET_RECIPE_CATEGORIES_TITLES_ACTION_FAILURE: 'GET_RECIPE_CATEGORIES_TITLES_ACTION_FAILURE',

    GET_CATEGORIES_SECTION_TITLE_ACTION_REQUEST: 'GET_CATEGORIES_SECTION_TITLE_ACTION_REQUEST',
    GET_CATEGORIES_SECTION_TITLE_ACTION_SUCCESS: 'GET_CATEGORIES_SECTION_TITLE_ACTION_SUCCESS',
    GET_CATEGORIES_SECTION_TITLE_ACTION_FAILURE: 'GET_CATEGORIES_SECTION_TITLE_ACTION_FAILURE',


}
