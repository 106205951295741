import {homePageConstants} from "../../_constants/User";
import axiosInstance from "../../_helpers/axiosInstance";
import config from "../../Config/config";

// get featured to left content
export const get_featured_top_left_video_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_FEATURED_TOP_LEFT_VIDEO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.topLeftfeatured)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_FEATURED_TOP_LEFT_VIDEO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_FEATURED_TOP_LEFT_VIDEO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get featured top right recipes content
export const get_featured_top_right_recipes_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.topRightFeaturedRecipes)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_FEATURED_TOP_RIGHT_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get 
export const get_newest_recipes_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_NEWEST_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.newestRecipes)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_NEWEST_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_NEWEST_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
// get 
export const get_popular_recipes_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_POPULAR_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.popularRecipes)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_POPULAR_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_POPULAR_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get 
export const get_seasonal_recipes_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_SEASONAL_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.seasonalRecipes)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_SEASONAL_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_SEASONAL_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
// get 
export const get_vegan_recipes_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_VEGAN_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.veganReceipes)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_VEGAN_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_VEGAN_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
// get 
export const get_expert_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_EXPERT_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.meetExpert)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_EXPERT_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_EXPERT_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


// get 
export const get_app_info_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_APP_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.appInfo)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_APP_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_APP_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get 
export const get_features_info_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_FEATURES_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.features)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_FEATURES_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_FEATURES_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}

// get 
export const get_social_media_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_SOCIAL_MEDIA_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.socialMediaLinks)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_SOCIAL_MEDIA_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_SOCIAL_MEDIA_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}



// get 
export const get_gallery_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_GALLERY_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.imagesGallery)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_GALLERY_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_GALLERY_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}
// get 
export const get_newsletter_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_NEWSLETTER_INFO_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.abc)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_NEWSLETTER_INFO_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_NEWSLETTER_INFO_ACTION_FAILURE,
            payload: e.msg
        })
    }
}


export const get_free_youtube_content_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_FREE_YOUTUBE_RECIPES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.freeYoutubeRecipe)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_FREE_YOUTUBE_RECIPES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_FREE_YOUTUBE_RECIPES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}




export const get_recipes_category_titles_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_RECIPE_CATEGORIES_TITLES_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.getRecipesCategoryTitles)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_RECIPE_CATEGORIES_TITLES_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_RECIPE_CATEGORIES_TITLES_ACTION_FAILURE,
            payload: e.msg
        })
    }
}




// get get_categories_section_title_action
export const get_categories_section_title_action = () => async (dispatch) => {
    dispatch({
        type: homePageConstants.GET_CATEGORIES_SECTION_TITLE_ACTION_REQUEST
    })
    try {
        const response = await axiosInstance.get(config.homepageNew.recipeSectionTitle)
        if (response.status) {
            dispatch ({
                type: homePageConstants.GET_CATEGORIES_SECTION_TITLE_ACTION_SUCCESS,
                payload: response.data
            })
            return response.data
        } else {
            return false
        }
    } catch (e) {
        dispatch({
            type: homePageConstants.GET_CATEGORIES_SECTION_TITLE_ACTION_FAILURE,
            payload: e.msg
        })
    }
}