export const carouselConstants = {

    GET_ALL_DASHBOARD_ITEMS_ACTION_REQUEST: 'GET_ALL_DASHBOARD_ITEMS_ACTION_REQUEST',
    GET_ALL_DASHBOARD_ITEMS_ACTION_SUCCESS: 'GET_ALL_DASHBOARD_ITEMS_ACTION_SUCCESS',
    GET_ALL_DASHBOARD_ITEMS_ACTION_FAILURE: 'GET_ALL_DASHBOARD_ITEMS_ACTION_FAILURE',

    GET_ALL_CONTENT_ACTION_REQUEST: 'GET_ALL_CONTENT_ACTION_REQUEST',
    GET_ALL_CONTENT_ACTION_SUCCESS: 'GET_ALL_CONTENT_ACTION_SUCCESS',
    GET_ALL_CONTENT_ACTION_FAILURE: 'GET_ALL_CONTENT_ACTION_FAILURE',

    ADD_TO_DASHBOARD_ITEM_ACTION_REQUEST: 'ADD_TO_DASHBOARD_ITEM_ACTION_REQUEST',
    ADD_TO_DASHBOARD_ITEM_ACTION_SUCCESS: 'ADD_TO_DASHBOARD_ITEM_ACTION_SUCCESS',
    ADD_TO_DASHBOARD_ITEM_ACTION_FAILURE: 'ADD_TO_DASHBOARD_ITEM_ACTION_FAILURE',

}