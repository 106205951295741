import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./MeetExpert.scss";
import { get_expert_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";
import VideoPlayer from "../../common/VideoPlayer/VideoPlayer";

export class MeetExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expertInfoContent: [],
      playbackId: "",
      videoTrue: false
    };
  }
  componentDidMount = () => {
    this.getExpertContentData();
    this.playVideo();
  };

  playVideo = ()=>{

    setTimeout(() => {
      this.setState({videoTrue: true});
    }, 2000)
    
  }
  getExpertContentData = async () => {
    let data = await this.props.getExpertContent();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.expertSection !== this.props.expertSection) {
      this.setState(() => ({
        expertInfoContent:
          this.props &&
          this.props.expertSection &&
          this.props.expertSection.data,
        playbackId:
          this.props &&
          this.props.expertSection &&
          this.props.expertSection.data &&
          this.props.expertSection.data.video_playback_id
      }));
    }
  };
  render() {
    let { expertInfoContent, playbackId,videoTrue } = this.state;
    return (
      <div className="meet-expert my-5">
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <div className="pt-4 pt-md-0">
                <h2 className="fs-48 mb-3">Let’s meet the expert</h2>
                <p>{expertInfoContent && expertInfoContent.content}</p>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0 ">
              {videoTrue ? (
                <>
                  <VideoPlayer playbackId={playbackId}>
                  </VideoPlayer>
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    expertSection: state.get_expert_info_reducer.data
  };
};
const mapDispatchToProps = (dispatch) => ({
  getExpertContent: () => dispatch(get_expert_content_action())
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetExpert);
