import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ContactUs.scss";
import { get_social_media_content_action } from "../../../../_actions/User/homepageNew.action";
import { connect } from "react-redux";

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getMediaContent: []
    };
  }
  componentDidMount() {
    this.getMediaContentData();
   
  }
  getMediaContentData = async () => {
    let data = await this.props.getMediaContent();
    this.setState({ getMediaContent: data?.data });
  };
  render() {
    let { getMediaContent } = this.state;
    return (
      <div className="contact-us bg-grey-10">
        <Container>
          <Row>
            <Col md={6}>
              <div className="py-4 px-3 p-md-5">
                <h2 className="text-primary">DariusCooks</h2>
                <p className="fs-18 text-dark-2 font-poppins mt-4">
                  We provide you with the latest breaking news and videos
                  straight from the entertainment.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-3 p-md-5 mt-3 mt-md-0">
                <ul className="bottom-menu d-flex flex-wrap alignt-items-center fs-18 font-weight-semi font-poppins text-dark-2">
                  <li className="mr-5">
                    <a href="#"> Home </a>
                  </li>
                  <li className="mr-5">
                    <a href="/all-recipes"> Recipes </a>
                  </li>
                  <li className="mr-5">
                    <a href="/all-videos"> Videos </a>
                  </li>
                  <li className="mr-5">
                    <a href="/all-shows">Shows</a>
                  </li>
                </ul>
                <p className="font-poppins fs-18 font-weight-semi text-dark-2 mt-4">
                  Contact Us:{" "}
                  <a
                    href="mailto:info@dariuscooks.tv"
                    className="text-primary font-weight-400"
                  >
                    info@dariuscooks.tv
                  </a>
                </p>
                <ul className="social-media-icons mt-4 d-flex align-items-center">
                  <li>
                    <a href={getMediaContent && getMediaContent.facebook_link} className="d-inline-block" title="facebook">
                      <img
                        src="/assets/images/fb-icon-group.png"
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li className="ml-2 ml-lg-4">
                    {" "}
                    <a href={getMediaContent && getMediaContent.instagram_link} className="d-inline-block" title="instagram">
                      <img
                        src="/assets/images/insta-icon-group.png"
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li className="ml-2 ml-lg-4">
                    <a href={getMediaContent && getMediaContent.twitter_link} className="d-inline-block" title="twitter">
                      <img
                        src="/assets/images/twitter-icon-group.png"
                        alt="twitter"
                      />
                    </a>
                  </li>
                  <li className="ml-2 ml-lg-4">
                    <a href= {getMediaContent && getMediaContent.youtube_link} className="d-inline-block" title="youtube">
                      <img
                        src="/assets/images/youtube-icon-group.png"
                        alt="youtube"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMediaContent: () => dispatch(get_social_media_content_action())
});

export default connect(null, mapDispatchToProps)(ContactUs);
