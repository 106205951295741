import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ContactUs from "../HomePageNew/ContactUs/ContactUs";
import CopyRight from "../HomePageNew/CopyRight/CopyRight";
import HeaderNew from "../HomePageNew/HeaderNew/HeaderNew";
import SubscribeForUpdate from "../HomePageNew/SubscribeForUpdate/SubscribeForUpdate";
import "./RecipeReadMore.scss";
import { connect } from "react-redux";
import { get_recipe_by_id_action } from "../../../_actions/User";
export class RecipeReadMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipeId:
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.recipeId,
      recipeDetails: []
    };
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
    // this.getRecipe();
  }

  getRecipe = async () => {
        let data = await this.props.getRecipeContent(this.state.recipeId);
    this.setState({ recipeDetails: data.data });
  };
  render() {
    let { recipeDetails } = this.state;

    return (
      <div className="common-dashboard read-more-dashboard bg-white">
        <Container className="p-0">
          <div className="main-content">
            <HeaderNew  history={this.props.history} />
            <div className="read-more-about-recipe p-t-100 pb-5">
              <h2 className="mb-5 mt-3 fs-48">{recipeDetails.title}</h2>
              <div>
                {recipeDetails.title_image ? (
                  <img
                    src={recipeDetails.title_image}
                    alt={recipeDetails.title}
                    className="img-fit img-fluid w-100"
                  />
                ) : (
                  <img
                    src={"/assets/images/placeholder-recipe.jpg"}
                    alt=""
                    className="img-fit img-fluid w-100"
                  />
                )}
              </div>

              <div className="lists-wrapper fs-18">
                {recipeDetails &&
                  recipeDetails.connected_recipe &&
                  recipeDetails.connected_recipe.map((recipe) => (
                    <Row>
                      {recipe.ingredients.length ? (
                        <Col md={6} className="ingredients pt-4 mt-4 mt-md-0">
                          <h3 className="my-3">Ingredients:</h3>
                          <ul className="pl-3">
                            {recipe &&
                              recipe.ingredients &&
                              recipe.ingredients.map((ingredient) => (
                                <li className="d-flex align-items-center justify-content-between">
                                  <span>
                                    <span className="fa fa-circle font-smallest mr-2"></span>
                                    <span>{ingredient.name}</span>
                                  </span>
                                  <span classNamme="ml-2">
                                    <span className="mr-2">
                                      {ingredient.quantity}
                                    </span>
                                    <span>{ingredient.unit}</span>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </Col>
                      ) : null}
                      {recipe.notes ? (
                        <Col md={6} className="crust pt-4 mt-4 mt-md-0">
                          <h3 className="my-3">For the Crust:</h3>
                          <p>{recipe.notes}</p>
                        </Col>
                      ) : null}

                      {recipe.instructions ? (
                        <Col md={12} className="steps pt-4 mt-4 mt-md-0">
                          <h3 className="my-3">Instructions:</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: recipe.instructions
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  ))}
              </div>

              <div className="more-recipes-wrapper pt-4">
                <h2 className="fs-48 my-4">More Recipes</h2>

                <Row>
                  <Col md={4} className="mt-4 mt-md-0">
                    <div className="recipes-wrapper border-radius-20 bg-grey-10">
                      <span className="recipes-img-wrapper">
                        <img
                          src="/assets/images/tutorials-cover-img.png"
                          alt=""
                          className="tutotials-img img-fit img-fluid w-100"
                        />
                      </span>
                      <div className="tutorials-content-wrapper p-4">
                        <div className="top-section d-flex align-items-center justify-content-between">
                          <h3>Title</h3>
                          <p className="fs-14 text-grey-4 font-weight-medium mb-0">
                            August 13, 2020
                          </p>
                        </div>
                        <p className="fs-16 text-dark-2 mt-3">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc id blandit est morbi aenean aliquet gravida
                          nunc cursus. Quam proin odio in netus quam tempor
                          ultricies.
                        </p>
                        <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                          <Button className="btn btn-outline-purple rounded py-2 px-3">
                            Read More
                          </Button>
                          <div className="comment">
                            <span>
                              <i
                                className="fa fa-commenting text-primary"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="fs-14 font-poppins font-weight-medium text-grey-4 pl-2">
                              45
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="mt-4 mt-md-0">
                    <div className="recipes-wrapper border-radius-20 bg-grey-10">
                      <span className="recipes-img-wrapper">
                        <img
                          src="/assets/images/tutorials-cover-img.png"
                          alt=""
                          className="tutotials-img img-fit img-fluid w-100"
                        />
                      </span>
                      <div className="tutorials-content-wrapper p-4">
                        <div className="top-section d-flex align-items-center justify-content-between">
                          <h3>Title</h3>
                          <p className="fs-14 text-grey-4 font-weight-medium mb-0">
                            August 13, 2020
                          </p>
                        </div>
                        <p className="fs-16 text-dark-2 mt-3">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc id blandit est morbi aenean aliquet gravida
                          nunc cursus. Quam proin odio in netus quam tempor
                          ultricies.
                        </p>
                        <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                          <Button className="btn btn-outline-purple rounded py-2 px-3">
                            Read More
                          </Button>
                          <div className="comment">
                            <span>
                              <i
                                className="fa fa-commenting text-primary"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="fs-14 font-poppins font-weight-medium text-grey-4 pl-2">
                              45
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="mt-4 mt-md-0">
                    <div className="recipes-wrapper border-radius-20 bg-grey-10">
                      <span className="recipes-img-wrapper">
                        <img
                          src="/assets/images/tutorials-cover-img.png"
                          alt=""
                          className="tutotials-img img-fit img-fluid w-100"
                        />
                      </span>
                      <div className="tutorials-content-wrapper p-4">
                        <div className="top-section d-flex align-items-center justify-content-between">
                          <h3>Title</h3>
                          <p className="fs-14 text-grey-4 font-weight-medium mb-0">
                            August 13, 2020
                          </p>
                        </div>
                        <p className="fs-16 text-dark-2 mt-3">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nunc id blandit est morbi aenean aliquet gravida
                          nunc cursus. Quam proin odio in netus quam tempor
                          ultricies.
                        </p>
                        <div className="footer-section mt-4  d-flex align-items-center justify-content-between">
                          <Button className="btn btn-outline-purple rounded py-2 px-3">
                            Read More
                          </Button>
                          <div className="comment">
                            <span>
                              <i
                                className="fa fa-commenting text-primary"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="fs-14 font-poppins font-weight-medium text-grey-4 pl-2">
                              45
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/* <SubscribeForUpdate /> */}
            <ContactUs />
          </div>
        </Container>
        <CopyRight />
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  getRecipeContent: (id) => dispatch(get_recipe_by_id_action(id))
});

export default connect(null, mapDispatchToProps)(RecipeReadMore);
