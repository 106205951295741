import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import "./HelpAccordion.scss";

class HelpAccordion extends Component {
  state = {
    activeTab: 0,
  };

  toggleActive(index) {
    const updatedIndexState = { ...this.state, activeTab: index };
    this.setState(updatedIndexState);
  }
  render() {
    let faqData = this.props && this.props.data && this.props.data.data;
    return (
      <Accordion className="outer-tabs d-block d-md-none mt-4">
        {faqData &&
          faqData.map((data, index) => (
            <Card className="border-0 mb-4">
              <Card.Header
                className={
                  "py-3 px-3 border-6 " +
                  (this.state.activeTab === index ? "active" : "")
                }
              >
                <Accordion.Toggle
                  as={Button}
                  onClick={() => this.toggleActive(index)}
                  variant=" "
                  block
                  eventKey={`${data.id}`}
                  className="p-0 text-left"
                >
                  {data.topic} 
                </Accordion.Toggle>
              </Card.Header>
              {data.subtopics.map((subtopicsdata) => (
                <Accordion.Collapse eventKey={`${data.id}`}>
                  <Card.Body className="py-2">
                    <div className="inner-accordion">
                      <Accordion className="inner-accordion">
                        <Card className="border-0">
                          <Card.Header className="py-2">
                            <Accordion.Toggle
                              as={Button}
                              block
                              variant=""
                              eventKey={`${subtopicsdata.id}`}
                              className="p-0 text-left"
                            >
                              <h5 className="text-left">
                                {subtopicsdata.subtopic}
                                {subtopicsdata.id}
                              </h5>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse
                            eventKey={`${subtopicsdata.id}`}
                          >
                            <Card.Body className="py-2">
                              <div
                                className="topic-desc text-grey-4 font-weight-medium fs-14"
                                dangerouslySetInnerHTML={{
                                  __html: subtopicsdata.answer,
                                }}
                              ></div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              ))}
            </Card>
          ))}
      </Accordion>
    );
  }
}
export default HelpAccordion;
