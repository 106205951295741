import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return (
        <Route {...rest} render={props => (
            (user && user.user.role === 'user')
                ? <Component {...props} />
                : <Redirect to='/home-page-new'  />
        )} />
    )
}